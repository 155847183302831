import React, { Fragment, useState, useEffect } from 'react';
import { IconName, PearlIcon, IconSizes } from 'dbi-pearl-ui-kit';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

const ToastStatuses = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
};

const StyledSnackbarContent = styled(SnackbarContent)`
    cursor: pointer;

    &.${ToastStatuses.SUCCESS} {
        color: ${(props) => props.theme.colors.primary.contrast};
        background-color: ${(props) => props.theme.colors.success.base};
    }

    &.${ToastStatuses.ERROR} {
        color: ${(props) => props.theme.colors.primary.contrast};
        background-color: ${(props) => props.theme.colors.error.base};
    }

    &.${ToastStatuses.INFO} {
        color: ${(props) => props.theme.colors.foreground.text};
        background-color: ${(props) => props.theme.colors.background.base};
    }
`;

const PearlToast = (props) => {
    const { isOpen, message, status, closeToast } = props;

    const [open, setOpen] = useState(isOpen);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        closeToast();
    };

    const action = (
        <Fragment>
            <IconButton size="small" aria-label="close" color="inherit">
                <PearlIcon size={IconSizes.SM} iconName={IconName.CLOSE} />
            </IconButton>
        </Fragment>
    );

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            onClick={(event) => handleClose(event, 'click')}
        >
            <StyledSnackbarContent
                className={status || ToastStatuses.INFO}
                message={message}
                action={action}
            />
        </Snackbar>
    );
};

export { PearlToast, ToastStatuses };
