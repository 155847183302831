import { useDrag, useDrop } from 'react-dnd';
import React from 'react';
import { runInAction } from 'mobx';

const Draggable = (props) => {
    const { id, move, children } = props;

    const [, drag] = useDrag(() => ({
        type: 'ANY',
        item: { sourceId: id },
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            };
        },
    }));
    const [dropProps, drop] = useDrop(() => ({
        accept: 'ANY',
        collect: (monitor, props) => {
            return {
                isOver: !!monitor.isOver(),
                targetId: id,
            };
        },
        drop: (item, monitor) => {
            const source = monitor.getItem();
            if (source.sourceId !== dropProps.targetId) {
                runInAction(() => {
                    move(source.sourceId, dropProps.targetId);
                });
            }
        },
    }));

    return (
        <div
            ref={(el) => drag(drop(el))}
            className={dropProps.isOver ? 'dropTarget' : ''}
            style={{ position: 'relative' }}
        >
            {children}
        </div>
    );
};

export { Draggable };
