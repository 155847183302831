import styled from 'styled-components';

export default styled.div`
    height: 50px;

    .optionsList {
        background-color: ${(props) => props.theme.colors.background.container};
        border: 1px solid ${(props) => props.theme.colors.foreground.border};
        position: relative;
        z-index: 5;
    }
`;
