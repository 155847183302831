import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PearlIcon } from 'dbi-pearl-ui-kit';
import { AmenitiesServicesStyled } from './AmenitiesServices.styled';
import { SectionHeader } from '../SectionHeader/SectionHeader';

const removeSpaces = (text) => text.replaceAll(' ', '');

const AmenityItem = (props) => {
    const { name } = props;

    return (
        <li
            key={name}
            className="amenityDescription"
            data-testid={`amenity-description-${removeSpaces(name)}`}
        >
            <PearlIcon
                formFieldName={name}
                iconName="checkmark-circle"
                data-testid="check-mark-yes"
            />
            <p className="p2">{name}</p>
        </li>
    );
};

const AmenityCategory = ({ serviceType }) => {
    const serviceNames = serviceType.services.map((s) => s.name);

    return (
        <div>
            <label>
                <b>{serviceType.name}</b>
            </label>
            <ul
                key={serviceType.id}
                className="amenities"
                data-testid={`amenities-${removeSpaces(serviceType.name)}`}
            >
                {serviceNames.map((serviceName) => (
                    <AmenityItem name={serviceName} key={serviceName} />
                ))}
            </ul>
        </div>
    );
};

const AmenitiesServices = (props) => {
    const { webstore, sectionRef, sectionId, iconName, banner } = props;
    const { t } = useTranslation();

    const serviceCount = webstore?.ServiceTypes?.reduce((p, c) => p + c.services.length, 0) || 0;

    const [column1, setColumn1] = useState([]);
    const [column2, setColumn2] = useState([]);
    const [column3, setColumn3] = useState([]);

    useEffect(() => {
        if (serviceCount > 0) {
            const first = [];
            const second = [];
            const third = [];
            webstore.ServiceTypes.forEach((serviceType, index) => {
                if ((index + 1) % 3 === 0) {
                    third.push(serviceType);
                } else if ((index + 1) % 2 === 0) {
                    second.push(serviceType);
                } else {
                    first.push(serviceType);
                }
            });
            setColumn1(first);
            setColumn2(second);
            setColumn3(third);
        }
    }, [serviceCount, webstore.ServiceTypes]);

    return (
        <AmenitiesServicesStyled
            ref={sectionRef}
            id={sectionId}
            className="amenities-services-section"
        >
            <SectionHeader
                title={t('WebstoreSections.AmenitiesServices.Title')}
                iconName={iconName}
                banner={banner}
            />
            <div className="amenityListContainer" data-testid="amenity-list-container">
                {serviceCount === 0 ? (
                    <p data-testid="empty-amenities">{t('NoInformationProvided')}</p>
                ) : (
                    <div>
                        <div className="amenity-column">
                            {column1.length > 0 &&
                                column1.map((serviceType) => {
                                    return (
                                        <AmenityCategory
                                            serviceType={serviceType}
                                            key={serviceType.id}
                                        />
                                    );
                                })}
                        </div>
                        <div className="amenity-column">
                            {column2.length > 0 &&
                                column2.map((serviceType) => {
                                    return (
                                        <AmenityCategory
                                            serviceType={serviceType}
                                            key={serviceType.id}
                                        />
                                    );
                                })}
                        </div>
                        <div className="amenity-column">
                            {column3.length > 0 &&
                                column3.map((serviceType) => {
                                    return (
                                        <AmenityCategory
                                            serviceType={serviceType}
                                            key={serviceType.id}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                )}
            </div>
        </AmenitiesServicesStyled>
    );
};

export { AmenitiesServices };
