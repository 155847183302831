import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PearlApps } from 'framework';
import { APP_ROUTES } from 'shared/constants/navigation';
import { useAppName } from '../../hooks/AppName';
import { useStore } from '../../../stores/Store';
import { SiteLoader } from '../../components/SiteLoader/SiteLoader';
import { StorageService, StorageKeys } from '../../../lib/StorageService';
import { useAuthUserContext } from '../../../contexts/AuthUserContext';

function OAuthPage() {
    const { appName } = useAppName();
    const auth = useAuthUserContext();
    const navigate = useNavigate();
    const { eventUser, event, vendor } = useStore();
    const storageService = StorageService();

    const [isOAuthDone, setIsOAuthDone] = useState(false);

    useEffect(() => {
        const work = async () => {
            try {
                await auth.loginCallback();
                // intercept admin here
                if (appName === PearlApps.ADMIN) {
                    const internalCallbackUrl = storageService.pop(StorageKeys.internalCallbackUrl);
                    if (internalCallbackUrl?.startsWith(APP_ROUTES.admin.root)) {
                        navigate(internalCallbackUrl);
                    } else {
                        navigate(APP_ROUTES.admin.authError);
                    }
                }
            } finally {
                if (appName !== PearlApps.ADMIN) {
                    setIsOAuthDone(true);
                }
            }
        };

        work();
    }, []);

    useEffect(() => {
        const work = async () => {
            // everyone else gets handled here
            if (isOAuthDone) {
                await eventUser.Reaction;
                await event.Reaction;
                await vendor.Reaction;

                const internalCallbackUrl = storageService.pop(StorageKeys.internalCallbackUrl);
                if (internalCallbackUrl) {
                    navigate(internalCallbackUrl);
                    return;
                }

                if (appName === PearlApps.EVENT_USER) {
                    navigate(APP_ROUTES.dashboard);
                } else if (appName === PearlApps.VENDOR) {
                    if (vendor && vendor.id) {
                        navigate(APP_ROUTES.vendor.dashboard);
                    } else {
                        navigate(APP_ROUTES.vendor.auth.welcome);
                    }
                } else {
                    navigate(APP_ROUTES.landing);
                }
            }
        };

        work();
    }, [isOAuthDone]);

    return <SiteLoader />;
}

export { OAuthPage };
