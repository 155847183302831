import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const MobileFooterNavigationStyled = styled.div`
    display: none;

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        display: inline-flex;
        align-items: center;
        position: fixed;
        width: 100vw;
        bottom: 0;
        left: 0;
        z-index: 2;
        height: 69px;
        background-color: ${(props) => props.theme.colors.background.container};
        box-shadow: 0px -1px 8px rgba(19, 33, 34, 0.08);

        button {
            cursor: pointer;
        }

        .active {
            color: ${(props) => props.theme.colors.primary.base};

            .p4 {
                color: ${(props) => props.theme.colors.foreground.text};
                font-weight: 700;
            }
        }

        .icons {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;

            > div {
                width: 100%;
                height: 100%;
            }

            .p4 {
                margin-block: 0;
            }

            button {
                background-color: ${(props) => props.theme.colors.background.container};
                border: none;
                width: 100%;
                height: 100%;
                padding: 0;
            }
        }
    }
`;

export { MobileFooterNavigationStyled };
