import React from 'react';
import { IconName, LinkButton, PearlIcon, SolidButton } from 'dbi-pearl-ui-kit';
import PearlUnsavedChangesAlertStyled from './PearlUnsavedChangesAlert.styled';
import { useTranslation } from 'react-i18next';

const PearlUnsavedChangesAlert = (props) => {
    const { handleOnSave, handleDiscard } = props;
    const { t } = useTranslation();

    return (
        <PearlUnsavedChangesAlertStyled>
            {/* The <div> element has a child <button> element that allows keyboard interaction */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div className="pearlAlertContent">
                <PearlIcon iconName={IconName.WARNING} id="warning" />
                <h3>{t('UnsavedPrimary')}</h3>
                <p className={'p2'}>{t('UnsavedSecondary')}</p>
                <SolidButton id="save-button" onClick={handleOnSave}>
                    {t('UnsavedBtnPrimary')}
                </SolidButton>
                <br />
                <LinkButton id="back-button" variant="primary" onClick={handleDiscard}>
                    {t('UnsavedBtnSecondary')}
                </LinkButton>
            </div>
        </PearlUnsavedChangesAlertStyled>
    );
};

export default PearlUnsavedChangesAlert;
