const defaultCity = 'New York City';
const defaultState = 'NY';
const defaultCityLabel = `${defaultCity}, ${defaultState}`;

const topCities = [
    {
        label: defaultCityLabel,
        longitude: '-74.0059728',
        latitude: '40.7127753',
        urlFormat: 'newyork-ny',
    },
    { label: 'Chicago, IL', longitude: '-87.6297982', latitude: '41.8781136' },
    {
        label: 'San Diego, CA',
        longitude: '-117.1610838',
        latitude: '32.715738',
    },
    {
        label: 'Los Angeles, CA',
        longitude: '-118.2436849',
        latitude: '34.0522342',
    },
    {
        label: 'San Francisco, CA',
        longitude: '-122.4194155',
        latitude: '37.7749295',
    },
];
export default topCities;

export { defaultCity, defaultState, defaultCityLabel };
