import { IconName, LinkButton, PearlIcon } from 'dbi-pearl-ui-kit';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useIsScreenLarge } from 'framework';
import { PearlDropzoneStyled } from './PearlDropzone.styled';

export async function addImageMetaData(files) {
    const filesArray = [...files];

    return await Promise.all(
        filesArray.map((file) => {
            return new Promise((resolve, reject) => {
                try {
                    if (file instanceof File) {
                        const image = new Image();
                        image.onload = function () {
                            file.width = image.width;
                            file.height = image.height;
                            resolve(file);
                        };
                        image.src = URL.createObjectURL(file);
                    } else {
                        reject('Not a file');
                    }
                } catch (e) {
                    reject(e);
                }
            });
        })
    );
}

const PearlDropzone = (props) => {
    const { handleUpload, showFileTypes, maxFiles, desktopDropzoneTextTitle } = props;

    const { t } = useTranslation();
    const isScreenLarge = useIsScreenLarge();

    const onDrop = useCallback(
        async (acceptedFiles, fileRejections) => {
            await addImageMetaData(acceptedFiles);
            handleUpload(acceptedFiles, fileRejections);
        },
        [handleUpload]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/jpeg,image/png',
        maxFiles: maxFiles || 0,
        multiple: maxFiles !== 1,
    });

    const shouldShowFileTypes = showFileTypes !== false;

    return (
        <PearlDropzoneStyled data-testid="dropzone" className="dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            <PearlIcon iconName={IconName.UPLOAD} />
            <div className="dropzoneText">
                <b className="mobile">
                    {maxFiles === 1 ? t('Dropzone.UploadPhoto') : t('Dropzone.UploadPhotos')}
                </b>
                <p className="desktop p2">
                    {desktopDropzoneTextTitle ? desktopDropzoneTextTitle : t('Dropzone.DropFiles')}
                </p>
                <span className="desktop">{t('Dropzone.Or')}</span>
            </div>
            {isScreenLarge ? (
                <LinkButton variant={'accent'}>{t('Forms.Buttons.BrowseFiles')}</LinkButton>
            ) : null}
            {shouldShowFileTypes && <p className="p3">{t('Dropzone.AcceptedFileTypes')}</p>}
        </PearlDropzoneStyled>
    );
};

export { PearlDropzone };
