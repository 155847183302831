import styled from 'styled-components';

const SectionEditHeaderStyled = styled.div`
    width: 100%;

    .title {
        color: ${(props) => props.theme.colors.primary.base};
    }

    .subtitle {
        margin-block: 0;
        margin-top: 8px;
        margin-bottom: 32px;
    }

    .description {
        a {
            color: ${(props) => props.theme.colors.primary.base};
        }
    }
`;

export { SectionEditHeaderStyled };
