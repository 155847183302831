import { useStore } from 'stores/Store';

const useVendorContext = () => {
    const { vendor, appData } = useStore();

    const updateVendorName = (name) => {
        vendor.updateVendor({ vendorName: name });
    };

    const updateVendorUnreadMessageCount = (count) => {
        vendor.updateVendor({ unreadMessageCount: count });
    };

    const getCategoryByCategoryId = (id, includeArchived = false) => {
        const list = includeArchived ? appData.categoryListIncludeArchived : appData.categoryList;
        return list?.find((cat) => cat.id === id);
    };

    const getSubCategoryBySubCategoryId = (categoryId, subCategoryId) => {
        const cat = getCategoryByCategoryId(categoryId, true);
        return cat?.subCategories.find((x) => x.id === subCategoryId);
    };

    const getServiceTypesByCategoryId = (id) => {
        return getCategoryByCategoryId(id)?.serviceTypes || [];
    };

    const getServiceVibesByCategoryId = (id) => {
        return getCategoryByCategoryId(id)?.serviceVibes || [];
    };

    const getStylesByCategoryId = (id) => {
        return getCategoryByCategoryId(id)?.styles || [];
    };

    return {
        vendor,
        appData,
        updateVendorName,
        getCategoryByCategoryId,
        getSubCategoryBySubCategoryId,
        getServiceTypesByCategoryId,
        getStylesByCategoryId,
        updateVendorUnreadMessageCount,
        getServiceVibesByCategoryId,
    };
};

export { useVendorContext };
