import React from 'react';
import styled from 'styled-components';

const AccountSetupStepCounterStyled = styled.label`
    .steps {
        color: ${(props) => props.theme.colors.primary.base};
    }
`;

function AccountSetupStepCounter(props) {
    const { stepCountText, stepNameText } = props;

    return (
        <AccountSetupStepCounterStyled>
            <b>
                <span className="steps">{stepCountText}</span> {stepNameText}
            </b>
        </AccountSetupStepCounterStyled>
    );
}

export { AccountSetupStepCounter };
