import React, { useState } from 'react';
import styled from 'styled-components';
import { ChatText } from './ChatText';
import { CircularProgress } from '../../../../utility/Progress/CircularProgress';

const ChatImageStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    img {
        width: 100%;
        max-width: 360px;
        max-height: 360px;
    }

    &.loading {
        height: 360px;

        & > p:last-child {
            display: none;
        }
    }
`;

const ChatImage = ({ filename, url, caption, pending }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <ChatImageStyled
            className={`message-image ${url && !isLoaded ? 'loading' : ''} ${
                pending ? 'pending' : ''
            }`}
        >
            {!pending && <img src={url} alt={filename} onLoad={() => setIsLoaded(true)} />}
            {pending && <CircularProgress />}
            {caption && <ChatText>{caption}</ChatText>}
        </ChatImageStyled>
    );
};

export { ChatImage };
