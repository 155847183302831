import styled from 'styled-components';

export default styled.div`
    .locationText {
        padding: 5px;
        border: ${(props) => props.theme.colors.primary.base};
    }

    .checkboxContainer {
        label {
            padding-left: 0;
        }
    }
`;
