import * as Yup from 'yup';
import i18n from 'i18n';

const vendorBusinessDetailsFormSchemaWrapper = (categoryList, isBusinessName) => {
    const vendorBusinessDetailsFormSchema = Yup.object()
        .shape({
            WebstoreName: Yup.string()
                .max(
                    120,
                    i18n.t('Forms.Validation.CannotExceedCharacterLimit', {
                        characterLimit: '120',
                    })
                )
                .nullable()
                .required(
                    i18n.t('Forms.Validation.FieldRequired', {
                        field: isBusinessName
                            ? i18n.t('Forms.EditBasics.BusinessNameLabel')
                            : i18n.t('Forms.Labels.WebstoreName'),
                    })
                ),
            VendorCategoryId: Yup.string()
                .label(i18n.t('Forms.Labels.VendorCategory'))
                .nullable()
                .required(
                    i18n.t('Forms.Validation.FieldRequired', {
                        field: i18n.t('Forms.Labels.VendorCategory'),
                    })
                ),
            VendorSubCategoryIds: Yup.array()
                .label(i18n.t('Forms.Labels.VendorSubCategory'))
                .nullable()
                .min(1)
                .test(
                    'checkSubCategoriesBelongToCategory',
                    'invalid subcategories',
                    async function (value, options) {
                        let valid = true;
                        const selectedWebstoreId = Number(options.parent.VendorCategoryId);

                        if (selectedWebstoreId) {
                            const validSubcategoryIds = categoryList
                                .find((x) => x.id === selectedWebstoreId)
                                .subCategories.map((x) => x.id);

                            for (const v of value) {
                                if (validSubcategoryIds.indexOf(v) === -1) {
                                    valid = false;
                                    break;
                                }
                            }
                        } else {
                            valid = false;
                        }

                        return valid;
                    }
                )
                .required(
                    i18n.t('Forms.Validation.FieldRequired', {
                        field: i18n.t('Forms.Labels.VendorSubCategory'),
                    })
                ),
            Agree: Yup.bool().oneOf(
                [true],
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.SignupAgreement'),
                })
            ),
        })
        .required();

    return vendorBusinessDetailsFormSchema;
};

export { vendorBusinessDetailsFormSchemaWrapper };
