import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountSetupStepLayout, AccountSetupStepGraphic, AccountSetupStepFooter } from 'framework';
import { vendorBusinessSummaryFormSchema } from 'vendor/constants/schema/VendorBusinessSummaryFormSchema';
import { VendorBusinessSummaryFormStyled } from './VendorBusinessSummaryForm.styled';
import PearlForm from 'vendor/components/forms/PearlForm';
import { BusinessSummary, EmptyCaptions } from 'assets/Defaults';
import { BusinessSummarySection } from 'vendor/components/Webstore/BusinessSummarySection/BusinessSummarySection';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useWebstoreSetupWizard } from 'vendor/components/WebstoreSetup/WebstoreSetupContext';
import {
    useTealium,
    buildWebstoreSetupStepPageData,
    buildWebstoreSetupStepNextClickData,
} from '../../../../Tealium';
import { WebstoreSetupHeader } from '../WebstoreSetupHeader';

const formName = 'BusinessSummary';

const SummaryFooter = () => {
    const { onBack, progress, onSubmit, onSkip } = useWebstoreSetupWizard();

    const {
        watch,
        formState: { isDirty },
    } = useFormContext();

    const currentValue = watch();
    const isSkippable = !currentValue.BusinessSummary;
    const shouldPatch = isSkippable && isDirty;

    const functionToPatch = () => onSubmit(currentValue);
    const functionToSkip = () => onSkip();

    return (
        <AccountSetupStepFooter
            handleOnBack={() => onBack()}
            handleOnSkip={isSkippable ? (shouldPatch ? functionToPatch : functionToSkip) : null}
            progress={progress}
        />
    );
};

const VendorBusinessSummaryForm = () => {
    const { link, view } = useTealium();
    const { webstore } = useWebstoreContext();
    const { onSubmit, onReturnToBusinessLocations, currentStepCount, totalSteps } =
        useWebstoreSetupWizard();
    const { t } = useTranslation();

    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );

        view(tealiumData);
    }, []);

    const formOptions = {
        defaultValues: {
            BusinessSummary: webstore.BusinessSummary,
        },
    };

    const videoDetails = {
        image: BusinessSummary,
        altText: `Forms.${formName}.GifAltText`,
        captions: EmptyCaptions,
    };

    const handleOnSubmit = (data) => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepNextClickData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps,
            data,
            data
        );

        link(tealiumData);

        onSubmit(data);
    };

    return (
        <PearlForm
            StyledForm={VendorBusinessSummaryFormStyled}
            formName={formName}
            formOptions={formOptions}
            validationSchema={vendorBusinessSummaryFormSchema}
            onSubmit={handleOnSubmit}
        >
            <AccountSetupStepLayout
                header={
                    <WebstoreSetupHeader
                        onReturnToBusinessLocations={onReturnToBusinessLocations}
                        stepName={formName}
                        currentStepCount={currentStepCount}
                        totalSteps={totalSteps}
                    />
                }
                formComponent={<BusinessSummarySection />}
                image={<AccountSetupStepGraphic {...videoDetails} />}
                footer={<SummaryFooter />}
            />
        </PearlForm>
    );
};

export { VendorBusinessSummaryForm };
