import styled from 'styled-components';

const HamburgerMenuStyled = styled.div`
    border-radius: 0;

    #hamburger-open {
        border-radius: 0;
        background: #faf4ef;
        border-color: #f5e9df;
    }
`;

export { HamburgerMenuStyled };
