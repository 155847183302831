import * as Yup from 'yup';
import i18n from '../../../i18n';

const eventDateFormSchema = Yup.object()
    .shape({
        EventDate: Yup.string()
            .nullable()
            .when('IsFlexible', {
                is: false,
                then: Yup.string()
                    .nullable()
                    .required(i18n.t('Forms.Validation.EventUserEventDateForm.FormCantBeEmpty')),
            }),
        IsFlexible: Yup.bool().nullable().required(),
        EventTimeline: Yup.string()
            .nullable()
            .when('IsFlexible', {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required(
                        i18n.t(
                            'Forms.Validation.EventUserEventDateForm.FlexibleRequiredWhenTimeline'
                        )
                    ),
            }),
    })
    .required();
export { eventDateFormSchema };
