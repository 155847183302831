import { RouteGuard } from 'framework';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

// https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
function UserContextRouting({ context, nonAuthedRoute, routeGuards, ...rest }) {
    const location = useLocation();

    if (context.store.isAuthedEventUser) {
        if (routeGuards) {
            return (
                <RouteGuard guards={routeGuards} context={context}>
                    <Outlet store={{ context }} {...rest} />
                </RouteGuard>
            );
        }
        return <Outlet store={{ context }} {...rest} />;
    } else {
        const qs = location.search;
        const state = {
            redirect: location.pathname + qs,
        };
        return <Navigate {...rest} to={nonAuthedRoute} state={state} />;
    }
}

export { UserContextRouting };
