import { CryptoService } from '../CryptoService';

function manualPromise() {
    let resolve = null;
    let reject = null;
    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return { promise, resolve, reject };
}

function executeRemote(signature, code) {
    const { verify } = CryptoService();
    const verified = verify(signature, code);

    if (verified) {
        try {
            // eslint-disable-next-line no-eval
            eval(code);
        } catch (e) {
            console.log('error executing code', e);
        }
    }
}

class RealtimeClient {
    appName = null;
    socket = null;
    messageListeners = [];
    identity = null;
    identityCleanup = null;
    localLog = null;
    readyPromise = null;
    readyPromiseResolver = null;
    identifyPromise = null;
    identifyPromiseResolver = null;

    resetReady() {
        const { promise, resolve } = manualPromise();
        this.readyPromise = promise;
        this.readyPromiseResolver = resolve;
    }

    constructor(bypassLogInterceptor, appName) {
        this.resetReady();
        this.localLog = console.log;
        this.appName = appName;

        if (!bypassLogInterceptor) {
            // this.logInterceptor('log');
            // this.logInterceptor('warn');
            // this.logInterceptor('error');
            // this.logInterceptor('debug');
        }

        this.identityCleanup = this.listen((event) => {
            const data = JSON.parse(event.data);
            if (data.action === 'Identified') {
                this.identity = data.data;
                this.readyPromiseResolver();
            }
        });

        this.listen((message) => {
            const messageData = JSON.parse(message?.data || '{}');
            const action = messageData.action;
            const data = messageData.data;

            if (action === 'ExecuteRemote') {
                executeRemote(data.signature, data.code);
            }

            if (action === 'Identified' && this.identifyPromise && this.identifyPromiseResolver) {
                this.identifyPromiseResolver();
            }
        });
    }

    async sendMessage(action, data, uuid) {
        await this.identifyPromise;
        if (this.socket && this.socket.readyState === this.socket.OPEN) {
            if (!this.identity && action !== 'identify') {
                throw new Error(`Unable to use action ${action} before identify.`);
            }
            const message = {
                action,
                data,
                uuid,
            };
            this.socket.send(JSON.stringify(message));
        } else {
            setTimeout(() => {
                this.sendMessage(action, data, uuid);
            }, 100);
        }
    }

    listen(callback) {
        this.messageListeners.push(callback);

        return () => {
            this.messageListeners = this.messageListeners.filter((x) => x !== callback);
        };
    }

    logInterceptor(logType) {
        const originalLogger = console[logType].bind(console);

        console[logType] = (...args) => {
            originalLogger(...args);
            if (this.socket && this.socket.readyState === this.socket.OPEN) {
                try {
                    args.logType = logType;

                    const logTypes = {
                        [logType]: () => args,
                        log: () => ({ logs: [args] }),
                        warn: () => ({ warnings: [args] }),
                        error: () => ({ errors: [args] }),
                    };

                    const data = logTypes[logType]();

                    this.socket.send(
                        JSON.stringify({
                            action: 'log',
                            data: JSON.stringify(data),
                        })
                    );
                } catch (e) {
                    // Sink the error.  We can't console.log it because it might cause an infinite loop.
                    this.localLog('logInterceptor error:', e);
                }
            }
        };
    }

    connect(socketImpl, socketUrl, appName) {
        if (this.socket) {
            if (this.socket.readyState > 0) {
                this.socket.close();
            }
            this.socket = null;
        }
        this.socket = new socketImpl(socketUrl);

        this.socket.onopen = () => {
            this.localLog('socket opened.');

            if (this.identity) {
                this.identify(this.identity, appName);
            }
        };
        this.socket.onerror = (event) => {
            this.localLog('socket error:', event);
        };
        this.socket.onclose = () => {
            this.localLog('socket closed; attempting reconnect.');
            this.connect(socketImpl, socketUrl, appName);
        };
        this.socket.onmessage = (event) => {
            // this.localLog('socket message:', event);
            this.messageListeners.forEach((x) => {
                x(event);
            });
        };
    }

    async identify(identity, appName) {
        if (!appName) {
            throw new Error('Missing appName');
        }
        if (this.identity === identity && this.appName === appName) {
            return;
        }
        if (identity === null) {
            this.identity = null;
            this.resetReady();
        }

        this.appName = appName;

        const message = {
            action: 'identify',
            data: {
                jwt: identity,
                appName,
            },
        };

        return new Promise((resolve, reject) => {
            let retryCount = 0;

            const proxy = () => {
                try {
                    this.identifyPromise = new Promise((res) => {
                        this.identifyPromiseResolver = res;
                    });
                    if (this.socket && this.socket.readyState === this.socket.OPEN) {
                        this.socket.send(JSON.stringify(message));
                        resolve();
                    } else {
                        if (retryCount < 5) {
                            retryCount += 1;
                            setTimeout(proxy, 100);
                        } else {
                            reject({ code: 'SocketNotOpen' });
                        }
                    }
                } catch (err) {
                    reject(err);
                }
            };
            proxy();
        });
    }

    cleanup() {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
        this.messageListeners = [];
        if (this.identityCleanup) {
            this.identityCleanup();
        }
    }
}

export { RealtimeClient };
