import React from 'react';
import styled from 'styled-components';

const QuoteBodyStyled = styled.div`
    margin: 0;
    padding: 24px;
    background-color: ${(props) => props.theme.colors.background.base};

    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const QuoteBody = ({ children }) => (
    <QuoteBodyStyled className="quote-content">{children}</QuoteBodyStyled>
);

export { QuoteBody };
