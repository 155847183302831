const unwrapResponse = (res) => {
    if (res?.ok) {
        return res.data;
    } else {
        const cause = {
            status: res?.status,
            message: res?.data,
        };
        throw new Error('request error', { cause });
    }
};

export { unwrapResponse };
