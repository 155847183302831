import { APP_ROUTES } from 'shared/constants/navigation';

const RequireEnsureVendor = (context) => {
    // authed, but not vendor -> ensure
    // (get or create vendor)
    if (context.store.isAuthed && !context.store.isAuthedVendor) {
        return APP_ROUTES.vendor.ensureVendor;
    }
    return 'next';
};

export { RequireEnsureVendor };
