import styled from 'styled-components';

const HamburgerUserMenuStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: min-content;
    color: ${(props) => props.theme.colors.foreground.text};
    background-color: transparent;
    padding: 0 24px;
    border-top: 1px solid ${(props) => props.theme.colors.background.base};
    align-items: start;
    width: auto;

    .non-authed-user-menu {
        .user-menu-header {
            h4 {
                margin-bottom: 0;
            }
            p {
                margin-top: 6px;
            }
        }
        .login-section-wrapper {
            display: flex;
            justify-content: center;
            .p1 {
                display: inline-flex;
                align-items: center;
            }
        }
        .sign-up-button {
            width: 100%;
            margin-top: 18px;
        }
        .hamburger-user-menu-login-button {
            padding-bottom: 0;

            label {
                padding-bottom: 0px;
                margin-left: 8px;
            }
            b {
                text-transform: lowercase;
            }
        }
    }

    .greeting {
        word-break: break-word;
        padding-bottom: 16px;
    }
`;

export { HamburgerUserMenuStyled };
