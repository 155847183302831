import styled from 'styled-components';

export default styled.div`
    .flagged-image {
        text-align: center;
    }

    .icon-warning {
        color: ${(props) => props.theme.colors.error.base};
        font-weight: bold;
        font-size: xx-large;
    }

    .flagged-icon {
        margin-top: 3px;
    }

    .flagged-header {
        margin-top: 3px;
    }

    .flagged-text {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .flagged-contact {
        padding-bottom: 1px;
        border-bottom: solid 2px ${(props) => props.theme.colors.foreground.base};
    }

    .flagged-header,
    .flagged-text,
    .flagged-contact {
        color: ${(props) => props.theme.colors.foreground.base};
    }

    .original-image {
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
    }

    .blur {
        position: relative;
        background-color: #ffffffcc;
        padding-top: 20px;
    }

    @media (max-width: 390px) {
        .icon-warning {
            font-size: large;
        }
        .flagged-icon {
            margin-top: 0;
        }
        .flagged-header {
            font-size: 12px;
            margin-top: 0;
        }
        .flagged-text {
            font-size: 10px;
            line-height: 12px;
            margin-top: 0 !important;
        }
        .flagged-contact b {
            font-size: 9px;
            margin-top: 0;
            line-height: 0;
        }
    }

    @media (min-width: 391px) and (max-width: 815px) {
        .icon-warning {
            font-size: x-large;
        }
        .flagged-icon {
            margin-top: 1px;
        }
        .flagged-header {
            font-size: 13px;
        }
        .flagged-text {
            font-size: 13px;
        }
        .flagged-contact b {
            font-size: 10px;
        }
    }
`;
