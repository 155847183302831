import React from 'react';
import styled from 'styled-components';
import { NewTabLink } from '../../../../utility/NewTabLink';
import { ChatText } from './ChatText';

const ChatAttachmentStyled = styled.div`
    p {
        margin: 0;
        padding: 0;
    }

    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ChatAttachment = ({ filename, url, caption, pending }) => (
    <ChatAttachmentStyled className="p2 message-attachment">
        <p className="p2">
            Attachment: {pending ? filename : <NewTabLink link={url}>{filename || url}</NewTabLink>}
        </p>
        {caption && <ChatText>{caption}</ChatText>}
    </ChatAttachmentStyled>
);

export { ChatAttachment };
