import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { VendorPriceTierFormStyled } from './VendorPriceTierForm.styled';
import { PriceTierSection } from '../../Webstore/PriceTierSection/PriceTierSection';
import PearlForm from 'vendor/components/forms/PearlForm';
import { PriceTier, EmptyCaptions } from 'assets/Defaults';
import { AccountSetupStepLayout, AccountSetupStepGraphic, AccountSetupStepFooter } from 'framework';
import { useWebstoreSetupWizard } from 'vendor/components/WebstoreSetup/WebstoreSetupContext';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import {
    useTealium,
    buildWebstoreSetupStepPageData,
    buildWebstoreSetupStepNextClickData,
} from '../../../../Tealium';
import { WebstoreSetupHeader } from '../WebstoreSetupHeader';

const formName = 'PriceTier';

function Footer(props) {
    const { handleOnSubmit } = props;
    const { onBack, onSkip, progress } = useWebstoreSetupWizard();

    const {
        watch,
        formState: { isDirty },
    } = useFormContext();

    const selectedPriceTier = watch('SelectedPriceTier');
    const isSkippable = !selectedPriceTier;
    const shouldPatch = isSkippable && isDirty;

    return (
        <AccountSetupStepFooter
            handleOnBack={() => onBack()}
            handleOnSkip={
                isSkippable ? (shouldPatch ? (data) => handleOnSubmit(data) : () => onSkip()) : null
            }
            progress={progress}
        />
    );
}

const VendorPriceTierForm = () => {
    const { link, view } = useTealium();
    const { webstore } = useWebstoreContext();
    const { onSubmit, onReturnToBusinessLocations, currentStepCount, totalSteps } =
        useWebstoreSetupWizard();
    const { t } = useTranslation();

    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );

        view(tealiumData);
    }, []);

    const videoDetails = {
        image: PriceTier,
        altText: `Forms.${formName}.GifAltText`,
        captions: EmptyCaptions,
    };

    // react-hook-form seems to work with undefined, but prefers null according to the docs
    const defaultValue = webstore.SelectedPriceTier?.toString() ?? null;

    const formOptions = {
        defaultValues: { SelectedPriceTier: defaultValue },
    };

    const handleOnSubmit = (data) => {
        data = {
            SelectedPriceTier: parseInt(data.SelectedPriceTier),
        };

        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepNextClickData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps,
            data,
            data
        );

        link(tealiumData);

        onSubmit(data);
    };

    return (
        <PearlForm
            formOptions={formOptions}
            StyledForm={VendorPriceTierFormStyled}
            formName={formName}
            onSubmit={(data) => handleOnSubmit(data)}
        >
            <AccountSetupStepLayout
                header={
                    <WebstoreSetupHeader
                        onReturnToBusinessLocations={onReturnToBusinessLocations}
                        stepName={formName}
                        currentStepCount={currentStepCount}
                        totalSteps={totalSteps}
                    />
                }
                formComponent={<PriceTierSection defaultTierValue={defaultValue} />}
                image={<AccountSetupStepGraphic {...videoDetails} />}
                footer={<Footer handleOnSubmit={handleOnSubmit} />}
            />
        </PearlForm>
    );
};

export { VendorPriceTierForm };
