import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';
const AboutSectionStyled = styled.div`
    .desktopOnly {
        display: block;
    }

    .row {
        display: flex;
        width: 100%;
    }

    .vertical-break {
        margin: 13px 24px;
        min-width: 1px;
        background-color: ${(props) => props.theme.colors.foreground.border};
    }

    .item {
        display: flex;
    }

    .item .icon-wrapper {
        margin: auto 16px auto 0;
    }

    .city-name {
        text-transform: capitalize;
    }

    .state-abbreviation {
        text-transform: uppercase;
    }

    .right-content {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        gap: 24px;
        width: 100%;
        word-break: break-word;
    }

    .label {
        color: ${(props) => props.theme.colors.primary.base};
        white-space: nowrap;
    }

    .p1 {
        flex-grow: 2;
        white-space: break-spaces;
        word-break: break-word;
        width: auto;
        min-width: 70%;
    }

    .links {
        flex-basis: 100%;
        display: flex;
        align-content: space-between;
        gap: 15px;
        flex-wrap: wrap;
    }

    .links.desktop {
        margin-top: 24px;
    }

    .mobile {
        display: none;
    }

    button.p1 {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        color: ${(props) => props.theme.colors.primary.base};
    }

    .p1 .expandButton {
        display: flex;
        justify-content: flex-start;
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .row {
            flex-direction: column;
            align-content: space-between;
            gap: 24px;
        }

        .desktopOnly {
            display: none;
        }

        .p1 {
            order: 1;
        }

        .links {
            order: 2;
            flex-basis: auto;
        }

        .right-content {
            order: 3;
        }

        .vertical-break {
            display: none;
        }

        .links.mobile {
            display: flex;
        }

        .links.desktop {
            display: none;
        }

        .p1 .mobile {
            display: inline;
            margin-left: 8px;
        }
    }
`;

export { AboutSectionStyled };
