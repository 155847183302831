import styled from 'styled-components';

const PearlDropzoneStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 16px;
    border: 2px dashed ${(props) => props.theme.colors.foreground.border};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
        0px 0px 1px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;

    .dropzoneText {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 8px;

        .desktop {
            display: initial;
        }

        .mobile {
            display: none;
        }

        @media (max-width: 815px) {
            .desktop {
                display: none;
            }

            .mobile {
                display: initial;
            }
        }
    }

    .p3 {
        margin-bottom: 0;
    }
`;

export { PearlDropzoneStyled };
