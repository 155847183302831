import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const UnauthedLayoutStyled = styled.div`
    display: grid;
    grid-template-columns: 35% 65%;
    height: 100%;
    width: 100%;
    position: relative;

    .contentContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0 16px;
    }

    .rightSide {
        flex: 2 1 0;
        display: flex;
        flex-direction: column;
        background-color: ${(props) => props.theme.colors.background.container};
    }

    .contentHeader {
        padding-left: 120px;
        padding-right: 120px;
        width: auto;

        @media (max-width: 1200px) {
            padding-left: 16%;
            padding-right: 16%;
        }

        @media (max-width: 815px) {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 20px;
        }
    }

    .image {
        width: 100%;
        position: relative;
        display: flex;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .loginButton {
        max-width: 400px;
        width: 70%;
        margin-top: 50px;
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        display: grid;
        grid-template-rows: 32% auto;
        grid-template-columns: unset;
        height: calc(100vh - 248px);

        .image {
            height: 100%;
            flex: 1 0 auto;
            width: 100%;
            max-width: unset;
        }

        .stepHeader {
            padding-top: 16px;
        }

        .contentContainer {
            justify-content: center;
        }

        .loginButton {
            width: 100%;
            margin-top: 25px;
        }
    }
`;

export { UnauthedLayoutStyled };
