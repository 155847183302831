import { Capacitor } from '@capacitor/core';
import { StatusBarStyled } from './StatusBar.styled';

const StatusBar = () => {
    const statusBarComponent = Capacitor.isNativePlatform() ? (
        <div className="status-bar"></div>
    ) : (
        <></>
    );

    return <StatusBarStyled>{statusBarComponent}</StatusBarStyled>;
};

export { StatusBar };
