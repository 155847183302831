import * as Yup from 'yup';
import i18n from 'i18n';

const vendorCapacityFormSchema = Yup.object().shape({
    Capacity: Yup.number()
        .positive(i18n.t('Forms.Capacity.MinCapacityError'))
        .integer(i18n.t('Forms.Capacity.InvalidCharactersError'))
        .typeError(i18n.t('Forms.Capacity.InvalidCharactersError')),
});

export { vendorCapacityFormSchema };
