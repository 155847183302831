import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledWebstoreBasics } from './WebstoreBasics.styled';
import { getServiceArea } from '../../../lib/WebstoreAddressService/WebstoreAddressService';
import { NewTabLink } from 'framework/components/utility/NewTabLink';

const WebstoreBasics = (props) => {
    const { webstore, controls, onClaimClick, sectionRef } = props;

    const { t } = useTranslation();

    const onlineOnlyWebstore = !webstore.PhysicalStore && !webstore.Travels && webstore.OnlineStore;

    const webstoreServiceArea = getServiceArea(webstore);

    const isCYB = webstore.IsCYB;

    return (
        <StyledWebstoreBasics id="webstore-basics" data-testid="webstore-basics">
            <div>
                <div className="textContainer">
                    <div className="basics-header">
                        <h1 ref={sectionRef}>{webstore.WebstoreName}</h1>
                        <div className="basics-controls">{controls}</div>
                    </div>
                    <div className="subtitle">
                        <p className="p1">{webstore.VendorCategoryName}</p>
                        {(onlineOnlyWebstore || webstoreServiceArea) && (
                            <>
                                <p className="p2 separator">
                                    <b>.</b>
                                </p>
                                {onlineOnlyWebstore ? (
                                    <p className="p1">{t('WebstoreSections.About.OnlineOnly')}</p>
                                ) : (
                                    webstoreServiceArea && (
                                        <p className="p1">
                                            <span className="city-name">
                                                {webstoreServiceArea.city},{' '}
                                            </span>
                                            <span className="state-abbreviation">
                                                {webstoreServiceArea.state}
                                            </span>
                                        </p>
                                    )
                                )}
                            </>
                        )}
                        {!!isCYB && !!onClaimClick && (
                            <>
                                <p className="p2 separator">
                                    <b>.</b>
                                </p>
                                <p className="p1" data-testid="CYB-link">
                                    <NewTabLink className="CYBLink" onClick={onClaimClick}>
                                        {t('Pages.VendorDetailsPage.ClaimYourBusiness')}
                                    </NewTabLink>
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </StyledWebstoreBasics>
    );
};

WebstoreBasics.propTypes = {
    /**
     * Webstore object - placeholder
     */
    webstore: PropTypes.object,
};

WebstoreBasics.displayName = 'WebstoreBasics';

export default WebstoreBasics;
