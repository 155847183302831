import styled from 'styled-components';

const ChatTimestampStyled = styled.p`
    margin: 8px 0 0;
    padding: 0;

    &.message-sent {
        text-align: right;
    }
`;

export { ChatTimestampStyled };
