import PropTypes from 'prop-types';
import { PearlIcon, IconName } from 'dbi-pearl-ui-kit';
import SearchOptionListStyled from './SearchOptionList.styled';

const Option = (props) => {
    const { option, handleClick, optionType } = props;

    const label = option.displayText;
    const imageSrc = option.imageUrl ? option.imageUrl : null;
    const defaultOptionType = optionType ? optionType : 'submit';
    return (
        <button
            className="option"
            data-testid="option"
            aria-describedby={label}
            onClick={() => handleClick(option)}
            type={defaultOptionType}
        >
            <div className="option-image">
                {imageSrc && <img src={imageSrc} alt="icon" height="50px" width="50px" />}
                {!imageSrc && (
                    <div className="image-wrapper">
                        <PearlIcon iconName={IconName.MAP_MARKER_OUTLINE} />
                    </div>
                )}
            </div>
            <div className="option-label">{label}</div>
        </button>
    );
};

export default function SearchOptionList(props) {
    const { options, updateSearch, optionType } = props;

    return (
        <SearchOptionListStyled>
            {options.map((option, index) => (
                <div key={index}>
                    <Option option={option} handleClick={updateSearch} optionType={optionType} />
                </div>
            ))}
        </SearchOptionListStyled>
    );
}

SearchOptionList.propTypes = {
    options: PropTypes.array,
    updateSearch: PropTypes.func,
};
