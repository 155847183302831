import { IconName, ToggleButton, ToggleButtonGroup } from 'dbi-pearl-ui-kit';
import React, { useState, useEffect } from 'react';
import { useWindowSize } from 'vendor/lib/ScreenSize';
import { useVendorContext } from 'vendor/contexts/VendorContext';
import { AmenitiesAndServicesSectionStyled } from './AmenitiesAndServicesSection.styled';
import { Accordion } from 'vendor/components/Utility/Accordion/Accordion';

// re-construct toggleGroup style data structure into
// webstore-matching data structure
const getPatchableData = (selections, serviceTypeOptions) => {
    const serviceTypes = [];

    Object.keys(selections)?.forEach((key) => {
        if (selections[key]?.length > 0) {
            const selectedIds = selections[key].map((s) => parseInt(s));
            const option = serviceTypeOptions.find((sto) => sto.name === key);

            const serviceType = {
                id: option.id,
                name: option.name,
                services: [
                    ...option.services
                        .filter((s) => selectedIds.includes(s.id))
                        .map((s) => ({ id: s.id, name: s.name })),
                ],
            };

            serviceTypes.push(serviceType);
        }
    });

    return serviceTypes;
};

const AmenitiesAndServicesSection = (props) => {
    const { webstore, enableExpandedView, onChange } = props;

    const size = useWindowSize();

    const { getServiceTypesByCategoryId } = useVendorContext();

    const serviceTypeOptions = getServiceTypesByCategoryId(parseInt(webstore.VendorCategoryId));

    const initialSelectedServices = {};

    // construct initialSelectedServices structure to match
    // structure returned by toggleGroup change handler
    serviceTypeOptions.forEach((sto) => {
        initialSelectedServices[sto.name] =
            webstore.ServiceTypes?.find((st) => st.id === sto.id)?.services?.map((s) =>
                s.id.toString()
            ) || [];
    });

    const [selectedServices, setSelectedServices] = useState(initialSelectedServices);

    const handleButtonClick = (toggleGroupName, toggleValues) => {
        setSelectedServices({
            ...selectedServices,
            [toggleGroupName]: toggleValues,
        });
    };

    useEffect(() => {
        const patchableData = getPatchableData(selectedServices, serviceTypeOptions);
        onChange(patchableData);
    }, [selectedServices, serviceTypeOptions, onChange]);

    const expandedServiceTypeComponents = serviceTypeOptions.map((serviceTypeOption, index) => {
        return (
            <div key={serviceTypeOption.id} className={`tag-group-wrapper tag-group-${index}`}>
                <label className="tag-group-label">
                    <b>{serviceTypeOption.name}</b>
                </label>
                <div className="toggle-group-helper">
                    <ToggleButtonGroup
                        groupName={serviceTypeOption.name}
                        selectedGroupItems={selectedServices[serviceTypeOption.name]}
                        onChange={handleButtonClick}
                    >
                        {serviceTypeOption.services?.map((service) => {
                            return (
                                <ToggleButton
                                    key={service.id}
                                    value={`${service.id}`}
                                    label={service.name}
                                    iconName={IconName.CHECKMARK}
                                />
                            );
                        })}
                    </ToggleButtonGroup>
                </div>
            </div>
        );
    });

    const accordionServiceTypeComponents = serviceTypeOptions.map((serviceTypeOption) => {
        return (
            <Accordion
                key={serviceTypeOption.id}
                accordionName={`${serviceTypeOption.id}`}
                label={serviceTypeOption.name}
                open={true}
            >
                <ToggleButtonGroup
                    groupName={serviceTypeOption.name}
                    selectedGroupItems={selectedServices[serviceTypeOption.name]}
                    onChange={handleButtonClick}
                >
                    {serviceTypeOption.services.map((service) => {
                        return (
                            <ToggleButton
                                key={service.id}
                                value={`${service.id}`}
                                label={service.name}
                                iconName={IconName.CHECKMARK}
                            />
                        );
                    })}
                </ToggleButtonGroup>
            </Accordion>
        );
    });

    return (
        <AmenitiesAndServicesSectionStyled>
            {size.width >= 815 && enableExpandedView
                ? expandedServiceTypeComponents
                : accordionServiceTypeComponents}
        </AmenitiesAndServicesSectionStyled>
    );
};

AmenitiesAndServicesSection.defaultProps = {
    onChange: () => {},
};

export { AmenitiesAndServicesSection };
