import { setAppName } from '../../../shared/hooks/AppName';
import { PearlApps } from '../../../framework';
import { useAuthUserContext } from '../../../contexts/AuthUserContext';
import { IdentityProviders } from '../../../lib/apis/TokenService';
import { useLocation } from 'react-router-dom';
import { StorageKeys, StorageService } from '../../../lib/StorageService';

const EventUserSignupRedirect = () => {
    const location = useLocation();
    const storageService = StorageService();
    if (location.state?.redirect) {
        storageService.set(StorageKeys.internalCallbackUrl, location.state.redirect);
    }
    const authUserContext = useAuthUserContext();
    setAppName(PearlApps.EVENT_USER);
    authUserContext.login(IdentityProviders.dbiSso);
    return <div data-testid="redirect-event-user">Redirecting...</div>;
};

export default EventUserSignupRedirect;
