import { useCallback } from 'react';

export function scrollWithOffset(el, offsetAmount = -150, addToHistory = true) {
    let elementToScrollTo;
    // accepts an id or an element
    if (typeof el === 'string') {
        elementToScrollTo = document.getElementById(el);
    } else {
        elementToScrollTo = el;
    }

    if (!!addToHistory) {
        window.history.pushState(null, null, '#' + el);
    }

    const yCoordinate = elementToScrollTo.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({
        top: yCoordinate + offsetAmount,
        behavior: 'smooth',
    });
}

export function scrollByWithOffset(el, offsetAmount = 0, addToHistory = true) {
    let elementToScrollTo;
    // accepts an id or an element
    if (typeof el === 'string') {
        elementToScrollTo = document.getElementById(el);
    } else {
        elementToScrollTo = el;
    }

    if (!!addToHistory) {
        window.history.pushState(null, null, '#' + el);
    }

    window.scrollBy({
        left: 0,
        top: elementToScrollTo.getBoundingClientRect().top - offsetAmount,
        behavior: 'instant',
    });
}

export function activeAnchorObserver(options, refs) {
    const removeAllActive = () => {
        document
            .querySelectorAll(`button[id*="webstore-"]`)
            .forEach((element) => element.classList.remove('active'));
    };

    const elToView = {
        ratio: -1,
        entry: null,
    };

    let observer = new IntersectionObserver((entries, observer) => {
        const existingActiveEl = entries?.find((e) => e.target === elToView.entry?.target);

        //update the existing active element to reflect that element's new position
        if (existingActiveEl) {
            elToView.entry = existingActiveEl;
            elToView.ratio = existingActiveEl.intersectionRatio;
        }

        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (entry.intersectionRatio >= elToView.ratio) {
                    elToView.ratio = entry.intersectionRatio;
                    elToView.entry = entry;
                }
            }
        });

        if (elToView.entry && elToView.entry?.intersectionRatio > 0) {
            const navElement = document.querySelector(
                `button[id="${elToView.entry?.target.id}-button"]`
            );
            removeAllActive();
            navElement?.classList.add('active');
        }
    }, options);

    Object.values(refs).forEach((ref) => {
        observer.observe(ref.current);
    });

    return observer;
}

export function watchRef(options, ref, stateFunc) {
    let observer = new IntersectionObserver((entries) => {
        const [entry] = entries;

        // if ref is intersecting the viewport or top of ref container is above 75px on the DOM's y-Coordinate, set state to true
        // 75 accounts for the portion of the viewport being occupied by webstore sticky headers
        if (entry.isIntersecting || entry.boundingClientRect.top > 75) {
            return stateFunc(true);
        } else {
            return stateFunc(false);
        }
    }, options);

    observer.observe(ref.current);

    return observer;
}

export function useScrollToConditionallyDisplayedElement() {
    const ref = useCallback((node) => {
        if (node !== null) {
            node.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, []);

    return ref;
}
