import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const PhysicalLocationOptionsStyled = styled.div`
    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .displayToggle {
            flex-direction: row;
        }
    }
`;

export { PhysicalLocationOptionsStyled };
