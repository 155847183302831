import { VendorService } from 'vendor/lib/apis/VendorService';
import { SystemService } from 'lib/apis/SystemService';
import { AemService } from '../lib/apis/AemService';
import { TokenService } from '../lib/apis/TokenService';

export const initAuthUserStore = async (user) => {
    return new Promise(async (resolve) => {
        const tokenService = TokenService();
        const token = await tokenService.getOrRefreshAccessToken();

        if (token && !user.Id) {
            const systemService = SystemService();
            user.setLoading(true);

            try {
                const authedUser = await systemService.getWhoAmI();
                if (authedUser) {
                    user.setAuthUser(authedUser);
                }
            } finally {
                user.setLoading(false);
            }
        }

        resolve();
    });
};

export const initAppData = async (appData) => {
    return new Promise(async (resolve) => {
        appData.setLoading(true);

        const vendorApi = VendorService();
        const systemApi = SystemService();
        const aemService = AemService();

        const [categories, toggles, aemUrls, location] = await Promise.all([
            vendorApi.getVendorCategoryList(),
            systemApi.getFeatureToggles(),
            aemService.getRoutes(),
            appData.getCurrentLocation(),
        ]);

        appData.setCategoryList(categories);
        appData.setFeatureToggles(toggles);
        appData.aemUrls = aemUrls || [];
        appData.currentLocation = location;

        appData.setLoading(false);
        resolve();
    });
};

export const initVendorFAQs = async (vendor) => {
    return new Promise(async (resolve) => {
        vendor.setLoading(true);

        const vendorApi = VendorService();
        const faqs = await vendorApi.getVendorFaqOptions();
        vendor.setFaqOptions(faqs);

        vendor.setLoading(false);
        resolve();
    });
};
