const timeToDisplayLoader = 400;
const monitorInterval = 100;

const apiLoaderId = 'api-loader';
const siteLoaderId = 'site-loader';

const hideLoader = () => {
    const loader = document.getElementById(apiLoaderId);

    if (loader) {
        loader.style.display = 'none';
        loader.style.visibility = 'hidden';
        loader.ariaBusy = 'false';
    }
};

const tryHideLoader = (activeRequests) => {
    const activeLoaderCount = Object.values(activeRequests).filter(
        (req) => req?.LoaderDisplayed === true
    ).length;

    if (activeLoaderCount === 0) {
        hideLoader();
    }
};

const showLoader = () => {
    const loader = document.getElementById(apiLoaderId);

    if (loader) {
        loader.style.display = 'flex';
        loader.style.visibility = 'visible';
        loader.ariaBusy = 'true';
    }
};

const getRequestId = (req) => {
    return `url-${req.config.url}_id-${req.config.requestInitiationTime}`;
};

const getRequest = (activeRequests, apiConfig) => {
    const requestId = `url-${apiConfig.url}_id-${apiConfig.requestInitiationTime}`;
    const request = activeRequests[requestId];

    return {
        requestId,
        request,
    };
};

const deleteRequest = (activeRequests, apiConfig) => {
    const { request } = getRequest(activeRequests, apiConfig);
    if (request) {
        window.clearTimeout(request.Timeout);
        const requestId = `url-${apiConfig.url}_id-${apiConfig.requestInitiationTime}`;
        delete activeRequests[requestId];
    }
};

const getRequestRuntime = (apiConfig) => {
    return Date.now() - apiConfig.requestInitiationTime;
};

const endLoader = (req, activeReqs) => {
    deleteRequest(activeReqs, req.config);
    tryHideLoader(activeReqs);
};

const displayPearlApiLoader = (apiConfig, activeRequests) => {
    const { request } = getRequest(activeRequests, apiConfig);

    const apiCallMonitor = () => {
        const requestRuntime = getRequestRuntime(apiConfig);

        const isTimedOut = requestRuntime > apiConfig.timeout;
        const isOverThreshold = requestRuntime >= timeToDisplayLoader;

        if (isTimedOut) {
            // request timed out, clear the request's timeout & remove loader (if no other request is displaying loader)
            const requestTimeout = request?.Timeout;

            if (requestTimeout) {
                window.clearTimeout(requestTimeout);
            }

            deleteRequest(activeRequests, apiConfig);
            tryHideLoader(activeRequests);
        } else if (isOverThreshold) {
            // request has crossed threshold, attempt to show loader
            const loader = document.getElementById(apiLoaderId);
            const siteLoader = document.getElementById(siteLoaderId);

            if (loader && !siteLoader) {
                if (request) {
                    showLoader();
                    request.LoaderDisplayed = true;
                }
            }
        } else {
            // request is young, schedule for re-check
            if (request) {
                request.Timeout = window.setTimeout(apiCallMonitor, monitorInterval);
            }
        }
    };

    // do the initial scheduling of the call monitor
    if (request) {
        request.Timeout = window.setTimeout(apiCallMonitor, monitorInterval);
    }
};

export { displayPearlApiLoader, tryHideLoader, getRequestId, endLoader };
