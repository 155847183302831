import styled from 'styled-components';

const ConversationCardStyled = styled.div`
    width: 100%;
    border-right: 2px solid transparent;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .conversation-button {
        display: grid;
        grid-template-columns: 10px max-content auto 74px;
        grid-column-gap: 10px;
        align-items: center;
        cursor: pointer;
        padding: 16px 16px 16px 8px;
        width: 100%;
        background-color: unset;
        border: none;
        text-align: left;

        .conversation-avatar {
            width: 56px;
            height: 56px;
            border: 1px solid ${(props) => props.theme.colors.foreground.primary};
            border-radius: 50%;
            display: grid;
            align-items: center;

            & > span {
                margin: 0 auto;
            }
        }

        .conversation-info {
            display: grid;
            height: 100%;
            padding-left: 6px;

            p {
                margin: 0;
            }

            .conversation-participants {
                align-self: start;
            }

            .conversation-context,
            .conversation-latest {
                align-self: end;
                color: ${(props) => props.theme.colors.disabled.contrast};

                // force <p> to take up vertical space even if empty
                p {
                    text-indent: -1px;

                    &:before {
                        content: ' ';
                        display: inline-block;
                        width: 1px;
                    }
                }
            }
        }

        .conversation-date {
            align-self: start;
            text-align: right;
            color: ${(props) => props.theme.colors.disabled.contrast};

            & > span {
                position: relative;
                top: 2px;
            }

            p {
                margin: 0 0 0 8px;
                display: inline-block;
            }
        }
    }

    .modal-action {
        display: flex;
        gap: 10px;
        background: unset;
        border: unset;
        cursor: pointer;
    }

    &:hover {
        background-color: ${(props) => props.theme.colors.foreground.base}20;
    }

    &.selected {
        border-right: 2px solid ${(props) => props.theme.colors.primary.base};
    }
`;

export { ConversationCardStyled };
