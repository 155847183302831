import { APP_ROUTES } from 'shared/constants/navigation';

const RequireAnalytics = (context) => {
    if (!context.store.appData.featureToggles.analytics) {
        return APP_ROUTES.vendor.dashboard;
    }

    return 'next';
};

export { RequireAnalytics };
