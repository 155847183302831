import { PropTypes } from 'prop-types';
import React from 'react';
import WebstoreSectionStyled from './WebstoreSection.styled';

const WebstoreSection = (props) => {
    const { children, className, showDivider, showSectionError } = props;

    return (
        <WebstoreSectionStyled
            data-testid="webstore-section"
            className={className}
            divider={showDivider}
            showSectionError={showSectionError}
        >
            {children}
        </WebstoreSectionStyled>
    );
};

WebstoreSection.propTypes = {
    /* A class to apply to the component element */
    className: PropTypes.string,
    /* If the divider should be shown below the section */
    showDivider: PropTypes.bool,
    /* If the error border should be shown around the section */
    showSectionError: PropTypes.bool,
};

WebstoreSection.defaultProps = {
    showDivider: false,
    showSectionError: false,
};

WebstoreSection.displayName = 'WebstoreSection';

export default WebstoreSection;
