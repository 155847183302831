import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    COMPLETE_STEP,
    DEFAULT_STEP,
    EventUserSetupStepForms,
} from '../../../constants/EventUserSetupSteps';
import { useStore } from 'stores/Store';
import { EventService } from '../../../lib/apis/EventService';
import { APP_ROUTES } from 'shared/constants/navigation';

const EventUserSetupContext = React.createContext();
EventUserSetupContext.displayName = 'EventUserSetupContext';

const setupRoutePrefix = APP_ROUTES.setup + '/';
const getSetupStepRoute = (step) => `${setupRoutePrefix}${step}`;

const useEventUserSetupWizard = () => {
    const [eventUserSetupProps] = React.useContext(EventUserSetupContext);
    const { event, eventUser, progress, totalSteps, currentStepCount, stepsArray, patchFunction } =
        eventUserSetupProps;

    const navigate = useNavigate();

    const getStep = (indexOffset) => {
        const currentIndex = stepsArray.indexOf(event.CurrentStep);
        return stepsArray[currentIndex + indexOffset];
    };

    const onBack = () => {
        const previousStep = getStep(-1);
        navigate(getSetupStepRoute(previousStep));
    };

    const onSkip = () => {
        const nextStep = getStep(1);
        if (nextStep) {
            navigate(getSetupStepRoute(nextStep));
        } else {
            event.CurrentStep = COMPLETE_STEP;
            eventUserSetupProps.updateStep();
            navigate(APP_ROUTES.dashboard);
        }
    };

    const onSubmit = async (formData) => {
        try {
            if (formData) {
                event.updateEvent(formData);
                if (formData.EventUser) {
                    eventUser.updateUser(formData.EventUser);
                }
                const payload = {
                    ...event.forApi,
                    ...eventUser.forApi,
                };
                const patch = await patchFunction(payload);
                if (patch.ok) {
                    event.setFromApi(patch.data);
                    eventUser.setFromApi(patch.data.event.users[0]);
                } else {
                    console.error(patch.originalError);
                }
            }
            const nextStep = getStep(1);
            if (nextStep) {
                navigate(getSetupStepRoute(nextStep));
            } else {
                event.CurrentStep = COMPLETE_STEP;
                eventUserSetupProps.updateStep();
                navigate(APP_ROUTES.dashboard);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return {
        onSubmit,
        onBack,
        onSkip,
        progress,
        totalSteps,
        currentStepCount,
    };
};

const EventUserSetupWizardProvider = ({ children }) => {
    const eventApi = EventService();
    const location = useLocation();
    const navigate = useNavigate();

    const { event, eventUser } = useStore();
    const recalcSteps = (options) => {
        const { locationChanged } = options;
        const updateStep = eventUserSetup.updateStep;

        if (locationChanged) {
            const currentPage = location.pathname.slice(
                location.pathname.indexOf(setupRoutePrefix) + setupRoutePrefix.length
            );

            const validPages = Object.values(EventUserSetupStepForms);
            if (validPages.includes(currentPage)) {
                if (event.CurrentStep) {
                    if (event.CurrentStep !== currentPage) {
                        event.CurrentStep = currentPage;
                        updateStep();
                    }
                } else {
                    event.CurrentStep = currentPage;
                }
            } else {
                if (!validPages.includes(event.CurrentStep)) {
                    event.CurrentStep = DEFAULT_STEP;
                    updateStep();
                }
                navigate(getSetupStepRoute(event.CurrentStep));
            }
        }

        let stepsArray = eventUserSetup.stepsArray;

        const currentStepCount = stepsArray.indexOf(event.CurrentStep) + 1;

        const totalSteps = stepsArray.length;

        const progress = currentStepCount / totalSteps;

        setEventUserSetup({
            ...eventUserSetup,
            progress: progress,
            totalSteps: totalSteps,
            currentStepCount: currentStepCount,
            currentStepName: event.CurrentStep,
            stepsArray: stepsArray,
        });
    };

    const updateStep = async () => {
        const response = await eventApi.patchEventProfile(event.forPatchSetupStep);
        if (response) {
            event.setFromApi(response.data);
        }
    };

    const defaultEventUserSetupProps = {
        patchFunction: eventApi.patchEventProfile,
        stepsArray: Object.values(EventUserSetupStepForms),
        progress: 0,
        totalSteps: Object.values(EventUserSetupStepForms).length,
        currentStepCount: 1,
        currentStepName: DEFAULT_STEP,
        event: event,
        eventUser: eventUser,
        updateStep,
    };

    const [eventUserSetup, setEventUserSetup] = useState(defaultEventUserSetupProps);

    useEffect(() => {
        recalcSteps({ locationChanged: true });
    }, [location]);

    return (
        <EventUserSetupContext.Provider value={[eventUserSetup, setEventUserSetup]}>
            {children}
        </EventUserSetupContext.Provider>
    );
};

export { useEventUserSetupWizard, EventUserSetupWizardProvider };
