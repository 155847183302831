import React, { useEffect } from 'react';
import AccountSetupStepLayoutStyled from './AccountSetupStepLayout.styled';
import { newPageTrigger } from 'LivePerson';

function AccountSetupStepLayout(props) {
    const { image, header, formComponent, footer } = props;

    useEffect(() => {
        newPageTrigger(window.location.href);
    }, [window.location.href]);

    return (
        <AccountSetupStepLayoutStyled>
            <div className="image">{image}</div>
            <div className="rightSide">
                <div className="innerRight">
                    <div className="stepHeader">{header}</div>
                    <main className="stepComponent">
                        <div className="formWrapper">{formComponent}</div>
                    </main>
                </div>
                {footer}
            </div>
        </AccountSetupStepLayoutStyled>
    );
}

export { AccountSetupStepLayout };
