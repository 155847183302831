import styled from 'styled-components';

const GoogleReviewsConnectorStyled = styled.div`
    .connectionContainer {
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        button {
            align-self: flex-start;
        }
    }

    .GooglePlaceLookup-container {
        width: 80%;
        max-width: 475px;
    }

    .GooglePlaceId-container {
        display: none;
    }

    .searchInput {
        padding-left: 8px;
    }

    .connectedWithIcon {
        display: inline-flex;
        column-gap: 8px;
    }

    .connectedBox {
        padding-top: 1px;
        max-width: 430px;
        color: ${(props) => props.theme.colors.primary.base};
        margin-left: 3px;
    }

    .disconnectButton {
        padding-top: 20px;
    }

    @media (max-width: 815px) {
        .GooglePlaceLookup-container {
            width: 100%;
            max-width: 100%;
        }
    }
`;

export { GoogleReviewsConnectorStyled };
