import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserBasicsFormStyled } from './UserBasicsForm.styled';
import { userBasicsSchema } from '../../../../constants/schema/UserBasicsSchema';
import { PenWriting, EmptyCaptions } from '../../../../../assets/Defaults';
import {
    AccountSetupStepLayout,
    AccountSetupStepGraphic,
    AccountSetupStepFooter,
    AccountSetupStepCounter,
    PearlForm,
} from 'framework';
import { useEventUserSetupWizard } from '../../context/EventUserSetupContext';
import { UserBasics } from '../UserBasics';
import { useStore } from 'stores/Store';
import { useEventContext } from 'event-user/contexts/EventContext';
import { DEFAULT_STEP } from 'event-user/constants/EventUserSetupSteps';

function Footer() {
    const { progress } = useEventUserSetupWizard();

    return <AccountSetupStepFooter progress={progress} />;
}

function UserBasicsForm() {
    const { currentStepCount, totalSteps, onSubmit } = useEventUserSetupWizard();

    const { t } = useTranslation();
    const { eventUser, event, authUser } = useStore();
    const { patchEvent } = useEventContext();

    const videoDetails = {
        image: PenWriting,
        altText: 'Forms.Basics.GifAltText',
        captions: EmptyCaptions,
    };

    const names = {
        firstName: eventUser.FirstName ? eventUser.FirstName : authUser.FirstName,
        lastName: eventUser.LastName ? eventUser.LastName : authUser.LastName,
    };

    const formOptions = {
        mode: 'onSubmit',
        defaultValues: {
            FirstName: names.firstName,
            LastName: names.lastName,
            EventType: event.EventType ? event.EventType : null,
            UserEventRole: eventUser.Role ? eventUser.Role : null,
        },
    };

    const handleSubmit = (formData) => {
        const payload = {
            EventUser: {
                ...formData,
                Role: formData.UserEventRole,
            },
            EventType: formData.EventType,
        };

        // mapped to EventUser.Role
        delete payload.EventUser.UserEventRole;

        // mapped to payload.EventType, not nested
        delete payload.EventUser.EventType;

        onSubmit(payload);
    };

    useEffect(async () => {
        if (!event.CurrentStep) {
            event.updateEvent({ CurrentStep: DEFAULT_STEP });
            await patchEvent();
        }
    }, []);

    return (
        <PearlForm
            formOptions={formOptions}
            StyledForm={UserBasicsFormStyled}
            formName="UserBasics"
            onSubmit={(data) => handleSubmit(data)}
            validationSchema={userBasicsSchema}
        >
            <AccountSetupStepLayout
                header={
                    <div>
                        <AccountSetupStepCounter
                            stepCountText={t('Forms.Labels.Steps', {
                                completedSteps: currentStepCount,
                                totalSteps: totalSteps,
                            })}
                            stepNameText={t('Forms.UserBasics.StepName')}
                        />
                        <h1 className="eventUserTitles">{t('Forms.UserBasics.StepTitle')}</h1>
                    </div>
                }
                formComponent={<UserBasics />}
                image={<AccountSetupStepGraphic {...videoDetails} />}
                footer={<Footer />}
            />
        </PearlForm>
    );
}

export { UserBasicsForm };
