import styled from 'styled-components';
import { CircularProgress as MuiCircularProgress } from '@mui/material';

const CircularProgress = styled(MuiCircularProgress)`
    &.MuiCircularProgress-root {
        opacity: 50%;
        color: ${(props) => props.theme.colors.primary.base};
    }
`;

export { CircularProgress };
