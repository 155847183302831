import React from 'react';
import WebstoreBasics from '../WebstoreBasics/WebstoreBasics';
import WebstoreHeaderStyled from './WebstoreHeader.styled';

function WebstoreHeader(props) {
    const { webstore, controls, onClaimClick, sectionRef, APP_ROUTES } = props;

    return (
        <WebstoreHeaderStyled id="webstore-header" data-testid="webstore-header">
            <WebstoreBasics
                webstore={webstore}
                controls={controls}
                onClaimClick={onClaimClick}
                sectionRef={sectionRef}
                APP_ROUTES={APP_ROUTES}
            />
        </WebstoreHeaderStyled>
    );
}

export default WebstoreHeader;
