import React from 'react';
import { format } from 'date-fns';
import { ChatTimestampStyled } from './ChatTimestamp.styled';

const getFormattedTimestamp = (timestamp) => {
    return format(timestamp, 'h:mm a, M/d/yyyy');
};

const ChatTimestamp = ({ timestamp, source }) => (
    <ChatTimestampStyled className={`p4 message-timestamp message-${source}`}>
        {getFormattedTimestamp(new Date(timestamp))}
    </ChatTimestampStyled>
);

export { ChatTimestamp };
