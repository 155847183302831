import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export default function PearlResolver(yupSchema, customValidator) {
    return async (values, context, options) => {
        let yupResult = {};
        let customResult = {};

        if (!yupSchema && !customValidator) {
            return yupResolver(Yup.object({}))(values, context, options);
        }

        if (yupSchema) {
            const resolver = yupResolver(yupSchema);
            yupResult = await resolver(values, context, options);
        }

        if (customValidator) {
            customResult = customValidator(values, options);
        }

        const combinedErrors = {
            ...yupResult?.errors,
            ...customResult?.errors,
        };
        return { values, errors: combinedErrors };
    };
}
