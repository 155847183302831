import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
    IconButton,
    IconName,
    IconSizes,
    DropDownMenu,
    LinkButton,
    SolidButton,
} from 'dbi-pearl-ui-kit';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES, navigation } from 'shared/constants/navigation';
import styled from 'styled-components';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useAuthUserContext } from 'contexts/AuthUserContext';
import { useStore } from 'stores/Store';
import { openUserChoiceLoginModal } from '../UserChoiceLogin/UserChoiceLogin';
import { usePearlModal, useIsScreenLarge, PearlApps } from 'framework';
import { AuthType } from '../../../constants/authType';
import { getAppName } from '../../../hooks/AppName';

const UnAuthedUserMenuStyled = styled.div`
    display: flex;
    column-gap: 24px;

    .user-menu-login-link-button {
        padding-bottom: 0;
        align-self: center;
        min-width: 55px;
    }
`;

const AuthedUserMenuStyled = styled.div`
    display: flex;
    justify-content: end;

    .user-menu-all {
        padding-left: 24px;
    }

    .user-inbox-icon-wrapper {
        padding-left: 24px;
    }

    button {
        :hover {
            color: ${(props) => props.theme.colors.primary.base};
            border-color: ${(props) => props.theme.colors.primary.base};
            cursor: pointer;
        }
    }

    .dropdownContainer {
        position: absolute;
        right: 10px;
        z-index: 100;
    }

    .menu-item {
        :hover {
            color: ${(props) => props.theme.colors.primary.base};
            cursor: pointer;
        }
    }

    .smoothenMouseMovement {
        width: 100%;
        height: 40px;
        position: absolute;
        top: -40px;
        z-index: 1;
    }
`;

const UserMenuNavigationStyled = styled.div`
    height: 38px;
    z-index: 8;
    .user-menu-wrapper {
        padding: 0 24px;
    }
`;

const vendorUserDataSetup = (webstore, translator, appName) => {
    const headerTitle = `${translator('Navigation.UserMenu.Greeting')} ${webstore.WebstoreName}`;
    const customHeader = {
        displayName: headerTitle,
        src: APP_ROUTES.vendor.dashboard,
    };

    const customSections = navigation.vendorUserMenu;

    customSections.forEach((section) => {
        customSections.displayName = translator(section.displayName);
        section.displayName = translator(section.displayName);
        if (section.flag === 'VendorCustomerView') {
            if (appName === PearlApps.VENDOR) {
                section.displayName = translator('Navigation.UserMenu.CustomerView.displayName');
                section.ariaLabel = translator('Navigation.UserMenu.CustomerView.ariaLabel');
                if (webstore.WebstoreUrl === null) {
                    section.src = APP_ROUTES.landing;
                } else {
                    section.src = `/${webstore.WebstoreUrl}`;
                }
            } else {
                section.displayName = translator('Navigation.UserMenu.VendorView.displayName');
                section.ariaLabel = translator('Navigation.UserMenu.VendorView.ariaLabel');
                section.src = APP_ROUTES.vendor.webstore.root;
            }
        }
    });

    return { customHeader, customSections };
};

const eventUserDataSetup = (eventUser, translator) => {
    const headerTitle = `${translator('Navigation.UserMenu.Greeting')} ${eventUser?.FirstName} ${
        eventUser.LastName
    }`;
    const customHeader = { displayName: headerTitle, src: APP_ROUTES.landing };

    const customSections = navigation.eventUserMenu;
    customSections.forEach((section) => {
        section.displayName = translator(section.displayName);
    });

    const customInboxLink = APP_ROUTES.inbox;

    return { customHeader, customSections, customInboxLink };
};

const UserNavigationMenu = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { eventUser } = useStore();
    const { authUser, logout } = useAuthUserContext();
    const { webstore } = useWebstoreContext();
    const { openModal, closeModal } = usePearlModal();
    const isScreenLarge = useIsScreenLarge();
    const appName = getAppName();

    const [open, setOpen] = useState(false);

    function handleNavigate(src) {
        setOpen(false);
        if (src === 'logout') {
            logout(APP_ROUTES.landing);
        } else {
            navigate(src);
        }
    }

    function navigateToInbox() {
        const inboxLink =
            authUser?.isAuthedVendor && appName === PearlApps.VENDOR
                ? APP_ROUTES.vendor.inbox
                : APP_ROUTES.inbox;
        navigate(inboxLink);
    }

    let header;
    let sections;

    if (authUser.isAuthedVendor || authUser.isAuthedEventUser) {
        if (authUser.isAuthedVendor) {
            const { customHeader, customSections } = vendorUserDataSetup(webstore, t, appName);
            header = customHeader;
            sections = customSections;
        } else {
            const { customHeader, customSections } = eventUserDataSetup(eventUser, t);
            header = customHeader;
            sections = customSections;
        }
    }

    const AuthedUserMenu = () => {
        return (
            <AuthedUserMenuStyled
                className="authed-user-menu-wrapper"
                data-testid="authed-user-menu-wrapper"
            >
                {appName === PearlApps.EVENT_USER && (
                    <div className="user-favorite-icon-wrapper">
                        <IconButton
                            size={IconSizes.MD}
                            iconName={IconName.HEART_OUTLINE}
                            clickHandler={() => navigate(APP_ROUTES.account.favorites)}
                            role={'button'}
                            ariaLabel={t('Navigation.EventAccountManagement.FavoritesHeader')}
                        />
                    </div>
                )}
                <div className="user-inbox-icon-wrapper">
                    <IconButton
                        size={IconSizes.MD}
                        iconName={IconName.EMAIL}
                        clickHandler={navigateToInbox}
                        role={'button'}
                        ariaLabel={t('Navigation.InboxIconAriaLabel')}
                    />
                </div>
                <div
                    className={`user-menu-all ${open ? 'user-menu-all' : ''}`}
                    data-testid={`user-menu-open-${open}`}
                    onMouseLeave={() => setOpen(false)}
                >
                    <IconButton
                        iconName={IconName.USER}
                        clickHandler={() => setOpen(!open)}
                        role={'button'}
                        ariaLabel={t('Navigation.UserMenu.AriaLabel')}
                    />
                    {open && (
                        <div className="dropdownContainer">
                            <div className="smoothenMouseMovement"></div>
                            <DropDownMenu
                                className="drop-down"
                                userMenu={sections}
                                header={header}
                                handleNavigate={handleNavigate}
                            />
                        </div>
                    )}
                </div>
            </AuthedUserMenuStyled>
        );
    };

    const UnAuthedUserMenu = () => {
        return (
            <UnAuthedUserMenuStyled>
                <SolidButton
                    className="user-menu-sign-up-button"
                    onClick={() =>
                        openUserChoiceLoginModal(
                            openModal,
                            closeModal,
                            isScreenLarge,
                            AuthType.signup
                        )
                    }
                >
                    {t('Forms.Buttons.SignupSubmitButton')}
                </SolidButton>
                <LinkButton
                    onClick={() =>
                        openUserChoiceLoginModal(
                            openModal,
                            closeModal,
                            isScreenLarge,
                            AuthType.login
                        )
                    }
                    variant={'accent'}
                    className="user-menu-login-link-button"
                >
                    {t('Forms.Buttons.LoginButton')}
                </LinkButton>
            </UnAuthedUserMenuStyled>
        );
    };

    return (
        <UserMenuNavigationStyled data-testid={`user-menu-wrapper`}>
            {(authUser.isAuthedVendor || authUser.isAuthedEventUser) && <AuthedUserMenu />}
            {!authUser.isAuthedVendor && !authUser.isAuthedEventUser && <UnAuthedUserMenu />}
        </UserMenuNavigationStyled>
    );
};

export { UserNavigationMenu };
