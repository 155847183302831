import * as Yup from 'yup';

const locationSchema = Yup.object()
    .shape({
        EventLocationType: Yup.string().nullable().when('EventLocationUnknown', {
            is: true,
            then: Yup.string().nullable().required(),
        }),
        EventLocationInput: Yup.string()
            .nullable()
            .when('EventLocationUnknown', {
                is: false || null,
                then: Yup.string().nullable().required(),
            }),
    })
    .required();
export { locationSchema };
