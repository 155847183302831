import { useTranslation } from 'react-i18next';
import GoogleLogoStyled from './GoogleLogo.styled';
import { GoogleLogoImage } from 'assets/logos/LogoImages';

const GoogleLogo = () => {
    const { t } = useTranslation();

    return (
        <GoogleLogoStyled className="googleLogo" data-testid="google-logo">
            <img className="googleLogoImage" src={GoogleLogoImage.default} alt={t('GoogleLogo')} />
        </GoogleLogoStyled>
    );
};

export { GoogleLogo };
