import React, { Fragment, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LocationFormStyled from './LocationForm.styled';
import { eventLocationType, eventTypes } from '../../../constants/accountCreationOptions';
import { EmptyCaptions, LocationPin } from '../../../../assets/Defaults';
import { Checkbox, Dropdown } from 'dbi-pearl-ui-kit';
import {
    AccountSetupStepLayout,
    AccountSetupStepGraphic,
    AccountSetupStepFooter,
    AccountSetupStepCounter,
    FormRow,
    PearlForm,
    PearlError,
} from 'framework';
import { useEventUserSetupWizard } from '../context/EventUserSetupContext';
import { locationSchema } from '../../../constants/schema/LocationSchema';
import { useEventContext } from '../../../contexts/EventContext';
import {
    CityStateAutoComplete,
    searchResultToLocationParser,
} from 'shared/components/CityStateAutoComplete/CityStateAutoComplete';
import styled from 'styled-components';

const StyledForm = styled.form``;

function Footer() {
    const { progress, onBack } = useEventUserSetupWizard();

    return <AccountSetupStepFooter progress={progress} handleOnBack={onBack} />;
}

const Location = (props) => {
    const { searchResult, setSearchResult } = props;

    const { t } = useTranslation();
    const { watch, setValue } = useFormContext();
    const { event } = useEventContext();
    const fieldNames = {
        EventLocationUnknown: 'EventLocationUnknown',
        EventLocationInput: 'EventLocationInput',
        EventLocationType: 'EventLocationType',
    };
    const [isChecked, setIsChecked] = useState(() => {
        if (event?.EventLocationKnown !== null) {
            if (event?.EventLocationKnown) {
                setValue(fieldNames.EventLocationUnknown, true);
                return false;
            } else {
                setValue(fieldNames.EventLocationUnknown, false);
                return true;
            }
        } else {
            setValue(fieldNames.EventLocationUnknown, null);
            return false;
        }
    });
    const [hideOptions, setHideOptions] = useState(true);

    const inputField = watch(fieldNames.EventLocationInput);

    useEffect(() => {
        if (inputField?.length > 0) {
            setHideOptions(false);
        } else {
            setHideOptions(true);
        }
    }, [inputField]);

    const handleIsUnknownField = (checked) => {
        if (checked) {
            setIsChecked(true);
            setSearchResult(null);
            setValue(fieldNames.EventLocationInput, '');
            setValue(fieldNames.EventLocationUnknown, true);
        } else {
            setIsChecked(false);
            setValue(fieldNames.EventLocationType, null);
            setValue(fieldNames.EventLocationUnknown, false);
        }
    };

    const getEventTypeLabel = () => {
        return eventTypes.find((type) => type.value === event.EventType)?.label;
    };

    const questionLabel = t('Forms.EventLocation.EventLocationTypeLabel', {
        eventType: t(getEventTypeLabel())?.toLowerCase() || t('Forms.Labels.Event'),
    });

    return (
        <LocationFormStyled>
            <FormRow
                columns={[
                    <Fragment>
                        <CityStateAutoComplete
                            disableInput={isChecked}
                            setSearchResult={setSearchResult}
                            hideOptions={hideOptions}
                            fieldName={fieldNames.EventLocationInput}
                            providedLabel={t('Forms.EventLocation.EventLocationLabel')}
                        />
                        <PearlError name={fieldNames.EventLocationInput} />
                    </Fragment>,
                ]}
            />
            {!searchResult && !inputField && (
                <FormRow
                    columns={[
                        <div className="checkboxContainer">
                            <Checkbox
                                onChange={handleIsUnknownField}
                                checked={isChecked}
                                name={fieldNames.EventLocationUnknown}
                                label={t('Forms.EventLocation.EventLocationUnknown')}
                            />
                        </div>,
                    ]}
                />
            )}
            {isChecked && (
                <FormRow
                    columns={[
                        <div>
                            <Dropdown
                                formFieldName={fieldNames.EventLocationType}
                                providedLabel={questionLabel}
                            >
                                {eventLocationType.map((option) => {
                                    return (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                            className="eventTypeOption"
                                            data-testid={`event-type-option-${option.value}`}
                                        >
                                            {t(option.label)}
                                        </option>
                                    );
                                })}
                            </Dropdown>
                            <PearlError name={fieldNames.EventLocationType} />
                        </div>,
                    ]}
                />
            )}
        </LocationFormStyled>
    );
};

function LocationForm() {
    const { event } = useEventContext();
    const { onSubmit, currentStepCount, totalSteps } = useEventUserSetupWizard();
    const { t } = useTranslation();

    const [searchResult, setSearchResult] = useState(null);

    const formOptions = {
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: {
            EventLocationUnknown: !event.EventLocationKnown,
            EventLocationInput:
                event.EventLocation.length > 0 ? event.EventLocation[0]?.displayText : '',
            EventLocationType: !event.EventLocationKnown
                ? event.EventLocationType?.toLowerCase()
                : null,
        },
    };

    const videoDetails = {
        image: LocationPin,
        altText: 'Forms.Basics.GifAltText',
        captions: EmptyCaptions,
    };

    const handleSubmit = (formData) => {
        let payload = {
            EventLocation: event.EventLocation,
            EventLocationType: formData.EventLocationType,
            EventLocationKnown: !formData.EventLocationUnknown,
        };

        if (searchResult) {
            //remove the original version of the location
            let locationToUpdate = event.EventLocation.shift();

            locationToUpdate = locationToUpdate || {};

            //then update the same location with the new data
            searchResultToLocationParser(searchResult, locationToUpdate);

            event.EventLocation.push(locationToUpdate);

            payload = {
                ...payload,
                EventLocation: event.EventLocation,
                EventLocationKnown: true,
                EventLocationType: null,
            };
        } else if (formData.EventLocationType) {
            payload.EventLocationKnown = false;
            payload.EventLocation[0] = {
                id: event.EventLocation[0]?.id,
                displayText: null,
                addressLine1: null,
                addressLine2: null,
                locationCity: null,
                locationState: null,
                locationZipCode: null,
                latitude: null,
                longitude: null,
                googlePlaceId: null,
            };
        }

        onSubmit(payload);
    };

    return (
        <PearlForm
            StyledForm={StyledForm}
            formName="EventLocation"
            formId="EventLocation"
            validationSchema={locationSchema}
            formOptions={formOptions}
            onSubmit={(data) => handleSubmit(data)}
        >
            <AccountSetupStepLayout
                header={
                    <div>
                        <AccountSetupStepCounter
                            stepCountText={t('Forms.Labels.Steps', {
                                completedSteps: currentStepCount,
                                totalSteps: totalSteps,
                            })}
                            stepNameText={t('Forms.EventLocation.StepName')}
                        />
                        <h1 className="eventUserTitles">{t('Forms.EventLocation.StepTitle')}</h1>
                    </div>
                }
                formComponent={
                    <Location setSearchResult={setSearchResult} searchResult={searchResult} />
                }
                image={<AccountSetupStepGraphic {...videoDetails} />}
                footer={<Footer />}
            />
        </PearlForm>
    );
}

export { LocationForm, Location };
