import { usPhoneNumberFormatter } from './PhoneNumberFormatters/USPhoneNumberFormatter';

const countries = {
    US: 'us',
};

const formatters = new Map([[countries.US, usPhoneNumberFormatter]]);

// as of this writing, priority of fallbacks is
// 1. specified country
// 2. inferred from text
// 3. US format
// 4. original text
const getFormatter = (text, country) => {
    if (country) {
        const formatter = formatters.get(country);
        if (formatter) {
            return formatter;
        }
    }

    const formatter = Array.from(formatters.values()).find((f) => f.belongsToCountry(text));
    if (formatter) {
        return formatter;
    }

    return formatters.get(countries.US);
};

// optional country to 'force' a specific format
// otherwise, infer from initial number formatting
const PhoneNumberService = (country) => {
    const formatPhoneNumberForDisplay = (text) => {
        const formatter = getFormatter(text, country);
        return formatter.formatPhoneNumberForDisplay(text.toString());
    };

    const formatPhoneNumberForSave = (text) => {
        const formatter = getFormatter(text, country);
        return formatter.formatPhoneNumberForSave(text.toString());
    };

    return {
        formatPhoneNumberForDisplay,
        formatPhoneNumberForSave,
    };
};

export { PhoneNumberService, countries };
