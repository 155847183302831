import React from 'react';
import { PearlIcon, IconName, IconSizes } from 'dbi-pearl-ui-kit';
import { NoInformationProvidedStyled } from './NoInformationProvided.styled';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../../../framework';

const NoInformationProvided = (props) => {
    const { sectionRef, sectionId, title, iconName, banner } = props;
    const { t } = useTranslation();

    return (
        <NoInformationProvidedStyled ref={sectionRef} id={sectionId}>
            <SectionHeader title={title} iconName={iconName} banner={banner} />
            <div className="no-info-container">
                <PearlIcon iconName={IconName.INFO} size={IconSizes.XXL} />
                <p className="label">
                    <b>{t('WebstoreSections.EmptySection.NoInfoMessage')}</b>
                </p>
                <p className="label">
                    <b>{t('WebstoreSections.EmptySection.NoInfoInstructions')}</b>
                </p>
            </div>
        </NoInformationProvidedStyled>
    );
};

export { NoInformationProvided };
