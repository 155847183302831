import React from 'react';
import { useTranslation } from 'react-i18next';
import { PearlIcon, IconName, IconSizes } from 'dbi-pearl-ui-kit';
import { AvatarStyled } from './ChatAvatar.styled';

const ChatAvatar = ({ message }) => {
    const { t } = useTranslation();

    let showBorder = true;

    // default / fallback
    let avatar = (
        <div className="avatar-default">
            <PearlIcon iconName={IconName.USER} size={IconSizes.MD} />
        </div>
    );

    if (message.avatarImage) {
        showBorder = false;
        avatar = (
            <img
                className="avatar-image"
                src={message.avatarImage}
                alt={t('Messaging.AvatarAlt')}
            />
        );
    } else if (message.avatarText) {
        avatar = (
            <div className="avatar-text">
                <p className="p1">{message.avatarText}</p>
            </div>
        );
    }

    return <AvatarStyled showBorder={showBorder}>{avatar}</AvatarStyled>;
};

export { ChatAvatar };
