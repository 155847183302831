import { WebstoreFaqs } from 'framework';
import { useTranslation } from 'react-i18next';
import { NoInformationProvided } from '../NoInformationProvided/NoInformationProvided';
import { UpgradeSubscription } from '../UpgradeSubscription/UpgradeSubscription';
import { IconName } from 'dbi-pearl-ui-kit';

const FaqsSection = (props) => {
    const { webstore, sectionRef, sectionId } = props;
    const { t } = useTranslation();
    const needsUpgrade = !webstore.ActiveFeatures?.feature_faqs;

    return (
        <>
            {webstore.Faqs?.length ? (
                <WebstoreFaqs
                    webstore={webstore}
                    sectionRef={sectionRef}
                    sectionId={sectionId}
                    iconName={needsUpgrade ? IconName.LOCK : null}
                    banner={!!needsUpgrade && <UpgradeSubscription />}
                />
            ) : (
                <NoInformationProvided
                    sectionRef={sectionRef}
                    sectionId={sectionId}
                    title={t('WebstoreSections.Faqs.Title')}
                    iconName={needsUpgrade ? IconName.LOCK : null}
                    banner={!!needsUpgrade && <UpgradeSubscription />}
                />
            )}
        </>
    );
};

export { FaqsSection };
