import React from 'react';
import { TruncatedTextDisplay, RatingStars } from 'dbi-pearl-ui-kit';
import { useIsScreenLarge } from '../../../hooks/PearlMediaQuery';
import { WebstoreReviewStyled } from './WebstoreReview.styled';

const Rating = ({ review }) => {
    return (
        <>
            <RatingStars totalRatingPossible={5} rating={review.rating} />
            <p className="p2 reviewTime">{review.relative_time_description}</p>
        </>
    );
};

const WebstoreReview = (props) => {
    const { review } = props;

    const isScreenLarge = useIsScreenLarge();

    const textLineCount = !isScreenLarge ? 6 : 3;

    return (
        <WebstoreReviewStyled>
            <div className="reviewContainer">
                <div className="leftSide">
                    <img className="reviewProfileImage" src={review.profile_photo_url} alt="" />
                    <h4 className="desktopOnly">{review.author_name}</h4>
                    <div className="reviewerInfo mobileOnly">
                        <h4>{review.author_name}</h4>
                        <Rating review={review} />
                    </div>
                </div>
                <div className="rightSide">
                    <span className="reviewRating">
                        <div className="desktopOnly">
                            <Rating review={review} />
                        </div>
                    </span>
                    <TruncatedTextDisplay lineCount={textLineCount}>
                        <p className="p2 reviewText">{review.text}</p>
                    </TruncatedTextDisplay>
                </div>
            </div>
        </WebstoreReviewStyled>
    );
};

export { WebstoreReview };
