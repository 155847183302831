import styled from 'styled-components';

export default styled.div`
    flex: 1 1 0;
    padding-bottom: 24px;

    &:hover {
        .box {
            color: ${(props) =>
                props.isChecked ? props.theme.colors.primary.contrast : 'initial'};
            background-color: ${(props) =>
                props.isChecked
                    ? props.theme.colors.primary.hover
                    : props.theme.colors.background.base};
        }

        input:checked + label .box .boxLeftContent {
            border: 1px solid ${(props) => props.theme.colors.primary.contrast};

            .dollarsSymbol {
                color: ${(props) => props.theme.colors.primary.contrast};
            }
        }

        .boxRightContent {
            color: ${(props) => props.theme.colors.primary.contrast};
        }
    }

    input {
        position: fixed;
        opacity: 0;
        pointer-events: auto;
    }

    .box {
        border: 1px solid ${(props) => props.theme.colors.foreground.border};
        display: flex;
        cursor: pointer;
        min-height: 100%;
    }
    .disabled {
        cursor: default;
        background-color: ${(props) => props.theme.colors.background.base};
    }
    .boxLeftContent {
        display: flex;
        min-width: 44px;
        min-height: 44px;
        margin: auto 16px;
        border: 1px solid ${(props) => props.theme.colors.foreground.base};
        border-radius: 50%;
        justify-content: center;
        align-items: center;
    }
    .boxDivider {
        background: ${(props) => props.theme.colors.foreground.border};
        width: 1px;
        margin: 16px 0;
    }
    .boxMiddleContent {
        margin: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;

        .p2 {
            margin-block: 0;
            padding-top: 2px;
        }
    }
    .boxRightContent {
        margin: auto 16px auto 0;
        color: ${(props) => props.theme.colors.primary.base};
        min-width: 24px;
        text-align: center;
        font-size: 24px;
    }
    .dollarsSymbol {
        margin: auto;
        padding-top: 3px;
        font-size: 16px;
        letter-spacing: 0;
        color: ${(props) => props.theme.colors.foreground.text};
    }
    input:checked + label .box {
        border: 1px solid ${(props) => props.theme.colors.foreground.border_selected};
    }
    input:checked + label .box .boxLeftContent {
        border: 1px solid ${(props) => props.theme.colors.primary.base};
    }
    input:checked + label .box .dollarsSymbol {
        color: ${(props) => props.theme.colors.primary.base};
    }
    input:checked + label .box .boxRightContent::after {
        font-family: 'pearl-icons' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        content: '\\e914';
    }
`;
