import { FormRow, PearlError } from 'framework';
import { Dropdown, IconButton, IconName, TextAreaInput, TextInput } from 'dbi-pearl-ui-kit';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaqEditFormStyled } from './FaqEditForm.styled';

const FaqEditForm = (props) => {
    const { index, pearlFaq, defaultFaqs, hasError, onDelete, isNew } = props;
    const { t } = useTranslation();
    const { watch, reset, getValues, setValue } = useFormContext();
    const [title, setTitle] = useState(t('Forms.Faqs.Title'));
    const currentQuestion = watch(`${index}.Question`);
    const [showCustomQuestionInput, setShowCustomQuestionInput] = useState(
        pearlFaq.CustomQuestion || currentQuestion === 'custom'
    );

    const open = watch(`${index}.open`);

    const toggleForm = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setValue(`${index}.open`, !open);
    };

    useEffect(() => {
        if (isNew && (open || typeof open === 'undefined')) {
            setValue(`${index}.open`, true);
        }
    }, [isNew]);

    const handleDropdownChange = (e) => {
        if (e.target.value === 'custom') {
            setShowCustomQuestionInput(true);
            setTitle(t('Forms.Faqs.Title'));
        } else {
            setShowCustomQuestionInput(false);
            setTitle(e.target.value);
        }
    };

    const handleOnDelete = (e, i) => {
        onDelete(e, index);
        reset();
    };

    const handleCustomTitle = (e) => {
        if (e.target.value) {
            setTitle(e.target.value);
        } else {
            setTitle(t('Forms.Faqs.Title'));
        }
    };

    useEffect(() => {
        const question = getValues(`${index}.Question`);
        if (question) {
            if (question === 'custom') {
                setTitle(getValues(`${index}.CustomQuestion`));
            } else {
                setTitle(question);
            }
        }
    }, []);

    return (
        <FaqEditFormStyled data-testid="faq-form" error={hasError}>
            <div className="header">
                <div className="title">
                    <b>{title}</b>
                </div>
                <div className="buttons">
                    <IconButton
                        id="delete-button"
                        iconName={IconName.TRASH}
                        clickHandler={(e) => handleOnDelete(e, index)}
                    />
                    <IconButton
                        id="toggle-button"
                        iconName={open ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN}
                        clickHandler={(e) => toggleForm(e)}
                    />
                </div>
            </div>
            <div
                data-testid="form-section"
                className={`form ${open ? 'form-open' : 'form-closed'}`}
            >
                <FormRow
                    columns={[
                        <div className="questionDropdown">
                            <Dropdown
                                formFieldName={`${index}.Question`}
                                data-testid={`${index}.Question`}
                                id={`${index}.Question`}
                                defaultValue={pearlFaq.Question}
                                providedLabel={t('Forms.Faqs.QuestionLabel')}
                                onChange={handleDropdownChange}
                            >
                                {defaultFaqs?.map((q) => (
                                    <option key={q} value={q}>
                                        {q}
                                    </option>
                                ))}
                                <option key={'custom'} value={'custom'}>
                                    {'custom'}
                                </option>
                            </Dropdown>
                            <PearlError name={`${index}.Question`} />
                        </div>,
                    ]}
                />
                {showCustomQuestionInput && (
                    <FormRow
                        columns={[
                            <div>
                                <TextInput
                                    formFieldName={`${index}.CustomQuestion`}
                                    data-testid={`${index}.CustomQuestion`}
                                    providedLabel={`${t('Forms.Faqs.CustomQuestionLabel')}*`}
                                    placeholder={t('Forms.Faqs.CustomQuestionLabel')}
                                    maxLength={240}
                                    defaultValue={pearlFaq.CustomQuestion}
                                    onChange={handleCustomTitle}
                                />
                                <PearlError name={`${index}.CustomQuestion`} />
                            </div>,
                        ]}
                    />
                )}
                <FormRow
                    columns={[
                        <div>
                            <TextAreaInput
                                formFieldName={`${index}.Answer`}
                                data-testid={`${index}.Answer`}
                                label={`${t('Forms.Faqs.AnswerLabel')}*`}
                                placeholder={t('Forms.Faqs.AnswerLabel')}
                                defaultValue={pearlFaq.Answer}
                                maxLength={240}
                            />
                            <PearlError name={`${index}.Answer`} />
                        </div>,
                    ]}
                />
            </div>
        </FaqEditFormStyled>
    );
};

export { FaqEditForm };
