import { Outlet, useParams } from 'react-router-dom';
import { WebstoreService } from '../../lib/apis/WebstoreService';
import { useEffect, useState } from 'react';
import { useStore } from '../../../stores/Store';
import { SiteLoader } from '../../../shared/components/SiteLoader/SiteLoader';
import { VendorService } from '../../lib/apis/VendorService';

const AdminStoreProvider = () => {
    const params = useParams();
    const vendorApi = VendorService();
    const webstoreApi = WebstoreService();
    const { vendor, webstore } = useStore();

    const [loading, setLoading] = useState(true);

    const webstoreId = params.webstoreId;

    const getVendor = async (vendorId) => {
        return await vendorApi.getVendorById(vendorId);
    };

    const getWebstore = async () => {
        if (!webstore.Id) {
            const res = await webstoreApi.getWebstoreById(webstoreId, {
                removeInvalidImgSrc: false,
            });
            if (res.ok && res.data) {
                return res.data;
            } else {
                throw new Error('error fetching webstore');
            }
        }
    };

    useEffect(() => {
        const work = async () => {
            const webstoreData = await getWebstore();
            webstore.setFromApi(webstoreData);

            const vendorData = await getVendor(webstoreData.vendorId);
            vendor.setVendor(vendorData);

            setLoading(false);
        };

        work();
    }, []);

    return <>{loading ? <SiteLoader /> : <Outlet />}</>;
};

export { AdminStoreProvider };
