import { Plugins } from '@capacitor/core';
import apisauce from 'apisauce';
import { v4 } from 'uuid';
import { Capacitor } from '@capacitor/core';
import { AuthType } from '../../shared/constants/authType';
const { Browser } = Plugins;

const oAuthClientId = process.env.REACT_APP_DBISSO_CLIENT_ID;
const oAuthUrl = process.env.REACT_APP_DBISSO_URL;
const oAuthApiUrl = process.env.REACT_APP_DBISSO_API_URL;
const oAuthScope = 'openid profile email phone birthdate';

const openMobileBrowser = async (signInUrl) => {
    await Browser.open({ url: signInUrl });
};

function toQs(input) {
    return Object.keys(input)
        .map((x) => encodeURIComponent(x) + '=' + encodeURIComponent(input[x]))
        .join('&');
}

function getSignInUrl(state, authType) {
    const redirect = Capacitor.isNativePlatform()
        ? process.env.REACT_APP_CUSTOM_URL_SCHEME
        : `${process.env.REACT_APP_BASE_URL}/authcallback`;

    const queryParams = {
        client_id: oAuthClientId,
        scope: oAuthScope,
        response_type: 'code',
        state,
        redirect_uri: redirect,
    };

    const path = authType === AuthType.signup ? 'sign-up' : 'login';

    return `${oAuthUrl}/${path}?${toQs(queryParams)}`;
}

async function login(authType) {
    const state = v4();
    const signInUrl = getSignInUrl(state, authType);

    window.sessionStorage.setItem('authSource', 'dbisso');
    window.sessionStorage.setItem('authState', state);

    if (Capacitor.isNativePlatform()) {
        openMobileBrowser(signInUrl);
    } else {
        window.location = signInUrl;
    }
}

async function getToken(callbackState, code) {
    const localState = window.sessionStorage.getItem('authState');

    if (callbackState !== localState) {
        // TODO: SSO stuff
        throw new Error('Invalid state object returned from SSO');
    }

    const redirect = Capacitor.isNativePlatform()
        ? process.env.REACT_APP_CUSTOM_URL_SCHEME
        : `${process.env.REACT_APP_BASE_URL}/authcallback`;

    const queryParams = {
        client_id: oAuthClientId,
        scope: oAuthScope,
        grant_type: 'authorization_code',
        redirect_uri: redirect,
        code,
    };

    const qs = toQs(queryParams);

    const client = apisauce.create({
        baseURL: oAuthApiUrl,
        timeout: 10000,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    const { data } = await client.axiosInstance.post('/oauth/token', qs);

    return data;
}

async function refresh(refreshToken) {
    const queryParams = {
        client_id: oAuthClientId,
        scope: oAuthScope,
        grant_type: 'refresh_token',
        redirect_uri: window.location.origin,
        refresh_token: refreshToken,
    };

    const qs = toQs(queryParams);

    const client = apisauce.create({
        baseURL: oAuthApiUrl,
        timeout: 10000,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    try {
        const { data } = await client.axiosInstance.post('/oauth/token', qs);
        return data;
    } catch (e) {
        const error = e?.response?.data?.error;
        const errorDescription = e?.response?.data?.error_description;

        // most common:
        // error = 'invalid_grant'
        // desc = 'session not found or expired'
        console.log('error:', error, errorDescription);

        throw new Error('error refreshing token');
    }
}

const name = 'dbiSso';

export { login, getToken, refresh, name };
