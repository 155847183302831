import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const GoogleReviewsStyled = styled.div`
    h2 {
        margin-block: 0;
    }

    .webstoreRating {
        display: inline-flex;
        align-items: center;
        column-gap: 16px;
        margin-top: 18px;
    }

    .starRating {
        padding-bottom: 3px;
    }

    .reviewsLink {
        color: ${(props) => props.theme.colors.primary.base};
    }

    .reviewsContainer {
        display: flex;
        flex-direction: column;
    }

    .allReviewsButton {
        align-self: center;
    }

    .googleLogo {
        padding: 0px;
    }

    ${(props) => props.theme.pearlBreaks.up(BreakpointSizes.MD)} {
        .desktopReviewsList {
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            padding: 20px 0 24px 0;
        }
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .webstoreRating {
            margin-top: 0;
            margin-bottom: 22px;
        }

        .swiper-wrapper {
            margin-bottom: 12px;
        }

        .allReviewsButton {
            margin-top: 16px;
            width: 100%;
        }

        .googleLogo {
            margin-top: -28px;
        }
    }
`;

export { GoogleReviewsStyled };
