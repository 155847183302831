const cleanNumber = (text) => {
    const cleaned = text.replace(/\D/g, '');
    return cleaned;
};

// these prefixes should work for the following examples
// 1. +1314...
// 2. 1 314...
// 3. 1(314)...
// 4. 1-314...
const generateCountryStartsWith = (countryCode) => {
    return [`+${countryCode}`, `${countryCode} `, `${countryCode}(`, `${countryCode}-`];
};

export { cleanNumber, generateCountryStartsWith };
