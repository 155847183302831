import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { IconButton, IconName, IconSizes } from 'dbi-pearl-ui-kit';
import { ChatInputStyled } from './ChatInput.styled';

const AttachmentUpload = (props) => {
    const { onChange, disabled } = props;

    const inputRef = useRef(null);

    const handleChange = (e) => {
        const file = e.target.files[0];

        file && onChange(file);
    };

    return (
        <div>
            <IconButton
                iconName={IconName.ATTACH}
                size={IconSizes.MD}
                clickHandler={() => inputRef.current.click()}
                disabled={disabled}
            />
            <input
                type="file"
                name="input-attachment"
                id="input-attachment"
                ref={inputRef}
                onChange={handleChange}
                hidden
                disabled={disabled}
            />
        </div>
    );
};

const ChatInput = (props) => {
    const { onSend, disabled } = props;
    const { t } = useTranslation();

    const [draftMessage, setDraftMessage] = useState('');
    const [draftAttachment, setDraftAttachment] = useState(null);

    const handleSend = () => {
        if (draftMessage || draftAttachment) {
            const payload = {
                message: draftMessage,
                attachment: draftAttachment,
            };

            onSend(payload);
        }

        setDraftMessage('');
        setDraftAttachment(null);
    };

    const handleKeyPress = (e) => {
        e.key === 'Enter' && handleSend();
    };

    const handleAttachFile = (file) => {
        setDraftAttachment(file);
    };

    return (
        <ChatInputStyled className={`chat-input ${disabled ? 'disabled' : ''}`}>
            <input
                type="text"
                className="p2 input-message"
                placeholder={t('Messaging.SendAMessage')}
                onKeyPress={handleKeyPress}
                onChange={(e) => setDraftMessage(e.target.value)}
                value={draftMessage}
                disabled={disabled}
            />
            <div className="button-container">
                {draftAttachment && (
                    <Chip
                        className="p3"
                        label={draftAttachment.name}
                        variant="outlined"
                        onDelete={() => setDraftAttachment(null)}
                    />
                )}
                <AttachmentUpload onChange={handleAttachFile} disabled={disabled} />
                <IconButton
                    iconName={IconName.SEND}
                    size={IconSizes.MD}
                    clickHandler={handleSend}
                    disabled={disabled}
                />
            </div>
        </ChatInputStyled>
    );
};

export { ChatInput };
