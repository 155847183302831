const validateFileSize = (files, MAX_FILE_SIZE) => {
    return [
        files?.filter((file) => file.size <= MAX_FILE_SIZE),
        files?.filter((file) => file.size > MAX_FILE_SIZE),
    ];
};

const trimFiles = (images, files, MAX_IMAGES) => {
    const remainingFiles = images ? MAX_IMAGES - images?.length : MAX_IMAGES;
    if (files?.length > remainingFiles) {
        return files.slice(0, remainingFiles);
    }
    return files;
};

export { validateFileSize, trimFiles };
