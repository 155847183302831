import React from 'react';
import { PearlIcon, IconName } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import FlaggedImageStyled from './FlaggedImage.styled';

const FlaggedImage = (props) => {
    const { image } = props;
    const { t } = useTranslation();
    return (
        <FlaggedImageStyled>
            {!!image && (
                <div
                    data-testid="flagged-image"
                    className="original-image"
                    style={{ background: `url(${image.url})` }}
                >
                    <div className="validation-overlay flagged-image blur">
                        <div className="flagged-icon">
                            <PearlIcon iconName={IconName.WARNING}></PearlIcon>
                        </div>
                        <h5 className="flagged-header">{t('FlaggedImages.Header')}</h5>
                        <p className="p2 flagged-text">{t('FlaggedImages.Text')}</p>
                        <label className="flagged-contact">
                            <b>{t('FlaggedImages.Contact')}</b>
                        </label>
                    </div>
                </div>
            )}
        </FlaggedImageStyled>
    );
};

export default FlaggedImage;
