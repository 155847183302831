import * as Yup from 'yup';
import i18n from 'i18n';

const businessAddressSchema = Yup.object().shape({
    BusinessAddress: Yup.string()
        .nullable()
        .test(
            'correct components count',
            i18n.t('Forms.EditBasics.BusinessAddressFormation'),
            (value) => {
                let valid = true;
                const commaCount = value?.match(/,/g)?.length;

                if (!commaCount || commaCount < 2) {
                    valid = false;
                }

                return valid;
            }
        )
        .required(
            i18n.t('Forms.Validation.FieldRequired', {
                field: i18n.t('Forms.EditBasics.BusinessLocationLabel'),
            })
        ),
});

export { businessAddressSchema };
