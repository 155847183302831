import React from 'react';
import { ImageStyled } from './Image.styled';
import { IconName, PearlIcon, TruncatedTextDisplay } from 'dbi-pearl-ui-kit';
import { ask, FlaggedImage } from 'framework';
import { WebstoreService } from '../../lib/apis/WebstoreService';
import { useWebstoreContext } from '../../contexts/WebstoreContext/WebstoreContext';
import { useTranslation } from 'react-i18next';

const Image = (props) => {
    const { image, imageSelected, imageDeleted } = props;
    const { webstore } = useWebstoreContext();
    const webstoreApi = WebstoreService();
    const { t } = useTranslation();

    const imageKeyPressed = (e, image) => {
        if (e.key === 'Enter') {
            imageSelected(image);
        }
    };

    const deleteImage = (event, image) => {
        event.preventDefault();
        event.stopPropagation();

        ask(t('ConfirmDeleteTitle'), t('ConfirmDeleteMessage'))
            .then(() => {
                webstoreApi.deleteImage(webstore.Id, image.id).then(() => {
                    imageDeleted();
                });
            })
            .catch(() => {
                // User canceled the navigation
            });

        return false;
    };

    return (
        <ImageStyled
            className="preview"
            onClick={() => imageSelected(image)}
            onKeyPress={(e) => imageKeyPressed(e, image)}
            role="button"
            tabIndex={0}
        >
            {image.validationResult || image.adminApproved ? (
                <div
                    data-testid="nonflagged-image"
                    className="webstoreImage"
                    style={{ background: `url(${image.url})` }}
                >
                    <div className="grid">
                        {image.isCoverPhoto && (
                            <div className="cover-photo">
                                <div className="label">
                                    <b>{t('Forms.MediaEdit.CoverPhoto')}</b>
                                </div>
                            </div>
                        )}
                        {image.caption && (
                            <TruncatedTextDisplay className="caption" lineCount={2}>
                                <p className="p2">
                                    <b>{image.caption}</b>
                                </p>
                            </TruncatedTextDisplay>
                        )}
                    </div>
                </div>
            ) : (
                <FlaggedImage data-testid="flagged-image" image={image} />
            )}
            <button className="delete" type="button" onClick={(e) => deleteImage(e, image)}>
                <PearlIcon iconName={IconName.TRASH} />
            </button>
        </ImageStyled>
    );
};

export { Image };
