import styled from 'styled-components';
import { Link } from 'react-router-dom';
import React from 'react';
import { APP_ROUTES } from '../../../shared/constants/navigation';
import { useTranslation } from 'react-i18next';

const AdminAuthErrorPageStyled = styled.div`
    text-align: center;
    max-width: 650px;
    margin: 0 auto;

    ul,
    ol {
        text-align: initial;
        max-width: 400px;
        margin: 0 auto;
    }
`;

const AdminAuthErrorPage = () => {
    const { t } = useTranslation();

    return (
        <AdminAuthErrorPageStyled>
            <h1>Admin Authentication Error</h1>
            <h4>
                If you arrived here in error and wish to be navigated to the
                {' ' + t('Brand')} home page, please{' '}
                <Link to={APP_ROUTES.landing}>
                    <b>click here</b>
                </Link>
            </h4>
            <hr />
            <div>
                <h4>If you have reached this page, either</h4>
                <ul>
                    <li>you do not have permission to access this resource</li>
                    <li>there was an error establishing your admin session</li>
                </ul>
                <h4>If you believe you have permission to access this resource</h4>
                <ol>
                    <li>click 'Logout' in the top right corner</li>
                    <li>attempt to access to the desired resource again</li>
                </ol>
            </div>
        </AdminAuthErrorPageStyled>
    );
};

export { AdminAuthErrorPage };
