import BeautyImage from './beauty.jpg';
import CaterersImage from './catering.jpg';
import FloristsImage from './florists.jpg';
import MusicImage from './music.jpg';
import PhotographyImage from './photography-videography.jpg';
import VenuesImage from './venues.jpg';
import LodgingImage from './lodging.jpg';
import OfficiantsImage from './officiant.jpg';
import PlanningImage from './planning.jpg';
import RentalEquipmentImage from './rental-equipment.jpg';
import InvitationsStationeryImage from './invitations-stationery.jpg';
import FavorsGiftImage from './favors-gifts.jpg';
import TransportationImage from './transportation.jpg';
import JewelryImage from './jewelry.jpg';
import EntertainmentImage from './entertainment.jpg';
import HealthWellnessImage from './health-wellness.jpg';
import TravelImage from './travel.jpg';
import PhotoboothsImage from './photobooths.jpg';
import DressesImage from './dresses.jpg';
import TuxedosImage from './tuxedo.jpg';
import HomeImage from './home.jpg';
import VideographyImage from './videography.jpg';
import WeddingCakesImage from './cakes.jpg';
import OtherServicesImage from './other.jpg';
import DefaultImage from '../../pearl-bg.jpg';

// todo: translate captions, probably in the caller, because react
// ooooor - vendorCategoryTranslations in the db i think

const DefaultCategoryImage = {
    externalId: 'default',
    url: DefaultImage,
};

const CategoryImages = {
    travel: {
        externalId: 'travel',
        url: TravelImage,
    },
    music: {
        externalId: 'music',
        url: MusicImage,
    },
    beauty: {
        externalId: 'beauty',
        url: BeautyImage,
    },
    foodbeverage: {
        externalId: 'foodbeverage',
        url: CaterersImage,
    },
    bridaltuxedo: {
        externalId: 'bridaltuxedo',
        url: DressesImage,
    },
    entertainmentotherservices: {
        externalId: 'entertainmentotherservices',
        url: EntertainmentImage,
    },
    favorsgifts: {
        externalId: 'favorsgifts',
        url: FavorsGiftImage,
    },
    florist: {
        externalId: 'florist',
        url: FloristsImage,
    },
    healthwellness: {
        externalId: 'healthwellness',
        url: HealthWellnessImage,
    },
    home: {
        externalId: 'home',
        url: HomeImage,
    },
    invitationsstationery: {
        externalId: 'invitationsstationery',
        url: InvitationsStationeryImage,
    },
    jewelry: {
        externalId: 'jewelry',
        url: JewelryImage,
    },
    lodging: {
        externalId: 'lodging',
        url: LodgingImage,
    },
    officiants: {
        externalId: 'officiants',
        url: OfficiantsImage,
    },
    photobooths: {
        externalId: 'photobooths',
        url: PhotoboothsImage,
    },
    photographyvideography: {
        externalId: 'photographyvideography',
        url: PhotographyImage,
    },
    rentalequipment: {
        externalId: 'rentalequipment',
        url: RentalEquipmentImage,
    },
    transportation: {
        externalId: 'transportation',
        url: TransportationImage,
    },
    tuxedos: {
        externalId: 'tuxedos',
        url: TuxedosImage,
    },
    venues: {
        externalId: 'venues',
        url: VenuesImage,
    },
    videography: {
        externalId: 'videography',
        url: VideographyImage,
    },
    weddingcakes: {
        externalId: 'weddingcakes',
        url: WeddingCakesImage,
    },
    planning: {
        externalId: 'planning',
        url: PlanningImage,
    },
    otherservices: {
        externalId: 'otherservices',
        url: OtherServicesImage,
    },
};

export { CategoryImages, DefaultCategoryImage };
