import React, { useEffect, useRef } from 'react';
import { ChatElement } from '../ChatElement/ChatElement';
import { ChatInput } from '../ChatInput/ChatInput';
import { ConversationWindowStyled } from './ConversationWindow.styled';

const ConversationWindow = (props) => {
    const { messages, className, onSend } = props;

    const conversationStartMarker = useRef(null);
    const conversationEndMarker = useRef(null);

    useEffect(() => {
        // block: nearest scrolls just the container vs scrolling entire page
        conversationEndMarker.current?.scrollIntoView({ block: 'nearest' });
    }, [messages]);

    const messageComponents =
        messages?.map((message, index) => (
            <ChatElement key={index + message.type} message={message} />
        )) || [];

    return (
        <ConversationWindowStyled className={`${className || ''}`}>
            <div className="conversation-messages">
                <div ref={conversationStartMarker} className="conversation-messages-start" />
                {messageComponents}
                <div ref={conversationEndMarker} className="conversation-messages-end" />
            </div>
            <div className="controls-container">
                <ChatInput onSend={onSend} disabled={!onSend} />
            </div>
        </ConversationWindowStyled>
    );
};

export { ConversationWindow };
