import { EventService } from 'event-user/lib/apis/EventService';
import { reaction } from 'mobx';
import { VendorService } from '../vendor/lib/apis/VendorService';
import { WebstoreService } from '../vendor/lib/apis/WebstoreService';
import { unwrapResponse } from '../lib/apis/utils';

// TODO: Get rid of mobx (and our promise workaround) and use RxJS observables.

export const loadVendorReaction = async (authUser, vendor) => {
    const loadVendorForUser = async (username) => {
        const vendorApi = VendorService();
        vendor.setLoading(true);
        try {
            const res = await vendorApi.getVendorByUsername(username);

            if (res) {
                vendor.setVendor(res);
                authUser.Roles.Vendor = true;
            }
        } finally {
            vendor.setLoading(false);
        }
    };

    return new Promise((resolve) => {
        reaction(
            () => authUser.Username,
            async (username) => {
                if (username && (!vendor.id || vendor.userId !== authUser.Id)) {
                    await loadVendorForUser(username);
                } else if (username && vendor.id) {
                    vendor.setLoading(false);
                }
                resolve();
            }
        );
    });
};

export const loadWebstoreReaction = async (authUser, vendor, webstore) => {
    const loadOrCreateWebstoreForVendor = async () => {
        const vendorApi = VendorService();
        const webstoreApi = WebstoreService();

        webstore.setLoading(true);

        const webstores = await vendorApi.getWebstoresByVendorId(vendor.id, {
            removeInvalidImgSrc: false,
        });

        const webstoreToLoad =
            webstores.find((w) => w.id === vendor.defaultWebstoreId) || webstores[0];

        if (webstoreToLoad?.id) {
            const webstoreRes = await webstoreApi.getWebstoreById(webstoreToLoad.id, {
                removeInvalidImgSrc: false,
            });
            const defaultWebstore = unwrapResponse(webstoreRes);
            webstore.setFromApi(defaultWebstore);
        } else {
            const webstoreRes = await webstoreApi.createWebstore(vendor.id);
            webstore.setFromApi(webstoreRes);
        }

        webstore.setLoading(false);
    };

    return new Promise((resolve) => {
        reaction(
            () => `${authUser?.Id}-${vendor?.id}`,
            async () => {
                // for cyb webstores
                // the cyb webstore will be set in context
                // before the vendor is set in context
                // to prevent this from running and loading a different webstore
                // or creating a new one erroneously
                if (authUser.Id && vendor.id && (!webstore.Id || webstore.VendorId !== vendor.id)) {
                    await loadOrCreateWebstoreForVendor();
                    resolve();
                }
            }
        );
    });
};

export const loadEventUserReaction = async (authUser, eventUser) => {
    return new Promise((resolve) => {
        //this creates the event - but it needs the event user first
        reaction(
            () => authUser?.Id,
            async () => {
                if (authUser?.Id && !eventUser?.Id) {
                    eventUser.setLoading(true);

                    const { getEventUser, postEvent } = EventService();
                    const eventUserResponse = await getEventUser();

                    if (eventUserResponse.ok && eventUserResponse.data) {
                        eventUser.setFromApi(eventUserResponse.data);
                    } else {
                        const eventResponse = await postEvent(eventUser.forApi);

                        if (eventResponse.ok) {
                            eventUser.setFromApi(eventResponse.data);
                        }
                    }
                    eventUser.setLoading(false);
                    resolve();
                } else if (authUser?.Id && eventUser?.Id) {
                    eventUser.setLoading(false);
                    resolve();
                }
            }
        );
    });
};

export const loadEventReaction = async (eventUser, event) => {
    return new Promise((resolve) => {
        reaction(
            () => eventUser?.Id,
            async () => {
                if (eventUser?.Id && eventUser?.EventId && !event?.Id) {
                    event.setLoading(true);

                    const { getEventByEventId } = EventService();
                    const res = await getEventByEventId(eventUser.EventId);
                    if (res.ok) {
                        event.setFromApi(res.data);
                    }
                    event.setLoading(false);
                    resolve();
                } else if (eventUser?.Id) {
                    event.setLoading(false);
                    resolve();
                }
            }
        );
    });
};

export const updateVendorWithAuthUserReaction = (authUser, vendor) => {
    reaction(
        () => `${authUser.Id}-${vendor.id}`,
        async () => {
            if (authUser?.Id && vendor?.id) {
                const payload = {};

                // update if nullish
                if (!vendor.contactFirstName && authUser.FirstName) {
                    payload.contactFirstName = authUser.FirstName;
                }
                if (!vendor.contactLastName && authUser.LastName) {
                    payload.contactLastName = authUser.LastName;
                }
                if (!vendor.contactEmail && authUser.Email) {
                    payload.contactEmail = authUser.Email;
                }
                if (!vendor.contactPhone && authUser.PhoneNumber) {
                    payload.contactPhone = authUser.PhoneNumber;
                }

                const shouldPatch = Object.keys(payload).length > 0;

                if (shouldPatch) {
                    payload.id = vendor.id;
                    payload.fromAuthUser = true;

                    const vendorApi = VendorService();
                    const res = await vendorApi.patchVendor(payload);

                    if (res.ok && res.data) {
                        vendor.setVendor(res.data);
                    }
                }
            }
        }
    );
};

export const updateEventUserWithAuthUserReaction = (authUser, eventUser) => {
    reaction(
        () => `${authUser.Id}-${eventUser.Id}`,
        async () => {
            if (authUser?.Id && eventUser?.Id) {
                const payload = {};

                // update if nullish
                if (!eventUser.FirstName && authUser.FirstName) {
                    payload.firstName = authUser.FirstName;
                }
                if (!eventUser.LastName && authUser.LastName) {
                    payload.lastName = authUser.LastName;
                }
                if (!eventUser.Email && authUser.Email) {
                    payload.email = authUser.Email;
                }
                if (!eventUser.PhoneNumber && authUser.PhoneNumber) {
                    payload.phoneNumber = authUser.PhoneNumber;
                }

                const shouldPatch = Object.keys(payload).length > 0;

                if (shouldPatch) {
                    payload.fromAuthUser = true;

                    const eventApi = EventService();
                    const res = await eventApi.patchEventProfile({
                        users: [payload],
                    });

                    if (res.ok && res.data[0]) {
                        eventUser.setFromApi(res.data[0]);
                    }
                }
            }
        }
    );
};

export const mapCategoryIdToName = (webstore, appData) => {
    reaction(
        () => webstore.VendorCategoryId,
        (categoryId) => {
            if (categoryId && appData.categoryListIncludeArchived) {
                webstore.setLoading(true);
                webstore.setVendorCategoryName(appData.getCategoryName(categoryId));
                webstore.setLoading(false);
            }
        }
    );
};

export const mapCategoryIdToExternalId = (webstore, appData) => {
    reaction(
        () => webstore.VendorCategoryId,
        (categoryId) => {
            if (categoryId && appData.categoryListIncludeArchived) {
                webstore.setLoading(true);
                webstore.setVendorCategoryExternalId(appData.getCategoryExternalId(categoryId));
                webstore.setLoading(false);
            }
        }
    );
};

export const mapSubCategoryIdToName = (webstore, appData) => {
    reaction(
        () => webstore.VendorSubCategoryIds,
        (subCategoryIds) => {
            if (
                subCategoryIds &&
                webstore.VendorCategoryId &&
                appData.categoryListIncludeArchived
            ) {
                webstore.setLoading(true);
                webstore.setVendorSubCategoryName(
                    appData.getSubCategoryName(webstore.VendorCategoryId, subCategoryIds)
                );
                webstore.setLoading(false);
            }
        }
    );
};
