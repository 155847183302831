const crypto = require('crypto');

const CryptoService = (keyLocation) => {
    keyLocation = keyLocation || process.env;

    const encoding = 'base64';
    const hashAlg = 'rsa-sha256';

    const getPublicKey = () => {
        const key = keyLocation.REACT_APP_API_PUBLIC_KEY;
        const formattedKey = key.replace(/\\n/g, '\n');

        return formattedKey;
    };

    const sign = (data, privateKey) => {
        let signature;

        try {
            const signer = crypto.createSign(hashAlg);
            const signable = Buffer.from(data);

            signer.write(signable);
            signer.end();

            const sig = signer.sign(privateKey);
            signature = sig.toString(encoding);
        } catch (e) {
            console.log('error signing', e);
        }

        return signature;
    };

    const verify = (signature, data) => {
        let verified;

        try {
            const sig = Buffer.from(signature, encoding);
            const verifier = crypto.Verify(hashAlg);
            const publicKey = getPublicKey();

            verifier.write(data);
            verifier.end();

            verified = verifier.verify(publicKey, sig);

            if (!verified) {
                console.log('signature not valid');
            }
        } catch (e) {
            console.log('error verifying signature', e);
            verified = false;
        }

        return verified;
    };

    return {
        _sign: sign,
        verify,
    };
};

export { CryptoService };
