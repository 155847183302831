import styled from 'styled-components';

export default styled.div`
    display: block;
    text-align: center;
    max-width: 100%;
    min-width: 100%;
    margin-top: 12px;
    color: ${(props) => props.theme.colors.foreground.secondary_text};
    gap: 17px;

    > * {
        width: 100%;
    }

    h3 {
        margin-bottom: 8px;
        text-transform: none;
    }

    #warning {
        font-size: 35px;
        color: ${(props) => props.theme.colors.error.base};
    }

    .p2 {
        margin-top: 0;
        margin-bottom: 24px;
    }

    .pearlAlertContent {
        margin-top: 22px;
    }

    #save-button {
        width: 200px;
        margin-bottom: 18px;
    }

    #back-button {
        width: 145px;
    }
`;
