import { MediaSectionEdit } from './SectionEdit/MediaSectionEdit/MediaSectionEdit';
import { AvailabilityCalendarSectionEdit } from './SectionEdit/AvailabilityCalendarSectionEdit/AvailabilityCalendarSectionEdit';
import { EditAboutPage } from 'vendor/pages/EditWebstoreSectionPages/EditAboutPage/EditAboutPage';
import { EditPackagesPage } from 'vendor/pages/EditWebstoreSectionPages/EditPackagesPage/EditPackagesPage';
import { EditAwardsPage } from 'vendor/pages/EditWebstoreSectionPages/EditAwardsPage/EditAwardsPage';
import { AmenitiesAndServicesSectionEdit } from './SectionEdit/AmenitiesAndServicesSectionEdit/AmenitiesAndServicesSectionEdit';
import { GoogleReviewsSectionEdit } from './SectionEdit/GoogleReviewsSectionEdit/GoogleReviewsSectionEdit';
import React from 'react';
import {
    AboutSection,
    AmenitiesServices,
    AvailabilityCalendar,
    PearlApps,
    WebstoreGallery,
    WebstoreSectionTypes,
    moderateImages,
} from 'framework';
import { PackagesSection } from './PackagesSection/PackagesSection';
import { FaqsSection } from './FaqsSection/FaqsSection';
import { PreferredPartnersSection } from './PreferredPartnersSection/PreferredPartnersSection';
import { AwardsSection } from './AwardsSection/AwardsSection';
import { GoogleReviewsSection } from './GoogleReviewsSection/GoogleReviewsSection';
import { EditFaqsPage } from 'vendor/pages/EditWebstoreSectionPages/EditFaqsPage/EditFaqsPage';
import { EditPartnersPage } from '../../pages/EditWebstoreSectionPages/EditPartnersPage/EditPartnersPage';
import { navigation } from '../../../shared/constants/navigation';

const WEBSTORE_HEADER_SECTION_NAME = 'basics';

const EditableSections = new Map([
    [WEBSTORE_HEADER_SECTION_NAME, <EditAboutPage />],
    [WebstoreSectionTypes.AMENITIESANDSERVICES, <AmenitiesAndServicesSectionEdit />],
    [WebstoreSectionTypes.AVAILABILITY_CALENDAR, <AvailabilityCalendarSectionEdit />],
    [WebstoreSectionTypes.GALLERY, <MediaSectionEdit />],
    [WebstoreSectionTypes.REVIEWS, <GoogleReviewsSectionEdit />],
    [WebstoreSectionTypes.PACKAGES, <EditPackagesPage />],
    [WebstoreSectionTypes.FAQS, <EditFaqsPage />],
    [WebstoreSectionTypes.PARTNERS, <EditPartnersPage />],
    [WebstoreSectionTypes.AWARDS, <EditAwardsPage />],
]);

const ToggleableSectionsValidation = {
    [WebstoreSectionTypes.PACKAGES]: {
        UIValidation: (activeFeatures, packages) =>
            packages?.length > 0 &&
            (activeFeatures?.feature_premiumBadge || activeFeatures?.feature_packages),
    },
    [WebstoreSectionTypes.AWARDS]: {
        UIValidation: (activeFeatures, awards) =>
            awards?.length > 0 &&
            (activeFeatures?.feature_premiumBadge || activeFeatures?.feature_awards),
    },
    [WebstoreSectionTypes.REVIEWS]: {
        UIValidation: (activeFeatures, googlePlaceId) =>
            !!googlePlaceId &&
            (activeFeatures?.feature_premiumBadge || activeFeatures?.feature_reviews),
    },
    [WebstoreSectionTypes.PARTNERS]: {
        UIValidation: (partners) => partners?.length > 0,
    },
    [WebstoreSectionTypes.FAQS]: {
        UIValidation: (activeFeatures, faqs) =>
            faqs?.length > 0 &&
            (activeFeatures?.feature_premiumBadge || activeFeatures?.feature_faqs),
    },
    [WebstoreSectionTypes.AVAILABILITY_CALENDAR]: {
        UIValidation: () => true,
    },
};

const ToggleableSections = [
    {
        name: WebstoreSectionTypes.PACKAGES,
        sectionValid: (webstore) =>
            ToggleableSectionsValidation[WebstoreSectionTypes.PACKAGES].UIValidation(
                webstore.ActiveFeatures,
                webstore.Packages
            ),
    },
    {
        name: WebstoreSectionTypes.REVIEWS,
        sectionValid: (webstore) =>
            ToggleableSectionsValidation[WebstoreSectionTypes.REVIEWS].UIValidation(
                webstore.ActiveFeatures,
                webstore.GooglePlaceId
            ),
    },
    {
        name: WebstoreSectionTypes.FAQS,
        sectionValid: (webstore) =>
            ToggleableSectionsValidation[WebstoreSectionTypes.FAQS].UIValidation(
                webstore.ActiveFeatures,
                webstore.Faqs
            ),
    },
    {
        name: WebstoreSectionTypes.PARTNERS,
        sectionValid: (webstore) =>
            ToggleableSectionsValidation[WebstoreSectionTypes.PARTNERS].UIValidation(
                webstore.Partners
            ),
    },
    {
        name: WebstoreSectionTypes.AWARDS,
        sectionValid: (webstore) =>
            ToggleableSectionsValidation[WebstoreSectionTypes.AWARDS].UIValidation(
                webstore.ActiveFeatures,
                webstore.Awards
            ),
    },
    {
        name: WebstoreSectionTypes.AVAILABILITY_CALENDAR,
        sectionValid: () =>
            ToggleableSectionsValidation[WebstoreSectionTypes.AVAILABILITY_CALENDAR].UIValidation(),
    },
];

function WebstoreDetailsSectionFactory(props) {
    const { webstore, webstoreApi, sectionType, sectionRefs } = props;

    moderateImages(webstore, PearlApps.VENDOR);

    const sectionId =
        navigation.navigationSections.find((x) => x.section === props.sectionType)?.anchorId || '';

    switch (sectionType) {
        case WebstoreSectionTypes.GALLERY:
            return (
                <WebstoreGallery
                    webstore={webstore}
                    sectionRef={sectionRefs.gallery}
                    sectionId={sectionId}
                />
            );
        case WebstoreSectionTypes.AMENITIESANDSERVICES:
            return (
                <AmenitiesServices
                    webstore={webstore}
                    sectionRef={sectionRefs.amenities}
                    sectionId={
                        navigation.navigationSections.find(
                            (x) => x.section === WebstoreSectionTypes.ABOUT
                        ).anchorId
                    }
                />
            );
        case WebstoreSectionTypes.ABOUT:
            return (
                <AboutSection
                    webstore={webstore}
                    sectionRef={sectionRefs.about}
                    sectionId={sectionId}
                    hideNoInfoText={false}
                />
            );
        case WebstoreSectionTypes.AVAILABILITY_CALENDAR:
            return (
                <AvailabilityCalendar
                    webstore={webstore}
                    webstoreApi={webstoreApi}
                    sectionRef={sectionRefs.availability}
                    sectionId={sectionId}
                />
            );
        case WebstoreSectionTypes.PACKAGES:
            return (
                <PackagesSection
                    webstore={webstore}
                    sectionRef={sectionRefs.packages}
                    sectionId={sectionId}
                    showFlaggedOverlay={true}
                />
            );
        case WebstoreSectionTypes.REVIEWS:
            return (
                <GoogleReviewsSection
                    webstore={webstore}
                    sectionRef={sectionRefs.reviews}
                    sectionId={sectionId}
                />
            );
        case WebstoreSectionTypes.FAQS:
            return (
                <FaqsSection
                    webstore={webstore}
                    sectionRef={sectionRefs.faqs}
                    sectionId={sectionId}
                />
            );
        case WebstoreSectionTypes.PARTNERS:
            return (
                <PreferredPartnersSection
                    webstore={webstore}
                    sectionRef={sectionRefs.partners}
                    sectionId={sectionId}
                />
            );
        case WebstoreSectionTypes.AWARDS:
            return (
                <AwardsSection
                    webstore={webstore}
                    sectionRef={sectionRefs.awards}
                    sectionId={sectionId}
                    showFlaggedOverlay={true}
                />
            );
        default:
            return 'Unknown Section Type';
    }
}

function SectionCanBeEdited(section) {
    return EditableSections.has(section);
}

export {
    WebstoreDetailsSectionFactory,
    SectionCanBeEdited,
    EditableSections,
    ToggleableSections,
    ToggleableSectionsValidation,
};
