const EventUserSetupStepForms = {
    EVENT_BASICS: 'event-basics',
    EVENT_DATE: 'event-date',
    EVENT_LOCATION: 'event-location',
    EVENT_DETAILS: 'event-details',
    EVENT_VENDORS: 'event-vendors',
};

const DEFAULT_STEP = EventUserSetupStepForms.EVENT_BASICS;
const COMPLETE_STEP = 'COMPLETE';

export { COMPLETE_STEP, DEFAULT_STEP, EventUserSetupStepForms };
