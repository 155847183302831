const addressTypes = {
    BusinessAddress: 'BusinessAddress',
    ServiceAddress: 'ServiceAddress',
};

const formFieldNames = {
    IsServiceTypeSelected: 'IsServiceTypeSelected',
    LocationAddressType: 'LocationAddressType',
    ServiceAddress: addressTypes.ServiceAddress,
    OnlineStore: 'OnlineStore',
    ShippingRange: 'ShippingRange',
    PhysicalStore: 'PhysicalStore',
    BusinessAddress: addressTypes.BusinessAddress,
    DisplayStoreAddress: 'DisplayStoreAddress',
    ServiceAddressStreet: 'ServiceAddressStreet',
    ServiceAddressStreet2: 'ServiceAddressStreet2',
    ServiceAddressCity: 'ServiceAddressCity',
    ServiceAddressState: 'ServiceAddressState',
    ServiceAddressZipcode: 'ServiceAddressZipcode',
    ServiceAddressIsBusinessAddress: 'ServiceAddressIsBusinessAddress',
    Travels: 'Travels',
    TravelOption: 'TravelOption',
    TravelDistance: 'TravelDistance',
    ServiceArea: 'ServiceArea',
    ServiceAreaCity: 'ServiceAreaCity',
    ServiceAreaState: 'ServiceAreaState',
    BusinessAddressStreet1: 'BusinessAddressStreet1',
    BusinessAddressStreet2: 'BusinessAddressStreet2',
    BusinessAddressCity: 'BusinessAddressCity',
    BusinessAddressState: 'BusinessAddressState',
    BusinessAddressZipcode: 'BusinessAddressZipcode',
};

const fieldToPropertyMap = {
    businessAddressFieldMapping: {
        [formFieldNames.BusinessAddressStreet1]: 'address1',
        [formFieldNames.BusinessAddressStreet2]: 'address2',
        [formFieldNames.BusinessAddressCity]: 'city',
        [formFieldNames.BusinessAddressState]: 'state',
        [formFieldNames.BusinessAddressZipcode]: 'zipCode',
    },
    serviceLocationAddressFieldMapping: {
        [formFieldNames.ServiceAddressStreet]: 'address1',
        [formFieldNames.ServiceAddressStreet2]: 'address2',
        [formFieldNames.ServiceAddressCity]: 'city',
        [formFieldNames.ServiceAddressState]: 'state',
        [formFieldNames.ServiceAddressZipcode]: 'zipCode',
    },
    serviceAreaAddressFieldMapping: {
        [formFieldNames.ServiceAreaCity]: 'city',
        [formFieldNames.ServiceAreaState]: 'state',
        [formFieldNames.TravelDistance]: 'travelRadius',
    },
};

const addressFieldToGoogleResponseMapping = (
    setValue,
    svcLocationQueryResult,
    addressFieldNames
) => {
    let streetAddress1;
    let streetAddress2;
    let city;
    let state;
    let zipcode;

    for (const field of svcLocationQueryResult.address_components) {
        const fieldType = field.types[0];

        switch (fieldType) {
            case 'street_number': {
                streetAddress1 = `${field.long_name} `;
                break;
            }
            case 'route': {
                streetAddress1 += field.short_name;
                break;
            }
            case 'subpremise': {
                streetAddress2 = `${field.long_name}`;
                break;
            }
            case 'locality': {
                city = field.long_name;
                break;
            }
            case 'administrative_area_level_1': {
                state = field.short_name;
                break;
            }
            case 'postal_code': {
                zipcode = `${field.long_name || ''}`;
                break;
            }
            case 'postal_code_suffix': {
                zipcode = `${zipcode}-${field.long_name || ''}`;
                break;
            }
            default: {
                break;
            }
        }
    }
    setValue(addressFieldNames.street1, streetAddress1);
    if (addressFieldNames.street2) {
        setValue(addressFieldNames.street2, streetAddress2);
    }
    setValue(addressFieldNames.city, city);
    setValue(addressFieldNames.state, state);
    setValue(addressFieldNames.zipcode, zipcode);
};

// Currently only 1 address per type is supported
// Pearl app uses 1st address of each type
// Business address is required - can only mutate, not delete address (UI validation ensures address is filled out)
const webstoreAddressTypes = {
    Store: 'store',
    Business: 'business',
    ServiceArea: 'serviceArea',
};

const travelOptions = {
    Locally: 'locally',
    Nationally: 'nationally',
    Globally: 'globally',
};

const resetAddressProperties = (address) => {
    return {
        ...address,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zipCode: null,
        travelRadius: null,
        latitude: null,
        longitude: null,
    };
};

const isServiceAddressSameAsBusiness = (webstore) => {
    return !!webstore.ServiceAddressIsBusinessAddress;
};

const getBusinessAddress = (webstore) => {
    return webstore.Addresses?.find(
        (address) => address.addressType === webstoreAddressTypes.Business
    );
};

const getServiceLocationAddress = (webstore) => {
    return webstore.Addresses?.find(
        (address) => address.addressType === webstoreAddressTypes.Store
    );
};

const getServiceArea = (webstore) => {
    return webstore.Addresses?.find(
        (address) => address.addressType === webstoreAddressTypes.ServiceArea
    );
};

const removeServiceLocation = (webstore) => {
    webstore.Addresses = webstore.Addresses.filter(
        (address) => address.addressType !== webstoreAddressTypes.Store
    );
};

const removeServiceArea = (webstore) => {
    webstore.Addresses = webstore.Addresses.filter(
        (address) => address.addressType !== webstoreAddressTypes.ServiceArea
    );
};

const copyBusinessAddressToServiceLocation = (webstore) => {
    const businessAddress = getBusinessAddress(webstore);
    const serviceAddress = getServiceLocationAddress(webstore);

    if (businessAddress) {
        if (serviceAddress) {
            removeServiceLocation(webstore);
        }
        const newServiceAddress = createNewServiceLocationAddress();

        newServiceAddress.street1 = businessAddress.street1;
        newServiceAddress.street2 = businessAddress.street2;
        newServiceAddress.city = businessAddress.city;
        newServiceAddress.state = businessAddress.state;
        newServiceAddress.zipCode = businessAddress.zipCode;

        webstore.Addresses.push(newServiceAddress);
    }
};

const createNewBusinessAddress = () => {
    return {
        id: null,
        addressType: webstoreAddressTypes.Business,
    };
};

const createNewServiceLocationAddress = () => {
    return {
        id: null,
        addressType: webstoreAddressTypes.Store,
    };
};

const createNewServiceArea = () => {
    return {
        id: null,
        addressType: webstoreAddressTypes.ServiceArea,
    };
};

// business address is required - can only mutate, not delete address (UI validation ensures address is filled out)
const formatBusinessLocationForPatch = (webstore, businessLocationData) => {
    const locToAddrMap = fieldToPropertyMap.businessAddressFieldMapping;

    let existingBusinessAddress = getBusinessAddress(webstore);

    if (!existingBusinessAddress) {
        existingBusinessAddress = createNewServiceLocationAddress();
    }

    webstore.Addresses = webstore.Addresses.filter(
        (addr) => addr.addressType !== webstoreAddressTypes.Business
    );

    const mapDataToPatchProps = () => {
        Object.keys(locToAddrMap).forEach((key) => {
            existingBusinessAddress = {
                ...existingBusinessAddress,
                [locToAddrMap[key]]: businessLocationData[key],
            };
        });

        existingBusinessAddress.addressType = webstoreAddressTypes.Business;
    };

    mapDataToPatchProps();

    webstore.Addresses.push(existingBusinessAddress);
};

const formatServiceLocationForPatch = (webstore, serviceLocationAddressData) => {
    const locToAddrMap = fieldToPropertyMap.serviceLocationAddressFieldMapping;

    let existingLocationAddress = getServiceLocationAddress(webstore);

    //if there isn't an existing serviceLocation & physical store isn't true on the form, do nothing
    if (!existingLocationAddress && !serviceLocationAddressData?.PhysicalStore) {
        return;
    }

    // if there is an existing service location, remove it from addresses
    if (existingLocationAddress) {
        webstore.Addresses = webstore.Addresses.filter(
            (addr) => addr.addressType !== webstoreAddressTypes.Store
        );
    }
    // if physical store is true, then handle updating/creating the properties
    if (serviceLocationAddressData?.PhysicalStore) {
        // create a new address to get correct service type added if it doesn't already exist
        if (!existingLocationAddress) {
            existingLocationAddress = createNewServiceLocationAddress();
        }

        const mapDataToPatchProps = () => {
            Object.keys(locToAddrMap).forEach((key) => {
                existingLocationAddress = {
                    ...existingLocationAddress,
                    [locToAddrMap[key]]: serviceLocationAddressData[key],
                };
            });

            existingLocationAddress.addressType = webstoreAddressTypes.Store;
        };

        mapDataToPatchProps();

        webstore.Addresses.push(existingLocationAddress);
    }
};

const formatServiceAreaForPatch = (webstore, serviceAreaData) => {
    const locToAddrMap = fieldToPropertyMap.serviceAreaAddressFieldMapping;

    let existingServiceArea = getServiceArea(webstore);

    //if there isn't an existing serviceArea & form didn't have local travel, do nothing
    if (!existingServiceArea && serviceAreaData?.TravelOption !== travelOptions.Locally) {
        return;
    }

    // if there is an existing service area, remove it from addresses
    if (existingServiceArea) {
        webstore.Addresses = webstore.Addresses.filter(
            (addr) => addr.addressType !== webstoreAddressTypes.ServiceArea
        );
    }

    // if travel is local, then handle updating/creating the properties
    if (serviceAreaData?.TravelOption === travelOptions.Locally) {
        // create a new address to get correct service type added if it doesn't already exist
        if (!existingServiceArea) {
            existingServiceArea = createNewServiceArea();
        }

        //if city/state was typed w/out google autocomplete, get the city and state from the displayed ServiceArea field
        if (!serviceAreaData.ServiceAreaCity && !serviceAreaData.ServiceAreaState) {
            const cityStateArray = serviceAreaData.ServiceArea?.split(',');

            if (cityStateArray) {
                if (cityStateArray[0]) {
                    serviceAreaData.ServiceAreaCity = cityStateArray[0].trim();
                }
                if (cityStateArray[1]) {
                    serviceAreaData.ServiceAreaState = cityStateArray[1]?.trim();
                }
            }
        }

        const mapDataToPatchProps = () => {
            Object.keys(locToAddrMap).forEach((key) => {
                existingServiceArea = {
                    ...existingServiceArea,
                    [locToAddrMap[key]]: serviceAreaData[key],
                };
            });

            existingServiceArea.addressType = webstoreAddressTypes.ServiceArea;
        };

        mapDataToPatchProps();

        webstore.Addresses.push(existingServiceArea);
    }
};

const updateServiceLocationDataWithBusinessLocationData = (data) => {
    data[formFieldNames.ServiceAddressStreet] = data[formFieldNames.BusinessAddressStreet1];
    data[formFieldNames.ServiceAddressStreet2] = data[formFieldNames.BusinessAddressStreet2];
    data[formFieldNames.ServiceAddressCity] = data[formFieldNames.BusinessAddressCity];
    data[formFieldNames.ServiceAddressState] = data[formFieldNames.BusinessAddressState];
    data[formFieldNames.ServiceAddressZipcode] = data[formFieldNames.BusinessAddressZipcode];
};

const getSelectedAddressType = (webstore) => {
    if (webstore.PhysicalStore && webstore.ServiceAddressIsBusinessAddress) {
        return addressTypes.BusinessAddress;
    }
    if (webstore.PhysicalStore && webstore.ServiceAddressIsBusinessAddress !== null) {
        return addressTypes.ServiceAddress;
    }
    return null;
};

const concatAddressProperties = (address) => {
    const { address1, address2, city, state, zipCode } = address;

    let concatted = '';
    if (!!address1) {
        concatted = String(concatted).concat(address1, ', ');
    }
    if (!!address2) {
        concatted = String(concatted).concat(address2, ', ');
    }
    if (!!city) {
        concatted = String(concatted).concat(city, ', ');
    }
    if (!!state) {
        concatted = String(concatted).concat(state);
    }
    if (!!zipCode) {
        concatted = String(concatted).concat(` ${zipCode}`);
    }
    return concatted;
};

const getWebstoreAddress = (webstore, options = {}) => {
    let addresses = [];

    if (webstore.addresses) {
        addresses = webstore.addresses;
    } else {
        addresses = webstore.Addresses;
    }

    const addressDto = {
        Addresses: addresses,
    };

    const serviceArea = getServiceArea(addressDto);

    if (serviceArea) {
        return serviceArea;
    }
    const serviceAddress = getServiceLocationAddress(addressDto);

    if (serviceAddress) {
        return serviceAddress;
    }

    const businessAddress = getBusinessAddress(addressDto);

    if (businessAddress) {
        return businessAddress;
    }

    return null;
};

const getSeparator = (displayTextToCheck) => {
    return displayTextToCheck?.length > 0 ? ', ' : '';
};

const formatSingleLineServiceArea = (addressToDisplay) => {
    let city;
    let state;

    city = addressToDisplay.city;
    state = addressToDisplay.state;
    let displayText = city?.length > 0 ? `${city}` : '';
    displayText =
        state?.length > 0 ? `${displayText}${getSeparator(displayText)}${state}` : displayText;
    return displayText;
};

const formatSingleLineServiceLocation = (addressToDisplay, includeStreet) => {
    let street;
    let city;
    let state;

    street = addressToDisplay.address1;
    city = addressToDisplay.city;
    state = addressToDisplay.state;
    let displayText = '';
    if (includeStreet) {
        displayText = street ? `${street}` : '';
    }
    displayText =
        city?.length > 0 ? `${displayText}${getSeparator(displayText)}${city}` : displayText;
    displayText =
        state?.length > 0 ? `${displayText}${getSeparator(displayText)}${state}` : displayText;
    return displayText;
};

const formatSingleLineBusinessAddress = (addressToDisplay, includeStreet) => {
    let street;
    let city;
    let state;

    street = addressToDisplay.address1;
    city = addressToDisplay.city;
    state = addressToDisplay.state;
    let displayText = '';

    if (includeStreet) {
        displayText = street ? `${street}` : '';
    }
    displayText =
        city?.length > 0 ? `${displayText}${getSeparator(displayText)}${city}` : displayText;
    displayText =
        state?.length > 0 ? `${displayText}${getSeparator(displayText)}${state}` : displayText;
    return displayText;
};

const formatSingleLineAddress = (webstore, includeStreet = true, options = {}) => {
    let addressToDisplay;

    const displayAddress =
        webstore.DisplayStoreAddress || webstore.displayStoreAddress || options.includeAll;

    if (!options.addressToDisplay) {
        addressToDisplay = getWebstoreAddress(webstore, options) || {};
    }

    if (addressToDisplay.addressType === webstoreAddressTypes.ServiceArea) {
        return formatSingleLineServiceArea(addressToDisplay);
    }

    if (addressToDisplay.addressType === webstoreAddressTypes.Store && displayAddress) {
        return formatSingleLineServiceLocation(addressToDisplay, includeStreet);
    }

    if (addressToDisplay.addressType === webstoreAddressTypes.Business && displayAddress) {
        return formatSingleLineBusinessAddress(addressToDisplay, includeStreet);
    }

    return '';
};

const ensureTravelOptionIfDistanceProvided = (webstore) => {
    if (!webstore.hasCapacity) {
        if (webstore.TravelOption) {
            return webstore.TravelOption;
        }

        const serviceArea = getServiceArea(webstore);

        if (serviceArea?.travelRadius) {
            return travelOptions.Locally;
        }
    }
    return null;
};

export {
    resetAddressProperties,
    travelOptions,
    webstoreAddressTypes,
    formatBusinessLocationForPatch,
    formatServiceAreaForPatch,
    formatServiceLocationForPatch,
    addressFieldToGoogleResponseMapping,
    isServiceAddressSameAsBusiness,
    getServiceLocationAddress,
    getServiceArea,
    getBusinessAddress,
    createNewBusinessAddress,
    createNewServiceLocationAddress,
    createNewServiceArea,
    getSelectedAddressType,
    concatAddressProperties,
    formatSingleLineAddress,
    removeServiceLocation,
    removeServiceArea,
    copyBusinessAddressToServiceLocation,
    ensureTravelOptionIfDistanceProvided,
    formatSingleLineServiceArea,
    formatSingleLineServiceLocation,
    formatSingleLineBusinessAddress,
    addressTypes,
    formFieldNames,
    fieldToPropertyMap,
    updateServiceLocationDataWithBusinessLocationData,
};
