import * as Yup from 'yup';
import { WebstoreService } from 'vendor/lib/apis/WebstoreService';
import i18n from 'i18n';
const webstoreUrlSchemaWrapper = (webstore) => {
    const validationSchema = Yup.object()
        .shape({
            WebstoreUrl: Yup.string()
                .max(
                    120,
                    i18n.t('Forms.Validation.CannotExceedCharacterLimit', {
                        characterLimit: '120',
                    })
                )
                .matches(new RegExp('^[a-zA-Z0-9-]*$'), 'invalid web store url')
                .test(
                    'checkWebstoreNameAvailable',
                    i18n.t('Forms.Validation.WebstoreNameExists'),
                    async function (value) {
                        const webStoreApi = WebstoreService();
                        const res = await webStoreApi.getCheckWebstoreUrlAvailable(
                            value,
                            webstore.Id
                        );
                        return res.ok && res.data.urlIsAvailable;
                    }
                )
                .required(
                    i18n.t('Forms.Validation.FieldRequired', {
                        field: i18n.t('Forms.Labels.PearlWebstoreUrl'),
                    })
                )
                .nullable(),
        })
        .required();
    return validationSchema;
};
export { webstoreUrlSchemaWrapper };
