import styled from 'styled-components';

const EditMediaLayoutStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .top {
        margin: 48px 15% 14px 15%;
        display: flex;
        flex-direction: column;
        height: 100%;

        .back-button {
            display: flex;
            align-self: start;
            border: unset;
            justify-content: center;
        }

        main {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            max-width: 1000px;

            section {
                margin-bottom: 40px;
            }
        }
    }

    @media (max-width: 815px) {
        width: unset;

        .top {
            padding: 5px 16px;
            margin: 25px 0 0 0;
        }
    }
`;

export { EditMediaLayoutStyled };
