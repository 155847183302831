import { useTranslation } from 'react-i18next';
import React from 'react';
import { SolidButton, LinkButton } from 'dbi-pearl-ui-kit';
import { usePearlModal } from 'framework';
import { GoogleReviewsDisconnectorStyled } from './GoogleReviewsDisconnector.styled';

function GoogleReviewsDisconnector(props) {
    const { disconnectAccount } = props;

    const { t } = useTranslation();
    const { closeModal } = usePearlModal();

    return (
        <GoogleReviewsDisconnectorStyled>
            <h3 className="promptHeader">{t('WebstoreSections.Reviews.Title')}</h3>
            <p className="p2 promptQuestion">{t('WebstoreSections.Reviews.DisconnectPrompt')}</p>
            <div className="promptButtons">
                <SolidButton onClick={disconnectAccount}>
                    {t('WebstoreSections.Reviews.DisconnectAccountButton')}
                </SolidButton>
                <LinkButton className="discardButton" variant="accent" onClick={closeModal}>
                    {t('WebstoreSections.Reviews.Discard')}
                </LinkButton>
            </div>
        </GoogleReviewsDisconnectorStyled>
    );
}

export { GoogleReviewsDisconnector };
