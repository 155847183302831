import React from 'react';
import { GhostButton, TruncatedTextDisplay } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import { PreferredPartnerTileStyled } from './PreferredPartnerTile.styled';

// TODO: DBIPP-2496: Change to use VendorTile for maintainability
function PreferredPartnerTile(props) {
    const {
        coverPhoto,
        partnerName,
        subCategoryName,
        serviceCity,
        serviceState,
        description,
        webstoreUrl,
        onViewVendorClick,
    } = props;

    const { t } = useTranslation();

    return (
        <PreferredPartnerTileStyled role="listitem" data-testid={`partner-${partnerName}`}>
            <div className="partner-image">
                {coverPhoto && (
                    <img
                        src={coverPhoto.url}
                        alt={coverPhoto.caption || ''}
                        className={coverPhoto.placeholder ? 'placeholder' : ''}
                    />
                )}
            </div>
            <div className="partner-details">
                <TruncatedTextDisplay lineCount={1}>
                    <h4>{partnerName}</h4>
                </TruncatedTextDisplay>
                <div className="partner-subtitle">
                    <p className="p4 category">{subCategoryName ? subCategoryName : ' '}</p>
                    <p className="p4 separator">&middot;</p>
                    <p className="p4 location">{`${serviceCity}, ${serviceState}`}</p>
                </div>
                <p className="label">
                    <b>{t('WebstoreSections.PreferredPartners.DescriptionHeader')}</b>
                </p>
                <p className="p3 description">{description}</p>
                <GhostButton
                    ariaLabel={`${t(
                        'WebstoreSections.PreferredPartners.ViewVendorButton'
                    )} - ${partnerName}`}
                    onClick={() => onViewVendorClick(webstoreUrl)}
                    variant="accent"
                >
                    {t('WebstoreSections.PreferredPartners.ViewVendorButton')}
                </GhostButton>
            </div>
        </PreferredPartnerTileStyled>
    );
}

export default PreferredPartnerTile;
