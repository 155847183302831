import styled from 'styled-components';

const ConversationWindowStyled = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .conversation-messages {
        overflow-y: auto;
        padding: 0 20px;
        margin: 0;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 32px;
    }

    // somewhat hacky solution to push messages in partially filled message boxes
    // to the bottom of the box; use until justify-content: flex-end bug is fixed
    // (bug causes scroll bar to disappear)
    .conversation-messages-start {
        flex: 1 1 auto;
    }

    .controls-container {
        margin: 0;
    }
`;

export { ConversationWindowStyled };
