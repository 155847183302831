import { IconName, LinkButton } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import { EditMediaLayoutStyled } from './EditMediaLayout.styled';

const EditMediaLayout = (props) => {
    const { t } = useTranslation();

    const { children, goBack } = props;

    return (
        <EditMediaLayoutStyled>
            <div className="top">
                <LinkButton
                    data-testid="back-button-link"
                    className={'back-button'}
                    startIcon={IconName.ARROW_LEFT}
                    variant="accent"
                    onClick={goBack}
                >
                    {t('Forms.MediaEdit.Back')}
                </LinkButton>
                <main>{children}</main>
            </div>
        </EditMediaLayoutStyled>
    );
};

EditMediaLayout.defaultProps = {
    disableSaveButton: false,
};

export { EditMediaLayout };
