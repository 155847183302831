import { useStore } from 'stores/Store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserService } from 'lib/apis/UserService';
import { useRealtimeContext } from './RealtimeContext';
import { useAppName } from '../shared/hooks/AppName';
import { WebstoreService } from 'shared/lib/apis/WebstoreService';
import { IdentityProviders, TokenService } from '../lib/apis/TokenService';
import { SystemService } from '../lib/apis/SystemService';
import { APP_ROUTES } from '../shared/constants/navigation';

const useAuthUserContext = () => {
    const systemService = SystemService();
    const tokenService = TokenService();
    const { eventUser, authUser, vendor, webstore } = useStore();
    const realtimeService = useRealtimeContext();
    const { appName } = useAppName();
    const navigate = useNavigate();
    const userApi = UserService();
    const webstoreApi = WebstoreService();
    const [searchParams] = useSearchParams();

    const identifyConnectedClient = async () => {
        try {
            const accessToken = await tokenService.getOrRefreshAccessToken();
            await realtimeService.identify(accessToken, appName);
        } catch (err) {
            console.log(err);
        }
    };

    const login = async (provider, authType) => {
        tokenService.setLoginProvider(provider.name);
        provider.login(authType);
    };

    const logout = async (redirectRoute, redirectOpts = {}) => {
        tokenService.clearTokens();
        eventUser.clear();
        authUser.clear();
        vendor.clear();
        webstore.clear();

        window?.sessionStorage?.clear();
        window?.localStorage?.clear();

        navigate(redirectRoute, redirectOpts);
    };

    const tryCreateUser = async (idToken) => {
        const createdUser = await userApi.createUser(idToken);

        if (createdUser) {
            authUser.setAuthUser(createdUser);
        } else {
            // stuff broke, check aws logs maybe?
            await logout(APP_ROUTES.landing);
        }
    };

    const updateUserFromToken = async (idToken) => {
        const updatedUser = await userApi.updateUser(idToken);

        if (updatedUser) {
            authUser.setAuthUser(updatedUser);
        }
    };

    const getWebstoreForUser = async () => {
        // TODO: Let the reactions handle loading the webstore stuff instead of re-implementing it here.
        // Ensure we have a webstore.  The Store.js component has already loaded.
        const userWebstore = await webstoreApi.getWebstoreByUserId(authUser.Id);

        if (!!userWebstore?.id) {
            authUser.Roles.Vendor = true;
            webstore.setFromApi(userWebstore);
        }
    };

    const loginCallback = async () => {
        const idp = tokenService.getLoginProvider();

        const state = searchParams.get('state');
        const code = searchParams.get('code');

        const provider = tokenService.getLoginProvider();
        const tokens = await provider.getToken(state, code);

        tokenService.setAccessToken(tokens.access_token);
        tokenService.setIdToken(tokens.id_token);
        tokenService.setRefreshToken(tokens.refresh_token);

        const currentUser = await systemService.getWhoAmI();
        const idToken = tokenService.getIdToken();

        if (currentUser?.id) {
            authUser.setAuthUser(currentUser);
            if (!authUser.equalsIdToken(idToken)) {
                await updateUserFromToken(idToken);
            }
            if (idp?.name !== IdentityProviders.cognito.name) {
                // don't need to do this for admins
                await getWebstoreForUser();
            }
        } else {
            await tryCreateUser(idToken);
        }

        await identifyConnectedClient();
    };

    const updateUserFavorites = async (authUser, webstoreId, successCallback, errorCallback) => {
        const userApi = UserService();

        if (authUser?.Id) {
            if (!authUser.Props.favorites?.includes(webstoreId)) {
                authUser.addFavorite(webstoreId);
            } else {
                authUser.removeFavorite(webstoreId);
            }

            const propsToPatch = {
                ...authUser.Props,
            };

            const updatedUser = await userApi.patchUserProps(authUser.Id, propsToPatch);

            if (updatedUser) {
                successCallback();
                authUser.setAuthUser(updatedUser);
            } else {
                errorCallback();
            }
        }
    };

    return {
        authUser,
        login,
        logout,
        updateUserFavorites,
        loginCallback,
    };
};

export { useAuthUserContext };
