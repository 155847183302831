import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useTranslation } from 'react-i18next';
import { EditSectionLayout } from '../EditSectionLayout/EditSectionLayout';
import PearlForm, { FormType } from 'vendor/components/forms/PearlForm';
import { useOutletContext } from 'react-router-dom';
import { EditPartnersPageStyled } from './EditPartnersPage.styled';
import { useState } from 'react';
import { GhostButton } from 'dbi-pearl-ui-kit';
import { WebstoreService } from 'vendor/lib/apis/WebstoreService';
import { get } from 'react-hook-form';
import { PartnersSectionEditForm } from '../../../components/Webstore/SectionEdit/PartnersSectionEdit/PartnersSectionEdit';
import { toNestError } from '@hookform/resolvers';
import { WebstoreSectionTypes } from 'framework';
import { ToggleableSectionsValidation } from 'vendor/components/Webstore/WebstoreSectionFactories';

const EditPartnersPage = () => {
    const { webstore } = useWebstoreContext();
    const { onSubmit } = useOutletContext();
    const { t } = useTranslation();
    const webstoreApi = WebstoreService();

    const [partnersErrors, setPartnersErrors] = useState([]);
    const [partners, setPartners] = useState(webstore.Partners);
    const [originalPartners] = useState(webstore.Partners);

    const formOptions = {
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldUnregister: true,
    };

    const enableToggle = (partnersToSubmit) => {
        if (originalPartners?.length === 0) {
            const validationCheck =
                ToggleableSectionsValidation[WebstoreSectionTypes.PARTNERS].UIValidation;

            return validationCheck(partnersToSubmit);
        }
        return false;
    };

    const handleSubmit = async (data) => {
        partners?.forEach((p, i) => {
            const newPartner = data[p.ordinal];
            partners[i] = newPartner;
        });

        const partnersToSubmit = partners;
        let optionalSectionToSubmit = [];
        const canEnable = enableToggle(partnersToSubmit);

        if (canEnable) {
            const sectionId = webstore.OptionalSections.find(
                (os) => os.name === WebstoreSectionTypes.PARTNERS
            )?.id;

            optionalSectionToSubmit = [
                {
                    id: sectionId,
                    name: WebstoreSectionTypes.PARTNERS,
                    enabled: true,
                },
            ];
        }
        await onSubmit({ Partners: partners });

        if (optionalSectionToSubmit.length > 0 && webstore.Partners.length > 0) {
            onSubmit({ OptionalSections: optionalSectionToSubmit });
        }
    };

    const removePartner = (event, partnerId) => {
        setPartners((partners) => partners.filter((p) => p.partnerId !== partnerId));
    };

    const setPartnerDescription = (ordinal, data) => {
        const partnerIndex = partners.findIndex((p) => p.ordinal === ordinal);
        partners[partnerIndex].description = data;
    };

    const addPartner = () => {
        const obj = {
            ordinal: partners.length
                ? partners.map((p) => p.ordinal).sort((p1, p2) => p1 - p2)[partners.length - 1] + 1
                : 0,
        };
        setPartners((partners) => [...partners, obj]);
    };

    const validatePartnersForm = (values, options) => {
        const errors = {};
        Object.keys(values)
            .filter((key) => {
                return key !== 'SearchParam';
            })
            .forEach((key) => {
                const val = values[key];
                const partnerIndex = partners.findIndex((p) => p.ordinal === val.ordinal);

                if (!val.description && !val.partnerId) {
                    return;
                }
                if (val.description && partnerIndex !== -1) {
                    partners[partnerIndex].description = val.description;
                }
                errors[key] = {};
                if (!val.description && partnerIndex !== -1) {
                    errors[key].Description = {
                        message: t('Forms.Validation.FieldRequired', {
                            field: t('Forms.Partners.DescriptionLabel'),
                        }),
                        ref: get(options.fields, `${key}.description`),
                        type: 'required',
                    };
                }

                if ((!val.partnerId && !val.id) || (val.description && !val.description.length)) {
                    errors[key].Description = {
                        type: 'required',
                    };
                }

                if (!Object.keys(errors[key]).length) {
                    delete errors[key];
                } else {
                    setPartnersErrors([...partnersErrors, key]);
                }
            });

        if (!Object.keys(errors).length) {
            setPartnersErrors([]);
            return { errors: {}, values };
        } else {
            return { errors: toNestError(errors, options), values: {} };
        }
    };

    const PartnersErrorMessage = () => {
        return (
            <div>
                {!!partnersErrors?.length && (
                    <div data-testid="error-message" className="error">
                        <label>
                            <b>{t('Forms.Partners.Error')}</b>
                        </label>
                    </div>
                )}
            </div>
        );
    };

    const Partners = () => {
        if (webstore.PartnerToAdd) {
            const partnerToAdd = webstore.PartnerToAdd;
            if (
                partners.length &&
                !partners[
                    partners.findIndex((p) => webstore.PartnerToAdd.partnerId === p.partnerId)
                ]
            ) {
                const ordinalArr = [];
                partners.forEach((p) => {
                    ordinalArr.push(p.ordinal);
                });
                partnerToAdd.ordinal = ordinalArr.sort()[ordinalArr.length - 1] + 1;
            } else {
                partnerToAdd.ordinal = 0;
            }
            webstore.setPartnerToAdd(null);
            partners.push(partnerToAdd);
        }
        return (
            <div>
                {partners.length > 0 &&
                    partners.map((p, i) => {
                        return (
                            <div key={i}>
                                <PartnersSectionEditForm
                                    result={p}
                                    onDelete={removePartner}
                                    webstoreApi={webstoreApi}
                                    onFieldChange={setPartnerDescription}
                                />
                            </div>
                        );
                    })}
            </div>
        );
    };

    return (
        <PearlForm
            formName="Partners"
            formOptions={formOptions}
            onSubmit={(data) => handleSubmit(data)}
            formType={FormType.EDITABLE}
            StyledForm={EditPartnersPageStyled}
            customValidator={validatePartnersForm}
        >
            <EditSectionLayout sectionName={WebstoreSectionTypes.PARTNERS}>
                <h2>{t('Pages.EditPages.PartnersPageTitle')}</h2>
                <PartnersErrorMessage />
                <Partners />
                <GhostButton
                    data-testid="add-partner-button"
                    variant="accent"
                    endIcon="plus"
                    onClick={(data) => addPartner(data)}
                >
                    {t('Forms.Packages.AddItem')}
                </GhostButton>
            </EditSectionLayout>
        </PearlForm>
    );
};

export { EditPartnersPage };
