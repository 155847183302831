import styled from 'styled-components';

const ConversationHeaderStyled = styled.div`
    display: grid;
    grid-template-columns: 16px 1fr 16px;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.foreground.border};

    button {
        margin: 0;
        padding: 0;
    }
`;

export { ConversationHeaderStyled };
