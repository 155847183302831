import styled from 'styled-components';

const NoInformationProvidedStyled = styled.div`
    .no-info-container {
        text-align: center;

        & > span {
            margin-bottom: 24px;
        }

        p {
            margin: 0px;
        }
    }
`;

export { NoInformationProvidedStyled };
