import React from 'react';
import { GhostButton, TruncatedTextDisplay } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import FlaggedImage from '../../utility/FlaggedImage/FlaggedImage';
import { WebstorePackageStyled } from './WebstorePackage.styled';

const WebstorePackageSizes = {
    THUMB: 'thumb',
    FULL: 'full',
};

// TODO: DBIPP-2496: Change to use VendorTile for maintainability
const WebstorePackage = (props) => {
    const { pkg, size, onDetailsClick, showFlaggedOverlay } = props;

    const { t } = useTranslation();

    const isThumb = size === WebstorePackageSizes.THUMB;

    // if image failed validation, a flagged overlay with help text is presented in the vendor app.
    // if image failed validation, a placeholder image is displayed for the other app views
    const displayFlaggedOverlay =
        pkg.image && !pkg.image.validationResult && !pkg.image.adminApproved && showFlaggedOverlay;

    return (
        <WebstorePackageStyled isThumb={isThumb}>
            <div className="package-image">
                {!!displayFlaggedOverlay && <FlaggedImage image={pkg.image} />}
                {!!pkg.image && !displayFlaggedOverlay && (
                    <img
                        data-testid={pkg.image.placeholder ? 'placeholder-image' : 'package-image'}
                        src={pkg.image.url}
                        alt={pkg.image.caption || ''}
                        className={pkg.image.placeholder ? 'placeholder' : ''}
                    />
                )}
            </div>
            <div className="package-content">
                <TruncatedTextDisplay className="details-section" lineCount={1}>
                    <h4>{pkg.name}</h4>
                </TruncatedTextDisplay>
                <p className="label">
                    <b>{t('WebstoreSections.Packages.PriceLabel')}</b>
                </p>
                <h5 className="details-section">{pkg.price}</h5>
                <TruncatedTextDisplay className="details-section" lineCount={5}>
                    <p className="p3">{pkg.description}</p>
                </TruncatedTextDisplay>
                {!isThumb && (
                    <>
                        <p className="label">
                            <b>{t('WebstoreSections.Packages.InclusionsLabel')}</b>
                        </p>
                        <pre className="p3">{pkg.inclusions}</pre>
                    </>
                )}
                {!!isThumb && (
                    <GhostButton variant="accent" onClick={() => onDetailsClick(pkg.id)}>
                        {t('WebstoreSections.Packages.DetailsButtonLabel')}
                    </GhostButton>
                )}
            </div>
        </WebstorePackageStyled>
    );
};

WebstorePackage.defaultProps = {
    onDetailsClick: () => {},
};

export { WebstorePackage, WebstorePackageSizes };
