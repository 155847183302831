import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const NotFoundPageStyled = styled.div`
    .content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .slider {
        width: 100%;
    }

    .message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 298px;
        padding: 140px 0 0;
        gap: 24px;
        position: relative;
        text-align: center;

        a {
            text-decoration: none;
            color: ${(props) => props.theme.colors.primary.base};
        }
    }

    .message:before {
        content: '404';
        font-size: 160px;
        font-weight: bold;
        position: absolute;
        top: 64px;
        color: ${(props) => props.theme.colors.primary.base}1A;
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .message {
            padding: 64px 32px 0;
        }

        .message:before {
            font-size: 128px;
            top: 12px;
        }
    }
`;

export { NotFoundPageStyled };
