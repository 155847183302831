import { PearlApiClient } from 'framework';
import { getAppName } from 'shared/hooks/AppName';

export function UserService() {
    const appName = getAppName();
    const api = PearlApiClient(appName);

    const createUser = async (idToken) => {
        const res = await api.post('users', { idToken });
        if (res?.ok) {
            return res.data;
        }
        return null;
    };

    const updateUser = async (idToken) => {
        const res = await api.patch('users', { idToken });
        if (res?.ok) {
            return res.data;
        }
        return null;
    };

    const getUserById = async (userId) => {
        const res = await api.get(`users/${userId}`);
        if (res?.ok) {
            return res.data;
        }
        return null;
    };

    const patchUser = async (userId, data) => {
        const res = await api.patch(`users/${userId}`, data);

        if (res?.ok && res.data) {
            return res.data;
        }

        return null;
    };

    const patchUserProps = async (userId, props) => {
        const res = await api.patch(`users/${userId}`, { props });
        if (res?.ok) {
            return res.data;
        }
        return null;
    };

    const reportUser = async (userId, body) => {
        const res = await api.post(`users/${userId}/report`, body);
        if (res?.ok) {
            return res.data;
        }
        return null;
    };

    const ensureVendor = async () => {
        const res = await api.post('users/ensureVendor');
        if (res?.ok) {
            return res.data;
        }
        return null;
    };

    return {
        createUser,
        updateUser,
        getUserById,
        patchUser,
        patchUserProps,
        reportUser,
        ensureVendor,
        axiosInstance: api.axiosInstance,
    };
}
