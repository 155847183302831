import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const ApiLoaderStyled = styled.div`
    display: none;
    visibility: hidden;
    color: ${(props) => props.theme.colors.primary.base};
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 100%;
    bottom: 100%;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    height: 100vh;

    img {
        width: 176px;
        margin-bottom: 30px;
    }

    .loader {
        display: flex;
        background-color: ${(props) => props.theme.colors.background.container};
        border: 1px solid ${(props) => props.theme.colors.foreground.border};
        box-sizing: border-box;
        height: 400px;
        width: 400px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .icons {
        display: flex;
        justify-content: space-between;
        width: 176px;
        z-index: 1000;
    }

    .icon {
        animation: loaderComponentWave 1.5s linear infinite;
    }

    .icon:nth-child(2) {
        animation-delay: 0.2s;
    }

    .icon:nth-child(3) {
        animation-delay: 0.3s;
    }

    .icon:nth-child(4) {
        animation-delay: 0.4s;
    }

    .icon:nth-child(5) {
        animation-delay: 0.5s;
    }

    @keyframes loaderComponentWave {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .loader {
            margin: 0 32px;
            height: 50%;
            width: 50%;
        }
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.SM)} {
        .loader {
            margin: 0 16px;
            height: calc(100vw - 32px);
            width: 100%;
        }
    }
`;

export { ApiLoaderStyled };
