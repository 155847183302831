import { useState, useEffect } from 'react';

const MODES = [
    { name: 'Desktop', max: Number.POSITIVE_INFINITY },
    { name: 'Mobile', max: 815 },
];
MODES.forEach((x) => {
    MODES[x.name] = x;
});

const useScreenSize = () => {
    const hasWindow = typeof window !== 'undefined';

    function getScreenSize() {
        const width = hasWindow ? window.innerWidth : null;
        const height = hasWindow ? window.innerHeight : null;
        return {
            width,
            height,
        };
    }

    const [screenSize, setScreenSize] = useState(getScreenSize());

    useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setScreenSize(getScreenSize());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    });

    return {
        width: screenSize.width,
        height: screenSize.height,
        large: screenSize.width >= MODES.Mobile.max,
        small: screenSize.width < MODES.Mobile.max,
    };
};

const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
};

const useWindowMonitor = (onBreakpoint) => {
    const [mode, setMode] = useState(MODES.Desktop);

    useEffect(() => {
        function handleResize() {
            let computedMode;

            MODES.forEach((x) => {
                if (window.innerWidth <= x.max) {
                    computedMode = x;
                }
            });

            if (mode !== computedMode) {
                setMode(computedMode);
                onBreakpoint(computedMode);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [mode, setMode, onBreakpoint]);
};

export { useScreenSize, useWindowSize, useWindowMonitor, MODES };
