import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsScreenLarge, useIsScreenSmall } from '../../hooks/PearlMediaQuery';
import { CappedCarouselStyled } from './CappedCarousel.styled';
import { Swiper } from 'swiper/react/swiper-react';
import { Navigation, Pagination } from 'swiper';

const CappedCarousel = (props) => {
    const { capText, capButton, capImage, slides } = props;
    const { t } = useTranslation();
    const isScreenSmall = useIsScreenSmall();
    const isScreenLarge = useIsScreenLarge();

    return (
        <CappedCarouselStyled data-testid="capped-carousel">
            <div className="vendor-results">
                {!isScreenSmall && (
                    <div className="tile-container" role="list">
                        <div className="inner-carousel-tile-container">
                            <div className="carousel-cap">
                                <div className="cap-content">
                                    <div className="cap-inner">
                                        {!!capImage && capImage}
                                        {!!capText && <h2>{t(capText)}</h2>}
                                        {!!capButton && capButton}
                                    </div>
                                </div>
                            </div>
                            <Swiper
                                className="vendorResultsSlider"
                                navigation={true}
                                modules={[Navigation, Pagination]}
                                spaceBetween={16}
                                slidesPerView={'auto'}
                                slidesPerGroup={!!isScreenLarge ? 3 : 2}
                                slidesPerGroupAuto={true}
                                data-testid="desktop-swiper"
                            >
                                {slides}
                            </Swiper>
                        </div>
                    </div>
                )}
                {!!isScreenSmall && (
                    <>
                        {!!capImage && capImage}
                        {!!capText && <h3>{t(capText)}</h3>}
                        <Swiper
                            className="vendorResultsSlider"
                            navigation={true}
                            modules={[Navigation, Pagination]}
                            spaceBetween={16}
                            slidesPerView={'auto'}
                            slidesPerGroup={1}
                            slidesPerGroupAuto={true}
                        >
                            {slides}
                        </Swiper>
                        {!!capButton && capButton}
                    </>
                )}
            </div>
        </CappedCarouselStyled>
    );
};

export { CappedCarousel };
