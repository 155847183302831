import React from 'react';
import styled from 'styled-components';

const ChatTextStyled = styled.p`
    margin: 0;
    padding: 0;
`;

const ChatText = ({ children }) => (
    <ChatTextStyled className="p2 message-text">{children}</ChatTextStyled>
);

export { ChatText };
