import React, { useState, useCallback, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RatingStars, GhostButton } from 'dbi-pearl-ui-kit';
import { NewTabLink } from '../../../utility/NewTabLink';
import { WebstoreReview } from '../../WebstoreReview/WebstoreReview';
import { GoogleReviewsStyled } from './GoogleReviews.styled';
import { useIsScreenLarge } from '../../../../hooks/PearlMediaQuery';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { GoogleLogo } from 'shared/components/GoogleLogo/GoogleLogo';
const WebstoreSectionContentSlider = lazy(() =>
    import('../../WebstoreSectionContentSlider/WebstoreSectionContentSlider')
);

// https://developers.google.com/maps/documentation/places/web-service/details?hl=en#PlaceReview
// https://developers.google.com/maps/documentation/places/web-service/details?hl=en#optional-parameters

const GoogleReviews = (props) => {
    const { webstore, sectionRef, sectionId, loadScript, iconName, banner } = props;
    const { t } = useTranslation();

    const isScreenLarge = useIsScreenLarge();

    const [reviews, setReviews] = useState([]);
    const [place, setPlace] = useState(null);
    const [reviewsRef, setReviewsRef] = useState(null);

    const loadReviews = useCallback(
        (place) => {
            if (place) {
                setPlace(place);
                setReviews(place.reviews);
            }
        },
        [setReviews]
    );

    useEffect(() => {
        if (reviewsRef) {
            const options = {
                placeId: webstore?.GooglePlaceId,
                fields: ['name', 'reviews', 'rating', 'user_ratings_total', 'url'],
            };
            loadScript(loadReviews, reviewsRef, options);
        } else {
            const ref = document.querySelector('#review-list');
            setReviewsRef(ref);
        }
    }, [reviewsRef]);

    const singleOrMultiReviewText = (reviewCount) => {
        return reviewCount > 1
            ? t('WebstoreSections.Reviews.MultipleReviewsText')
            : t('WebstoreSections.Reviews.SingleReviewText');
    };

    const reviewsList = reviews?.map((review, index) => {
        return <WebstoreReview review={review} key={index} />;
    });

    return (
        <GoogleReviewsStyled ref={sectionRef} id={sectionId} data-testid="google-reviews">
            {webstore.GooglePlaceId && (
                <div className="reviewsContainer">
                    <SectionHeader
                        title={t('WebstoreSections.Reviews.Title')}
                        iconName={iconName}
                        banner={banner}
                    />
                    <span className="webstoreRating">
                        <h2>{place?.rating}</h2>
                        <span className="starRating">
                            <RatingStars
                                totalRatingPossible={5}
                                rating={place?.rating}
                                iconSize="24px"
                            />
                        </span>
                        <NewTabLink
                            href={place?.url}
                            className="p1 reviewsLink"
                            data-testid="webstore-reviews-link"
                        >
                            ({place?.user_ratings_total}{' '}
                            {singleOrMultiReviewText(place?.user_ratings_total)})
                        </NewTabLink>
                    </span>
                    <div className="reviewsList">
                        {isScreenLarge && (
                            <div
                                className="desktopReviewsList"
                                data-testid="review-list"
                                id="review-list"
                            >
                                {reviewsList}
                            </div>
                        )}
                        {!isScreenLarge && (
                            <div data-testid="review-list" id="review-list">
                                <WebstoreSectionContentSlider slides={reviewsList} />
                            </div>
                        )}
                    </div>
                    <GoogleLogo />
                    <GhostButton
                        className="allReviewsButton"
                        to={place?.url}
                        variant="accent"
                        onClick={() => window.open(place?.url)}
                    >
                        {t('WebstoreSections.Reviews.SeeAllButton')}
                    </GhostButton>
                </div>
            )}
        </GoogleReviewsStyled>
    );
};

GoogleReviews.propTypes = {
    webstore: PropTypes.object,
    loadScript: PropTypes.func,
};

export { GoogleReviews };
