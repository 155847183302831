import { MediaSectionEditStyled } from './MediaSectionEdit.styled';
import { WebstoreGalleryEdit } from 'vendor/components/Webstore/WebstoreGalleryEdit/WebstoreGalleryEdit.js';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditSectionLayout } from 'vendor/pages/EditWebstoreSectionPages/EditSectionLayout/EditSectionLayout';
import { MediaEdit } from '../../MediaEdit/MediaEdit';
import { EditMediaLayout } from '../../../../pages/EditWebstoreSectionPages/EditMediaLayout/EditMediaLayout';
import { ask, WebstoreSectionTypes } from 'framework';

const MediaSectionEdit = (props) => {
    const { t } = useTranslation();
    const [selectedImage, setSelectedImage] = useState(null);
    const [dirty, setDirty] = useState(false);

    const preDone = async () => {
        if (dirty) {
            try {
                await ask('', t('UnsavedPrimary') + '\n' + t('UnsavedSecondary'));
            } catch (e) {
                // User canceled the navigation
                return;
            }
            setSelectedImage(null);
        } else {
            setSelectedImage(null);
        }
    };

    if (selectedImage) {
        return (
            <MediaSectionEditStyled>
                <EditMediaLayout goBack={preDone}>
                    <MediaEdit image={selectedImage} changesMade={setDirty} done={preDone} />
                </EditMediaLayout>
            </MediaSectionEditStyled>
        );
    }

    return (
        <MediaSectionEditStyled>
            <EditSectionLayout disableSaveButton={true} sectionName={WebstoreSectionTypes.GALLERY}>
                <h2>{t('Forms.MediaUpload.StepName')}</h2>
                <WebstoreGalleryEdit
                    imageSelected={(image) => setSelectedImage(image)}
                ></WebstoreGalleryEdit>
            </EditSectionLayout>
        </MediaSectionEditStyled>
    );
};

export { MediaSectionEdit };
