import { makeAutoObservable } from 'mobx';

const initialValues = {};

export default class EventStore {
    Id = null;
    EventType = null;
    EventTypeId = null;
    EventLocation = [];
    EventLocationType = null;
    EventLocationKnown = null;
    EventDate = {
        EventDate: null,
        EventTimeline: null,
    };
    EventDetails = {
        BudgetTier: null,
        GuestCount: null,
        BudgetTierKnown: null,
        GuestCountKnown: null,
    };
    EventVendors = [];
    BookedVendors = [];
    CurrentStep = null;
    EventPreferences = [];
    SelectedHeroImage = null;
    DashboardHeroImage = null;
    Media = null;
    Loading = false;

    constructor(options) {
        for (const [k, v] of Object.entries(this)) {
            initialValues[k] = v;
        }

        makeAutoObservable(this);
    }

    updateEvent(update) {
        for (const [key, value] of Object.entries(update)) {
            if (this.hasOwnProperty(key)) {
                this[key] = value;
            }
        }
    }

    get forApi() {
        return this.forPatchBase;
    }

    get forPatchBase() {
        return {
            event: {
                id: this.Id,
                basics: {
                    eventType: this.EventType,
                    budgetTier: this.EventDetails.BudgetTier,
                    guestCount: this.EventDetails.GuestCount,
                    guestCountKnown: this.EventDetails.GuestCountKnown,
                    budgetTierKnown: this.EventDetails.BudgetTierKnown,
                },
                eventDate: {
                    eventDate: this.EventDate.EventDate,
                    timeline: this.EventDate.EventTimeline,
                },
                eventLocations: this.EventLocation,
                eventLocationType: this.EventLocationType,
                locationKnown: this.EventLocationKnown,
                eventVendors: this.EventVendors,
                props: {
                    selectedHeroImage: this.SelectedHeroImage,
                    currentSetupStep: this.CurrentStep,
                },
            },
        };
    }

    get forPatchSetupStep() {
        return {
            ...this.forPatchBase,
            props: {
                ...this.forPatchBase.props,
                currentStep: this.CurrentStep,
            },
        };
    }

    setFromApi(data) {
        if (data?.event) {
            this.Id = data.event.id;
            this.EventType = data.event.basics.eventType;
            this.EventTypeId = data.event.basics.eventTypeId;
            this.EventLocation = data.event.eventLocations;
            this.EventLocationType = data.event.eventLocationType;
            this.EventLocationKnown = data.event.locationKnown;
            this.EventDate = {
                EventDate: data.event.eventDate.eventDate,
                EventTimeline: data.event.eventDate.timeline,
            };
            this.EventDetails.BudgetTier = data.event.basics.budgetTier;
            this.EventDetails.GuestCount = data.event.basics.guestCount;
            this.EventDetails.BudgetTierKnown = data.event.basics.budgetTierKnown;
            this.EventDetails.GuestCountKnown = data.event.basics.guestCountKnown;
            data.event.eventVendors.forEach((vendor) => {
                vendor['categoryId'] = vendor['vendorCategoryId'];
                delete vendor['vendorCategoryId'];
            });
            this.EventVendors = data.event.eventVendors;
            this.DashboardHeroImage = data.event.dashboardHeroImage;
            if (data.event.props) {
                this.CurrentStep = data.event.props.currentSetupStep || null;
                this.SelectedHeroImage = data.event.props.selectedHeroImage || 0;
            }
        }
    }

    setLoading(value) {
        this.Loading = value;
    }

    setDefaults() {
        for (const [k, v] of Object.entries(initialValues)) {
            this[k] = v;
        }
    }

    clear() {
        this.setDefaults();
    }
}
