const WebstoreSetupStepForms = {
    ADDRESS: 'address',
    AMENITIES_SERVICES: 'services',
    CAPACITY: 'capacity',
    DETAILS: 'details',
    MEDIA_UPLOAD: 'media',
    PRICE_TIER: 'pricetier',
    SERVICE_AREA: 'servicearea',
    SERVICE_CHARACTERISTICS: 'servicevibes',
    SOCIAL_LINKS: 'social',
    SUMMARY: 'summary',
};

const WebstoreSetupStepsMap = new Map([
    [
        'beauty',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.SERVICE_CHARACTERISTICS,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'venues',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.CAPACITY,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'music',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.SERVICE_CHARACTERISTICS,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'lodging',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.CAPACITY,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'foodbeverage',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.SERVICE_CHARACTERISTICS,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'officiants',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.SERVICE_CHARACTERISTICS,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'planning',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'rentalequipment',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'invitationsstationery',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'florist',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.SERVICE_CHARACTERISTICS,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'photographyvideography',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.SERVICE_CHARACTERISTICS,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'favorsgifts',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'transportation',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'jewelry',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.SERVICE_CHARACTERISTICS,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'entertainmentotherservices',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'healthwellness',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.SERVICE_CHARACTERISTICS,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'travel',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'photobooths',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'bridaltuxedo',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'home',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'tuxedos',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'videography',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.SERVICE_CHARACTERISTICS,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'weddingcakes',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.SERVICE_CHARACTERISTICS,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
    [
        'otherservices',
        [
            WebstoreSetupStepForms.ADDRESS,
            WebstoreSetupStepForms.SERVICE_AREA,
            WebstoreSetupStepForms.AMENITIES_SERVICES,
            WebstoreSetupStepForms.PRICE_TIER,
            WebstoreSetupStepForms.SUMMARY,
            WebstoreSetupStepForms.SOCIAL_LINKS,
            WebstoreSetupStepForms.MEDIA_UPLOAD,
        ],
    ],
]);

const DEFAULT_STEP = WebstoreSetupStepForms.DETAILS;
const COMPLETE_STEP = 'COMPLETE';

export { COMPLETE_STEP, DEFAULT_STEP, WebstoreSetupStepForms, WebstoreSetupStepsMap };
