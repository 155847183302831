import { IconName, PearlIcon } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import { EmptyInboxStyled } from './EmptyInbox.styled';

const EmptyInbox = () => {
    const { t } = useTranslation();

    return (
        <EmptyInboxStyled>
            <PearlIcon iconName={IconName.EMAIL} size="70px" />
            <p className="p1">
                <b>{t('Messaging.EmptyInbox.Text')}</b>
            </p>
            <p className="p3">{t('Messaging.EmptyInbox.Subtext')}</p>
        </EmptyInboxStyled>
    );
};

export { EmptyInbox };
