import styled from 'styled-components';

const VendorSocialFormStyled = styled.form`
    .subtitle {
        margin-bottom: 20px;
    }

    .cta {
        color: ${(props) => props.theme.colors.primary.base};
    }

    .webstoreStepComponent {
        margin-top: 0;
        margin-bottom: 20px;
    }

    @media (max-width: 815px) {
        .stepInstructions {
            display: flex;
            flex-direction: column;
        }
    }

    @media (max-width: 1200px) {
        .link-row > div {
            flex-direction: column;
        }
    }
`;

export { VendorSocialFormStyled };
