import * as Yup from 'yup';
import i18n from '../../../i18n';

const eventDetailsSchema = Yup.object()
    .shape({
        GuestCount: Yup.number()
            .nullable()
            .positive(i18n.t('Forms.EventDetails.MinGuestCountError'))
            .integer(i18n.t('Forms.EventDetails.InvalidCharactersError'))
            .typeError(i18n.t('Forms.EventDetails.InvalidCharactersError')),
        GuestCountKnown: Yup.bool()
            .nullable()
            .when('GuestCount', {
                is: null,
                then: Yup.bool()
                    .nullable()
                    .oneOf(
                        [false],
                        i18n.t('Forms.Validation.FieldRequired', {
                            field: i18n.t('Forms.Labels.GuestCount'),
                        })
                    )
                    .required(
                        i18n.t('Forms.Validation.FieldRequired', {
                            field: i18n.t('Forms.Labels.GuestCount'),
                        })
                    ),
            }),
        BudgetTier: Yup.number().nullable(),
        BudgetTierKnown: Yup.bool()
            .nullable()
            .when('BudgetTier', {
                is: null,
                then: Yup.bool()
                    .nullable()
                    .oneOf(
                        [false],
                        i18n.t('Forms.Validation.FieldRequired', {
                            field: i18n.t('Forms.Labels.BudgetTier'),
                        })
                    )
                    .required(
                        i18n.t('Forms.Validation.FieldRequired', {
                            field: i18n.t('Forms.Labels.BudgetTier'),
                        })
                    ),
            }),
    })
    .required();

export { eventDetailsSchema };
