import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import PearlForm from 'vendor/components/forms/PearlForm';
import { EditSectionLayout } from 'vendor/pages/EditWebstoreSectionPages/EditSectionLayout/EditSectionLayout';
import { GoogleReviewsConnector } from './GoogleReviewsConnector/GoogleReviewsConnector';
import { validationSchema } from './GoogleReviewsConnector/GoogleReviewsConnector.schema';
import { WebstoreSectionTypes } from 'framework';
import { ToggleableSectionsValidation } from 'vendor/components/Webstore/WebstoreSectionFactories';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { IconName, IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';

const BUSINESS_NAME_FORM_FIELD = 'GooglePlaceLookup';
const PLACE_ID_FORM_FIELD = 'GooglePlaceId';

const GoogleReviewsSectionEdit = () => {
    const { onSubmit } = useOutletContext();
    const { webstore } = useWebstoreContext();
    const { t } = useTranslation();

    const [queryRef, setQueryRef] = useState(null);
    const [queryResult, setQueryResult] = useState('');
    const [originalPlaceId] = useState(webstore.GooglePlaceId);

    const enableToggle = (placeIdToSubmit) => {
        if (!originalPlaceId) {
            const validationCheck =
                ToggleableSectionsValidation[WebstoreSectionTypes.REVIEWS].UIValidation;

            return validationCheck(webstore.ActiveFeatures, placeIdToSubmit);
        }
        return false;
    };

    const connectAccount = (data) => {
        setQueryRef(null);

        if (queryResult && queryResult.place_id) {
            let optionalSectionToSubmit = [];
            const canEnable = enableToggle(queryResult.place_id);

            if (canEnable) {
                const sectionId = webstore.OptionalSections.find(
                    (os) => os.name === WebstoreSectionTypes.REVIEWS
                )?.id;

                optionalSectionToSubmit = [
                    {
                        id: sectionId,
                        name: WebstoreSectionTypes.REVIEWS,
                        enabled: true,
                    },
                ];
            }
            onSubmit({
                GooglePlaceId: queryResult.place_id,
                OptionalSections: optionalSectionToSubmit,
            });
            setQueryResult(null);
        } else {
            let optionalSectionToSubmit = [];
            const canEnable = enableToggle(data?.GooglePlaceId);

            if (canEnable) {
                const sectionId = webstore.OptionalSections.find(
                    (os) => os.name === WebstoreSectionTypes.REVIEWS
                )?.id;

                optionalSectionToSubmit = [
                    {
                        id: sectionId,
                        name: WebstoreSectionTypes.REVIEWS,
                        enabled: true,
                    },
                ];
            }

            onSubmit(data, { OptionalSections: optionalSectionToSubmit });
            setQueryResult(null);
        }
    };

    const formOptions = {
        mode: 'onSubmit',
        revalidateMode: 'onSubmit',
        defaultValues: {
            [PLACE_ID_FORM_FIELD]: '',
            [BUSINESS_NAME_FORM_FIELD]: '',
        },
    };

    return (
        <EditSectionLayout hideSaveButton={true} sectionName={WebstoreSectionTypes.REVIEWS}>
            <PearlForm
                formName="Reviews"
                formOptions={formOptions}
                onSubmit={(data) => connectAccount(data)}
                formType="Editable"
                validationSchema={validationSchema}
            >
                <h2>{t('WebstoreSections.Reviews.Title')}</h2>
                <GoogleReviewsConnector
                    onSubmit={onSubmit}
                    queryRef={queryRef}
                    setQueryRef={setQueryRef}
                    queryResult={queryResult}
                    setQueryResult={setQueryResult}
                    connectAccount={connectAccount}
                    disconnectAccount={onSubmit}
                />
                <div className="bottomCopy">
                    <PearlIcon iconName={IconName.INFO} size={IconSizes.SM} />
                    <span className="addressCopy">{t('WebstoreSections.Reviews.AddressCopy')}</span>
                </div>
            </PearlForm>
        </EditSectionLayout>
    );
};

export { GoogleReviewsSectionEdit };
