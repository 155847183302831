import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PhoneNumberInputStyled } from './PhoneNumberInputstyled';
import { useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const StyledBorderLabel = styled.div`
    z-index: 1;
    position: relative;
    left: 8px;
    width: max-content;
    margin-bottom: -10px;
    background-color: ${(props) => props.theme.colors.background.container};
    padding-left: 4px;
    padding-right: 4px;

    &.disabled {
        cursor: default;
    }
`;

//https://github.com/bl00mber/react-phone-input-2
const PhoneNumberInput = (props) => {
    const { borderLabel, disabled, field } = props;

    const { t } = useTranslation();

    const BorderLabel = ({ children }) => {
        return (
            <StyledBorderLabel className={`p4 ${disabled ? 'disabled' : ''}`}>
                {children}
            </StyledBorderLabel>
        );
    };

    const showArrow = () => {
        const phoneNumberInput = document
            .getElementById('phoneNumber')
            .getElementsByClassName('selected-flag')[0];
        const existingDownArrow = document.getElementById('flag-arrow-down');

        if (!existingDownArrow) {
            const tag = document.createElement('span');
            tag.className = 'icon-arrow-down disabledArrow';
            tag.id = 'flag-arrow-down';
            tag.dataset.testid = 'flag-arrow-down';

            phoneNumberInput.appendChild(tag);
        }
    };

    useEffect(() => {
        if (disabled) {
            showArrow();
        }
    }, []);

    return (
        <PhoneNumberInputStyled className={disabled ? 'disabled' : ''} id="phoneNumber">
            <BorderLabel>{borderLabel}</BorderLabel>
            <PhoneInput
                {...field}
                disabled={disabled}
                country="us"
                placeholder={t('Forms.Labels.PhoneNumber')}
                inputClass={`phoneInput p3 ${disabled ? 'disabled' : ''}`}
                buttonClass={`flagDropdown ${disabled ? 'disabled' : ''}`}
            />
        </PhoneNumberInputStyled>
    );
};

export { PhoneNumberInput };
