import styled from 'styled-components';

const VendorCapacityFormStyled = styled.form`
    display: flex;
    height: 100%;

    .Capacity-container {
        margin-top: 20px;
        width: 50%;
    }

    @media (max-width: 1200px) {
        .Capacity-container {
            width: 60%;
        }
    }

    @media (max-width: 815px) {
        .Capacity-container {
            width: 100%;
        }
    }
`;

export { VendorCapacityFormStyled };
