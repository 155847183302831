import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventVendorsFormStyled } from './EventVendorsForm.styled';
import { Bridesmaids, EmptyCaptions } from '../../../../assets/Defaults';
import {
    AccountSetupStepLayout,
    AccountSetupStepGraphic,
    AccountSetupStepFooter,
    AccountSetupStepCounter,
    PearlForm,
} from 'framework';
import { useEventUserSetupWizard } from '../context/EventUserSetupContext';
import { Checkbox, ToggleButton, ToggleButtonGroup } from 'dbi-pearl-ui-kit';
import { useStore } from '../../../../stores/Store';
import { useFormContext } from 'react-hook-form';
import { useEventContext } from '../../../contexts/EventContext';

function Footer() {
    const { watch } = useFormContext();
    const { progress, onBack, onSkip } = useEventUserSetupWizard();

    const currentValue = watch();

    return (
        <AccountSetupStepFooter
            progress={progress}
            handleOnBack={onBack}
            handleOnSkip={
                !currentValue.categoryIds || currentValue.categoryIds.length === 0 ? onSkip : null
            }
        />
    );
}

const EventVendors = () => {
    const { t } = useTranslation();
    const { appData } = useStore();
    const { event } = useEventContext();

    // eslint-disable-next-line
    const [selectedCategoryIds, setSelectedCategoryIds] = useState(
        event.EventVendors?.map((eventVendor) => eventVendor.categoryId.toString()) || []
    );

    const [lookingForEverythingChecked, setLookingForEverythingChecked] = useState(
        selectedCategoryIds.length === appData.categoryList.length
    );

    const vendorOptions = appData.categoryList.map((category) => {
        return (
            <ToggleButton
                label={category.name}
                value={`${category.id}`}
                key={category.id}
                startImg={{
                    src: category.image?.url,
                    height: '60px',
                    width: '60px',
                }}
            />
        );
    });

    return (
        <div>
            <div className="checkboxContainer">
                <Checkbox
                    label={t('Forms.EventVendors.LookingForEverythingCheckbox')}
                    checked={lookingForEverythingChecked}
                    onChange={setLookingForEverythingChecked}
                />
            </div>
            <div className="categories">
                <ToggleButtonGroup
                    formFieldName="testField"
                    groupName="categoryIds"
                    selectedGroupItems={selectedCategoryIds}
                    onChange={() => {}}
                    selectAll={lookingForEverythingChecked}
                    exclusive={false}
                    disabled={false}
                >
                    {vendorOptions}
                </ToggleButtonGroup>
            </div>
        </div>
    );
};

export default function EventVendorsForm() {
    const { currentStepCount, totalSteps, onSubmit } = useEventUserSetupWizard();

    const { t } = useTranslation();

    const videoDetails = {
        image: Bridesmaids,
        captions: EmptyCaptions,
    };

    const formOptions = {
        mode: 'onSubmit',
    };

    const handleSubmit = (formData) => {
        const eventVendors = formData.categoryIds.map((categoryId) => ({
            categoryId: parseInt(categoryId),
            webstoreId: null,
            nickname: '',
        }));
        const payload = { EventVendors: eventVendors };
        onSubmit(payload);
    };

    return (
        <PearlForm
            formOptions={formOptions}
            StyledForm={EventVendorsFormStyled}
            formName="EventVendors"
            onSubmit={(data) => handleSubmit(data)}
        >
            <AccountSetupStepLayout
                header={
                    <div>
                        <AccountSetupStepCounter
                            stepCountText={t('Forms.Labels.Steps', {
                                completedSteps: currentStepCount,
                                totalSteps: totalSteps,
                            })}
                            stepNameText={t('Forms.EventVendors.StepName')}
                        />
                        <h1 className="eventUserTitles">{t('Forms.EventVendors.Title')}</h1>
                    </div>
                }
                formComponent={<EventVendors />}
                image={<AccountSetupStepGraphic {...videoDetails} />}
                footer={<Footer />}
            />
        </PearlForm>
    );
}
