import { IdentityProviders, TokenService } from './lib/apis/TokenService';

const checkAndApplyImpersonation = () => {
    const params = new URLSearchParams(window.location.search);
    const idToken = params.get('idToken');
    const accessToken = params.get('accessToken');

    if (idToken && accessToken) {
        const tokenService = TokenService();

        tokenService.clearTokens();
        tokenService.setLoginProvider(IdentityProviders.adminImpersonation.name);
        tokenService.setIdToken(idToken);
        tokenService.setAccessToken(accessToken);

        window.location = '/';
    }
};

export { checkAndApplyImpersonation };
