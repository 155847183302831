import styled from 'styled-components';

export default styled.div`
    height: 55px;
    width: 100vw;
    background-color: ${(props) => props.theme.colors.background.container};

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    h4 {
        line-height: 25px;
        margin: 0px;
        padding-top: 8px;
    }

    .subtitle {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-bottom: 8px;

        .p4 {
            margin: 0px;
        }

        .separator {
            height: 15px;
            margin-block: 0;

            b {
                line-height: 15px;
            }
        }
    }
`;
