import apisauce from 'apisauce';
import { AEM_URL, AEM_URL_SUFFIX, AEM_RESPONSE_FORMAT } from '../../aem/const';

export function AemService() {
    const client = apisauce.create({
        baseURL: `${AEM_URL}${AEM_URL_SUFFIX}/`,
        timeout: 10000,
    });

    const getRoutes = async () => {
        try {
            const res = await client.get(`/routes.routes${AEM_RESPONSE_FORMAT}`);
            if (res.ok && res.data) {
                return res.data.paths || [];
            }
        } catch (e) {
            console.log('error fetching aem routes', e);
        }
        return null;
    };

    const getRoutePage = async (pathname) => {
        const res = await client.get(`${pathname}.model${AEM_RESPONSE_FORMAT}`);
        if (res?.ok) {
            return res.data;
        }
        return null;
    };

    return { getRoutes, getRoutePage };
}
