import { PearlError } from 'framework';
import { TextAreaInput } from 'dbi-pearl-ui-kit';
import { BusinessSummarySectionStyled } from './BusinessSummarySection.styled';

const BusinessSummarySection = (props) => {
    return (
        <BusinessSummarySectionStyled>
            <TextAreaInput formFieldName="BusinessSummary" maxLength={1500} {...props} />
            <PearlError name="BusinessSummary" />
        </BusinessSummarySectionStyled>
    );
};

export { BusinessSummarySection };
