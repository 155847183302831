import React from 'react';
import { CapacitySection } from '../../CapacitySection/CapacitySection';
import { CapacitySectionEditStyled } from './CapacitySectionEdit.styled';
import { SectionEditHeader } from '../SectionEditHeader/SectionEditHeader';
import { useWebstoreContext } from '../../../../contexts/WebstoreContext/WebstoreContext';
import { categoryExternalIds } from 'framework';

function CapacitySectionEdit() {
    const { webstore } = useWebstoreContext();

    const formName =
        webstore.VendorCategoryExternalId === categoryExternalIds.venues
            ? 'VenueCapacity'
            : 'LodgingCapacity';

    return (
        <CapacitySectionEditStyled>
            <SectionEditHeader
                title={`Forms.${formName}.StepName`}
                description={`Forms.${formName}.EditSectionDescription`}
            />
            <CapacitySection />
        </CapacitySectionEditStyled>
    );
}

export { CapacitySectionEdit };
