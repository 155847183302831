import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

export const EventVendorsFormStyled = styled.form`
    .checkboxContainer {
        label {
            padding-left: 0;
        }
    }

    img {
        align-self: center;
    }

    .categories {
        label {
            min-width: 240px;
        }
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.LG)} {
        .categories {
            label {
                flex: unset;
                width: 100%;
            }
        }
    }
`;
