import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dropdown, TextInput } from 'dbi-pearl-ui-kit';
import stateOptions from 'vendor/constants/select-options/states';
import { PearlError, loadAutocomplete, FormRow } from 'framework';
import AddressAutocompleteStyled from './AddressAutocomplete.styled';
import { formFieldNames } from '../Webstore/ServiceAreaForm/Functions/ServiceAreaForm.Functions';

function TextInputComponent(props) {
    const { fieldName, fieldLabel } = props;

    return <TextInput id={fieldName} formFieldName={fieldName} providedLabel={fieldLabel} />;
}

const AddressAutoComplete = (props) => {
    const { addressLine1Label, addressLine2Label, cityLabel, stateLabel, zipcodeLabel } = props;

    const [queryRef, setQueryRef] = useState(null);
    const [queryResult, setQueryResult] = useState('');

    const { t } = useTranslation();

    const {
        setValue,
        formState: { isSubmitted },
        trigger,
    } = useFormContext();

    const formName = `${t('Forms.Names.BusinessAddress')}`;
    const [
        addressLine1FieldName,
        addressLine2FieldName,
        cityFieldName,
        stateFieldName,
        zipcodeFieldName,
    ] = [
        formFieldNames.ServiceAddressStreet,
        formFieldNames.ServiceAddressStreet2,
        formFieldNames.ServiceAddressCity,
        formFieldNames.ServiceAddressState,
        formFieldNames.ServiceAddressZipcode,
    ];

    useEffect(() => {
        if (queryRef) {
            const autocompleteOptions = {
                searchTypes: ['address'],
                setFields: ['address_components'],
                focusRef: `#${addressLine2FieldName}`,
                scriptId: 'address-autocomplete',
            };
            loadAutocomplete(setQueryResult, queryRef, autocompleteOptions);
        } else {
            const ref = document.querySelector(`#${addressLine1FieldName}`);
            setQueryRef(ref);
        }
    }, [queryRef, addressLine1FieldName, addressLine2FieldName]);

    useEffect(() => {
        if (queryResult && queryResult.address_components) {
            let address1 = '';
            let city = '';
            let state = '';
            let zipcode = '';

            for (const field of queryResult.address_components) {
                const fieldType = field.types[0];

                switch (fieldType) {
                    case 'street_number': {
                        address1 = `${field.long_name} `;
                        break;
                    }
                    case 'route': {
                        address1 += field.short_name;
                        break;
                    }
                    case 'locality': {
                        city = field.long_name;
                        break;
                    }
                    case 'administrative_area_level_1': {
                        state = field.short_name;
                        break;
                    }
                    case 'postal_code': {
                        zipcode = `${field.long_name || ''}`;
                        break;
                    }
                    case 'postal_code_suffix': {
                        zipcode = `${zipcode}-${field.long_name || ''}`;
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }

            setValue(addressLine1FieldName, address1, { shouldValidate: true });
            setValue(cityFieldName, city, { shouldValidate: true });
            setValue(zipcodeFieldName, zipcode, { shouldValidate: true });
            setValue(stateFieldName, state, { shouldValidate: true });

            // trigger revalidate after autofill if form isSubmitted
            // - remain consistent with validationMode and remove
            // any existing validation errors
            if (isSubmitted) {
                trigger();
            }
        }
    }, [
        queryResult,
        addressLine1FieldName,
        cityFieldName,
        zipcodeFieldName,
        stateFieldName,
        setValue,
        isSubmitted,
        trigger,
    ]);

    return (
        <AddressAutocompleteStyled className="formComponent">
            <PearlError name={formName} />
            <div className="addressLines">
                <FormRow
                    columns={[
                        <div key={0}>
                            <TextInputComponent
                                fieldLabel={addressLine1Label}
                                fieldName={addressLine1FieldName}
                            />
                            <PearlError name={addressLine1FieldName} />
                        </div>,
                        <div>
                            <TextInputComponent
                                fieldLabel={addressLine2Label}
                                fieldName={addressLine2FieldName}
                            />
                        </div>,
                    ]}
                />
            </div>
            <div className="cityStateZipEntry">
                <FormRow
                    columns={[
                        <div className="cityEntry" key={0}>
                            <TextInputComponent fieldLabel={cityLabel} fieldName={cityFieldName} />
                            <PearlError name={cityFieldName} />
                        </div>,
                        <div className="stateDropdown" key={1}>
                            <Dropdown
                                formFieldName={stateFieldName}
                                id={stateFieldName}
                                providedLabel={stateLabel}
                            >
                                {stateOptions.map((state) => (
                                    <option key={state.value} value={state.value}>
                                        {state.label}
                                    </option>
                                ))}
                            </Dropdown>
                            <PearlError name={stateFieldName} />
                        </div>,
                        <div className="zipcodeEntry" key={2}>
                            <TextInputComponent
                                fieldName={zipcodeFieldName}
                                fieldLabel={zipcodeLabel}
                            />
                            <PearlError name={zipcodeFieldName} />
                        </div>,
                    ]}
                />
            </div>
        </AddressAutocompleteStyled>
    );
};

export { AddressAutoComplete };
