import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const PackageModalStyled = styled.div`
    width: 100%;
    max-width: 580px;
    padding: 20px;

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        width: unset;
        padding: 8px 0 0 0;
    }
`;

export { PackageModalStyled };
