import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useTranslation } from 'react-i18next';
import { EditSectionLayout } from '../EditSectionLayout/EditSectionLayout';
import PearlForm, { FormType } from 'vendor/components/forms/PearlForm';
import { useOutletContext } from 'react-router-dom';
import { EditPackagesPageStyled } from './EditPackagesPage.styled';
import { useState } from 'react';
import { GhostButton } from 'dbi-pearl-ui-kit';
import { PackageEditForm } from 'vendor/components/Webstore/SectionEdit/PackageEditForm/PackageEditForm';
import { IMAGE_CATEGORIES, WebstoreService } from 'vendor/lib/apis/WebstoreService';
import { get, useFormContext } from 'react-hook-form';
import { toNestError } from '@hookform/resolvers';
import { WebstoreSectionTypes } from 'framework';
import { ToggleableSectionsValidation } from 'vendor/components/Webstore/WebstoreSectionFactories';

const EditPackagesPage = () => {
    const { webstore } = useWebstoreContext();
    const { onSubmit } = useOutletContext();
    const { t } = useTranslation();
    const webstoreApi = WebstoreService();
    const [packageErrors, setPackageErrors] = useState([]);
    const [originalPackages] = useState(webstore.Packages);
    const [packages, setPackages] = useState(webstore.packagesForEditForm());

    const formOptions = {
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: packages,
        shouldUnregister: true,
    };

    const enableToggle = (packagesToSubmit) => {
        if (originalPackages.length === 0) {
            const validationCheck =
                ToggleableSectionsValidation[WebstoreSectionTypes.PACKAGES].UIValidation;
            return validationCheck(webstore.ActiveFeatures, packagesToSubmit);
        }
        return false;
    };

    const handleSubmit = async (data) => {
        await syncImages(data);
        // make sure the previous id gets added to the form data
        Object.keys(packages).forEach((key) => {
            if (packages[key].Id && data[key]) {
                data[key].Id = packages[key].Id;
            }
        });
        const packagesToSubmit = webstore.packagesFromEditForm(data);
        let optionalSectionToSubmit = [];
        const canEnable = enableToggle(packagesToSubmit);

        if (canEnable) {
            const sectionId = webstore.OptionalSections.find(
                (os) => os.name === WebstoreSectionTypes.PACKAGES
            )?.id;

            optionalSectionToSubmit = [
                {
                    id: sectionId,
                    name: WebstoreSectionTypes.PACKAGES,
                    enabled: true,
                },
            ];
        }
        await onSubmit({ Packages: packagesToSubmit });

        if (optionalSectionToSubmit.length > 0 && webstore.Packages.length > 0) {
            onSubmit({ OptionalSections: optionalSectionToSubmit });
        }
    };

    const syncImages = async (data) => {
        await Promise.all(
            Object.keys(data).map(async (key) => {
                let newImage = data[key]?.Image;
                const previousImage = packages[key]?.Image;

                // If new image was added, upload it
                if (newImage instanceof File) {
                    const image = await webstoreApi.uploadImage(
                        webstore.Id,
                        newImage,
                        IMAGE_CATEGORIES.PACKAGES
                    );
                    data[key].Image = newImage = image;
                }

                // If package had a previous image and it doesn't match new image, delete it
                if (previousImage && (!newImage || previousImage.id !== newImage.id)) {
                    await webstoreApi.deleteImage(webstore.Id, previousImage.id);
                }
            })
        );
    };

    const removePackage = (event, index) => {
        event.stopPropagation();
        event.preventDefault();
        const newPackagesArray = Object.values(packages);
        newPackagesArray.splice(index, 1);
        setPackages(newPackagesArray);
    };

    const addPackage = () => {
        const nextIndex = Object.keys(packages).length;
        const newPackages = { ...packages };
        newPackages[nextIndex] = {
            Ordinal: nextIndex,
            isAdded: true,
            Open: true,
        };
        setPackages(newPackages);
    };

    const validatePackageForms = (values, options) => {
        setPackageErrors([]);
        const errors = {};
        Object.keys(values).forEach((key) => {
            const p = values[key];
            errors[key] = {};
            if (!p.Name) {
                errors[key].Name = {
                    message: t('Forms.Validation.FieldRequired', {
                        field: t('Forms.Packages.NameLabel'),
                    }),
                    ref: get(options.fields, `${key}.Name`),
                    type: 'required',
                };
            }
            if (!p.Price) {
                errors[key].Price = {
                    message: t('Forms.Validation.FieldRequired', {
                        field: t('Forms.Packages.PriceLabel'),
                    }),
                    ref: get(options.fields, `${key}.Price`),
                    type: 'required',
                };
            }
            if (!p.Description) {
                errors[key].Description = {
                    message: t('Forms.Validation.FieldRequired', {
                        field: t('Forms.Packages.DescriptionLabel'),
                    }),
                    ref: get(options.fields, `${key}.Description`),
                    type: 'required',
                };
            }
            if (p.Description?.length > 240) {
                errors[key].Description = {
                    message: t('Forms.Validation.CannotExceedCharacterLimit', {
                        characterLimit: 240,
                    }),
                    ref: get(options.fields, `${key}.Description`),
                    type: 'required',
                };
            }
            if (!p.Inclusions) {
                errors[key].Inclusions = {
                    message: t('Forms.Validation.FieldRequired', {
                        field: t('Forms.Packages.InclusionsLabel'),
                    }),
                    ref: get(options.fields, `${key}.Inclusions`),
                    type: 'required',
                };
            }

            if (!Object.keys(errors[key]).length) {
                delete errors[key];
            } else {
                setPackageErrors([...packageErrors, key]);
            }
        });
        if (!Object.keys(errors).length) {
            return { errors: {}, values };
        } else {
            return { errors: toNestError(errors, options), values: {} };
        }
    };

    const PackageErrorMessage = () => {
        return (
            <div>
                {!!packageErrors.length && (
                    <div data-testid="error-message" className="error">
                        <label>
                            <b>{t('Forms.Packages.Error')}</b>
                        </label>
                    </div>
                )}
            </div>
        );
    };

    const Packages = () => {
        const { getValues } = useFormContext();
        let formValues = getValues();
        let currentFormRepresentation = [];

        function getKeyByValue(object, value) {
            return Object.keys(object).find((key) => {
                return object[key].Ordinal === value.Ordinal;
            });
        }

        if (packages) {
            Object.keys(packages).forEach((key) => {
                let value = getKeyByValue(formValues, packages[key]);
                if (value) {
                    currentFormRepresentation.push(value);
                }
            });
            Object.keys(packages).forEach((key) => {
                if (packages[key].isAdded) {
                    currentFormRepresentation.push(packages[key].Ordinal.toString());
                }
            });
        }

        return (
            <div>
                {currentFormRepresentation.length > 0 &&
                    currentFormRepresentation.map((pkgForm, i) => (
                        <PackageEditForm
                            key={i}
                            index={pkgForm}
                            hasError={packageErrors.includes(pkgForm)}
                            onDelete={removePackage}
                            isNew={packages[pkgForm]?.isAdded ? packages[pkgForm].isAdded : false}
                        />
                    ))}
            </div>
        );
    };

    return (
        <PearlForm
            formName="Packages"
            formOptions={formOptions}
            onSubmit={(data) => handleSubmit(data)}
            formType={FormType.EDITABLE}
            StyledForm={EditPackagesPageStyled}
            customValidator={validatePackageForms}
        >
            <EditSectionLayout sectionName={WebstoreSectionTypes.PACKAGES}>
                <h2>{t('Pages.EditPages.PackagesPageTitle')}</h2>
                <PackageErrorMessage />
                <Packages />
                <GhostButton
                    className={'add-button'}
                    data-testid="add-package-button"
                    variant="accent"
                    endIcon="plus"
                    onClick={addPackage}
                >
                    {t('Forms.Packages.AddItem')}
                </GhostButton>
            </EditSectionLayout>
        </PearlForm>
    );
};

export { EditPackagesPage };
