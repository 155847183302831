import React, { useState, useEffect } from 'react';
import { vendorBusinessDetailsFormSchemaWrapper } from 'vendor/constants/schema/VendorBusinessDetailsFormSchema';
import { VendorCategorySelectDropdown } from 'vendor/components/VendorCategorySelectDropdown';
import { Trans, useTranslation } from 'react-i18next';
import { VendorBusinessDetailsFormStyled } from './VendorBusinessDetailsForm.styled';
import { Checkbox, TextInput } from 'dbi-pearl-ui-kit';
import PearlForm from 'vendor/components/forms/PearlForm';
import {
    FormRow,
    PearlError,
    AccountSetupStepLayout,
    AccountSetupStepGraphic,
    AccountSetupStepFooter,
    NewTabLink,
} from 'framework';
import { BusinessDetails, EmptyCaptions } from 'assets/Defaults';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useWebstoreSetupWizard } from 'vendor/components/WebstoreSetup/WebstoreSetupContext';
import { APP_ROUTES } from '../../../../shared/constants/navigation';
import { useTealium, buildWebstoreSetupStepPageData } from '../../../../Tealium';
import { useStore } from 'stores/Store';
import { useFormContext } from 'react-hook-form';
import { TermsOfServiceService } from '../../../lib/apis/TermsOfServiceService';
import { useVendorContext } from '../../../contexts/VendorContext';
import { unwrapResponse } from '../../../../lib/apis/utils';
import { WebstoreSetupHeader } from '../WebstoreSetupHeader';

const formName = 'BusinessDetails';
const AGREE_FIELD = 'Agree';

const Footer = () => {
    const { progress } = useWebstoreSetupWizard();

    return <AccountSetupStepFooter progress={progress} />;
};

const DetailsForm = (props) => {
    const { webstore, onCategoryChange } = props;
    const { t } = useTranslation();
    const { register, setValue } = useFormContext();

    const [selectedCategoryId, setSelectedCategoryId] = useState(webstore.VendorCategoryId);

    const handleCategoryChange = (newCategoryId) => {
        // make sure cat changed, not just a dropdown re-render
        // need to bubble to show correct # steps, etc
        const isCatActuallyChanged = selectedCategoryId !== newCategoryId;
        if (isCatActuallyChanged) {
            onCategoryChange(newCategoryId);
            setSelectedCategoryId(newCategoryId);
        }
    };

    register(AGREE_FIELD, { value: false });

    const handleAgreeChange = (val) => setValue(AGREE_FIELD, val);

    return (
        <div className="formComponent">
            <FormRow
                columns={[
                    <div key={0}>
                        <TextInput formFieldName="WebstoreName" />
                        <PearlError name="WebstoreName" />
                    </div>,
                ]}
            />
            <div className="categoryContainer">
                <VendorCategorySelectDropdown onCategoryChange={handleCategoryChange} />
            </div>
            <div className="agreement">
                <Checkbox
                    label={t(`Forms.Signup.${AGREE_FIELD}`)}
                    name={AGREE_FIELD}
                    onChange={handleAgreeChange}
                />
                <PearlError name={AGREE_FIELD} />
                <p className="p4">
                    <Trans
                        i18nKey="Forms.Signup.SubmitAgreement"
                        components={{
                            vendorAgreement: (
                                <NewTabLink
                                    link={
                                        process.env.REACT_APP_BASE_URL +
                                        APP_ROUTES.vendor.vendorAgreement
                                    }
                                />
                            ),
                        }}
                    />
                </p>
            </div>
        </div>
    );
};

const VendorBusinessDetailsForm = () => {
    const { link, view } = useTealium();
    const {
        appData: { categoryList },
    } = useStore();
    const { vendor } = useVendorContext();
    const { webstore } = useWebstoreContext();
    const {
        onSubmit,
        onReturnToBusinessLocations,
        onCategoryChange,
        currentStepCount,
        totalSteps,
    } = useWebstoreSetupWizard();
    const termsOfServiceApi = TermsOfServiceService();

    const { t } = useTranslation();

    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );

        view(tealiumData);
    }, []);

    const videoDetails = {
        image: BusinessDetails,
        altText: `Forms.${formName}.GifAltText`,
        captions: EmptyCaptions,
    };

    const formOptions = {
        mode: 'onChange',
        defaultValues: {
            WebstoreName: webstore.WebstoreName,
            VendorCategoryId: webstore.VendorCategoryId,
            VendorSubCategoryIds: webstore.VendorSubCategoryIds,
        },
    };

    const handleSubmit = async (data) => {
        const categorySelected = categoryList.find((cat) => cat.id === data.VendorCategoryId);

        // TODO: SUBCAT FIX
        const subCategorySelected = categorySelected?.subCategories?.find(
            (subCat) => subCat.id === data.VendorSubCategoryIds
        );

        const tealiumData = {
            event_name: 'setup-step-advance-click',
            setup_step_number: 1,
            setup_step_total_step_count: totalSteps,
            setup_step_name: t(`Forms.${formName}.StepName`),
            setup_step_category_selected: categorySelected?.name,
            setup_step_subcagetory_selected: subCategorySelected?.name,
            setup_step_form_data: data,
            setup_step_patch_data: data,
            webstore_id: webstore.Id,
        };

        link(tealiumData);

        if (data[AGREE_FIELD] && !vendor.tosAccepted) {
            if (data[AGREE_FIELD] === true) {
                const res = await termsOfServiceApi.putAcceptTermsOfService(vendor.id);
                const data = unwrapResponse(res);
                if (data?.success) {
                    vendor.updateVendor({ tosAccepted: true });
                }
            }
            delete data[AGREE_FIELD];
        }

        onSubmit(data);
    };

    const vendorBusinessDetailsFormSchema = vendorBusinessDetailsFormSchemaWrapper(categoryList);

    return (
        <PearlForm
            StyledForm={VendorBusinessDetailsFormStyled}
            formName={formName}
            formOptions={formOptions}
            validationSchema={vendorBusinessDetailsFormSchema}
            onSubmit={handleSubmit}
        >
            <AccountSetupStepLayout
                header={
                    <WebstoreSetupHeader
                        onReturnToBusinessLocations={onReturnToBusinessLocations}
                        stepName={formName}
                        currentStepCount={currentStepCount}
                        totalSteps={totalSteps}
                    />
                }
                formComponent={
                    <DetailsForm
                        vendor={vendor}
                        webstore={webstore}
                        onCategoryChange={onCategoryChange}
                    />
                }
                image={<AccountSetupStepGraphic {...videoDetails} />}
                footer={<Footer />}
            />
        </PearlForm>
    );
};

export { VendorBusinessDetailsForm };
