import jwtDecode from 'jwt-decode';
import * as cognito from './cognito';
import * as dbiSso from './dbisso';
import * as adminImpersonation from './adminImpersonation';

const IdentityProviders = {
    cognito,
    dbiSso,
    adminImpersonation,
};

const TokenKeys = {
    IDP: 'PEARL_IDP',
    ACCESS_TOKEN_EXPIRATION: 'PEARL_ACCESS_TOKEN_EXPIRATION',
    ACCESS_TOKEN: 'PEARL_ACCESS_TOKEN',
    ID_TOKEN: 'PEARL_ID_TOKEN',
    ID_ATTRIBUTES: 'PEARL_ID_ATTRIBUTES',
    REFRESH_TOKEN: 'PEARL_REFRESH_TOKEN',
};

const TokenService = (storageLocation = window.localStorage) => {
    const getLoginProvider = () => {
        const providerName = storageLocation.getItem(TokenKeys.IDP);
        return IdentityProviders[providerName];
    };
    const getAccessToken = () => {
        return storageLocation.getItem(TokenKeys.ACCESS_TOKEN);
    };
    const getIdToken = () => {
        return storageLocation.getItem(TokenKeys.ID_TOKEN);
    };
    const getUserAttributes = () => {
        const attributes = JSON.parse(storageLocation.getItem(TokenKeys.ID_ATTRIBUTES) || '{}');
        return attributes;
    };
    const getRefreshToken = () => {
        return storageLocation.getItem(TokenKeys.REFRESH_TOKEN);
    };
    const getTokenExpiration = () => {
        return storageLocation.getItem(TokenKeys.ACCESS_TOKEN_EXPIRATION);
    };

    const setLoginProvider = (provider) => {
        return storageLocation.setItem(TokenKeys.IDP, provider);
    };
    const setAccessToken = (val) => {
        // decode the token and save both the token, and it's expiration in storageLocation
        const decoded = jwtDecode(val);
        storageLocation.setItem(TokenKeys.ACCESS_TOKEN_EXPIRATION, decoded.exp);
        return storageLocation.setItem(TokenKeys.ACCESS_TOKEN, val);
    };
    const setIdToken = (val) => {
        const decoded = jwtDecode(val);
        const userAttributes = JSON.stringify(decoded);
        storageLocation.setItem(TokenKeys.ID_ATTRIBUTES, userAttributes);
        return storageLocation.setItem(TokenKeys.ID_TOKEN, val);
    };
    const setRefreshToken = (val) => {
        return storageLocation.setItem(TokenKeys.REFRESH_TOKEN, val);
    };

    const clearTokens = () => {
        Object.values(TokenKeys).forEach((t) => storageLocation.removeItem(t));
    };

    const tryRefreshTokens = async () => {
        const provider = getLoginProvider();
        const refreshToken = getRefreshToken();

        try {
            const newTokens = await provider.refresh(refreshToken);
            setAccessToken(newTokens.access_token);
            setRefreshToken(newTokens.refresh_token);
        } catch {
            clearTokens();
        }
    };

    const getOrRefreshAccessToken = async () => {
        const provider = getLoginProvider();

        if (!provider) {
            return null;
        } else {
            const expiry = getTokenExpiration();
            let accessToken = getAccessToken();

            // expiry is in seconds, use 1 minute buffer
            if (accessToken && Date.now() > (Number(expiry) - 60) * 1000) {
                await tryRefreshTokens();
                return getAccessToken();
            }

            return accessToken;
        }
    };

    return {
        getLoginProvider,
        getIdToken,
        getUserAttributes,
        getRefreshToken,
        getOrRefreshAccessToken,
        setLoginProvider,
        setAccessToken,
        setIdToken,
        setRefreshToken,
        clearTokens,
    };
};

export { TokenService, IdentityProviders };
