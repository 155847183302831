import styled from 'styled-components';

const VendorMediaUploadFormStyled = styled.form`
    display: flex;
    flex-direction: column;
    height: 100%;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .subtitle {
        margin: 0 0 20px 0;
    }

    .addLabel {
        color: ${(props) => props.theme.colors.primary.base};
    }

    .p2 {
        margin: 8px 0 0;
    }

    .error {
        color: ${(props) => props.theme.colors.error.base};
    }

    .imageContainer {
        display: flex;
        margin: 24px 0 0;
        flex-wrap: wrap;
    }

    .dropPlaceholder,
    .preview {
        margin: 0 12px 12px 0;
    }

    .dropPlaceholder {
        background: ${(props) => props.theme.colors.background.container};
        box-sizing: border-box;
        height: 210px;
        width: 210px;
        text-align: center;
    }

    .preview {
        display: inline-flex;
        border: 1px solid ${(props) => props.theme.colors.foreground.border};
        width: 210px;
        height: 210px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;

        .loader {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -48px;
            right: -48px;
            bottom: -48px;
            left: -48px;
            background-color: ${(props) => props.theme.colors.background.base};
            opacity: 0.5;
            animation: rotation 2s infinite linear;
        }

        .delete {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            padding: 8px;
            margin: 8px;
            border-radius: 50%;
            box-sizing: border-box;
            border: none;
            background-color: ${(props) => props.theme.colors.background.container};
            cursor: pointer;
            height: 40px;
            width: 40px;
            color: ${(props) => props.theme.colors.error.base};
        }

        img {
            display: block;
            width: auto;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }

    .validation-failed {
        filter: blur(0.25rem);
    }
`;

export { VendorMediaUploadFormStyled };
