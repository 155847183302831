import { LinearProgress as MuiLinearProgress } from '@mui/material';
import styled from 'styled-components';

const LinearProgress = styled(MuiLinearProgress)`
    &.MuiLinearProgress-root {
        opacity: 50%;
    }

    &.MuiLinearProgress-colorPrimary {
        background-color: ${(props) => props.theme.colors.primary.base}80;
    }

    .MuiLinearProgress-barColorPrimary {
        background-color: ${(props) => props.theme.colors.primary.base};
    }
`;

export { LinearProgress };
