import { GalleryError } from 'assets/media-upload-images/MediaUploadImages';
import { AwardsError } from 'assets/media-upload-images/MediaUploadImages';
import { PackagesError } from 'assets/media-upload-images/MediaUploadImages';
import { PearlApps } from '../../lib/apis/PearlApiClient';

const moderateImage = (image) => {
    if (image && (!image.url || !image.validationResult)) {
        image.url = GalleryError.default;
        image.placeholder = true;
    }
};

const moderateGalleryImages = (webstore) => {
    if (webstore.media && webstore.media.images) {
        return webstore.media?.images.forEach((image, index) => {
            if (!image.url || (!image.validationResult && !image.adminApproved)) {
                if (image?.props) {
                    Object.keys(image.props?.alternateImages).forEach(
                        (key) => (image.props.alternateImages[key] = GalleryError.default)
                    );
                }

                webstore.media.images[index] = {
                    ...webstore.media.images[index],
                    url: GalleryError.default,
                    placeholder: true,
                };
            }
        });
    } else if (webstore.Media && webstore.Media?.images) {
        return webstore.Media?.images.forEach((image, index) => {
            // webstore.Media.images[index] = moderateImage(image)
            if (!image.url || (!image.validationResult && !image.adminApproved)) {
                if (image?.props) {
                    Object.keys(image.props?.alternateImages).forEach(
                        (key) => (image.props.alternateImages[key] = GalleryError.default)
                    );
                    image.placeholder = true;
                }

                webstore.Media.images[index] = {
                    ...webstore.Media.images[index],
                    url: GalleryError.default,
                    placeholder: true,
                };
            }
        });
    }
};

const moderateAwardImages = (webstore) => {
    webstore.Awards?.forEach((award) => {
        if (award.image) {
            if (!award.image.url || (!award.image.validationResult && !award.image.adminApproved)) {
                if (award.image.props) {
                    Object.keys(award.image.props.alternateImages).forEach(
                        (key) => (award.image.props.alternateImages[key] = AwardsError.default)
                    );
                }

                award.image = {
                    ...award.image,
                    url: AwardsError.default,
                    placeholder: true,
                };
            }
        }
    });
};

const moderatePackageImages = (webstore) => {
    webstore.Packages?.forEach((pkg) => {
        if (pkg.image) {
            if (!pkg.image.url || (!pkg.image.validationResult && !pkg.image.adminApproved)) {
                if (pkg.image.props) {
                    Object.keys(pkg.image.props.alternateImages).forEach(
                        (key) => (pkg.image.props.alternateImages[key] = PackagesError.default)
                    );
                }

                pkg.image = {
                    ...pkg.image,
                    url: PackagesError.default,
                    placeholder: true,
                };
            }
        }
    });
};

const moderatePartners = (webstore) => {
    webstore.Partners?.forEach((partner) => {
        let coverPhoto = partner.partnerData.coverPhoto;

        if (coverPhoto) {
            if (!coverPhoto.url || (!coverPhoto.validationResult && !coverPhoto.adminApproved)) {
                coverPhoto.url = GalleryError.default;
                coverPhoto.placeholder = true;

                if (coverPhoto.props) {
                    Object.keys(partner.partnerData.coverPhoto.props.alternateImages).forEach(
                        (key) =>
                            (partner.partnerData.coverPhoto.props.alternateImages[key] =
                                GalleryError.default)
                    );
                }

                partner.partnerData.coverPhoto = coverPhoto;
            }
        }
    });
};

const moderateImages = (webstore, appName) => {
    if (appName !== PearlApps.ADMIN && appName !== PearlApps.VENDOR) {
        moderateGalleryImages(webstore);
        moderateAwardImages(webstore);
        moderatePackageImages(webstore);
        moderatePartners(webstore);
    }

    return webstore;
};

export {
    moderateImage,
    moderateImages,
    moderateAwardImages,
    moderateGalleryImages,
    moderatePackageImages,
    moderatePartners,
};
