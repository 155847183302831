import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const VendorServiceCharacteristicsFormStyled = styled.form`
    display: flex;
    height: 100%;
    overflow-y: hidden;

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        overflow-y: visible;

        .accordion-toggle:checked + .accordion-group-label + .collapsible-content: {
            max-height: 100%;
        }
    }
`;

export { VendorServiceCharacteristicsFormStyled };
