import { useTranslation } from 'react-i18next';
import { ReturnToBusinessLocationsButton } from './ReturnToBusinessLocationsButton';
import { AccountSetupStepCounter } from '../../../framework';
import React from 'react';

const WebstoreSetupHeader = (props) => {
    const { onReturnToBusinessLocations, stepName, currentStepCount, totalSteps } = props;

    const { t } = useTranslation();
    const stepTns = t(`Forms.${stepName}`, { returnObjects: true });

    return (
        <>
            <div className="actions">
                {!!onReturnToBusinessLocations && (
                    <ReturnToBusinessLocationsButton onClick={onReturnToBusinessLocations} />
                )}
            </div>
            <AccountSetupStepCounter
                stepCountText={t('Forms.Labels.Steps', {
                    completedSteps: currentStepCount,
                    totalSteps: totalSteps,
                })}
                stepNameText={stepTns.StepName}
            />
            <h1>{stepTns.Title}</h1>
            <p className="subtitle">{stepTns.Subtitle}</p>
            {!!stepTns.Requirement && (
                <div className="stepInstructions">
                    <label className="cta">
                        <b>{stepTns.Requirement}</b>
                    </label>
                </div>
            )}
        </>
    );
};

export { WebstoreSetupHeader };
