import React from 'react';
import { ChatAvatar } from '../../ChatAvatar/ChatAvatar';
import { ChatTimestamp } from '../ChatTimestamp/ChatTimestamp';
import { ChatText } from './Plugins/ChatText';
import { ChatImage } from './Plugins/ChatImage';
import { ChatAttachment } from './Plugins/ChatAttachment';
import { ChatBubbleStyled } from './ChatBubble.styled';
import { LinearProgress } from '../../../utility/Progress/LinearProgress';
import { MessageTypes } from '../../MessageTypes';

const Plugin = ({ message }) => {
    const comps = new Map([
        [
            MessageTypes.IMAGE,
            () => (
                <ChatImage
                    filename={message.data.filename}
                    url={message.data.url}
                    caption={message.data.caption}
                    pending={message.pending}
                />
            ),
        ],
        [
            MessageTypes.ATTACHMENT,
            () => (
                <ChatAttachment
                    filename={message.data.filename}
                    url={message.data.url}
                    caption={message.data.caption}
                    pending={message.pending}
                />
            ),
        ],
        [MessageTypes.TEXT, () => <ChatText>{message.message?.toString()}</ChatText>],
    ]);

    return <>{(comps.get(message.type) || comps.get(MessageTypes.TEXT))()}</>;
};

const ChatBubble = (props) => {
    const { message } = props;

    return (
        <ChatBubbleStyled
            className={`chat-bubble message-${message.source} ${message.pending ? 'pending' : ''}`}
        >
            <ChatAvatar message={message} />
            <div className="message-container">
                <div className="message-content">
                    <Plugin message={message} />
                </div>
                {message.pending && <LinearProgress />}
                <ChatTimestamp timestamp={message.ts} source={message.source} />
            </div>
        </ChatBubbleStyled>
    );
};

export { ChatBubble };
