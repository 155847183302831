import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useTranslation } from 'react-i18next';
import { EditSectionLayout } from '../EditSectionLayout/EditSectionLayout';
import PearlForm, { FormType } from 'vendor/components/forms/PearlForm';
import { useOutletContext } from 'react-router-dom';
import { EditFaqsPageStyled } from './EditFaqsPage.styled';
import { useState } from 'react';
import { GhostButton } from 'dbi-pearl-ui-kit';
import { FaqEditForm } from 'vendor/components/Webstore/SectionEdit/FaqEditForm/FaqEditForm';
import { get } from 'react-hook-form';
import { toNestError } from '@hookform/resolvers';
import { useVendorContext } from 'vendor/contexts/VendorContext';
import { WebstoreSectionTypes } from 'framework';
import { ToggleableSectionsValidation } from 'vendor/components/Webstore/WebstoreSectionFactories';

const EditFaqsPage = () => {
    const { webstore } = useWebstoreContext();
    const {
        vendor: { faqOptions },
    } = useVendorContext();
    const { onSubmit } = useOutletContext();
    const { t } = useTranslation();
    const [faqErrors, setFaqErrors] = useState([]);
    const [faqs, setFaqs] = useState(webstore.Faqs);
    const [originalFaqs] = useState(webstore.Faqs);

    const faqFormComponents = faqs?.map((q) => {
        const isDefaultQuestion = q.question.length && faqOptions?.includes(q.question);
        return {
            Question: isDefaultQuestion || q.isNew ? q.question : 'custom',
            CustomQuestion: !isDefaultQuestion ? q.question : null,
            Answer: q.answer,
            Ordinal: q.ordinal,
            isDefault: isDefaultQuestion,
            Open: false,
        };
    });

    const formOptions = {
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: faqFormComponents,
        shouldUnregister: true,
    };

    const enableToggle = (faqsToSubmit) => {
        if (originalFaqs.length === 0) {
            const validationCheck =
                ToggleableSectionsValidation[WebstoreSectionTypes.FAQS].UIValidation;

            return validationCheck(webstore.ActiveFeatures, faqsToSubmit);
        }
        return false;
    };

    const handleSubmit = async (data) => {
        // make sure the previous id gets added to the form data
        Object.keys(faqs).forEach((key) => {
            if (faqs[key].Id && data[key]) {
                data[key].Id = faqs[key].Id;
            }
            if (data && data[key].CustomQuestion) {
                data[key].Question = data[key].CustomQuestion;
                delete data[key].CustomQuestion;
            }
        });
        const payload = Object.values(data).map((f, i) => {
            return {
                question: f.Question,
                answer: f.Answer,
                ordinal: i,
            };
        });

        setFaqs(payload);
        const faqsToSubmit = payload;
        let optionalSectionToSubmit = [];
        const canEnable = enableToggle(faqsToSubmit);

        if (canEnable) {
            const sectionId = webstore.OptionalSections.find(
                (os) => os.name === WebstoreSectionTypes.FAQS
            )?.id;

            optionalSectionToSubmit = [
                {
                    id: sectionId,
                    name: WebstoreSectionTypes.FAQS,
                    enabled: true,
                },
            ];
        }

        await onSubmit({ Faqs: payload });

        if (optionalSectionToSubmit.length > 0 && webstore.Faqs.length > 0) {
            onSubmit({ OptionalSections: optionalSectionToSubmit });
        }
    };

    const removeFaq = (event, index) => {
        event.stopPropagation();
        event.preventDefault();
        const newFaqsArray = Object.values(faqs);
        newFaqsArray.splice(index, 1);
        setFaqs(newFaqsArray);
    };

    const addFaq = () => {
        const nextIndex = Object.keys(faqs).length;
        const newFaqs = [...faqs];
        newFaqs[nextIndex] = {
            ordinal: nextIndex,
            question: '',
            answer: '',
            isNew: true,
            open: true,
        };
        setFaqs(newFaqs);
    };

    const validateFaqForms = (values, options) => {
        setFaqErrors([]);
        const errors = {};
        Object.keys(values).forEach((key) => {
            const p = values[key];
            errors[key] = {};
            if (!p.Question) {
                errors[key].Question = {
                    message: t('Forms.Validation.FieldRequired', {
                        field: t('Forms.Faqs.QuestionLabel'),
                    }),
                    ref: get(options.fields, `${key}.Question`),
                    type: 'required',
                };
            }
            if (p.Question === 'custom' && !p.CustomQuestion) {
                errors[key].CustomQuestion = {
                    message: t('Forms.Validation.FieldRequired', {
                        field: t('Forms.Faqs.CustomQuestionLabel'),
                    }),
                    ref: get(options.fields, `${key}.CustomQuestion`),
                    type: 'required',
                };
            }
            if (!p.Answer) {
                errors[key].Answer = {
                    message: t('Forms.Validation.FieldRequired', {
                        field: t('Forms.Faqs.AnswerLabel'),
                    }),
                    ref: get(options.fields, `${key}.Answer`),
                    type: 'required',
                };
            }
            if (p.Answer.length > 240) {
                errors[key].Answer = {
                    message: t('Forms.Validation.CannotExceedCharacterLimit', {
                        characterLimit: 240,
                    }),
                    ref: get(options.fields, `${key}.Answer`),
                    type: 'required',
                };
            }

            if (!Object.keys(errors[key]).length) {
                delete errors[key];
            } else {
                setFaqErrors([...faqErrors, key]);
            }
        });
        if (!Object.keys(errors).length) {
            return { errors: {}, values };
        } else {
            return { errors: toNestError(errors, options), values: {} };
        }
    };

    const FaqsErrorMessage = () => {
        return (
            <div>
                {!!faqErrors.length && (
                    <div data-testid="error-message" className="error">
                        <b>{t('Forms.Faqs.Error')}</b>
                    </div>
                )}
            </div>
        );
    };

    const Faqs = () => {
        return (
            <div>
                {faqFormComponents?.length > 0 &&
                    faqFormComponents.map((q, i) => (
                        <FaqEditForm
                            key={i}
                            index={i}
                            pearlFaq={q}
                            defaultFaqs={faqOptions}
                            hasError={faqErrors.includes(i)}
                            onDelete={removeFaq}
                            isNew={faqs[i].isNew}
                        />
                    ))}
            </div>
        );
    };

    return (
        <PearlForm
            formName="Faqs"
            formOptions={formOptions}
            onSubmit={handleSubmit}
            formType={FormType.EDITABLE}
            StyledForm={EditFaqsPageStyled}
            customValidator={validateFaqForms}
        >
            <EditSectionLayout sectionName={WebstoreSectionTypes.FAQS}>
                <h2>{t('Pages.EditPages.FaqsPageTitle')}</h2>
                <FaqsErrorMessage />
                <Faqs />
                <GhostButton
                    data-testid="add-faq-button"
                    variant="accent"
                    endIcon="plus"
                    onClick={addFaq}
                >
                    {t('Forms.Faqs.AddItem')}
                </GhostButton>
            </EditSectionLayout>
        </PearlForm>
    );
};

export { EditFaqsPage };
