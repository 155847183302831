import { WebstoreSectionTypes } from './WebstoreSectionTypes';

const navigation = {
    navigationSections: [
        {
            section: WebstoreSectionTypes.ABOUT,
            displayName: 'AboutLinkDisplayName',
            anchorId: 'webstore-about',
        },
        {
            section: WebstoreSectionTypes.AVAILABILITY_CALENDAR,
            displayName: 'AvailabilityLinkDisplayName',
            anchorId: 'webstore-availability',
        },
        {
            section: WebstoreSectionTypes.GALLERY,
            displayName: 'MediaGalleryLinkDisplayName',
            anchorId: 'webstore-media-gallery',
        },
        {
            section: WebstoreSectionTypes.REVIEWS,
            displayName: 'ReviewsLinkDisplayName',
            anchorId: 'webstore-google-reviews',
        },
        {
            section: WebstoreSectionTypes.PACKAGES,
            displayName: 'PackagesLinkDisplayName',
            anchorId: 'webstore-packages',
        },
        {
            section: WebstoreSectionTypes.FAQS,
            displayName: 'FaqsLinkDisplayName',
            anchorId: 'webstore-faqs',
        },
        {
            section: WebstoreSectionTypes.PARTNERS,
            displayName: 'PartnersLinkDisplayName',
            anchorId: 'webstore-partners',
        },
        {
            section: WebstoreSectionTypes.AWARDS,
            displayName: 'AwardsLinkDisplayName',
            anchorId: 'webstore-awards',
        },
    ],
};

export { navigation };
