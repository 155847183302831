import { AvailabilityCalendarSectionEditStyled } from './AvailabilityCalendarSectionEdit.styled';
import { t } from 'i18next';
import { CalendarDisplay, LinkButton, SolidButton } from 'dbi-pearl-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { addMonths, format, subMonths } from 'date-fns';
import PearlForm, { FormType } from 'vendor/components/forms/PearlForm';
import { useScreenSize } from 'vendor/lib/ScreenSize';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
    PearlModalSizes,
    PearlUnsavedChangesAlert,
    usePearlModal,
    usePearlToast,
    WebstoreSectionTypes,
} from 'framework';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { WebstoreService } from 'vendor/lib/apis/WebstoreService';
import calendarStyles from 'react-date-range/dist/styles.css';
import calendarThemeStyles from 'react-date-range/dist/theme/default.css';

const API_DATE_FORMAT = 'yyyy-MM-dd';

const getAvailabilityValue = (status) => {
    switch (status) {
        case 'partial':
            return '1';
        case 'unavailable':
            return '2';
        default:
            return '0';
    }
};

const getPreviousStatus = (date, webstore) => {
    if (date && webstore.Availability) {
        const previous = webstore.Availability.find((ad) => ad.date.getTime() === date.getTime());
        if (previous) {
            return getAvailabilityValue(previous.state);
        } else {
            return '0';
        }
    }

    return null;
};

const HeaderSection = ({ handleClick }, screenSize, showStatusEdit) => {
    const {
        formState: { isDirty },
        getValues,
        reset,
    } = useFormContext();

    return (
        <div>
            <LinkButton
                data-testid="backButton"
                startIcon="arrow-left"
                variant={'accent'}
                className={'back-button'}
                onClick={() => handleClick(isDirty, getValues(), reset)}
            >
                {screenSize.small && showStatusEdit
                    ? t('WebstoreSections.AvailabilityCalendar.BackToCalendar')
                    : t('EditBack')}
            </LinkButton>
        </div>
    );
};

const AvailabilityStatusForm = ({ selectedDate, webstore }) => {
    const statusOptions = [
        {
            key: 'StatusAvailable',
            value: 0,
            title: 'WebstoreSections.AvailabilityCalendar.Available',
        },
        {
            key: 'StatusPartial',
            value: 1,
            title: 'WebstoreSections.AvailabilityCalendar.PartiallyAvailable',
        },
        {
            key: 'StatusUnavailable',
            value: 2,
            title: 'WebstoreSections.AvailabilityCalendar.Unavailable',
        },
    ];
    const { register, setValue, getValues, reset } = useFormContext();

    useEffect(() => {
        reset(getValues());
        setValue('AvailabilityStatus', getPreviousStatus(selectedDate, webstore));
        setValue('SelectedDate', selectedDate);
    }, [selectedDate, setValue, webstore, register, getValues, reset]);

    return (
        <div>
            <div className="section">
                <label className="formLabel">
                    {t('WebstoreSections.AvailabilityCalendar.DatesSelected')}
                </label>

                {selectedDate ? (
                    <h3 data-testid="selectedDate">{format(selectedDate, 'MMM d')}</h3>
                ) : (
                    <span data-testid="noDateSelected">No date selected</span>
                )}
            </div>

            <div className="section">
                <label className="formLabel">
                    {t('WebstoreSections.AvailabilityCalendar.Availability')}
                </label>
                <div className="statusButtons">
                    {statusOptions.map((option) => {
                        return (
                            <div className="boxInputWrapper" key={option.key}>
                                <input
                                    type="radio"
                                    id={option.key}
                                    data-testid={option.key}
                                    name="availabilityStatus"
                                    value={option.value}
                                    {...register('AvailabilityStatus')}
                                />
                                <label htmlFor={option.key}>
                                    <div className="box">
                                        <div className="boxLeftContent">
                                            <span
                                                className={`statusIndicator ${option.key}`}
                                            ></span>
                                        </div>
                                        <div className="boxMiddleContent">
                                            <p className="p3 boxTitle">{t(`${option.title}`)}</p>
                                        </div>
                                        <div className="boxRightContent"></div>
                                    </div>
                                </label>
                            </div>
                        );
                    })}
                </div>
            </div>
            <SolidButton
                id="saveButton"
                data-testid="saveButton"
                type="submit"
                disabled={!selectedDate}
            >
                {t('Forms.Buttons.Save')}
            </SolidButton>
        </div>
    );
};

const AvailabilityCalendarDatesSection = ({
    setSelectedDate,
    handleShownDate,
    selectedDate,
    shownDate,
    availability,
    handleDirtyDate,
}) => {
    const {
        formState: { isDirty },
        getValues,
        reset,
    } = useFormContext();

    const handleDateChange = (date) => {
        if (date?.getDate() === selectedDate?.getDate()) {
            return setSelectedDate(null);
        } else {
            return isDirty ? handleDirtyDate(getValues(), reset, date) : setSelectedDate(date);
        }
    };

    const ariaLabels = {
        prevButton: t('WebstoreSections.AvailabilityCalendar.AriaLabels.PreviousButton'),
        nextButton: t('WebstoreSections.AvailabilityCalendar.AriaLabels.NextButton'),
    };

    return (
        <CalendarDisplay
            months={1}
            editable={true}
            onDateSelected={handleDateChange}
            dayStates={availability}
            showDateSelectors={true}
            selectedDate={selectedDate}
            shownDate={shownDate}
            calendarChangeCallback={handleShownDate}
            ariaLabels={ariaLabels}
        />
    );
};

const AvailabilityCalendarSectionEditBase = () => {
    const { webstore } = useWebstoreContext();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(null);
    const [shownDate, setShownDate] = useState(null);
    const [showStatusEdit, setShowStatusEdit] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const webstoreApi = WebstoreService();
    const screenSize = useScreenSize();
    const { toastSuccess, toastError } = usePearlToast();
    const { openModal, closeModal } = usePearlModal();

    const loadAvailabilityDates = useCallback(
        (date) => {
            const baseDate = date || shownDate || new Date();
            const start = format(subMonths(baseDate, 1), API_DATE_FORMAT);
            const end = format(addMonths(baseDate, 1), API_DATE_FORMAT);
            webstoreApi.getWebstoreAvailability(webstore.Id, start, end).then((res) => {
                webstore.setAvailability(res);
            });
        },
        [webstore, webstoreApi, shownDate]
    );

    useEffect(() => {
        if (!initialized) {
            loadAvailabilityDates();
            setInitialized(true);
        }
    }, [initialized, setInitialized, loadAvailabilityDates]);

    const handleAvailabilitySubmit = async (data) => {
        const availability = {
            date: format(data.SelectedDate, API_DATE_FORMAT),
            availability: getAvailabilityString(data.AvailabilityStatus),
        };

        const response = await webstoreApi.patchWebstoreAvailability(webstore.Id, availability);

        if (response.ok) {
            const updatedWebstore = await webstoreApi.getWebstoreById(webstore.Id, {
                removeInvalidImgSrc: false,
            });

            if (updatedWebstore.ok && updatedWebstore.data) {
                webstore.setFromApi(updatedWebstore.data);
            }

            loadAvailabilityDates();
            if (screenSize.small) {
                setShowStatusEdit(false);
            }
            toastSuccess();
        } else {
            toastError();
        }
    };

    const handleShownDateChange = (date) => {
        setShownDate(new Date(date));
        loadAvailabilityDates(date);
    };

    const handleSaveFromAlert = async (values, formReset, nextDate) => {
        await handleSubmitOnChange(values);
        formReset();
        setSelectedDate(nextDate);
    };

    const handleDiscardFromAlert = (formReset, nextDate) => {
        closeModal();
        formReset();
        setShowStatusEdit(false);
        if (nextDate) {
            setSelectedDate(nextDate);
        }
    };

    const handleBackClick = (isDirty, values, reset) => {
        if (isDirty && screenSize.small) {
            openModal(
                <PearlUnsavedChangesAlert
                    handleOnSave={() => handleSaveFromAlert(values, reset)}
                    handleDiscard={() => {
                        handleDiscardFromAlert(reset);
                    }}
                />,
                PearlModalSizes.SM.MOBILE,
                null,
                () => handleDiscardFromAlert(reset)
            );
        } else {
            if (showStatusEdit) {
                setShowStatusEdit(false);
            } else {
                navigate(-1, {
                    state: {
                        scrollToSection: WebstoreSectionTypes.AVAILABILITY_CALENDAR,
                    },
                });
            }
        }
    };

    const handleUnsavedDateChange = (values, reset, nextDate) => {
        openModal(
            <PearlUnsavedChangesAlert
                handleOnSave={() => handleSaveFromAlert(values, reset, nextDate)}
                handleDiscard={() => {
                    handleDiscardFromAlert(reset, nextDate);
                }}
            />,
            PearlModalSizes.LG,
            null,
            () => handleDiscardFromAlert(reset, nextDate)
        );
    };

    const handleSubmitOnChange = async (data) => {
        await handleAvailabilitySubmit(data);
        closeModal();
        setShowStatusEdit(false);
    };

    const getAvailabilityString = (status) => {
        switch (status) {
            case '0':
                return 'available';
            case '1':
                return 'partial';
            case '2':
                return 'unavailable';
            default:
                return 'unknown';
        }
    };

    const formOptions = {
        defaultValues: {
            AvailabilityStatus: getPreviousStatus(selectedDate, webstore),
            SelectedDate: null,
        },
    };

    return (
        <PearlForm
            formName="AvailabilityStatusForm"
            formOptions={formOptions}
            onSubmit={handleAvailabilitySubmit}
            formType={FormType.EDITABLE}
        >
            <style>
                {calendarStyles.default}
                {calendarThemeStyles.default}
            </style>
            <AvailabilityCalendarSectionEditStyled>
                <div className="calendar-edit-section">
                    <div className="section-header">
                        <HeaderSection
                            handleClick={handleBackClick}
                            screenSize={screenSize}
                            showStatusEdit={showStatusEdit}
                        />
                        <h2>{t('WebstoreSections.AvailabilityCalendar.AvailabilityCalendar')}</h2>
                        <div className="subtitleMargin">
                            {t('WebstoreSections.AvailabilityCalendar.Subtitle')}
                        </div>
                    </div>
                    <main>
                        {(screenSize.large || !showStatusEdit) && (
                            <div className="availabilityWrapper">
                                <AvailabilityCalendarDatesSection
                                    setSelectedDate={setSelectedDate}
                                    availability={webstore.Availability}
                                    selectedDate={selectedDate}
                                    shownDate={shownDate}
                                    handleShownDate={handleShownDateChange}
                                    handleDirtyDate={handleUnsavedDateChange}
                                />
                                {screenSize.small && (
                                    <div className="editButton">
                                        <SolidButton
                                            id="editButton"
                                            data-testid="editButton"
                                            label="Button"
                                            onClick={() => setShowStatusEdit(true)}
                                            variant="accent"
                                            disabled={!selectedDate}
                                        >
                                            {t(
                                                'WebstoreSections.AvailabilityCalendar.EditAvailability'
                                            )}
                                        </SolidButton>
                                    </div>
                                )}
                            </div>
                        )}
                        {(screenSize.large || showStatusEdit) && (
                            <div className="statusContainer">
                                <AvailabilityStatusForm
                                    selectedDate={selectedDate}
                                    webstore={webstore}
                                ></AvailabilityStatusForm>
                            </div>
                        )}
                    </main>
                </div>
            </AvailabilityCalendarSectionEditStyled>
        </PearlForm>
    );
};

const AvailabilityCalendarSectionEdit = observer(AvailabilityCalendarSectionEditBase);

export { AvailabilityCalendarSectionEdit };
