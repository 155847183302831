import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AmenitiesAndCharacteristics, EmptyCaptions } from 'assets/Defaults';
import PearlForm from 'vendor/components/forms/PearlForm';
import { AccountSetupStepLayout, AccountSetupStepGraphic, AccountSetupStepFooter } from 'framework';
import { VendorServiceCharacteristicsFormStyled } from './VendorServiceCharacteristicsForm.styled';
import { ServiceCharacteristicsSection } from 'vendor/components/Webstore/ServiceCharacteristicsSection/ServiceCharacteristicsSection';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useWebstoreSetupWizard } from 'vendor/components/WebstoreSetup/WebstoreSetupContext';
import {
    useTealium,
    buildWebstoreSetupStepPageData,
    buildWebstoreSetupStepNextClickData,
} from '../../../../Tealium';
import { WebstoreSetupHeader } from '../WebstoreSetupHeader';

const formName = 'VendorCharacteristics';

const Footer = (props) => {
    const { isSkippable, handleSkip } = props;
    const { onBack, progress } = useWebstoreSetupWizard();

    return (
        <AccountSetupStepFooter
            handleOnBack={() => onBack()}
            handleOnSkip={isSkippable ? handleSkip : null}
            progress={progress}
        />
    );
};

const VendorServiceCharacteristicsForm = () => {
    const { link, view } = useTealium();
    const { webstore } = useWebstoreContext();
    const { onSubmit, onReturnToBusinessLocations, onSkip, currentStepCount, totalSteps } =
        useWebstoreSetupWizard();
    const { t } = useTranslation();

    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );

        view(tealiumData);
    }, []);

    const videoDetails = {
        image: AmenitiesAndCharacteristics,
        altText: `Forms.${formName}.GifAltText`,
        captions: EmptyCaptions,
    };

    const [patchableData, setPatchableData] = useState(webstore.Styles || []);
    const [isSkippable, setIsSkippable] = useState();

    useEffect(() => {
        setIsSkippable(!patchableData?.length);
    }, [patchableData]);

    const handleSubmit = (data) => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepNextClickData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps,
            data,
            patchableData
        );

        link(tealiumData);

        onSubmit({ Styles: patchableData });
    };

    const handleSkip = () => {
        // if no currently selected values AND
        // no previously selected values, then skip
        // else, send patch
        if (isSkippable && !webstore.Styles?.length) {
            onSkip();
        } else {
            handleSubmit();
        }
    };

    return (
        <PearlForm
            StyledForm={VendorServiceCharacteristicsFormStyled}
            formName="VendorServiceCharacteristics"
            onSubmit={handleSubmit}
        >
            <AccountSetupStepLayout
                header={
                    <WebstoreSetupHeader
                        onReturnToBusinessLocations={onReturnToBusinessLocations}
                        stepName={formName}
                        currentStepCount={currentStepCount}
                        totalSteps={totalSteps}
                    />
                }
                formComponent={
                    <ServiceCharacteristicsSection
                        webstore={webstore}
                        onChange={setPatchableData}
                    />
                }
                image={<AccountSetupStepGraphic {...videoDetails} />}
                footer={<Footer isSkippable={isSkippable} handleSkip={handleSkip} />}
            />
        </PearlForm>
    );
};

export { VendorServiceCharacteristicsForm };
