import styled from 'styled-components';

const Separator = styled.hr`
    height: 1px;
    background-color: ${(props) => props.theme.colors.foreground.border};
    border: none;
    margin: 0;
`;

export { Separator };
