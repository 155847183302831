import styled from 'styled-components';

const ChatBubbleStyled = styled.div`
    display: flex;
    gap: 14px;

    .message-container {
        .message-content {
            margin: 0;
            padding: 24px;
        }
    }

    &.message-received {
        flex-direction: row;

        .message-container {
            .message-content {
                background-color: ${(props) => props.theme.colors.foreground.border};
            }
        }
    }

    &.message-sent {
        flex-direction: row-reverse;

        .message-container {
            .message-content {
                background-color: ${(props) => props.theme.colors.background.base};
            }
        }
    }

    &.pending {
        .MuiLinearProgress-root {
            margin-top: -4px;
        }
    }
`;

export { ChatBubbleStyled };
