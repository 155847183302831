import { HamburgerMenuStyled } from './HamburgerMenu.styled';
import { HamburgerHeaderStyled } from './HamburgerHeader.styled';
import { HamburgerSectionsStyled } from './HamburgerSections.styled';
import { useTranslation } from 'react-i18next';
import { IconButton, IconName, IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { navigation } from '../../../constants/navigation';
import { HamburgerUserMenu } from './HamburgerUserMenu/HamburgerUserMenu';
import styled from 'styled-components';
import { PearlBlack } from 'assets/logos/LogoImages';
import { NewTabLink, PearlApps } from 'framework';
import { useAuthUserContext } from 'contexts/AuthUserContext';
import { getAppName } from 'shared/hooks/AppName';

const HamburgerContentStyled = styled.div`
    width: 324px;

    .navList {
        display: grid;
        grid-template-rows: 80px 100%;
        height: 100vh;
    }

    .bodyContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: fit-content;
        height: calc(100% - 80px);
    }

    .logo {
        width: 50%;
    }
`;

const SectionTitle = (props) => {
    const { section, clickHandler } = props;
    const { t } = useTranslation();

    if (section.src) {
        return (
            <Link
                className="sectionTitle sectionTitleLink"
                to={section.src}
                state={section.stateToSend}
                onClick={clickHandler}
            >
                <PearlIcon iconName={section.iconName} size={IconSizes.SM} />
                <label>
                    <b>{t(`Navigation.HamburgerMenu.${section.displayName}`)}</b>
                </label>
            </Link>
        );
    }

    return (
        <div className="sectionTitle" state={section.stateToSend}>
            <PearlIcon iconName={section.iconName} size={IconSizes.SM} />
            <label>
                <b>{t(`Navigation.HamburgerMenu.${section.displayName}`)}</b>
            </label>
        </div>
    );
};

const InternalLink = (props) => {
    const { link, clickHandler, translator } = props;

    return (
        <Link to={link.src} state={link.stateToSend} onClick={clickHandler}>
            <p className="p2">{translator(`Navigation.HamburgerMenu.${link.displayName}`)}</p>
        </Link>
    );
};

const ExternalLink = (props) => {
    const { link, clickHandler, translator } = props;

    return (
        <NewTabLink link={link.src} onClick={clickHandler} target={'_blank'}>
            <p className="p2">{translator(`Navigation.HamburgerMenu.${link.displayName}`)}</p>
        </NewTabLink>
    );
};

const Sections = (props) => {
    const { menuData, clickHandler } = props;
    const { t } = useTranslation();

    return (
        <ul key="hamburger-menu-inner">
            {menuData.map((section) => (
                <li key={section.displayName}>
                    <div className="hamburgerSection">
                        <SectionTitle section={section} clickHandler={clickHandler} />
                        <div className="sectionLinks">
                            {section?.sublinks?.map((link) => {
                                if (link.external) {
                                    return (
                                        <ExternalLink
                                            link={link}
                                            clickHandler={clickHandler}
                                            translator={t}
                                            section={section}
                                            key={`${section.displayName}-${link.displayName}`}
                                        />
                                    );
                                } else {
                                    return (
                                        <InternalLink
                                            link={link}
                                            clickHandler={clickHandler}
                                            translator={t}
                                            section={section}
                                            key={`${section.displayName}-${link.displayName}`}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
};

const HamburgerMenu = () => {
    const { t } = useTranslation();

    const { authUser } = useAuthUserContext();
    const appName = getAppName();
    let sections;

    if (authUser.isAuthedVendor || authUser.isAuthedEventUser) {
        if (appName === PearlApps.VENDOR) {
            sections = navigation.authedVendorUserHamburgerLinks;
        } else {
            sections = navigation.authedEventUserHamburgerLinks;
        }
    } else {
        sections = navigation.unauthedHamburgerMenuLinks;
    }

    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleClose = () => {
        setDrawerOpen(false);
    };

    return (
        <HamburgerMenuStyled>
            <IconButton
                iconName={IconName.MENU}
                size={IconSizes.LG}
                clickHandler={toggleDrawer(true)}
                id="hamburger-open"
                ariaLabel={t(`Navigation.HamburgerMenu.OpenIconAriaLabel`)}
            ></IconButton>

            <SwipeableDrawer
                anchor={'left'}
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={0}
                sx={{
                    '& .MuiBackdrop-root': {
                        bgcolor: 'transparent',
                    },
                }}
            >
                <HamburgerContentStyled>
                    <nav id="hamburger-navigation-menu" className="navList">
                        <HamburgerHeaderStyled>
                            <div className="content">
                                <img
                                    className="logo"
                                    src={PearlBlack.default}
                                    alt={t('DavidsBridalLogoAlt')}
                                />
                                <div data-testid="hamburger-close-button">
                                    <IconButton
                                        iconName={IconName.CLOSE}
                                        size={IconSizes.MD}
                                        clickHandler={toggleDrawer(false)}
                                        id="hamburger-close"
                                        ariaLabel={t(`Navigation.HamburgerMenu.CloseIconAriaLabel`)}
                                        withBorder={false}
                                    ></IconButton>
                                </div>
                            </div>
                        </HamburgerHeaderStyled>
                        <div className="bodyContent">
                            <HamburgerSectionsStyled>
                                <Sections menuData={sections} clickHandler={handleClose} />
                            </HamburgerSectionsStyled>
                            <HamburgerUserMenu drawerCloser={handleClose} />
                        </div>
                    </nav>
                </HamburgerContentStyled>
            </SwipeableDrawer>
        </HamburgerMenuStyled>
    );
};

export { HamburgerMenu };
