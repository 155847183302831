import styled from 'styled-components';

const shadow = `
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14),
        0px 1px 18px rgba(0, 0, 0, 0.12);
`;

const ChatInputStyled = styled.div`
    ${shadow}
    padding: 16px;
    display: flex;

    &.disabled {
        color: ${(props) => props.theme.colors.disabled.contrast};
        background-color: ${(props) => props.theme.colors.disabled.base};
    }

    .input-message {
        width: 100%;
        margin: 0;
        padding: 0;
        border: none;

        &:focus {
            border: none;
            outline: none;
        }

        &:disabled {
            color: inherit;
            background-color: inherit;
        }
    }

    .button-container {
        display: flex;
        gap: 4px;
        margin-left: 16px;

        .MuiChip-root {
            position: relative;
            top: 2px;
            color: ${(props) => props.theme.colors.foreground.text};
        }

        button {
            border: none;
        }

        div:last-child {
            button {
                background-color: ${(props) => props.theme.colors.background.base};
            }
        }
    }
`;

export { ChatInputStyled };
