export const pearlTxt = `
           ______                 _
          (_____ \\               | |TM
           _____) )___ ____  ____| |
          |  ____/ _  ) _  |/ ___) |
          | |   ( (/ ( ( | | |   | |
          |_|    \\____)_||_|_|   |_|
 _              _____              _     _   _
| |            (____ \\            (_)   | | ( )
| | _  _   _    _   \\ \\ ____ _   _ _  _ | |  / ___
| || \\| | | |  | |   | / _  | | | | |/ || |   /___)
| |_) ) |_| |  | |__/ ( ( | |\\ V /| ( (_| |  |___ |
|____/ \\__  |  |_____/ \\_||_| \\_/ |_|\\____|  (___/
      (____/
`;
