import styled from 'styled-components';

const VendorBusinessDetailsFormStyled = styled.form`
    display: flex;
    height: 100%;

    .agreement {
        margin: 18px 0 0;

        & > label {
            padding-left: 0;
            display: flex;
        }
    }

    .categoryContainer {
        padding-top: 40px;
    }

    .subcategoryContainer {
        padding-top: 40px;
    }

    @media (max-width: 1200px) {
        .categoryContainer > div > div {
            flex-direction: column;
        }
    }

    .radio-label {
        margin-right: 36px;
    }
`;

export { VendorBusinessDetailsFormStyled };
