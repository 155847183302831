import { supportsHEVCAlpha } from 'framework';
const hevc = supportsHEVCAlpha();
export const ProfilePicture = require('./pearl-bg.jpg');
export const BusinessDetails = hevc
    ? require('./onboarding-step-gifs/Step-1-safari.mov')
    : require('./onboarding-step-gifs/Step-1.webm');
export const BusinessAddress = hevc
    ? require('./onboarding-step-gifs/Step-2-safari.mov')
    : require('./onboarding-step-gifs/Step-2.webm');
export const AmenitiesAndCharacteristics = hevc
    ? require('./onboarding-step-gifs/Step-4_5-safari.mov')
    : require('./onboarding-step-gifs/Step-4_5.webm');
export const PriceTier = hevc
    ? require('./onboarding-step-gifs/Step-6-safari.mov')
    : require('./onboarding-step-gifs/Step-6.webm');
export const BusinessSummary = hevc
    ? require('./onboarding-step-gifs/Step-7-safari.mov')
    : require('./onboarding-step-gifs/Step-7.webm');
export const SocialMedia = hevc
    ? require('./onboarding-step-gifs/Step-8-safari.mov')
    : require('./onboarding-step-gifs/Step-8.webm');
export const MediaUpload = hevc
    ? require('./onboarding-step-gifs/Step-9-safari.mov')
    : require('./onboarding-step-gifs/Step-9.webm');
export const Bridesmaids = hevc
    ? require('./onboarding-step-gifs/Bridesmaids.mov')
    : require('./onboarding-step-gifs/Bridesmaids.webm');
export const Calendar = hevc
    ? require('./onboarding-step-gifs/Calendar.mov')
    : require('./onboarding-step-gifs/Calendar.webm');
export const Calculator = hevc
    ? require('./onboarding-step-gifs/Calculator.mov')
    : require('./onboarding-step-gifs/Calculator.webm');
export const Camera = hevc
    ? require('./onboarding-step-gifs/Camera.mov')
    : require('./onboarding-step-gifs/Camera.webm');
export const Envelope = hevc
    ? require('./onboarding-step-gifs/Envelope.mov')
    : require('./onboarding-step-gifs/Envelope.webm');
export const LocationPin = hevc
    ? require('./onboarding-step-gifs/LocationPin.mov')
    : require('./onboarding-step-gifs/LocationPin.webm');
export const PenWriting = hevc
    ? require('./onboarding-step-gifs/PenWriting.mov')
    : require('./onboarding-step-gifs/PenWriting.webm');
export const StoreFront = hevc
    ? require('./onboarding-step-gifs/StoreFront.mov')
    : require('./onboarding-step-gifs/StoreFront.webm');
export const EmptyCaptions = require('./captions/empty-captions.vtt');
export const YouTubeLive = require('./youtube-live-icon-db-black.gif');
export const FeaturedStar = require('./featuredStar.png');
export const NonPremiumAnalytics = require('./nonPremiumAnalytics.jpg');
export const NonPremiumAnalyticsMobile = require('./nonPremiumAnalyticsMobile.jpg');
