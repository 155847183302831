import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthUserContext } from 'contexts/AuthUserContext';

function ContextRoutingPage(props) {
    const { eventUserRoute, vendorUserRoute, unAuthRoute } = props;
    const { authUser } = useAuthUserContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (authUser?.isAuthedVendor) {
            navigate(vendorUserRoute);
        } else if (authUser?.isAuthedEventUser) {
            navigate(eventUserRoute);
        } else {
            navigate(unAuthRoute);
        }
    }, []);

    return <></>;
}

export { ContextRoutingPage };
