import { PearlApiClient, PearlApps } from 'framework';
import { WebstoreSetupStepsMap } from '../../constants/WebstoreSetupSteps';
import { unwrapResponse } from '../../../lib/apis/utils';

export const VendorService = () => {
    const api = PearlApiClient(PearlApps.VENDOR);

    const getVendorList = async () => {
        return await api.get('vendors');
    };

    const getVendorCategoryList = async () => {
        const res = await api.get('vendorcategories');
        if (res.ok) {
            const validCategories = Array.from(WebstoreSetupStepsMap)
                .filter((x) => x[1].length > 0)
                .map((x) => x[0]);

            return res.data.filter((x) => validCategories.includes(x.externalId));
        }
    };

    const getVendorFaqOptions = async () => {
        const res = await api.get('vendorfaqoptions');
        if (res.ok) {
            return res.data;
        }
    };

    const getVendorById = async (id) => {
        const res = await api.get(`vendors/${id}`);
        if (res.ok) {
            return res.data;
        } else {
            return null;
        }
    };

    const getVendorByUsername = async (username) => {
        const res = await api.get(`vendors/username/${username}`);
        if (res.ok) {
            return res.data;
        } else {
            return null;
        }
    };

    const getVendorBySearchString = async (searchString) =>
        await api.get(`vendors/${searchString}`);

    const patchVendor = async (data) => {
        return await api.patch('vendors', data);
    };

    const getWebstoresByVendorId = async (id) => {
        const res = await api.get(`vendors/${id}/webstores`);

        if (res.ok && res.data) {
            return res.data;
        }

        return [];
    };

    const isStripeCustomerById = async (vendorId) => {
        const res = await api.get(`vendors/${vendorId}/isStripeCustomer`);
        if (res?.ok && res?.data?.isStripeCustomer !== null) {
            return res.data.isStripeCustomer;
        }

        // for whatever reason that might not have been caught above, we couldn't
        // determine their status, want to be very explicit about that
        // so we don't take action (like create a new customer / subscription)
        // when they might already have one
        throw new Error('error determining stripe customer status');
    };

    const getStripeData = async (vendorId) => {
        const res = await api.get(`vendors/${vendorId}/stripeData`);
        return unwrapResponse(res);
    };

    const assignSeats = async (vendorId, webstoreIds) => {
        const res = await api.patch(`vendors/${vendorId}/assignSeats`, {
            webstoreIds,
        });
        return unwrapResponse(res);
    };

    const unassignSeats = async (vendorId, webstoreIds) => {
        const res = await api.patch(`vendors/${vendorId}/unassignSeats`, {
            webstoreIds,
        });
        return unwrapResponse(res);
    };

    return {
        getVendorList,
        getVendorById,
        getVendorBySearchString,
        getVendorByUsername,
        getVendorCategoryList,
        getVendorFaqOptions,
        patchVendor,
        getWebstoresByVendorId,
        isStripeCustomerById,
        getStripeData,
        assignSeats,
        unassignSeats,
    };
};
