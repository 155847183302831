import { makeAutoObservable } from 'mobx';

export default class EventUserStore {
    Id = null;
    Email = null;
    Loading = false;
    FirstName = null;
    EventId = null;
    LastName = null;
    Role = null;
    EmailOptIn = false;
    PhoneNumber = null;

    props = {
        addDashboard: null,
        needsCategory: false,
    };

    constructor(options) {
        makeAutoObservable(this);
    }

    setLoading(value) {
        this.Loading = value;
    }

    updateUser(update) {
        Object.entries(update).forEach(([key, value]) => {
            if (this.hasOwnProperty(key)) {
                this[key] = value;
            }
        });
    }

    clear() {
        this.Id = null;
        this.Username = null;
        this.Email = null;
        this.IsAdmin = false;
        this.Verified = false;
        this.EmailOptIn = false;
        this.PhoneNumber = null;
    }

    setFromApi(data) {
        this.EventId = data.eventId;
        this.FirstName = data.firstName;
        this.LastName = data.lastName;
        this.Role = data.role;
        this.Email = data.email;
        this.Id = data.id;
        this.EmailOptIn = false;
        this.PhoneNumber = data.phoneNumber;
    }

    get forApi() {
        const data = {
            users: [
                {
                    id: this.Id,
                    firstName: this.FirstName,
                    lastName: this.LastName,
                    role: this.Role,
                    email: this.Email,
                    emailOptIn: false,
                    phoneNumber: this.PhoneNumber,
                },
            ],
        };
        return data;
    }
}
