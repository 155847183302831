import { APP_ROUTES } from 'shared/constants/navigation';

const RequireWebstorePublished = (context) => {
    if (!context.store.webstore.HasBeenPublished) {
        return APP_ROUTES.vendor.webstore.root;
    }
    return 'next';
};

export default RequireWebstorePublished;
