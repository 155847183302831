import { IconName, PearlIcon } from 'dbi-pearl-ui-kit';
import { useNavigate } from 'react-router-dom';
import SearchButton from '../Search/SearchButton/SearchButton';
import { SearchControlsStyled } from './SearchControls.styled';
import { APP_ROUTES } from 'shared/constants/navigation';
import { usePearlSearch } from '../../contexts/PearlSearchContext';

const SearchControls = (props) => {
    const { openEmptyDrawersOnload, hideBackButton, isVDP } = props;
    const navigate = useNavigate();
    const { clearFilters } = usePearlSearch();

    const handleBackClick = () => {
        if (!isVDP) {
            clearFilters();
            navigate(APP_ROUTES.landing, { pathname: APP_ROUTES.landing, search: '' });
        } else {
            navigate(APP_ROUTES.vendors);
        }
    };

    return (
        <SearchControlsStyled data-testid="search-button">
            {!hideBackButton && (
                <button
                    className={'icon-back'}
                    onClick={handleBackClick}
                    data-testid="back-button"
                    aria-label="back to the homepage"
                >
                    <PearlIcon iconName={IconName.CHEVRON_LEFT} />
                </button>
            )}
            <SearchButton openEmptyDrawersOnload={openEmptyDrawersOnload} />
        </SearchControlsStyled>
    );
};

export { SearchControls };
