// got tired of putting this in random files during debugging
// shouldn't really have any references, but keeping here for
// future convenience
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const ensureAsync = (callback) => {
    if (callback.constructor.name === 'AsyncFunction') {
        return callback();
    }

    return new Promise((resolve, reject) => {
        try {
            const val = callback();
            resolve(val);
        } catch (e) {
            reject(e);
        }
    });
};

export { sleep, ensureAsync };
