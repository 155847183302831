import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const AvailabilityCalendarStyled = styled.div`
    .rdrDays {
        padding: 16px 0px 0px 0px;

        .rdrDayPassive .rdrDayNumber span {
            color: ${(props) => props.theme.colors.foreground.secondary_text};
        }
    }

    .calendarLegendContainer {
        display: flex;
        gap: 16px;
    }

    .spinner {
        position: absolute;
        left: 45%;
        top: 50%;
    }

    .calendarLegendContainer > div {
        display: flex;
        gap: 4px;
        align-items: center;
    }

    .availableGraphic,
    .partialGraphic,
    .unavailableGraphic {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border-color: ${(props) => props.theme.colors.accent.base};
        border-width: 1px;
        border-style: solid;
    }

    .unavailableGraphic {
        background: linear-gradient(
            -45deg,
            ${(props) => props.theme.colors.foreground.border},
            ${(props) => props.theme.colors.foreground.border} 49%,
            ${(props) => props.theme.colors.foreground.secondary_text} 49%,
            ${(props) => props.theme.colors.foreground.secondary_text} 51%,
            ${(props) => props.theme.colors.foreground.border} 51%
        );
    }

    .partialGraphic {
        background: linear-gradient(
            -45deg,
            ${(props) => props.theme.colors.foreground.border},
            ${(props) => props.theme.colors.foreground.border} 49%,
            ${(props) => props.theme.colors.foreground.secondary_text} 49%,
            ${(props) => props.theme.colors.foreground.secondary_text} 51%,
            ${(props) => props.theme.colors.background.container} 51%
        );
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .calendarLegendContainer {
            flex-direction: column;
            gap: 2px;
        }

        .available > .p1,
        .partial > .p1,
        .unavailable > .p1 {
            margin: unset;
        }

        .availableGraphic,
        .partialGraphic,
        .unavailableGraphic {
            width: 20px;
            height: 20px;
        }
    }
`;

export { AvailabilityCalendarStyled };
