import React from 'react';
import { Outlet } from 'react-router-dom';
import { WebstoreSetupStepForms } from 'vendor/constants/WebstoreSetupSteps';
import { VendorAmenitiesServicesForm } from 'vendor/components/WebstoreSetup/VendorAmenitiesServices/VendorAmenitiesServicesForm';
import { VendorServiceCharacteristicsForm } from 'vendor/components/WebstoreSetup/VendorServiceCharacteristics/VendorServiceCharacteristicsForm';
import { VendorBusinessAddressForm } from 'vendor/components/WebstoreSetup/VendorBusinessAddress/VendorBusinessAddressForm';
import { VendorBusinessSummaryForm } from 'vendor/components/WebstoreSetup/VendorBusinessSummary/VendorBusinessSummaryForm';
import { VendorServiceAreaForm } from 'vendor/components/WebstoreSetup/VendorServiceArea/VendorServiceAreaForm';
import { VendorSocialForm } from 'vendor/components/WebstoreSetup/VendorSocial/VendorSocialForm';
import { VendorPriceTierForm } from 'vendor/components/WebstoreSetup/VendorPriceTier/VendorPriceTierForm';
import { WebstoreSetupWizardStyled } from './WebstoreSetupWizard.styled';
import { VendorMediaUploadForm } from 'vendor/components/WebstoreSetup/VendorMediaUpload/VendorMediaUploadForm';
import { VendorCapacityForm } from '../VendorCapacity/VendorCapacityForm';
import { VendorBusinessDetailsForm } from '../VendorBusinessDetails/VendorBusinessDetailsForm';

const WebstoreSetupFormsMap = new Map([
    [WebstoreSetupStepForms.DETAILS, <VendorBusinessDetailsForm />],
    [WebstoreSetupStepForms.ADDRESS, <VendorBusinessAddressForm />],
    [WebstoreSetupStepForms.AMENITIES_SERVICES, <VendorAmenitiesServicesForm />],
    [WebstoreSetupStepForms.CAPACITY, <VendorCapacityForm />],
    [WebstoreSetupStepForms.MEDIA_UPLOAD, <VendorMediaUploadForm />],
    [WebstoreSetupStepForms.PRICE_TIER, <VendorPriceTierForm />],
    [WebstoreSetupStepForms.SERVICE_AREA, <VendorServiceAreaForm />],
    [WebstoreSetupStepForms.SERVICE_CHARACTERISTICS, <VendorServiceCharacteristicsForm />],
    [WebstoreSetupStepForms.SOCIAL_LINKS, <VendorSocialForm />],
    [WebstoreSetupStepForms.SUMMARY, <VendorBusinessSummaryForm />],
]);

function WebstoreSetupWizard() {
    return (
        <WebstoreSetupWizardStyled>
            <Outlet />
        </WebstoreSetupWizardStyled>
    );
}

export { WebstoreSetupWizard, WebstoreSetupFormsMap };
