import styled from 'styled-components';

const WebstoreImageSliderStyled = styled.div`
    position: relative;
    padding-top: 1px;
    background-color: ${(props) => props.theme.colors.background.container};

    // suppress arrow quick-clicks from highlighting images
    user-select: none;
    img {
        width: 100%;
        height: 450px;
        object-fit: fill;
        object-position: 50% 50%;
    }

    .slide {
        cursor: pointer;
    }
`;

export { WebstoreImageSliderStyled };
