import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const WebstoreReviewStyled = styled.div`
    .mobileOnly {
        display: none;
    }

    h4 {
        margin-block: 0;
    }

    .reviewContainer {
        display: grid;
        grid-template-columns: 30% 70%;
        padding: 40px;
        column-gap: 5px;
        word-break: break-word;
        box-sizing: border-box;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);
    }

    .leftSide {
        display: inline-flex;
        column-gap: 15px;
    }

    .reviewProfileImage {
        height: 56px;
        width: 56px;
    }

    .reviewRating {
        display: inline-flex;
        align-items: flex-start;
        column-gap: 8px;

        p {
            margin-block: 0;
        }
    }

    .reviewText {
        color: ${(props) => props.theme.colors.foreground.base};
        margin-top: 24px;
    }

    .reviewTime {
        color: ${(props) => props.theme.colors.foreground.secondary_text};
        float: right;
        padding-top: 2px;
        margin-left: 8px;
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        height: 335px;
        width: 343px;

        .desktopOnly {
            display: none;
        }

        .mobileOnly {
            display: block;
        }

        .reviewContainer {
            grid-template-rows: 75px 180px;
            grid-template-columns: unset;
            padding: 24px;
            border: 1px solid ${(props) => props.theme.colors.foreground.border};
            height: 100%;
        }

        .reviewerInfo {
            float: right;
        }

        .reviewTime {
            float: right;
            padding-top: 2px;
            margin: 0 0 0 8px;
        }

        .reviewText {
            margin-top: 0;
        }
    }
`;

export { WebstoreReviewStyled };
