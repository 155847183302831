import { useStore } from '../../stores/Store';
import { EventService } from '../lib/apis/EventService';

const useEventContext = () => {
    const { event } = useStore();
    const eventService = EventService();

    const patchEvent = async () => {
        const res = await eventService.patchEvent(event.forApi);
        if (res.ok) {
            event.setFromApi(res.data);
        }
        return res;
    };

    return { event, patchEvent };
};

export { useEventContext };
