import React from 'react';
import { PearlIcon, IconName, IconSizes, TruncatedTextDisplay, IconButton } from 'dbi-pearl-ui-kit';
import { UnreadMessageDot } from '../UnreadMessageDot/UnreadMessageDot';
import { format } from 'date-fns';
import { ConversationCardStyled } from './ConversationCard.styled';
import { MessageTypes } from '../MessageTypes';
import { useTranslation } from 'react-i18next';

const getFormattedDate = (ts) => format(new Date(ts), 'MM/dd/yy');

const MessagePreview = ({ msg }) => {
    const { t } = useTranslation();

    const tns = t('Messaging.MessageTypes', {
        returnObjects: true,
    });

    let preview = '';

    if (msg.type === MessageTypes.TEXT) {
        preview = msg.message;
    } else if (msg.type === MessageTypes.IMAGE) {
        preview = `${tns.Image}: ${msg.data.filename}`;
    } else if (msg.type === MessageTypes.ATTACHMENT) {
        preview = `${tns.Attachment}: ${msg.data.filename}`;
    } else if (msg.type === MessageTypes.QUOTE_REQUEST) {
        preview = tns.QuoteRequest;
    }

    return <>{preview}</>;
};

const SingleLineText = ({ children, ...rest }) => {
    return (
        <TruncatedTextDisplay {...rest} lineCount={1}>
            {children}
        </TruncatedTextDisplay>
    );
};

const ConversationCard = (props) => {
    const { data, onClick, className, onActionsClick } = props;

    return (
        <ConversationCardStyled
            className={`${className || ''} ${data.isSelected ? 'selected' : ''}`}
        >
            <button className="conversation-button" onClick={() => onClick(data.id)}>
                <div>{data.isUnread && <UnreadMessageDot />}</div>
                <div className="conversation-avatar">
                    <PearlIcon iconName={IconName.USER} size={IconSizes.XL} />
                </div>
                <div className="conversation-info">
                    <SingleLineText className="conversation-participants">
                        <p className="p3">
                            <b>{data.displayName}</b>
                        </p>
                    </SingleLineText>
                    <SingleLineText className="conversation-context">
                        <p className="p3">{data.context && data.context}</p>
                    </SingleLineText>
                    <SingleLineText className="conversation-latest">
                        <p className="p4">
                            {data.latestMessage && <MessagePreview msg={data.latestMessage} />}
                        </p>
                    </SingleLineText>
                </div>
                {data.latestMessage?.ts && (
                    <div className="conversation-date">
                        <PearlIcon iconName={IconName.CLOCK} size={IconSizes.SMD} />
                        <p className="p4">{getFormattedDate(data.latestMessage.ts)}</p>
                    </div>
                )}
            </button>
            <IconButton
                withBorder={false}
                iconName={IconName.MENU_DOT_VERTICAL}
                size={IconSizes.SMD}
                clickHandler={() => onActionsClick(data)}
                ariaLabel="conversation actions"
            ></IconButton>
        </ConversationCardStyled>
    );
};

export { ConversationCard };
