import { IconName, IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';
import { useState } from 'react';
import { Divider } from 'vendor/components/Utility/Divider/Divider';
import { AccordionStyled } from './Accordion.styled';

const Accordion = ({ accordionName, label, subLabel, children, open }) => {
    const [openAccordions, setOpenAccordions] = useState(() => {
        if (open) {
            return [accordionName];
        } else {
            return [];
        }
    });

    const handleIconChange = (key) => {
        if (openAccordions.includes(key)) {
            setOpenAccordions(openAccordions.filter((val) => val !== key));
        } else {
            setOpenAccordions([...openAccordions, key]);
        }
    };

    return (
        <AccordionStyled>
            <Divider />
            <div>
                <input
                    id={`collapsible-${accordionName}`}
                    data-testid={`collapsible-${accordionName}`}
                    className="accordion-toggle"
                    type="checkbox"
                    defaultChecked={openAccordions.includes(accordionName)}
                    onClick={() => handleIconChange(accordionName)}
                />
                <label htmlFor={`collapsible-${accordionName}`} className="accordion-group-label">
                    <b>{label}</b>
                    {subLabel && <p className="p4 sub-label">{subLabel}</p>}
                    {openAccordions.includes(accordionName) ? (
                        <PearlIcon size={IconSizes.MD} iconName={IconName.MINUS} />
                    ) : (
                        <PearlIcon size={IconSizes.MD} iconName={IconName.PLUS} />
                    )}
                </label>
                <div className="collapsible-content">
                    <div className="content-inner">{children}</div>
                </div>
            </div>
        </AccordionStyled>
    );
};

export { Accordion };
