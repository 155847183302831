export function mergeSchemas(schemas) {
    const [first, ...rest] = schemas;

    const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);

    return merged;
}

export function slugify(value, separator = '-') {
    return value
        ?.toString()
        .normalize('NFD') // split an accented letter in the base letter and the accent
        .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
        .toLowerCase()
        .replace(/[^a-z0-9 -]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
        .trim()
        .replace(/\s+/g, separator);
}
