import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PearlFormDefault from './PearlFormDefault.styled';
import { useUnsavedChangesPrompt } from 'framework';
import PearlResolver from 'vendor/lib/PearlResolver/PearlResolver';
import { useEffect } from 'react';
import { pearlErrorClass } from '../../../framework/components/forms/PearlError';

export const FormType = {
    DEFAULT: 'Default',
    EDITABLE: 'Editable',
};

const UnsavedChangePrompt = (formType) => {
    let { formState } = useFormContext();

    useUnsavedChangesPrompt(formType.formType === FormType.EDITABLE ? formState.isDirty : null);

    return null;
};

export const PearlForm = ({
    children,
    StyledForm,
    onSubmit,
    formName,
    formOptions,
    formType = FormType.DEFAULT,
    validationSchema,
    customValidator,
}) => {
    const myFormOptions = {
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        // disable this (defaults to true), since some of our forms use
        // custom `register`s, which do not work with hook-form's auto-scroller;
        // use our auto-scroller instead
        // having both makes the scrolling a bit jumpy
        // https://react-hook-form.com/api/useform/#shouldFocusError
        shouldFocusError: false,
        ...formOptions,
    };

    myFormOptions.resolver = PearlResolver(validationSchema, customValidator);

    const formMethods = useForm(myFormOptions);

    const {
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
    } = formMethods;

    const submitForm = handleSubmit(async (data) => {
        if (onSubmit) {
            onSubmit(data);
        }
        reset(getValues());
    });

    const myMethods = {
        ...formMethods,
        submitForm,
        formName,
        translator: useTranslation().t,
    };

    if (!StyledForm) {
        // fixes errors/warnings about dynamically created styledComponents
        StyledForm = PearlFormDefault;
    } else if (StyledForm.target !== 'form') {
        throw new Error('StyledForm must be a form element (ex. USE styled.form, NOT styled.div)');
    }

    useEffect(() => {
        const firstError = document.getElementsByClassName(pearlErrorClass)?.[0];
        if (firstError) {
            const elt = firstError.previousSibling || firstError.parentElement;
            elt?.scrollIntoView &&
                elt.scrollIntoView({
                    behavior: 'smooth',
                });
        }
    }, [errors]);

    return (
        <FormProvider {...myMethods}>
            <StyledForm
                style={{ height: '100%', width: '100%' }}
                onSubmit={submitForm}
                id={`styled-${formName}`}
                data-testid={`styled-${formName}`}
            >
                <UnsavedChangePrompt formType={formType} />
                {children}
            </StyledForm>
        </FormProvider>
    );
};

export default PearlForm;

export function PearlFormValue(props) {
    const { t } = useTranslation();
    const { getValues } = useFormContext();
    return (
        <span
            dangerouslySetInnerHTML={{
                __html: t(props.translationKey, {
                    [props.formFieldName]: getValues(props.formFieldName),
                }),
            }}
        ></span>
    );
}
