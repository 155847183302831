import React from 'react';
import FormRowStyled from './FormRow.styled';

const FormRow = (props) => {
    const { columns, className } = props;
    return (
        <FormRowStyled className={className}>
            {columns.map((c, index) => (
                <div key={`column${index}`}>{c}</div>
            ))}
        </FormRowStyled>
    );
};

export { FormRow };
