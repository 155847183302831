import { APP_ROUTES } from 'shared/constants/navigation';

const RequireEventProfileStarted = (context) => {
    if (!context.store.event.CurrentStep) {
        return APP_ROUTES.setup;
    }
    return 'next';
};

export { RequireEventProfileStarted };
