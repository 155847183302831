import React, { useState } from 'react';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { EditSectionLayout } from 'vendor/pages/EditWebstoreSectionPages/EditSectionLayout/EditSectionLayout';
import PearlForm, { FormType } from 'vendor/components/forms/PearlForm';
import { useTranslation } from 'react-i18next';
import { AmenitiesAndServicesSectionEditStyled } from './AmenitiesAndServicesSectionEdit.styled';
import { useOutletContext } from 'react-router-dom';
import { AmenitiesAndServicesSection } from 'vendor/components/Webstore/AmenitiesAndServicesSection/AmenitiesAndServicesSection';
import { ServiceCharacteristicsSection } from 'vendor/components/Webstore/ServiceCharacteristicsSection/ServiceCharacteristicsSection';
import { Accordion } from 'vendor/components/Utility/Accordion/Accordion';
import { useVendorContext } from 'vendor/contexts/VendorContext';
import { WebstoreSectionTypes } from 'framework';

const AmenitiesAndServicesSectionEdit = () => {
    const { webstore } = useWebstoreContext();
    const { getStylesByCategoryId } = useVendorContext();
    const { onSubmit } = useOutletContext();
    const { t } = useTranslation();

    const [patchableAmenitiesData, setPatchableAmenitiesData] = useState(
        webstore.ServiceTypes || []
    );

    const [patchableStylesData, setPatchableStylesData] = useState(webstore.Styles || []);

    const showStyleOptions = getStylesByCategoryId(parseInt(webstore.VendorCategoryId))?.length > 0;

    const handleSubmit = () => {
        onSubmit({
            ServiceTypes: patchableAmenitiesData,
            Styles: patchableStylesData,
        });
    };

    return (
        <PearlForm
            StyledForm={AmenitiesAndServicesSectionEditStyled}
            formName="AmenitiesServicesSectionEdit"
            formType={FormType.EDITABLE}
            onSubmit={handleSubmit}
        >
            <EditSectionLayout sectionName={WebstoreSectionTypes.AMENITIESANDSERVICES}>
                <h2>{t('Forms.AmenitiesServices.StepName')}</h2>
                <AmenitiesAndServicesSection
                    webstore={webstore}
                    onChange={setPatchableAmenitiesData}
                />
                {showStyleOptions && (
                    <>
                        <h2>{t('Forms.VendorCharacteristics.StepName')}</h2>
                        <Accordion
                            accordionName={'styles-accordion'}
                            label={t('Forms.VendorCharacteristics.AccordionName')}
                            subLabel={t('Forms.VendorCharacteristics.AccordionSubLabel')}
                            open={true}
                        >
                            <ServiceCharacteristicsSection
                                webstore={webstore}
                                onChange={setPatchableStylesData}
                            />
                        </Accordion>
                    </>
                )}
            </EditSectionLayout>
        </PearlForm>
    );
};

export { AmenitiesAndServicesSectionEdit };
