import {
    usePearlModal,
    WebstorePackage,
    WebstorePackageSizes,
    WebstorePackages,
    useScreenSize,
} from 'framework';
import { IconName } from 'dbi-pearl-ui-kit';
import { NoInformationProvided } from '../NoInformationProvided/NoInformationProvided';
import { PackageModalStyled } from './PackagesSection.styled';
import { UpgradeSubscription } from '../UpgradeSubscription/UpgradeSubscription';
import { useTranslation } from 'react-i18next';

const PackagesSection = (props) => {
    const { webstore, sectionRef, sectionId, showFlaggedOverlay } = props;
    const screenSize = useScreenSize();
    const { openModal } = usePearlModal();
    const { t } = useTranslation();
    const needsUpgrade = !webstore.ActiveFeatures?.feature_packages;

    const openPackageModal = (id) => {
        const pkg = webstore.Packages.find((p) => p.id === id);

        const modalSize = screenSize.small
            ? { WIDTH: '80vw', HEIGHT: '80vh' }
            : { WIDTH: '620px', HEIGHT: 'auto' };

        openModal(
            <PackageModalStyled>
                <WebstorePackage
                    pkg={pkg}
                    size={WebstorePackageSizes.FULL}
                    showFlaggedOverlay={true}
                />
            </PackageModalStyled>,
            modalSize
        );
    };

    return (
        <>
            {webstore.Packages?.length ? (
                <WebstorePackages
                    webstore={webstore}
                    sectionRef={sectionRef}
                    sectionId={sectionId}
                    onDetailsClick={openPackageModal}
                    iconName={needsUpgrade ? IconName.LOCK : null}
                    banner={!!needsUpgrade && <UpgradeSubscription />}
                    showFlaggedOverlay={showFlaggedOverlay}
                />
            ) : (
                <NoInformationProvided
                    sectionRef={sectionRef}
                    sectionId={sectionId}
                    title={t('WebstoreSections.Packages.Title')}
                    iconName={needsUpgrade ? IconName.LOCK : null}
                    banner={!!needsUpgrade && <UpgradeSubscription />}
                />
            )}
        </>
    );
};

export { PackagesSection };
