import React from 'react';
import { ChatTimestamp } from '../../ChatTimestamp/ChatTimestamp';
import { IconName } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import { QuoteHeader } from '../Layout/QuoteHeader';
import { QuoteSection } from '../Layout/QuoteSection';
import { QuoteBody } from '../Layout/QuoteBody';
import { Separator } from '../../../../utility/Separator';
import { formatEventDate } from '../../../../../utils/DateUtils';
import { QuoteContainer } from '../Layout/QuoteContainer';

const sentenceCase = (t) => t[0].toUpperCase() + t.substring(1);

const QuoteRequest = (props) => {
    const { message } = props;

    const { t } = useTranslation();

    const translated = t('Messaging.Quotes.QuoteRequest', {
        returnObjects: true,
    });

    let formattedDate;

    if (message.data.eventDate) {
        formattedDate = formatEventDate(new Date(message.data.eventDate));
    }

    return (
        <QuoteContainer className={`quote-request request-${message.source}`}>
            <QuoteHeader iconName={IconName.CHECKLIST} text={translated.Title} />
            <QuoteBody>
                <QuoteSection title={translated.MessageToVendor} content={message.data.message} />
                <Separator />
                <QuoteSection title={translated.Name} content={message.data.name} />
                {message.data.eventDate && (
                    <QuoteSection title={translated.EventDate} content={formattedDate} />
                )}
                {message.data.budget && (
                    <QuoteSection
                        title={translated.Budget}
                        content={sentenceCase(message.data.budget)}
                    />
                )}

                {message.data.eventType && (
                    <QuoteSection
                        title={translated.EventType}
                        content={sentenceCase(message.data.eventType)}
                    />
                )}
            </QuoteBody>
            <ChatTimestamp timestamp={message.ts} source={message.source} />
        </QuoteContainer>
    );
};

export { QuoteRequest };
