import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import {
    PearlError,
    usePearlModal,
    loadAutocomplete,
    useScreenSize,
    PearlModalSizes,
} from 'framework';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { IconSizes, PearlIcon, SolidButton, TextInput } from 'dbi-pearl-ui-kit';
import { useFormContext } from 'react-hook-form';
import { GoogleReviewsConnectorStyled } from './GoogleReviewsConnector.styled';
import { GoogleReviewsDisconnector } from '../GoogleReviewsDisconnector/GoogleReviewsDisconnector';

const BUSINESS_NAME_FORM_FIELD = 'GooglePlaceLookup';
const PLACE_ID_HIDDEN_FORM_FIELD = 'GooglePlaceId';
const AUTOCOMPLETE_ANCHOR_EL = 'business-search';

function GoogleReviewsConnector(props) {
    const { queryRef, setQueryRef, queryResult, setQueryResult, connectAccount, onSubmit } = props;

    const { setValue, reset } = useFormContext();
    const { openModal, closeModal } = usePearlModal();
    const screenSize = useScreenSize();
    const { t } = useTranslation();
    const { webstore } = useWebstoreContext();

    const makeConnection = () => {
        if (queryResult && queryResult.place_id) {
            connectAccount();
        }
        reset({ keepDirty: false, keepValues: false, isSubmitted: true });
        setQueryRef(null);
    };

    const disconnectAccount = () => {
        reset({ keepDirty: false, keepValues: false, isSubmitted: true });
        setQueryResult('');
        onSubmit({ [PLACE_ID_HIDDEN_FORM_FIELD]: null });
        closeModal();
        setQueryRef(null);
    };

    const openDisconnectModel = () => {
        const modalSize = screenSize.small
            ? PearlModalSizes.LG
            : { WIDTH: '620px', HEIGHT: 'auto' };

        openModal(<GoogleReviewsDisconnector disconnectAccount={disconnectAccount} />, modalSize);
    };

    useEffect(() => {
        if (queryRef) {
            const autocompleteOptions = {
                searchTypes: ['establishment'],
                setFields: ['place_id'],
                focusRef: `#${AUTOCOMPLETE_ANCHOR_EL}`,
                scriptId: 'google-reviews-autocomplete',
            };
            loadAutocomplete(setQueryResult, queryRef, autocompleteOptions);
        } else {
            const ref = document.querySelector(`#${AUTOCOMPLETE_ANCHOR_EL}`);
            if (ref) {
                setQueryRef(ref);
            }
        }
    }, [queryRef, webstore?.GooglePlaceId]);

    useEffect(() => {
        if (queryResult) {
            setValue(PLACE_ID_HIDDEN_FORM_FIELD, queryResult.place_id);
        }
    }, [queryResult]);

    return (
        <GoogleReviewsConnectorStyled>
            {!webstore.GooglePlaceId && (
                <div className="connectionContainer">
                    <TextInput
                        id={AUTOCOMPLETE_ANCHOR_EL}
                        data-testid="google-search"
                        formFieldName={BUSINESS_NAME_FORM_FIELD}
                        className="searchInput"
                        startIcon="google-colorful"
                        providedLabel={t('WebstoreSections.Reviews.ConnectPlaceholder')}
                    />
                    <PearlError name={BUSINESS_NAME_FORM_FIELD} />
                    <TextInput
                        data-testid="hidden-place-id"
                        formFieldName={PLACE_ID_HIDDEN_FORM_FIELD}
                    />
                    <SolidButton onClick={makeConnection}>
                        {t('WebstoreSections.Reviews.ConnectAccountButton')}
                    </SolidButton>
                </div>
            )}
            {webstore?.GooglePlaceId && (
                <div className="connectionContainer">
                    <div className="connectedWithIcon">
                        <PearlIcon iconName="google-colorful" size={IconSizes.MD} />
                        <label className="connectedBox">
                            <b>{t('WebstoreSections.Reviews.Connected')}</b>
                        </label>
                    </div>
                    <div className="disconnectButton">
                        <SolidButton onClick={openDisconnectModel}>
                            {t('WebstoreSections.Reviews.DisconnectAccountButton')}
                        </SolidButton>
                    </div>
                </div>
            )}
        </GoogleReviewsConnectorStyled>
    );
}

export { GoogleReviewsConnector };
