import { PearlApps } from 'framework';
import { adminRoot, APP_ROUTES, vendorRoot } from 'shared/constants/navigation';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StorageKeys, StorageService } from '../../lib/StorageService';

const appNameKey = StorageKeys.appName;

const useAppName = () => {
    const { pathname } = useLocation();

    const [appNameState, setAppNameState] = useState(getAppName());

    useEffect(() => {
        const currentAppName = getAppName();
        setAppNameState(currentAppName);
    }, [pathname]);

    return { appName: appNameState };
};

// Use this when you're not in a React Component and need to know which app is "active".
const getAppName = (container = window) => {
    const previousAppName = container.localStorage.getItem(appNameKey);
    const pathname = container.location.pathname;

    if (pathname.startsWith(APP_ROUTES.authCallback) && previousAppName) {
        return previousAppName;
    }

    if (pathname.startsWith(adminRoot)) {
        return PearlApps.ADMIN;
    }

    if (pathname.startsWith(vendorRoot)) {
        return PearlApps.VENDOR;
    }

    return PearlApps.EVENT_USER;
};

const setAppName = (appName) => {
    const storageService = StorageService();
    storageService.set(appNameKey, appName);
};

export { useAppName, getAppName, setAppName };
