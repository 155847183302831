export async function ask(title, message) {
    return new Promise((resolve, reject) => {
        // TODO: Implement modal dialog instead of default window.confirm
        // Requirement: (AC#19) https://davidsbridal.atlassian.net/wiki/spaces/DBIPP/pages/3171254289/Media+Upload+-+finally+hit+us+with+your+best+shots.
        const result = window.confirm(message);

        if (result) {
            return resolve();
        }
        return reject();
    });
}
