import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PearlBlack } from 'assets/logos/LogoImages';

const AccountSetupStepGraphicStyled = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    left: 0;

    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) -8.67%,
        #faf4ef 45.52%,
        #f5e9df 100%
    );

    .logo {
        height: auto;
        width: 25%;
    }

    .stepGraphicContainer {
        align-self: center;
        height: 43%;
        width: 100%;
        margin: 12em 2em 12em 2em;
    }

    .stepGraphic {
        height: 100%;
        width: 100%;
    }

    @media (max-width: 815px) {
        overflow: hidden;
        height: 100%;
        margin: unset;
        justify-content: space-around;

        .stepGraphicContainer {
            display: flex;
            justify-content: center;
            align-self: center;
            height: 76%;
            margin: 0;
            align-items: center;
        }

        .stepGraphic {
            margin: 0;
        }
    }
`;

const AccountSetupStepGraphic = (props) => {
    const { image, translatedAltText, captions } = props;
    const { t } = useTranslation();
    const refVideo = useRef(null);

    useEffect(() => {
        if (!refVideo?.current) {
            return;
        }

        //AutoPlay requires 'muted' attribute, but react testing library throws a console warning since 'muted' is trigged by browser before component renders
        //When the DOM <video> element does not have muted, it will throw a browser console error that <video> requires <track>
        //Setting 'muted' through React to autoplay the video w/out throwing testing warnings.
        //Using empty .vtt files for track captions
        //Open Bug Since 2017 - cannot set muted in video element https://github.com/facebook/react/issues/10389
        //React Testing Library Issue Log: https://github.com/testing-library/react-testing-library/issues/470 discussing the error thrown for muted
        refVideo.current.autoplay = true;
        refVideo.current.defaultmuted = true;
        refVideo.current.muted = true;
        refVideo.current.src = image?.default ? image.default : '';
    }, [image]);

    return (
        <AccountSetupStepGraphicStyled>
            <div className="stepGraphicContainer">
                <video
                    className="stepGraphic"
                    ref={refVideo}
                    type="video/webm"
                    alt={
                        translatedAltText
                            ? translatedAltText
                            : t('AccountSetup.DefaultAccountSetupStepGraphicAlt')
                    }
                    data-testid="onboarding-graphic"
                    disableRemotePlayback
                    playsInline
                >
                    {t('AccountSetup.EmbeddedVideoNotSupported')}
                    <track default kind="captions" src={captions} />
                </video>
            </div>
            <img
                className="logo"
                src={PearlBlack.default}
                alt={t('Navigation.GlobalNavigationBar.PearlLogoAlt')}
            />
        </AccountSetupStepGraphicStyled>
    );
};

export { AccountSetupStepGraphic };
