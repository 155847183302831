import apisauce from 'apisauce';

export function GeocodeService() {
    const client = apisauce.create({
        // base URL is read from the "constructor"
        baseURL: 'https://maps.googleapis.com/maps/api/geocode/json',
        // 10 second timeout...
        timeout: 10000,
    });

    const getGeocodeByPlaceId = async (placeId) => {
        const res = await client.get('', {
            place_id: placeId,
            key: process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY,
        });
        if (res.ok) {
            const loc = res.data.results[0].geometry.location;
            return {
                latitude: loc.lat.toString(),
                longitude: loc.lng.toString(),
                addressComponents: res.data.results[0].address_components,
            };
        } else {
            return {};
        }
    };

    const parseGeocodeResult = (res) => {
        if (res.ok && res.data?.results?.length > 0) {
            const loc = res.data.results[0].geometry.location;
            const city = res.data.results[0].address_components.find((c) =>
                c.types.includes('locality')
            )?.short_name;
            const state = res.data.results[0].address_components.find((c) =>
                c.types.includes('administrative_area_level_1')
            )?.short_name;
            return {
                latitude: loc.lat.toString(),
                longitude: loc.lng.toString(),
                place_id: res.data.results[0].place_id,
                displayText: `${city}, ${state}`,
            };
        } else {
            return {};
        }
    };
    const getGeocodeByAddress = async (city, state) => {
        const res = await client.get('', {
            address: `${city},${state}`,
            key: process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY,
        });
        return parseGeocodeResult(res);
    };

    const getReverseGeoCode = async (lat, long) => {
        const res = await client.get('', {
            latlng: `${lat},${long}`,
            key: process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY,
        });
        return parseGeocodeResult(res);
    };

    return {
        getGeocodeByPlaceId,
        getGeocodeByAddress,
        getReverseGeoCode,
    };
}
