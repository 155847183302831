import { PearlBlack } from '../../../assets/logos/LogoImages';
import { YouTubeLive } from 'assets/Defaults';
import { IconName, IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { vendorRoot, APP_ROUTES, EXTERNAL_LINKS, CONTACTS } from 'shared/constants/navigation';
import GlobalFooterStyled from './GlobalFooter.styled';
import { useAuthUserContext } from 'contexts/AuthUserContext';
import { Capacitor } from '@capacitor/core';

function GlobalFooter() {
    const { t } = useTranslation();
    const { authUser } = useAuthUserContext();

    return (
        <GlobalFooterStyled>
            <div className="upper-footer">
                <div className="social">
                    <img className="logo" src={PearlBlack.default} alt="" />
                    {!Capacitor.isNativePlatform() && (
                        <div className="label">
                            <b>{t('Footer.StartPlanning')}</b>
                            <p className="p3">{t('Footer.PlanningText')}</p>
                        </div>
                    )}
                    {!Capacitor.isNativePlatform() && (
                        <div className="app-buttons">
                            <a
                                href={EXTERNAL_LINKS.appleAppStore.link}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    display: 'inline-block',
                                    overflow: 'hidden',
                                    borderRadius: '13px',
                                    width: '112px',
                                }}
                            >
                                <img
                                    src={EXTERNAL_LINKS.appleAppStore.button}
                                    target="_blank"
                                    alt="Download on the App Store"
                                    style={{
                                        borderRadius: '13px',
                                        width: '112px',
                                    }}
                                />
                            </a>
                            <a
                                href={EXTERNAL_LINKS.googlePlayStore.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    alt={t('Footer.GooglePlay')}
                                    src={EXTERNAL_LINKS.googlePlayStore.button}
                                />
                            </a>
                        </div>
                    )}
                    <div className="label icons-label">
                        <b>{t('Footer.Follow')}</b>
                    </div>
                    <div className="icons">
                        <a
                            href={EXTERNAL_LINKS.facebook}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={t('Footer.Facebook')}
                        >
                            <PearlIcon iconName={IconName.FACEBOOK_ROUND}></PearlIcon>
                        </a>
                        <a
                            href={EXTERNAL_LINKS.pinterest}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={t('Footer.Pinterest')}
                        >
                            <PearlIcon iconName={IconName.PINTEREST_ROUND}></PearlIcon>
                        </a>
                        <a
                            href={EXTERNAL_LINKS.instagram}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={t('Footer.Instagram')}
                        >
                            <PearlIcon iconName={IconName.INSTAGRAM}></PearlIcon>
                        </a>
                        <a
                            href={EXTERNAL_LINKS.tikTok}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={t('Footer.TikTok')}
                        >
                            <PearlIcon iconName={IconName.TIK_TOK}></PearlIcon>
                        </a>
                        <a
                            href={EXTERNAL_LINKS.twitter}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={t('Footer.Twitter')}
                        >
                            <PearlIcon iconName={IconName.TWITTER}></PearlIcon>
                        </a>
                        <a
                            href={EXTERNAL_LINKS.youTube}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={t('Footer.YouTube')}
                        >
                            <img src={YouTubeLive.default} alt="youtube icon" height="45" />
                        </a>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="links">
                    <div className="column">
                        <div className="label">
                            <b>{t('Footer.Help')}</b>
                        </div>
                        <Link to={APP_ROUTES.aem.aboutUs.contact} target="_blank" className="p3">
                            {t('Footer.ContactUs')}
                        </Link>
                        <Link to={APP_ROUTES.aem.help.root} target="_blank" className="p3">
                            {authUser.isAuthedVendor ? t('Footer.VendorFaq') : t('Footer.Faq')}
                        </Link>
                        <div className="spaced">
                            <div className="p3 name">
                                <PearlIcon iconName={IconName.MOBILE} size={IconSizes.MS} />
                                &nbsp;
                                <b>{t('Footer.TextUs')}</b>
                            </div>
                            <a
                                className="p3"
                                href={`sms:${CONTACTS.TEXT_NUMBER};?&body=${CONTACTS.TEXT_BODY}`}
                            >
                                {t('Footer.Text', {
                                    textNumber: CONTACTS.TEXT_NUMBER,
                                })}
                            </a>
                        </div>
                        <div className="spaced">
                            <div className="p3 name">
                                <PearlIcon iconName={IconName.PHONE} size={IconSizes.MS} />
                                &nbsp;
                                <b>{t('Footer.CallUs')}</b>
                            </div>
                            <a className="p3" href={`tel:+${CONTACTS.PHONE_NUMBER}`}>
                                {CONTACTS.PHONE_NUMBER}
                            </a>
                        </div>
                    </div>
                    <div className="column">
                        <div className="label">
                            <b>{t('Footer.Information')}</b>
                        </div>
                        <a
                            className="p3"
                            href={APP_ROUTES.vendor.auth.welcome}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('Footer.VendorLogin')}
                        </a>
                        <a className="p3" href={EXTERNAL_LINKS.dbiBlog}>
                            {t('Footer.Blog')}
                        </a>
                        <a
                            className="p3"
                            href={EXTERNAL_LINKS.pressKit}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('Footer.PressKit')}
                        </a>
                        <a
                            className="p3"
                            href={EXTERNAL_LINKS.caResidentsOnly}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('Footer.CaResidents')}
                        </a>
                        <a
                            className="p3"
                            href={EXTERNAL_LINKS.statementOfAccessibility}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('Footer.AccessibilityStatement')}
                        </a>
                    </div>
                    <div className="column">
                        <div className="label">
                            <b>{t('Footer.Company')}</b>
                        </div>
                        <Link
                            to={authUser.isAuthedVendor ? vendorRoot : APP_ROUTES.landing}
                            target="_blank"
                            className="p3"
                        >
                            {t('Footer.Home')}
                        </Link>
                        <Link to={APP_ROUTES.aem.aboutUs.root} target="_blank" className="p3">
                            {t('Footer.AboutUs')}
                        </Link>
                        <Link to={APP_ROUTES.aem.aboutUs.contact} target="_blank" className="p3">
                            {t('Footer.ContactUs')}
                        </Link>
                    </div>
                    <div className="column">
                        <div className="label">
                            <b>{t('Footer.Save')}</b>
                        </div>
                        <a
                            className="p2 name"
                            href={EXTERNAL_LINKS.diamond}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <b>{t('Footer.DiamondLoyalty')}</b>
                        </a>
                        <div className="p3">{t('Footer.LoyaltyText')}</div>
                    </div>
                </div>
            </div>
            <div className="lower-footer">
                <div className="p3">&copy;{t('Footer.Copyright')}</div>
                <div className="links p3">
                    <a
                        className="p3"
                        href={EXTERNAL_LINKS.privacyPolicy}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('Footer.Privacy')}
                    </a>
                    &middot;
                    <a
                        className="p3"
                        href={EXTERNAL_LINKS.accessibility}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('Footer.Accessibility')}
                    </a>
                    &middot;
                    <a
                        className="p3"
                        href={EXTERNAL_LINKS.termsAndConditions}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('Footer.Terms')}
                    </a>
                    &middot;
                    <a
                        className="p3"
                        href={EXTERNAL_LINKS.californiaPrivacyRights}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('Footer.CCPA')}
                    </a>
                    &middot;
                    <a
                        className="p3"
                        href={EXTERNAL_LINKS.doNotSellMyInfoCaResidentsOnly}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('Footer.DoNotSell')}
                    </a>
                    &middot;
                    <a
                        className="p3"
                        href={APP_ROUTES.aem.siteMap}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('Footer.Sitemap')}
                    </a>
                    &middot;
                    <a
                        className="p3"
                        href={EXTERNAL_LINKS.transparencyInSupplyChain}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('Footer.Transparency')}
                    </a>
                </div>
            </div>
        </GlobalFooterStyled>
    );
}

export { GlobalFooter };
