import React from 'react';
import { ImageSlider, BreakpointSizes } from 'dbi-pearl-ui-kit';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ModalImageGalleryStyled = styled.div`
    .modal-image-gallery-wrapper {
        display: flex;
        align-items: center;
        height: 80vh;

        .swiper {
            width: 80vw;
        }

        .swiper-slide {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            row-gap: 10px;
        }

        .slide-caption-container {
            display: flex;
            justify-content: center;
            align-items: center;

            .slide-caption: {
                height: min-content;
            }
        }

        .withCaption {
            height: min-content;
            max-height: 91%;
        }
    }

    .gallery-image-button-wrapper {
        all: unset;
        cursor: pointer;
    }

    .center-gallery-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .modal-image-gallery-wrapper {
            height: 50vh;

            .slide-caption-container {
                .p2 {
                    text-align: center;
                }
                height: auto;
            }
        }
    }

    @media (max-width: 555px) {
        .modal-image-gallery-wrapper {
            height: 65vh;
            img {
                object-fit: contain;
            }
        }
    }
`;

export function ModalImageGallery({ images, initIndex }) {
    return (
        <ModalImageGalleryStyled id="modal-image-gallery" data-testid="modal-image-gallery">
            <div className="modal-image-gallery-wrapper" data-testid="modal-image-gallery">
                <ImageSlider
                    images={images}
                    initialSlide={initIndex}
                    hideCaptions={false}
                    slidesPerView={1}
                    /*providing spaceBetween ensures the edge of the next image isn't in view with current image and displays gap between current image & next when scrolling*/
                    spaceBetween={8}
                />
            </div>
        </ModalImageGalleryStyled>
    );
}

ModalImageGallery.propTypes = {
    images: PropTypes.array,
    initIndex: PropTypes.number,
};
