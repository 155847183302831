import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PearlFormDefault from './PearlFormDefault.styled';
import { useUnsavedChangesPrompt } from '../../contexts/PearlPromptContext';

export const FormType = {
    DEFAULT: 'Default',
    EDITABLE: 'Editable',
};

export const UnsavedChangePrompt = () => {
    let { formState } = useFormContext();

    useUnsavedChangesPrompt(formState.isDirty);

    return null;
};

export const PearlForm = ({
    children,
    StyledForm,
    onSubmit,
    formName,
    formOptions,
    formType = FormType.DEFAULT,
    validationSchema,
    customValidator,
}) => {
    const myFormOptions = {
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        ...formOptions,
    };

    if (validationSchema) {
        myFormOptions.resolver = yupResolver(validationSchema);
    } else {
        myFormOptions.resolver = yupResolver(Yup.object());
    }
    const formMethods = useForm(myFormOptions);

    const { handleSubmit, reset, getValues } = formMethods;
    const submitForm = handleSubmit(async (data) => {
        if (onSubmit) {
            onSubmit(data);
        }
        reset(getValues());
    });

    const myMethods = {
        ...formMethods,
        submitForm,
        formName,
        translator: useTranslation().t,
    };

    if (!StyledForm) {
        // fixes errors/warnings about dynamically created styledComponents
        StyledForm = PearlFormDefault;
    } else if (StyledForm.target !== 'form') {
        throw new Error('StyledForm must be a form element (ex. USE styled.form, NOT styled.div)');
    }

    return (
        <FormProvider {...myMethods}>
            <StyledForm
                style={{ height: '100%', width: '100%' }}
                onSubmit={submitForm}
                id={`styled-${formName}`}
                data-testid={`styled-${formName}`}
            >
                {formType === FormType.EDITABLE && <UnsavedChangePrompt />}
                {children}
            </StyledForm>
        </FormProvider>
    );
};

export default PearlForm;
