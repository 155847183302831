import { observer } from 'mobx-react-lite';
import { MediaEditStyled } from './MediaEditStyled';
import {
    SolidButton,
    Checkbox,
    TextAreaInput,
    LinkButton,
    IconName,
    PearlIcon,
} from 'dbi-pearl-ui-kit';
import React, { useState, useEffect } from 'react';
import { WebstoreService } from '../../../lib/apis/WebstoreService';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useTranslation } from 'react-i18next';
import PearlForm from '../../forms/PearlForm';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { MediaEditValidationSchema } from './MediaEdit.schema';
import { PearlError, ask } from 'framework';

function CheckboxWrapper(props) {
    const { fieldName, label } = props;
    const { setValue, getValues } = useFormContext();

    const updateFieldValue = (isChecked) => {
        setValue(fieldName, isChecked, { shouldDirty: true });
    };

    return (
        <Checkbox
            name={fieldName}
            label={label}
            onChange={updateFieldValue}
            checked={getValues(fieldName)}
        />
    );
}

function ChangesMade(props) {
    const { changesMade } = props;
    const { isDirty } = useFormState();
    const caption = useWatch({ name: 'caption' });
    const makeCover = useWatch({ name: 'makeCover' });

    useEffect(() => {
        changesMade(isDirty);
    }, [changesMade, isDirty, caption, makeCover]);

    return null;
}

const MediaEditBase = (props) => {
    const { image, changesMade, done } = props;
    const { t } = useTranslation();
    const { webstore } = useWebstoreContext();
    const webstoreApi = WebstoreService();
    const [imDone, setImDone] = useState(false);

    useEffect(() => {
        if (imDone) {
            done();
        }
    }, [imDone, done]);

    const save = async (data) => {
        await webstoreApi.updateImage(webstore.Id, image.id, {
            caption: data.caption,
            isCoverPhoto: data.makeCover,
        });
        const updatedWebstore = await webstoreApi.getWebstoreById(webstore.Id, {
            removeInvalidImgSrc: false,
        });
        webstore.setFromApi(updatedWebstore.data);
        await changesMade(false);
        setImDone(true);
    };

    const deleteImage = async () => {
        try {
            await ask(t('ConfirmDeleteTitle'), t('ConfirmDeleteMessage'));
        } catch (e) {
            // User canceled the navigation
            return;
        }
        await webstoreApi.deleteImage(webstore.Id, image.id);
        const updatedWebstore = await webstoreApi.getWebstoreById(webstore.Id, {
            removeInvalidImgSrc: false,
        });
        webstore.setFromApi(updatedWebstore.data);
        setImDone(true);
    };

    const formOptions = {
        defaultValues: {
            makeCover: image.isCoverPhoto || false,
            caption: image.caption || '',
        },
    };

    return (
        <PearlForm
            StyledForm={MediaEditStyled}
            formName="MediaEdit"
            formOptions={formOptions}
            onSubmit={save}
            validationSchema={MediaEditValidationSchema}
        >
            <ChangesMade changesMade={changesMade} />
            <h2>{t('Forms.MediaEdit.Title')}</h2>

            <div className="edit-container" data-testid="media-edit">
                <div className="media-preview-container">
                    {!image.validationResult && !image.adminApproved && (
                        <div className="media-flagged">
                            <div className="flagged-icon">
                                <PearlIcon iconName={IconName.WARNING}></PearlIcon>
                            </div>
                            <h5 className="flagged-header">{t('FlaggedImages.Header')}</h5>
                            <p className="p2 flagged-text">{t('FlaggedImages.Text')}</p>
                            <label className="flagged-contact">
                                <b>{t('FlaggedImages.Contact')}</b>
                            </label>
                        </div>
                    )}
                    <div
                        className="media-preview"
                        style={{ backgroundImage: `url(${image.url})` }}
                    ></div>
                </div>
                <div className="edit-form">
                    <div className="label coverPhotoLabel">
                        <b>{t('Forms.MediaEdit.CoverPhotoSectionHeader')}</b>
                    </div>
                    <CheckboxWrapper
                        label={t('Forms.MediaEdit.MakeCover')}
                        fieldName={'makeCover'}
                    />

                    <div className="label captionLabel">
                        <b>{t('Forms.MediaEdit.CaptionSectionHeader')}</b>
                    </div>
                    <TextAreaInput data-testid="caption" formFieldName="caption" maxLength={100} />
                    <div className="media-edit-error-wrapper">
                        <PearlError name="caption"></PearlError>
                    </div>

                    <SolidButton type="submit" className="saveButton" data-testid="save-button">
                        {t('Forms.MediaEdit.SaveButtonText')}
                    </SolidButton>
                </div>
                <div className="action-buttons">
                    <LinkButton
                        data-testid="delete-button"
                        startIcon={IconName.TRASH}
                        onClick={deleteImage}
                    >
                        {t('Forms.MediaEdit.RemoveButtonText')}
                    </LinkButton>
                </div>
            </div>
        </PearlForm>
    );
};

MediaEditBase.defaultProps = {
    done: () => {},
    changesMade: () => {},
};

const MediaEdit = observer(MediaEditBase);

export { MediaEdit };
