import { APP_ROUTES } from 'shared/constants/navigation';

const RequireEventUserRole = (context) => {
    if (!context.store.isAuthedEventUser) {
        return APP_ROUTES.auth.welcome;
    }
    return 'next';
};

export default RequireEventUserRole;
