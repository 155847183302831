import { EmptyCaptions, Calendar } from '../../../../assets/Defaults';
import { eventDateFormDropdownOptions } from '../../../constants/accountCreationOptions';
import { eventDateFormSchema } from '../../../constants/schema/EventDateFormSchema';
import { useEventContext } from '../../../contexts/EventContext';
import { format } from 'date-fns';
import {
    AccountSetupStepCounter,
    AccountSetupStepFooter,
    AccountSetupStepGraphic,
    AccountSetupStepLayout,
    FormRow,
    PearlError,
    PearlForm,
    useScrollToConditionallyDisplayedElement,
} from 'framework';
import { CalendarDisplay, Checkbox, Dropdown } from 'dbi-pearl-ui-kit';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEventUserSetupWizard } from '../context/EventUserSetupContext';
import EventDateFormStyled from './EventDateForm.styled';
import calendarStyles from 'react-date-range/dist/styles.css';
import calendarThemeStyles from 'react-date-range/dist/theme/default.css';
const apiDateFormat = 'yyyy-MM-dd';
const eventDateFieldName = 'EventDate';
const eventFlexibleFieldName = 'IsFlexible';
const eventTimelineFieldName = 'EventTimeline';

function CheckboxWrapper(props) {
    const { fieldName, label, toggle, setSelectedEventDate } = props;
    const { setValue, getValues } = useFormContext();

    const updateFieldValue = (isChecked) => {
        setValue(fieldName, isChecked, { shouldDirty: true });
        toggle(isChecked);
        setValue(eventDateFieldName, null);
        setSelectedEventDate(null);
    };

    return (
        <Checkbox
            name={fieldName}
            label={label}
            onChange={updateFieldValue}
            checked={getValues(fieldName)}
        />
    );
}

function CalendarWrapper(props) {
    const { fieldName, callback, selectedDate, setIsFlexible } = props;
    const { setValue } = useFormContext();
    const { t } = useTranslation();

    const updateFieldValue = (date) => {
        callback(date);
        setValue(fieldName, format(date, apiDateFormat), {
            shouldDirty: true,
        });
        setIsFlexible(false);
        setValue(eventFlexibleFieldName, false);
        setValue(eventTimelineFieldName, false);
    };

    return (
        <CalendarDisplay
            months={1}
            formFieldName={fieldName}
            editable={true}
            showDateSelectors={true}
            selectedDate={selectedDate}
            onDateSelected={(date) => updateFieldValue(date)}
            ariaLabels={{
                monthPicker: t('Calendar.MonthLabel'),
                yearPicker: t('Calendar.YearLabel'),
                prevButton: t('WebstoreSections.AvailabilityCalendar.AriaLabels.PreviousButton'),
                nextButton: t('WebstoreSections.AvailabilityCalendar.AriaLabels.NextButton'),
            }}
        />
    );
}

const Footer = () => {
    const { progress, onBack } = useEventUserSetupWizard();

    return <AccountSetupStepFooter progress={progress} handleOnBack={onBack} />;
};

export default function EventDateForm() {
    const { onSubmit, currentStepCount, totalSteps } = useEventUserSetupWizard();

    const { t } = useTranslation();
    const { event } = useEventContext();

    // EventTimeline is either a string: 'false' ...
    // OR the ID of a timeline option '1','2','3' ...
    // OR null (not a string, just null)
    const [isFlexible, setIsFlexible] = useState(
        event?.EventDate?.EventTimeline && event?.EventDate?.EventTimeline !== 'false'
    );

    const [selectedEventDate, setSelectedEventDate] = useState(
        event.EventDate?.EventDate ? new Date(`${event.EventDate.EventDate} 00:00:00`) : null
    );

    const videoDetails = {
        image: Calendar,
        altText: 'Forms.EventDate.GifAltText',
        captions: EmptyCaptions,
    };

    const formOptions = {
        mode: 'onSubmit',
        defaultValues: {
            [eventDateFieldName]: event.EventDate?.EventDate ? event.EventDate.EventDate : null,
            [eventFlexibleFieldName]: isFlexible,
            [eventTimelineFieldName]: isFlexible ? event.EventDate.EventTimeline : null,
        },
    };

    const timelineQuestionRef = useScrollToConditionallyDisplayedElement();

    const handleSubmit = (formData) => {
        const payload = { EventDate: formData };
        onSubmit(payload);
    };

    return (
        <PearlForm
            StyledForm={EventDateFormStyled}
            formName={'EventDate'}
            formId={'EventDate'}
            formOptions={formOptions}
            validationSchema={eventDateFormSchema}
            onSubmit={(data) => handleSubmit(data)}
        >
            <AccountSetupStepLayout
                header={
                    <div>
                        <AccountSetupStepCounter
                            stepCountText={t('Forms.Labels.Steps', {
                                completedSteps: currentStepCount,
                                totalSteps: totalSteps,
                            })}
                            stepNameText={t('Forms.EventDate.StepName')}
                        />
                        <h1 className="eventUserTitles">{t('Forms.EventDate.Title')}</h1>
                    </div>
                }
                formComponent={
                    <FormRow
                        columns={[
                            <div key={'EventDateForm'} className="formComponent">
                                <div className="mainContent">
                                    <style>
                                        {calendarStyles.default}
                                        {calendarThemeStyles.default}
                                    </style>
                                    <PearlError name={eventDateFieldName} />
                                    <div
                                        data-testid="event-date-calendar-wrapper"
                                        className="event-date-calendar-wrapper"
                                    >
                                        <CalendarWrapper
                                            fieldName={eventDateFieldName}
                                            callback={setSelectedEventDate}
                                            selectedDate={selectedEventDate}
                                            setIsFlexible={setIsFlexible}
                                        />
                                    </div>
                                    <div className="flexibleCheck">
                                        <CheckboxWrapper
                                            label={t('Forms.EventDate.FlexibleCheck')}
                                            fieldName={eventFlexibleFieldName}
                                            toggle={setIsFlexible}
                                            setSelectedEventDate={setSelectedEventDate}
                                        />
                                        <PearlError name={eventFlexibleFieldName} />
                                    </div>
                                    {isFlexible && (
                                        <div>
                                            <p ref={timelineQuestionRef}>
                                                {t('Forms.EventDate.EventQuestion')}
                                            </p>

                                            <Dropdown
                                                formFieldName={eventTimelineFieldName}
                                                providedLabel={t(
                                                    'Forms.EventDate.DropdownPlaceholder'
                                                )}
                                            >
                                                {eventDateFormDropdownOptions.options.map(
                                                    (option) => {
                                                        return (
                                                            <option
                                                                key={option.value}
                                                                value={option.value}
                                                                className="dateOption"
                                                                data-testid={`date-option-${option.value}`}
                                                            >
                                                                {t(option.label)}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </Dropdown>
                                            <PearlError name={eventTimelineFieldName} />
                                        </div>
                                    )}
                                </div>
                            </div>,
                        ]}
                    ></FormRow>
                }
                image={<AccountSetupStepGraphic {...videoDetails} />}
                footer={<Footer />}
            />
        </PearlForm>
    );
}
