import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountSetupStepLayout, AccountSetupStepGraphic, AccountSetupStepFooter } from 'framework';
import { AmenitiesAndCharacteristics, EmptyCaptions } from 'assets/Defaults';
import PearlForm from 'vendor/components/forms/PearlForm';
import { VendorAmenitiesServicesStyled } from './VendorAmenitiesServicesForm.styled';
import { AmenitiesAndServicesSection } from 'vendor/components/Webstore/AmenitiesAndServicesSection/AmenitiesAndServicesSection';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useWebstoreSetupWizard } from 'vendor/components/WebstoreSetup/WebstoreSetupContext';
import {
    useTealium,
    buildWebstoreSetupStepPageData,
    buildWebstoreSetupStepNextClickData,
} from '../../../../Tealium';
import { WebstoreSetupHeader } from '../WebstoreSetupHeader';

const formName = 'AmenitiesServices';

const Footer = (props) => {
    const { isSkippable, handleSubmit } = props;

    const { onBack, progress } = useWebstoreSetupWizard();

    return (
        <AccountSetupStepFooter
            handleOnBack={() => onBack()}
            handleOnSkip={isSkippable ? handleSubmit : null}
            progress={progress}
        />
    );
};

const VendorAmenitiesServicesForm = () => {
    const { link, view } = useTealium();
    const { onSubmit, onReturnToBusinessLocations, currentStepCount, totalSteps } =
        useWebstoreSetupWizard();
    const { webstore } = useWebstoreContext();
    const { t } = useTranslation();

    const [patchableData, setPatchableData] = useState(webstore.ServiceTypes || []);

    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );

        view(tealiumData);
    }, []);

    const selectionsCount = patchableData.reduce((p, c) => p + c.services.length, 0);

    const isSkippable = selectionsCount === 0;

    const handleSubmit = (data) => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepNextClickData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps,
            data,
            patchableData
        );

        link(tealiumData);

        onSubmit({ ServiceTypes: patchableData });
    };

    return (
        <PearlForm
            StyledForm={VendorAmenitiesServicesStyled}
            formName={formName}
            onSubmit={handleSubmit}
        >
            <AccountSetupStepLayout
                header={
                    <WebstoreSetupHeader
                        onReturnToBusinessLocations={onReturnToBusinessLocations}
                        stepName={formName}
                        currentStepCount={currentStepCount}
                        totalSteps={totalSteps}
                    />
                }
                formComponent={
                    <AmenitiesAndServicesSection
                        webstore={webstore}
                        enableExpandedView={true}
                        onChange={setPatchableData}
                    />
                }
                image={
                    <AccountSetupStepGraphic
                        translatedAltText={t(`Forms.${formName}.GifAltText`)}
                        image={AmenitiesAndCharacteristics}
                        captions={EmptyCaptions}
                    />
                }
                footer={<Footer isSkippable={isSkippable} handleSubmit={handleSubmit} />}
            />
        </PearlForm>
    );
};

export { VendorAmenitiesServicesForm };
