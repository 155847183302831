import React from 'react';
import PropTypes from 'prop-types';
import { ImageSlider } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import { WebstoreImageSliderStyled } from './WebstoreImageSlider.styled';
import { usePearlModal } from '../../../contexts/PearlModalContext';
import { ModalImageGallery } from '../ModalImageGallery/ModalImageGallery';

const WebstoreImageSlider = (props) => {
    const { webstore, onButtonClick, objectFitValue } = props;
    const { t } = useTranslation();
    const { openModal } = usePearlModal();

    const unFlaggedImages = webstore.Media?.images?.filter(
        (x) => x.validationResult || x.adminApproved
    );

    const onImageClick = (id) => {
        openModal(<ModalImageGallery images={unFlaggedImages} initIndex={id} />);
    };

    return (
        <WebstoreImageSliderStyled>
            <div className="carousel">
                {unFlaggedImages?.length > 0 && (
                    <ImageSlider
                        images={unFlaggedImages}
                        imageVariant="gallery"
                        buttonLabel={t('WebstoreSections.Media.SeeAllMediaButtonLabel')}
                        onButtonClick={onButtonClick}
                        objectFitValue={objectFitValue}
                        onImageClick={onImageClick}
                    />
                )}
            </div>
        </WebstoreImageSliderStyled>
    );
};

WebstoreImageSlider.propTypes = {
    webstore: PropTypes.object,
    onButtonClick: PropTypes.func,
    objectFitValue: PropTypes.string,
};

WebstoreImageSlider.defaultProps = {
    objectFitValue: 'cover',
};

export { WebstoreImageSlider };
