import { navigation as frameworkNavigation } from 'framework';

const vendorRoot = '/vendor';
const adminRoot = '/admin';

const APP_ROUTES = {
    authCallback: '/authcallback',
    dashboard: '/dashboard',
    inbox: '/inbox',
    landing: '/',
    home: '/home',
    setup: '/setup',
    vendorsWithParams: '/vendors/:citystate/:category',
    vendors: '/vendors',
    nationalTravelVendorsWithParams: '/national-vendors/:category',
    nationalTravelVendors: '/national-vendors',
    vendorCategoriesListingPage: '/vendor-categories',
    webstoreUrl: '/:webstoreUrl',
    logout: '/logout',
    planningToolkit: '/planning',
    inboxUnauthed: '/inbox/login',
    dashboardUnauthed: '/dashboard/login',
    account: {
        settings: '/account-settings',
        information: '/account-information',
        eventInformation: '/event-information',
        favorites: '/favorites',
    },
    claimBusiness: {
        helpfulInformation: '/CYB-helpful-information',
        sendEmail: '/verify-identity/send',
        verifyEmail: '/verify-identity/code',
        claimForm: '/claim-business-form',
        requestReceived: '/request-received',
    },
    // some of these no longer in use, but want them to still point
    // to the auth redirect page for historical purposes
    auth: {
        signup: '/signup',
        welcome: '/welcome',
        login: '/login',
    },
    vendor: {
        root: `${vendorRoot}`,
        dashboard: `${vendorRoot}/dashboard`,
        ensureVendor: `${vendorRoot}/ensureVendor`,
        inbox: `${vendorRoot}/inbox`,
        inboxUnauthed: `${vendorRoot}/inbox/login`,
        helpCenter: `${vendorRoot}/help`,
        prices: `${vendorRoot}/prices`,
        termsOfService: `${vendorRoot}/terms-of-service`,
        vendorAgreement: `${vendorRoot}/vendor-agreement`,
        vendorAnalytics: `${vendorRoot}/analytics`,
        account: {
            billingHistory: `${vendorRoot}/billing-history`,
            contracts: `${vendorRoot}/account-contracts`,
            information: `${vendorRoot}/account-information`,
            settings: `${vendorRoot}/account-settings`,
            subscriptions: `${vendorRoot}/account-subscriptions`,
            webstoreManagement: `${vendorRoot}/webstores`,
        },
        webstore: {
            root: `${vendorRoot}/webstore`,
            editAvailability: `${vendorRoot}/webstore/editavailability`,
            editPartners: `${vendorRoot}/webstore/editpartners`,
            setup: `${vendorRoot}/webstoresetup`,
            error: `${vendorRoot}/error`,
        },
        // some of these no longer in use, but want them to still point
        // to the auth redirect page for historical purposes
        auth: {
            signup: `${vendorRoot}/signup`,
            welcome: `${vendorRoot}/welcome`,
            login: `${vendorRoot}/login`,
        },
    },
    admin: {
        root: adminRoot,
        adminLoginPage: `${adminRoot}/adminLogin`,
        authError: `${adminRoot}/authError`,
        webstore: {
            root: `${adminRoot}/webstores`,
            byId: `${adminRoot}/webstores/:webstoreId`,
        },
    },
    aem: {
        home: '/home',
        routes: '/routes',
        join: {
            root: '/join',
            pricing: '/join/pricing',
        },
        aboutUs: {
            root: '/about-us',
            contact: '/about-us/contact',
            howItWorks: '/about-us/how-it-works',
        },
        planning: '/planning',
        visionBoard: '/planning/wedding-vision-board',
        checklist: '/planning/wedding-checklist',
        help: {
            root: '/pearl-help',
            planningToolsCelebrant: '/pearl-help/planning-tools-celebrant',
            membershipVendor: '/pearl-help/membership-vendor',
            accountCelebrant: '/pearl-help/account-celebrant',
            generalCelebrant: '/pearl-help/general-celebrant',
            generalVendor: '/pearl-help/general-vendor',
            dashboardCelebrant: '/pearl-help/dashboard-celebrant',
            accountVendor: '/pearl-help/account-vendor',
            webstoreVendor: '/pearl-help/webstore-vendor',
            localVendorsCelebrant: '/pearl-help/local-vendors-celebrant',
        },
        privacyAndCookies: '',
        termsAndConditions: '',
        siteMap: '/sitemap',
    },
};

const EXTERNAL_LINKS = {
    davidsBridal: 'https://davidsbridal.com/',
    diamond: 'https://davidsbridal.com/loyalty',
    weddingWebsite: 'https://www.blueprintregistry.com/wedding-websites?ref=pearl',
    registry: 'https://www.blueprintregistry.com/wedding-registry?ref=pearl',
    weddingDressQuiz: 'https://www.davidsbridal.com/planning/wedding-dress-quiz',
    bridesmaidDressQuiz: 'https://www.davidsbridal.com/planning/bridesmaid-dress-quiz',
    appleAppStore: {
        link: 'https://apps.apple.com/us/app/planning-by-davids-bridal/id1567104483',
        button: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1626134400&h=77bc190509853802f6b1fa53f16bec51',
    },
    googlePlayStore: {
        link: 'https://play.google.com/store/apps/details?id=com.DavidsBridal.MobilePlanning&hl=en_US&gl=US',
        button: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
    },
    facebook: 'https://www.facebook.com/pearlbydavids',
    facebookVendorResources: 'https://www.facebook.com/groups/685468934807733/',
    pinterest: 'https://www.pinterest.com/pearlbydavids/',
    instagram: 'https://www.instagram.com/pearlbydavids/',
    tikTok: 'https://www.tiktok.com/@pearlbydavids',
    twitter: 'https://twitter.com/pearlbydavids',
    twitterHashtag: 'https://twitter.com/hashtag/pearlbydavids',
    youTube: 'https://www.youtube.com/channel/UCpFX9mTOg4P5XqDt3uHgP6A',
    dbiBlog: 'https://pearl.davidsbridal.com/inspiration/',
    pressKit: 'https://pearl.davidsbridal.com/press-kit',
    caResidentsOnly: 'https://www.davidsbridal.com/Content_Legal_DoNotSellMyInfo',
    statementOfAccessibility: 'https://www.davidsbridal.com/Content_Legal_StatementofAccessibility',
    privacyPolicy: 'https://davidsbridal.com/legal/privacy-policy',
    accessibility: 'https://www.davidsbridal.com/legal/accessibility',
    termsAndConditions: 'https://davidsbridal.com/legal/terms-conditions',
    californiaPrivacyRights: 'https://www.davidsbridal.com/legal/california-privacy-policy',
    doNotSellMyInfoCaResidentsOnly: 'https://www.davidsbridal.com/Content_Legal_DoNotSellMyInfo',
    dbiAccountDetails: 'https://www.davidsbridal.com/account/details',
    transparencyInSupplyChain: 'https://www.davidsbridal.com/legal/ethical-sourcing-policy',
    planningFineJewelry: 'https://www.davidsbridal.com/planning/fine-jewelry',
    planningFineTuxedos: 'https://www.davidsbridal.com/planning/tuxedos',
    makeupKit: 'https://mally.com/?al_pg_id=4b853458-efca-4e47-ba8b-5d9e02e23bdb',
    sizzleReel: 'https://vimeo.com/788666946/5ac604d7d1',
    cybSizzleReel: 'https://vimeo.com/826925609/0d2878ab8d',
};

const CONTACTS = {
    TEXT_NUMBER: '38201',
    TEXT_BODY: 'PEARL',
    PHONE_NUMBER: '1-888-369-8514',
    VENDOR_EMAIL: 'pearlvendorsupport@davidsbridal.com',
};

const navigation = {
    eventUserMenu: [
        {
            displayName: 'Navigation.UserMenu.AccountSettings.displayName',
            src: APP_ROUTES.account.information,
            iconName: 'gear',
            ariaLabel: 'Navigation.UserMenu.AccountSettings.ariaLabel',
            isTranslated: false,
        },

        {
            displayName: 'Navigation.UserMenu.HelpCenter.displayName',
            src: APP_ROUTES.aem.help.generalCelebrant,
            iconName: 'help',
            ariaLabel: 'Navigation.UserMenu.HelpCenter.ariaLabel',
            isTranslated: false,
        },
        {
            displayName: 'Navigation.UserMenu.LogOut.displayName',
            src: 'logout',
            iconName: 'logout',
            ariaLabel: 'Navigation.UserMenu.LogOut.ariaLabel',
            isTranslated: false,
        },
    ],
    vendorUserMenu: [
        {
            displayName: 'Navigation.UserMenu.AccountSettings.displayName',
            src: APP_ROUTES.vendor.account.information,
            iconName: 'gear',
            ariaLabel: 'Navigation.UserMenu.AccountSettings.ariaLabel',
            isTranslated: false,
        },
        {
            displayName: 'Navigation.UserMenu.CustomerView.displayName',
            iconName: 'chevron-right',
            ariaLabel: 'Navigation.UserMenu.CustomerView.ariaLabel',
            isTranslated: false,
            flag: 'VendorCustomerView',
        },
        {
            displayName: 'Navigation.UserMenu.HelpCenter.displayName',
            src: APP_ROUTES.aem.help.generalVendor,
            iconName: 'help',
            ariaLabel: 'Navigation.UserMenu.HelpCenter.ariaLabel',
            isTranslated: false,
        },
        {
            displayName: 'Navigation.UserMenu.LogOut.displayName',
            src: 'logout',
            iconName: 'logout',
            ariaLabel: 'Navigation.UserMenu.LogOut.ariaLabel',
            isTranslated: false,
        },
    ],
    unauthedGlobalBottomBar: [
        {
            displayName: 'Navigation.GlobalNavigationBar.Vendors',
            src: APP_ROUTES.vendors,
        },
        {
            displayName: 'Navigation.GlobalNavigationBar.Planning',
            src: APP_ROUTES.aem.planning,
        },
        {
            displayName: 'Navigation.GlobalNavigationBar.WeddingWebsite',
            src: EXTERNAL_LINKS.weddingWebsite,
            external: true,
        },
        {
            displayName: 'Navigation.GlobalNavigationBar.Registry',
            src: EXTERNAL_LINKS.registry,
            external: true,
        },
    ],
    vendorUserGlobalBottomBar: [
        {
            displayName: 'Navigation.GlobalNavigationBar.Dashboard',
            src: APP_ROUTES.vendor.dashboard,
        },
        {
            displayName: 'Navigation.GlobalNavigationBar.Webstore',
            src: APP_ROUTES.vendor.webstore.root,
        },
        {
            displayName: 'Navigation.GlobalNavigationBar.Calendar',
            src: APP_ROUTES.vendor.webstore.editAvailability,
        },
    ],
    eventUserGlobalBottomBar: [
        {
            displayName: 'Navigation.GlobalNavigationBar.Vendors',
            src: APP_ROUTES.vendors,
        },
        {
            displayName: 'Navigation.GlobalNavigationBar.Planning',
            src: APP_ROUTES.aem.planning,
        },
        {
            displayName: 'Navigation.GlobalNavigationBar.Dashboard',
            src: APP_ROUTES.dashboard,
        },
    ],
    unauthedHamburgerMenuLinks: [
        {
            displayName: 'VendorsDisplayName',
            src: APP_ROUTES.vendors,
            iconName: 'store',
        },
        {
            displayName: 'PlanningToolsDisplayName',
            src: APP_ROUTES.aem.planning,
            iconName: 'planning',
            sublinks: [
                {
                    displayName: 'ChecklistDisplayName',
                    src: APP_ROUTES.aem.checklist,
                },
                {
                    displayName: 'RegistryDisplayName',
                    src: EXTERNAL_LINKS.registry,
                    external: true,
                },
                {
                    displayName: 'WeddingWebsiteDisplayName',
                    src: EXTERNAL_LINKS.weddingWebsite,
                    external: true,
                },
                {
                    displayName: 'BlogDisplayName',
                    src: EXTERNAL_LINKS.dbiBlog,
                    external: true,
                },
                {
                    displayName: 'VisionBoardDisplayName',
                    src: APP_ROUTES.aem.visionBoard,
                },
            ],
        },
        {
            displayName: 'BecomeVendorDisplayName',
            src: APP_ROUTES.aem.join.root,
            iconName: 'champagne-glasses',
            sublinks: [
                {
                    displayName: 'PricingDisplayName',
                    src: APP_ROUTES.aem.join.pricing,
                },
                {
                    displayName: 'HowItWorksDisplayName',
                    src: APP_ROUTES.aem.aboutUs.howItWorks,
                },
            ],
        },
        {
            displayName: 'HelpDisplayName',
            src: APP_ROUTES.aem.help.root,
            iconName: 'help',
            sublinks: [
                {
                    displayName: 'ContactUsDisplayName',
                    src: APP_ROUTES.aem.aboutUs.contact,
                },
            ],
        },
    ],
    authedEventUserHamburgerLinks: [
        {
            displayName: 'VendorsDisplayName',
            src: APP_ROUTES.vendors,
            iconName: 'store',
        },
        {
            displayName: 'PlanningToolsDisplayName',
            src: APP_ROUTES.aem.planning,
            iconName: 'planning',
            sublinks: [
                {
                    displayName: 'ChecklistDisplayName',
                    src: APP_ROUTES.aem.checklist,
                },
                {
                    displayName: 'RegistryDisplayName',
                    src: EXTERNAL_LINKS.registry,
                    external: true,
                },
                {
                    displayName: 'WeddingWebsiteDisplayName',
                    src: EXTERNAL_LINKS.weddingWebsite,
                    external: true,
                },
                {
                    displayName: 'BlogDisplayName',
                    src: EXTERNAL_LINKS.dbiBlog,
                    external: true,
                },
                {
                    displayName: 'VisionBoardDisplayName',
                    src: APP_ROUTES.aem.visionBoard,
                },
            ],
        },
        {
            displayName: 'BecomeVendorDisplayName',
            src: APP_ROUTES.aem.join.root,
            iconName: 'champagne-glasses',
            sublinks: [
                {
                    displayName: 'PricingDisplayName',
                    src: APP_ROUTES.aem.join.pricing,
                },
                {
                    displayName: 'HowItWorksDisplayName',
                    src: APP_ROUTES.aem.aboutUs.howItWorks,
                },
            ],
        },
        {
            displayName: 'HelpDisplayName',
            src: APP_ROUTES.aem.help.root,
            iconName: 'help',
            sublinks: [
                {
                    displayName: 'ContactUsDisplayName',
                    src: APP_ROUTES.aem.aboutUs.contact,
                },
            ],
        },
    ],
    authedVendorUserHamburgerLinks: [
        {
            displayName: 'DashboardDisplayName',
            src: APP_ROUTES.vendor.dashboard,
            iconName: 'dashboard-outline',
        },
        {
            displayName: 'WebstoreDisplayName',
            src: APP_ROUTES.vendor.webstore.root,
            iconName: 'store',
        },
        {
            displayName: 'HelpDisplayName',
            iconName: 'help',
            sublinks: [
                {
                    displayName: 'ContactUsDisplayName',
                    src: APP_ROUTES.aem.aboutUs.contact,
                },
            ],
        },
    ],
    ...frameworkNavigation,
};

const nonFooterRoutes = [APP_ROUTES.inbox, APP_ROUTES.vendor.inbox];

export { navigation, EXTERNAL_LINKS, APP_ROUTES, CONTACTS, vendorRoot, adminRoot, nonFooterRoutes };
