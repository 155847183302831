import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { usePearlSearch } from '../../event-user/contexts/PearlSearchContext';

const usePageTitle = () => {
    const { t } = useTranslation();
    const loc = useLocation();
    const { category, location } = usePearlSearch();
    const [pageTitle, setPageTitle] = useState();
    const siteTitle = t('Routes./');

    // returns the translation if available, else empty string
    const tryTranslateRoute = () => {
        const key = `Routes.${loc.pathname}`;
        const translated = t(key);
        return translated === key ? '' : translated;
    };

    const getPageTitle = () => {
        if (loc.pathname.startsWith('/vendors') && category?.namePlural && location?.displayText) {
            return `${category?.namePlural} in ${location?.displayText}`;
        } else if (loc.pathname.startsWith('/national-vendors') && category?.namePlural) {
            return category?.namePlural;
        } else {
            return tryTranslateRoute();
        }
    };

    const updatePageTitle = (newTitle) => {
        newTitle = newTitle || getPageTitle();
        setPageTitle(newTitle);
    };

    useEffect(() => {
        // always have site title, sometimes prefixed by 'thing - ', ex:
        // florists in carbondale - pearl by david's
        // some store name - pearl by david's
        // pearl by david's (if no prefix available)
        document.title =
            !pageTitle || pageTitle === siteTitle ? siteTitle : `${pageTitle} - ${siteTitle}`;
    }, [pageTitle]);

    return {
        updatePageTitle,
    };
};

// 'Global' page title updater, need to render something to
// fire the effect & hook automatically on nav
const PageTitle = () => {
    const loc = useLocation();
    const { category, location } = usePearlSearch();
    const { updatePageTitle } = usePageTitle();

    useEffect(() => {
        updatePageTitle();
        // this *is* technically a race with page-specific titles, but this
        // should run/complete first (because path-based) and the
        // page-specific will work (because not set til render / called after this)
        // if usePageTitle is expanded or this stops being the case,
        // write some override logic in the usePageTitle hook
    }, [category?.id, location?.displayText, loc?.pathname]);

    return <template />;
};

export { PageTitle, usePageTitle };
