import styled from 'styled-components';

const VendorMediaUploadFormFooterStyledStyled = styled.footer`
    position: sticky;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background: ${(props) => props.theme.colors.background.base};

    .navigationButtonWrapper {
        display: flex;
        justify-content: end;
        margin-bottom: 12px;
        margin-right: 48px;

        button:first-child {
            margin-right: 24px;
        }
    }

    .navigationProgress {
        width: ${(props) => props.progress * 100}%;
        background: ${(props) =>
            `linear-gradient(90deg, ${props.theme.colors.primary.base} 0%, #ff5680 100%)`};
        height: 5px;
    }
    .navigationProgressBar {
        margin-bottom: 12px;
        background: ${(props) => props.theme.colors.background.base};
    }

    @media (max-width: 815px) {
        .navigationButtonWrapper {
            flex-wrap: wrap;
            justify-content: center;
            margin: unset;
            column-gap: 24px;
            padding-bottom: 12px;
            button:first-child {
                margin-right: unset;
            }
        }
    }
`;

export { VendorMediaUploadFormFooterStyledStyled };
