import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    .addressLines > div {
        flex-direction: column;
    }

    .cityStateZipEntry > div > div {
        width: 25%;

        &:nth-child(1) {
            width: 50%;
        }
    }

    @media (max-width: 1200px) {
        .cityStateZipEntry > div {
            flex-wrap: wrap;

            & > div {
                &:nth-child(1) {
                    width: 100%;
                    flex-grow: 3;
                }
                &:nth-child(2),
                &:nth-child(3) {
                    width: 46%;
                    column-gap: 0.5em;
                }
            }
        }
    }

    @media (max-width: 815px) {
        .cityStateZipEntry > div {
            flex-direction: row;
        }
    }
`;
