import React, { useEffect, useState } from 'react';
import { AboutSectionStyled } from './AboutSection.styled';
import { IconButton, IconName, IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import { socialLinksPrefixes } from '../../../constants/socialLinksPrefixes';
import { useIsScreenLarge } from '../../../hooks/PearlMediaQuery';
import {
    getServiceArea,
    getServiceLocationAddress,
    travelOptions,
} from '../../../lib/WebstoreAddressService/WebstoreAddressService';

const RightContentItem = (props) => {
    const { title, content, icon } = props;
    return (
        <div className="item">
            <div className="icon-wrapper">
                <PearlIcon iconName={icon} />
            </div>
            <div className="item-wrapper">
                <div className="label">
                    <b>{title}</b>
                </div>
                <div className="p2">{content}</div>
            </div>
        </div>
    );
};

const IconLink = (props) => {
    const { link, icon, ariaLabel } = props;
    return (
        <IconButton
            href={link}
            target={'_blank'}
            size={IconSizes.MD}
            iconName={icon}
            ariaLabel={ariaLabel}
        />
    );
};

const Links = (props) => {
    const { webstore, screenSize } = props;
    const { t } = useTranslation();
    return (
        <div className={`links ${screenSize}`}>
            {webstore.Website && (
                <IconLink
                    link={`${socialLinksPrefixes.website.url}${webstore.Website}`}
                    icon={IconName.WEB}
                    ariaLabel={t('SocialLinks.WebsiteLabel')}
                />
            )}
            {webstore.YouTube && (
                <IconLink
                    link={`${socialLinksPrefixes.youtube.url}${webstore.YouTube}`}
                    icon={IconName.YOUTUBE}
                    ariaLabel={t('SocialLinks.YouTubeLabel')}
                />
            )}
            {webstore.Google && (
                <IconLink
                    link={`${socialLinksPrefixes.google.url}${webstore.Google}`}
                    icon={IconName.GOOGLE}
                    ariaLabel={t('SocialLinks.GoogleLabel')}
                />
            )}
            {webstore.Pinterest && (
                <IconLink
                    link={`${socialLinksPrefixes.pinterest.url}${webstore.Pinterest}`}
                    icon={IconName.PINTEREST}
                    ariaLabel={t('SocialLinks.PinterestLabel')}
                />
            )}
            {webstore.Yelp && (
                <IconLink
                    link={`${socialLinksPrefixes.yelp.url}${webstore.Yelp}`}
                    icon={IconName.YELP}
                    ariaLabel={t('SocialLinks.YelpLabel')}
                />
            )}
            {webstore.Twitter && (
                <IconLink
                    link={`${socialLinksPrefixes.twitter.url}${webstore.Twitter}`}
                    icon={IconName.TWITTER}
                    ariaLabel={t('SocialLinks.TwitterLabel')}
                />
            )}
            {webstore.Facebook && (
                <IconLink
                    link={`${socialLinksPrefixes.facebook.url}${webstore.Facebook}`}
                    icon={IconName.FACEBOOK}
                    ariaLabel={t('SocialLinks.FacebookLabel')}
                />
            )}
            {webstore.TikTok && (
                <IconLink
                    link={`${socialLinksPrefixes.tikTok.url}${webstore.TikTok}`}
                    icon={IconName.TIK_TOK}
                    ariaLabel={t('SocialLinks.TikTokLabel')}
                />
            )}
            {webstore.Instagram && (
                <IconLink
                    link={`${socialLinksPrefixes.instagram.url}${webstore.Instagram}`}
                    icon={IconName.INSTAGRAM}
                    ariaLabel={t('SocialLinks.InstagramLabel')}
                />
            )}
            {webstore.Vimeo && (
                <IconLink
                    link={`${socialLinksPrefixes.vimeo.url}${webstore.Vimeo}`}
                    icon={IconName.VIMEO}
                    ariaLabel={t('SocialLinks.VimeoLabel')}
                />
            )}
            {webstore.Spotify && (
                <IconLink
                    link={`${socialLinksPrefixes.spotify.url}${webstore.Spotify}`}
                    icon={IconName.SPOTIFY}
                    ariaLabel={t('SocialLinks.SpotifyLabel')}
                />
            )}
            {webstore.SoundCloud && (
                <IconLink
                    link={`${socialLinksPrefixes.soundCloud.url}${webstore.SoundCloud}`}
                    icon={IconName.SOUNDCLOUD}
                    ariaLabel={t('SocialLinks.SoundCloudLabel')}
                />
            )}
        </div>
    );
};

const BusinessSummary = (props) => {
    const { webstore } = props;
    const { t } = useTranslation();
    const isScreenLarge = useIsScreenLarge();

    const fullSummary = webstore.BusinessSummary;
    const shortSummary = getShortenedBusinessSummary(webstore.BusinessSummary);

    const [displayedSummary, setDisplayedSummary] = useState(() =>
        isScreenLarge ? fullSummary : shortSummary
    );

    const [buttonKey, setButtonKey] = useState(() =>
        displayedSummary === fullSummary ? 'ShowLess' : 'ReadMore'
    );

    const [userToggled, setUserToggled] = useState(false);

    const showExpandCollapse = fullSummary && fullSummary !== shortSummary;

    const setFull = () => {
        setDisplayedSummary(fullSummary);
        setButtonKey('ShowLess');
    };

    const setShort = () => {
        setDisplayedSummary(shortSummary);
        setButtonKey('ReadMore');
    };

    const businessSummaryToggle = () => {
        setUserToggled(true);
        displayedSummary === shortSummary ? setFull() : setShort();
    };

    useEffect(() => {
        if (!userToggled) {
            isScreenLarge ? setFull() : setShort();
        }
    }, [isScreenLarge]);

    return (
        <div className="p1">
            <span>{displayedSummary}</span>
            {!!showExpandCollapse && (
                <div>
                    <button onClick={businessSummaryToggle} className="p1 expandButton">
                        <b>{t(`WebstoreSections.About.${buttonKey}`)}</b>
                    </button>
                </div>
            )}
        </div>
    );
};

const capitalizeFirstLetter = (string) => {
    if (string?.length > 0) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
};

const getShortenedBusinessSummary = (summary) => {
    if (summary?.length > 260) {
        return summary?.substring(0, 259) + '...';
    } else {
        return summary;
    }
};

const StreetAddressDisplay = (props) => {
    const { children } = props;
    return (
        <>
            {(children || '').split(';').map((x) => (
                <div key={x}>{x.trim()}</div>
            ))}
        </>
    );
};

const cleanAddressField = (field) => {
    return field ? field : '';
};

const getServiceLocationToDisplay = (webstore) => {
    const serviceLocation = getServiceLocationAddress(webstore);

    if (serviceLocation && !!webstore.PhysicalStore) {
        return (
            <div>
                {!!webstore.DisplayStoreAddress && !!serviceLocation?.address1 && (
                    <StreetAddressDisplay>{serviceLocation.address1}</StreetAddressDisplay>
                )}
                <div>
                    {!!serviceLocation?.city && (
                        <span className="city-name">
                            {cleanAddressField(serviceLocation?.city)}
                        </span>
                    )}
                    {!!serviceLocation?.state && (
                        <span className="state-abbreviation">
                            {`, ${cleanAddressField(serviceLocation?.state)}  ${cleanAddressField(
                                serviceLocation?.zipCode
                            )}`}
                        </span>
                    )}
                </div>
            </div>
        );
    }

    return null;
};

const getServiceAreaToDisplay = (webstore) => {
    const serviceArea = getServiceArea(webstore);

    if (serviceArea && !!webstore.Travels && webstore.TravelOption === travelOptions.Locally) {
        return (
            <div>
                <div>
                    {!!serviceArea?.city && (
                        <span className="city-name">{cleanAddressField(serviceArea?.city)}</span>
                    )}
                    {!!serviceArea?.state && (
                        <span className="state-abbreviation">
                            {`, ${cleanAddressField(serviceArea?.state)}  ${cleanAddressField(
                                serviceArea?.zipCode
                            )}`}
                        </span>
                    )}
                </div>
            </div>
        );
    }

    return null;
};

const getTravelRadius = (webstore, distanceUnit) => {
    if (webstore.Travels) {
        const webstoreServiceArea = getServiceArea(webstore);

        if (
            webstore.TravelOption?.toLowerCase() === travelOptions.Locally &&
            webstoreServiceArea?.travelRadius
        ) {
            return `${webstoreServiceArea?.travelRadius} ${distanceUnit}`;
        } else if (webstore.TravelOption) {
            return capitalizeFirstLetter(webstore.TravelOption);
        }

        return null;
    }
    return null;
};

const getShippingRadiusContent = (webstore) => {
    return webstore.OnlineStore && !!webstore.OnlineAllowed
        ? capitalizeFirstLetter(webstore.ShippingRange)
        : null;
};

const getPriceTierContent = (webstore, t) => {
    let priceTierContent = '';

    switch (webstore.SelectedPriceTier) {
        case 1:
            priceTierContent = capitalizeFirstLetter(
                t('WebstoreSections.PriceTier.BudgetFriendly')
            );
            break;
        case 2:
            priceTierContent = capitalizeFirstLetter(t('WebstoreSections.PriceTier.Standard'));
            break;
        case 3:
            priceTierContent = capitalizeFirstLetter(t('WebstoreSections.PriceTier.Premium'));
            break;
        case 4:
            priceTierContent = capitalizeFirstLetter(t('WebstoreSections.PriceTier.Luxury'));
            break;
        default:
            priceTierContent = capitalizeFirstLetter(t('NoInformationProvided'));
            break;
    }

    return priceTierContent;
};

const getCapacityContent = (webstore, t) => {
    return `${webstore.Capacity} ${t('WebstoreSections.About.Guests')}`;
};

const getTravelRadiusContent = (webstore, t) => {
    return getTravelRadius(webstore, t('WebstoreSections.About.Miles'));
};

const AboutSection = (props) => {
    const { webstore, sectionRef, sectionId, hideNoInfoText } = props;

    const { t } = useTranslation();

    const serviceLocationContent = getServiceLocationToDisplay(webstore);
    const serviceAreaContent = getServiceAreaToDisplay(webstore);
    const shippingRadiusContent = getShippingRadiusContent(webstore);
    const priceTierContent = getPriceTierContent(webstore, t);
    const capacityContent = getCapacityContent(webstore, t);
    const travelRadiusContent = getTravelRadiusContent(webstore, t);

    return (
        <AboutSectionStyled ref={sectionRef} id={sectionId}>
            <div className="row">
                {!!webstore.BusinessSummary && <BusinessSummary webstore={webstore} />}
                {!webstore.BusinessSummary && (
                    <div className={`p1 ${!!hideNoInfoText ? 'desktopOnly' : ''}`}>
                        {!hideNoInfoText ? t('NoInformationProvided') : ''}
                    </div>
                )}
                <div className="vertical-break" />
                <Links webstore={webstore} screenSize="mobile" />
                <div className="right-content">
                    {!!serviceLocationContent && !!webstore.DisplayStoreAddress && (
                        <RightContentItem
                            title={t('WebstoreSections.About.StoreLocation')}
                            content={serviceLocationContent}
                            icon={IconName.MAP_MARKER_OUTLINE}
                        />
                    )}
                    {!!serviceAreaContent && (
                        <RightContentItem
                            title={t('WebstoreSections.About.MarketsServed')}
                            content={serviceAreaContent}
                            icon={IconName.MAP_MARKER_OUTLINE}
                        />
                    )}
                    {!!travelRadiusContent && (
                        <RightContentItem
                            title={t('WebstoreSections.About.TravelRadius')}
                            content={travelRadiusContent}
                            icon={IconName.AIRPLANE}
                        />
                    )}
                    {!!shippingRadiusContent && (
                        <RightContentItem
                            title={t('WebstoreSections.About.ShippingRadius')}
                            content={shippingRadiusContent}
                            icon={IconName.SHIPPING}
                        />
                    )}
                    {!!webstore.Capacity && (
                        <RightContentItem
                            title={t('WebstoreSections.About.Capacity')}
                            content={capacityContent}
                            icon={IconName.USER}
                        />
                    )}
                    <RightContentItem
                        title={t('WebstoreSections.About.PriceTier')}
                        content={priceTierContent}
                        icon={IconName.BUDGET}
                    />
                </div>
            </div>
            <Links webstore={webstore} screenSize="desktop" />
        </AboutSectionStyled>
    );
};

export { AboutSection };
