import styled from 'styled-components';

const SiteLoaderStyled = styled.div`
    color: #e8184c;

    .loader {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    img {
        width: 176px;
        margin-bottom: 30px;
    }

    .icons {
        display: flex;
        justify-content: space-between;
        width: 176px;
    }

    .icon {
        animation: loaderComponentWave 1.5s linear infinite;
    }

    .icon:nth-child(2) {
        animation-delay: 0.2s;
    }

    .icon:nth-child(3) {
        animation-delay: 0.3s;
    }

    .icon:nth-child(4) {
        animation-delay: 0.4s;
    }

    .icon:nth-child(5) {
        animation-delay: 0.5s;
    }

    // this animation is scoped to only this component.
    // the app-level loader uses the 'wave' animation created in public/index.html
    @keyframes loaderComponentWave {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
`;

export { SiteLoaderStyled };
