import {
    EditableSections,
    ToggleableSections,
} from 'vendor/components/Webstore/WebstoreSectionFactories';
import { useStore } from 'stores/Store';

const useWebstoreContext = () => {
    const { webstore } = useStore();

    const sectionIsEditable = (section) => EditableSections.has(section);

    const sectionIsToggleable = (section) =>
        ToggleableSections.map((ts) => ts.name).includes(section);

    return {
        webstore,
        sectionIsEditable,
        sectionIsToggleable,
    };
};

export { useWebstoreContext };
