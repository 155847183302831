import { IconName, SolidButton } from 'dbi-pearl-ui-kit';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MediaUpload, EmptyCaptions } from 'assets/Defaults';
import PearlForm from 'vendor/components/forms/PearlForm';
import { VendorMediaUploadFormFooterStyledStyled } from './VendorMediaUploadFormFooterStyled.styled';
import { VendorMediaUploadFormStyled } from './VendorMediaUploadForm.styled';
import { WebstoreGalleryEdit } from '../../Webstore/WebstoreGalleryEdit/WebstoreGalleryEdit';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useWebstoreSetupWizard } from 'vendor/components/WebstoreSetup/WebstoreSetupContext';
import { AccountSetupStepLayout, AccountSetupStepGraphic } from 'framework';
import { useVendorContext } from '../../../contexts/VendorContext';
import { useTealium, buildWebstoreSetupStepPageData } from '../../../../Tealium';
import { WebstoreSetupHeader } from '../WebstoreSetupHeader';

const formName = 'MediaUpload';
const MAX_IMAGES = 25;
const EXCEEDS_MAX_IMAGES = `Forms.${formName}.ExceedsMaxImages`;

const MediaFooter = (props) => {
    const { handleOnSubmit, handleOnBack } = props;

    const { t } = useTranslation();

    return (
        <VendorMediaUploadFormFooterStyledStyled {...props}>
            <div className="navigationProgressBar">
                <div className="navigationProgress"></div>
            </div>
            <div className="navigationButtonWrapper">
                <SolidButton
                    data-testid="back-button"
                    startIcon={IconName.ARROW_LEFT}
                    onClick={() => handleOnBack()}
                    variant="secondary"
                    iconVariant="arrow"
                >
                    {t('Forms.Labels.Back')}
                </SolidButton>
                <SolidButton data-testid="complete-webstore-button" onClick={handleOnSubmit}>
                    {t('Forms.Buttons.CompleteWebstore')}
                </SolidButton>
            </div>
        </VendorMediaUploadFormFooterStyledStyled>
    );
};

const VendorMediaUploadFormBase = () => {
    const { link, view } = useTealium();
    const { webstore } = useWebstoreContext();
    const { vendor } = useVendorContext();
    const { onSubmit, onReturnToBusinessLocations, onBack, currentStepCount, totalSteps } =
        useWebstoreSetupWizard();
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );

        view(tealiumData);
    }, []);

    const videoDetails = {
        image: MediaUpload,
        altText: `Forms.${formName}.GifAltText`,
        captions: EmptyCaptions,
    };

    const onDone = () => {
        setErrors([]);
        const images = webstore?.Media?.images;
        const stepName = t(`Forms.${formName}.StepName`);
        if (images.length > MAX_IMAGES) {
            const tealiumData = {
                event_name: 'setup-step-done-click-error',
                setup_step_number: currentStepCount,
                setup_step_total_step_count: totalSteps,
                setup_step_name: stepName,
                setup_step_image_amount: images.length,
                setup_step_images: images,
                setup_step_image_error: `image count is greater than max of ${MAX_IMAGES}`,
                webstore_category: webstore.VendorCategoryName,
                webstore_subCategory: webstore.VendorSubCategoryName,
                webstore_id: webstore.Id,
            };
            link(tealiumData);
            setErrors([{ text: EXCEEDS_MAX_IMAGES }]);
        } else {
            const tealiumData = {
                event_name: 'setup-step-done-click',
                setup_step_number: currentStepCount,
                setup_step_total_step_count: totalSteps,
                setup_step_name: stepName,
                setup_step_image_amount: images.length,
                setup_step_images: images,
                webstore_category: webstore.VendorCategoryName,
                webstore_category_external_id: webstore.VendorCategoryExternalId,
                webstore_subCategory: webstore.VendorSubCategoryName,
                webstore_id: webstore.Id,
            };
            link(tealiumData);

            onSubmit(null);
        }
    };

    return (
        <PearlForm StyledForm={VendorMediaUploadFormStyled} formName={formName}>
            <AccountSetupStepLayout
                header={
                    <WebstoreSetupHeader
                        onReturnToBusinessLocations={onReturnToBusinessLocations}
                        stepName={formName}
                        currentStepCount={currentStepCount}
                        totalSteps={totalSteps}
                    />
                }
                image={<AccountSetupStepGraphic {...videoDetails} />}
                formComponent={
                    <WebstoreGalleryEdit
                        webstore={webstore}
                        vendor={vendor}
                        parentErrors={errors}
                    />
                }
                footer={
                    <MediaFooter handleOnBack={() => onBack()} handleOnSubmit={() => onDone()} />
                }
            />
        </PearlForm>
    );
};

const VendorMediaUploadForm = observer(VendorMediaUploadFormBase);

export { VendorMediaUploadForm };
