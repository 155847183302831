import styled from 'styled-components';

export default styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .divider {
        border-bottom: 1px solid ${(props) => props.theme.colors.foreground.border};
        width: 100%;
    }

    .divider-content {
        padding: 0 10px 0 10px;
    }
`;
