import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

export default styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
    margin-top: 19px;
    gap: 17px;

    > * {
        width: 100%;
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        display: flex;
        flex-direction: column;
    }
`;
