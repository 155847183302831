import styled from 'styled-components';

export default styled.form`
    .event-date-calendar-wrapper {
        display: flex;
        justify-content: start;
    }

    .flexibleCheck {
        label {
            padding-left: 0;
        }
    }
    .rdrWeekDays {
        margin-bottom: 18px;
        margin-top: 18px;
    }
`;
