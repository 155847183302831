import apisauce from 'apisauce';
import { PearlApiClient, PearlApps } from 'framework';
import { v4 as uuidv4 } from 'uuid';

export const IMAGE_CATEGORIES = {
    GENERAL: 'general',
    DASHBOARD_HERO: 'dashboardhero',
};

export function EventService() {
    const api = PearlApiClient(PearlApps.EVENT_USER);

    const patchEventProfile = async (data) => {
        return await api.patch(`eventUser`, data);
    };

    const getEventUserId = async () => {
        return await api.get(`eventUserId`);
    };

    const getEventUser = async () => {
        return await api.get(`eventUser`);
    };

    //maybe use this as the equiv to postSignup
    //check endpoint to see what it does w/ the data
    //this calls: context.event.eventSignup(ctx, user);!! use it - don't need to make another func
    // 1/26/23 -> creates event using user attrs as default
    const postEvent = async () => {
        return await api.post('event');
    };

    const patchEvent = async (data) => {
        return await api.patch(`eventUser`, data);
    };

    const getEventByEventId = async (id) => {
        return await api.get(`event/${id}`);
    };

    const uploadImage = (id, file, category) => {
        return new Promise(async (resolve, reject) => {
            const noCacheKey = uuidv4();
            const client = apisauce.create({
                timeout: 60000,
            });
            const urlRes = await api.get(
                `event/${id}/uploadImage?filename=${file.name}&noCacheKey=${noCacheKey}`
            );
            const s3Url = urlRes.data.signedUrl;
            const upload = await client.put(s3Url, file, {
                headers: { 'Content-Type': 'image/*' },
            });
            if (upload.ok) {
                const res = await api.put(`event/${id}/confirmUploadImage`, {
                    category: category || IMAGE_CATEGORIES.GENERAL,
                    preSignedUrl: s3Url,
                    contentType: file.type,
                    height: file.height,
                    width: file.width,
                    ordinal: 0,
                });
                if (res.ok) {
                    resolve(res.data.image);
                }
            } else {
                reject('Upload failed for', file.name);
            }
        });
    };

    const deleteImage = async (eventId, imageId) => {
        const res = await api.delete(`event/${eventId}/images/${imageId}`);
        if (!res.ok || !res.data) {
            return res;
        }

        return res.data;
    };

    return {
        patchEventProfile,
        getEventUserId,
        getEventUser,
        getEventByEventId,
        postEvent,
        uploadImage,
        deleteImage,
        patchEvent,
        axiosInstance: api.axiosInstance,
    };
}
