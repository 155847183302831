import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const AmenitiesServicesStyled = styled.div`
    border-style: solid;
    border-color: ${(props) => props.theme.colors.foreground.border};
    border-width: 1px;
    padding: 4px 40px 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
        0px 0px 1px rgba(0, 0, 0, 0.04);

    ul {
        padding-inline-start: 0;
        margin: 0px;
    }

    .icon-checkmark-circle {
        padding-right: 5px;
    }

    .amenityListContainer {
        > div {
            display: flex;
            justify-content: space-between;

            .amenity-column {
                flex: 0 1 33%;
            }
        }

        label {
            color: ${(props) => props.theme.colors.primary.base};
        }

        .amenities {
            display: flex;
            flex-direction: column;
            padding-bottom: 8px;
        }

        .amenityDescription {
            display: inline-flex;
            align-items: center;
        }

        .checkmarkCircle {
            width: 20px;
            font-size: 20px;
            line-height: 20px;
            color: ${(props) => props.theme.colors.foreground.base};
        }
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        border: none;
        box-shadow: none;
        padding: 4px 0;

        .amenityListContainer > div {
            display: flex;
            flex-direction: column;
        }
    }
`;

export { AmenitiesServicesStyled };
