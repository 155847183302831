import React, { useState, useEffect } from 'react';
import { ServiceCharacteristicsSectionStyled } from './ServiceCharacteristicsSection.styled';
import { ToggleButtonGroup, ToggleButton, IconName } from 'dbi-pearl-ui-kit';
import { useVendorContext } from 'vendor/contexts/VendorContext';

// structure to match webstore/patch format
const getPatchableData = (selectedStyles, styleOptions) => {
    const selectedStyleIds = selectedStyles.map((x) => parseInt(x));

    const patchableData = styleOptions.filter((styleOption) =>
        selectedStyleIds.includes(styleOption.id)
    );

    return patchableData;
};

const ServiceCharacteristicsSection = (props) => {
    const { webstore, onChange } = props;

    // structure to match toggleGroup change handler
    const initialSelectedStyles = webstore.Styles?.map((x) => x.id.toString()) || [];

    const { getStylesByCategoryId } = useVendorContext();

    const styleOptions = getStylesByCategoryId(parseInt(webstore.VendorCategoryId));

    const [selectedStyles, setSelectedStyles] = useState(initialSelectedStyles);

    const handleButtonClick = (_, groupValues) => {
        setSelectedStyles(groupValues);
    };

    useEffect(() => {
        const patchableData = getPatchableData(selectedStyles, styleOptions);
        onChange(patchableData);
    }, [selectedStyles, styleOptions, onChange]);

    const buildToggleComponents = styleOptions.map((styleOption) => {
        return (
            <ToggleButton
                key={styleOption.id}
                value={`${styleOption.id}`}
                label={styleOption.name}
                iconName={IconName.CHECKMARK}
            />
        );
    });

    return (
        <ServiceCharacteristicsSectionStyled>
            <div className="toggle-group-wrapper">
                <ToggleButtonGroup
                    groupName={`styles-toggle-group`}
                    selectedGroupItems={selectedStyles}
                    onChange={handleButtonClick}
                >
                    {buildToggleComponents}
                </ToggleButtonGroup>
            </div>
        </ServiceCharacteristicsSectionStyled>
    );
};

ServiceCharacteristicsSection.defaultProps = {
    onChange: () => {},
};

export { ServiceCharacteristicsSection };
