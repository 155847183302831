import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Dropdown, ListView, MultiSelect } from 'dbi-pearl-ui-kit';
import { PearlError } from 'framework';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStore } from '../../stores/Store';

const VendorCategorySelectDropdownStyled = styled.div`
    .subcategoryContainer {
        padding-top: 40px;
    }
    .mini-container {
        margin-top: 24px;
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
`;

const VendorCategorySelectDropdown = (props) => {
    const { t } = useTranslation();
    const { onCategoryChange, mini } = props;
    const { getValues } = useFormContext();
    const { appData } = useStore();
    const { categoryList } = appData;

    const [categoryListItems] = useState(
        categoryList.map((x) => {
            return {
                id: x.id,
                label: x.name,
                imageUrl: x.image?.url,
                category: x,
                subCategories: x.subCategories.map((y) => ({
                    id: y.id,
                    label: y.name,
                    imageUrl: null,
                    subCategory: y,
                })),
            };
        })
    );

    const formCategoryId = getValues('VendorCategoryId');
    const [selectedCategory, setSelectedCategory] = useState(
        categoryListItems.find((x) => x.id === formCategoryId)
    );
    const [currentCategorySubcategories, setCurrentCategorySubcategories] = useState(
        selectedCategory?.subCategories
    );

    const handleCategoryChange = (categoryId) => {
        const catId = Number(categoryId?.target?.value) || categoryId;
        const cat = categoryListItems.find((x) => x.id === catId);
        if (cat) {
            setSelectedCategory(cat);
            setCurrentCategorySubcategories(cat.subCategories);
            // setValue('VendorSubCategoryIds', []);
            if (onCategoryChange && categoryId) {
                onCategoryChange(parseInt(categoryId));
            }
        }
    };

    const fullSize = (
        <div>
            {categoryListItems && (
                <div key="CategoryList">
                    <ListView
                        formFieldName="VendorCategoryId"
                        label={t('Forms.Labels.SelectCategory')}
                        multiSelect={false}
                        listItems={categoryListItems}
                        onChange={handleCategoryChange}
                    ></ListView>
                    <PearlError name="VendorCategoryId"></PearlError>
                </div>
            )}
            {selectedCategory && (
                <div className="subcategoryContainer" key="SubCategory List">
                    <ListView
                        formFieldName="VendorSubCategoryIds"
                        label={t('Forms.Labels.SelectSubCategory')}
                        multiSelect={true}
                        listItems={selectedCategory.subCategories}
                    ></ListView>
                    <PearlError name="VendorSubCategoryIds"></PearlError>
                </div>
            )}
        </div>
    );

    const miniSize = (
        <div className="mini-container">
            {categoryListItems && (
                <div key="CategoryList">
                    <Dropdown
                        formFieldName="VendorCategoryId"
                        providedLabel={t('Forms.Labels.SelectCategory')}
                        listItems={categoryListItems}
                        onChange={handleCategoryChange}
                    ></Dropdown>
                    <PearlError name="VendorCategoryId"></PearlError>
                </div>
            )}
            {selectedCategory && (
                <>
                    <div key="SubCategory List">
                        <MultiSelect
                            formFieldName="VendorSubCategoryIds"
                            providedLabel={t('Forms.Labels.SelectSubCategory')}
                            listItems={currentCategorySubcategories}
                        ></MultiSelect>
                        <PearlError name="VendorSubCategoryIds"></PearlError>
                    </div>
                </>
            )}
        </div>
    );

    return (
        <VendorCategorySelectDropdownStyled>
            {!mini && fullSize}
            {!!mini && miniSize}
        </VendorCategorySelectDropdownStyled>
    );
};
export { VendorCategorySelectDropdown };
