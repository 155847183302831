import * as Yup from 'yup';
import i18n from 'i18n';

const MediaEditValidationSchema = Yup.object().shape({
    caption: Yup.string()
        .nullable()
        .max(
            100,
            i18n.t('Forms.Validation.CannotExceedCharacterLimit', {
                characterLimit: '100',
            })
        ),
});
export { MediaEditValidationSchema };
