import * as Yup from 'yup';
import i18n from '../../../i18n';

const userBasicsSchema = Yup.object()
    .shape({
        FirstName: Yup.string()
            .nullable()
            .required(
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.FirstName'),
                })
            ),
        LastName: Yup.string()
            .nullable()
            .required(
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.LastName'),
                })
            ),
        EventType: Yup.string()
            .nullable()
            .notOneOf(
                ['DEFAULT'],
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.EventType'),
                })
            )
            .required(
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.EventType'),
                })
            ),
        UserEventRole: Yup.string()
            .nullable()
            .notOneOf(
                ['DEFAULT'],
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.UserEventRole'),
                })
            )
            .required(
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.UserEventRole'),
                })
            ),
    })
    .required();

export { userBasicsSchema };
