import React, { createContext, useContext, useEffect, useState } from 'react';
import { RealtimeClient } from 'framework';
import { useAppName } from 'shared/hooks/AppName';
import { SiteLoader } from '../shared/components/SiteLoader/SiteLoader';
import { TokenService } from '../lib/apis/TokenService';
import { RealtimeSubscription } from './RealtimeSubscription';

const realtimeActions = {
    ConversationList: 'ConversationList',
    ConversationState: 'ConversationState',
    IncomingMessage: 'IncomingMessage',
    SubscriptionUpdated: 'SubscriptionUpdated',
};

const RealtimeContext = createContext({
    identified: false,
});
RealtimeContext.displayName = 'RealtimeContext';

const useRealtimeContext = () => {
    const realtimeService = useContext(RealtimeContext);

    return {
        ...realtimeService,
    };
};

const RealtimeProvider = (props) => {
    const { children, store } = props;
    const { authUser } = store;
    const { appName } = useAppName();
    const tokenService = TokenService();
    const [realtime, setRealtime] = useState(null);

    useEffect(() => {
        const work = async () => {
            const rs = new RealtimeService(appName);
            await rs.connect(appName);
            setRealtime(rs);
        };
        work();
    }, []);

    useEffect(() => {
        const work = async () => {
            try {
                const token = await tokenService.getOrRefreshAccessToken();
                await realtime.identify(token, appName);
            } catch (err) {
                if (err === 'No current user') {
                    await realtime.identify(null, appName);
                } else {
                    console.warn('Unhandled Auth.currentSession error: ', err);
                }
            }
        };
        if (realtime && appName) {
            work();
        }
    }, [authUser.Roles, authUser.Id, realtime, appName]);

    if (!realtime) {
        return <SiteLoader />;
    }

    return <RealtimeContext.Provider value={realtime}>{children}</RealtimeContext.Provider>;
};

function RealtimeService(initialAppName) {
    const realtime = new RealtimeClient(
        process.env.REACT_APP_BYPASS_LOG_INTERCEPTOR,
        initialAppName
    );

    async function connect(appName) {
        await realtime.connect(WebSocket, process.env.REACT_APP_WS_URL, appName);
    }

    async function identify(jwt, appName) {
        await realtime.identify(jwt, appName);
    }

    async function listConversations() {
        await realtime.sendMessage('listConversations');
    }

    async function getConversation(id) {
        await realtime.sendMessage('getConversation', { conversationId: id });
    }

    async function startConversation(webstoreId, eventId, eventInfo) {
        await realtime.sendMessage('startConversation', {
            webstoreId,
            eventId,
            eventInfo,
        });
    }

    async function viewedConversation(conversationId) {
        await realtime.sendMessage('viewedConversation', {
            conversationId,
        });
    }

    async function sendMessage(conversationId, message, uuid) {
        await realtime.sendMessage('sendMessage', {
            conversationId,
            message,
            uuid,
        });
    }

    async function moveConversation(conversationId, to) {
        await realtime.sendMessage('moveConversation', { conversationId, to });
    }

    function listen(callback) {
        return realtime.listen(callback);
    }

    function cleanup() {
        return realtime.cleanup();
    }

    const realtimeSubscription = RealtimeSubscription(realtime, listen);

    realtimeSubscription.initializeSubscriptionService();

    return {
        identify,
        listConversations,
        getConversation,
        startConversation,
        viewedConversation,
        moveConversation,
        sendMessage,
        connect,
        cleanup,
        listen,
        subscribe: realtimeSubscription.subscribe,
        readyPromise: realtime.readyPromise,
    };
}

export { RealtimeProvider, RealtimeService, useRealtimeContext, realtimeActions };
