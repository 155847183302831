import React from 'react';
import PropTypes from 'prop-types';
import { TruncatedTextDisplay } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import WebstoreMobileHeaderStyled from './WebstoreMobileHeader.styled';

function WebstoreMobileHeader(props) {
    const { webstore } = props;

    const { t } = useTranslation();

    const onlineOnlyWebstore = (webstore) => {
        return !webstore.PhysicalStore && !webstore.Travels && webstore.OnlineStore;
    };

    return (
        <WebstoreMobileHeaderStyled>
            <div className="content">
                <TruncatedTextDisplay maxWidth={'250px'} lineCount={1} textAlign={'center'}>
                    <h4 data-testid="webstore-name">{webstore.WebstoreName}</h4>
                </TruncatedTextDisplay>
                <div className="subtitle">
                    {onlineOnlyWebstore(webstore) ? (
                        <p className="p4" data-testid="webstore-online-only">
                            {t('WebstoreSections.About.OnlineOnly')}
                        </p>
                    ) : (
                        <p className="p4" data-testid="webstore-service-area">
                            {webstore.ServiceAddressCity}, {webstore.ServiceAddressState}
                        </p>
                    )}
                </div>
            </div>
        </WebstoreMobileHeaderStyled>
    );
}

WebstoreMobileHeader.propTypes = {
    /**
     * Webstore object - placeholder
     */
    webstore: PropTypes.object,
};

WebstoreMobileHeader.displayName = 'WebstoreMobileHeader';

export default WebstoreMobileHeader;
