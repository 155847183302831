import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const WebstoreAwardsStyled = styled.section`
    .awards-container {
        ${(props) => props.theme.pearlBreaks.up(BreakpointSizes.MD)} {
            display: flex;
            flex-flow: row wrap;
            align-content: center;
            gap: 24px;
        }
    }
`;

export { WebstoreAwardsStyled };
