import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentCheckbox } from 'dbi-pearl-ui-kit';
import { PearlError } from 'framework';
import { formFieldNames } from '../Functions/ServiceAreaForm.Functions';
import { ServiceTypeOptionsStyled } from './ServiceTypeOptions.styled';

const OnlineContent = () => {
    const { t } = useTranslation();

    return (
        <div className="option-content">
            <p className="option-title">{t('Forms.ServiceArea.Online')}</p>
        </div>
    );
};

const AtStoreContent = () => {
    const { t } = useTranslation();

    return (
        <div className="option-content">
            <p className="option-title">{t('Forms.ServiceArea.Store')}</p>
            <p className="option-subtitle p3">{t('Forms.ServiceArea.StoreSubtitle')}</p>
        </div>
    );
};

const TravelsContent = () => {
    const { t } = useTranslation();

    return (
        <div className="option-content">
            <p className="option-title">{t('Forms.ServiceArea.Travel')}</p>
            <p className="option-subtitle p3">{t('Forms.ServiceArea.TravelSubtitle')}</p>
        </div>
    );
};

const ServiceTypeOptions = (props) => {
    const {
        onlineOptionAllowed,
        travelOptionAllowed,
        onlineStore,
        onlineStoreClickHandler,
        physicalStore,
        physicalStoreClickHandler,
        travel,
        travelsClickHandler,
    } = props;

    const { t } = useTranslation();

    return (
        <ServiceTypeOptionsStyled className="serviceOptions">
            <p className="option-question">
                <b>{t('Forms.ServiceArea.ServiceTypeQuestion')}</b>
            </p>
            <div className="checkboxGroup store-options" role="group">
                {!!onlineOptionAllowed && (
                    <ContentCheckbox
                        fieldName={formFieldNames.OnlineStore}
                        data-testid="online-checkbox-label"
                        onClick={onlineStoreClickHandler}
                        isSelected={!!onlineStore}
                        content={<OnlineContent />}
                        className="service-option"
                    />
                )}
                <ContentCheckbox
                    fieldName={formFieldNames.PhysicalStore}
                    onClick={physicalStoreClickHandler}
                    isSelected={physicalStore}
                    content={<AtStoreContent />}
                    className="service-option"
                />
                {!!travelOptionAllowed && (
                    <ContentCheckbox
                        fieldName={formFieldNames.Travels}
                        onClick={travelsClickHandler}
                        isSelected={travel}
                        content={<TravelsContent />}
                        className="service-option"
                    />
                )}
            </div>
            <PearlError name="IsServiceTypeSelected" />
        </ServiceTypeOptionsStyled>
    );
};

export { ServiceTypeOptions };
