import styled from 'styled-components';

const UserBasicsFormStyled = styled.form`
    .agreement {
        margin: 18px 0 0;

        & > label {
            padding-left: 0;
            display: flex;
        }
    }
`;

export { UserBasicsFormStyled };
