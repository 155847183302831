import styled from 'styled-components';

const VendorBusinessSummaryFormStyled = styled.form`
    display: flex;
    height: 100%;

    .BusinessSummary-container {
        height: 250px;
        margin-top: 20px;
    }
`;

export { VendorBusinessSummaryFormStyled };
