import { FormRow, socialLinksPrefixes } from 'framework';
import { IconName, TextInput } from 'dbi-pearl-ui-kit';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SocialLinksSectionStyled } from './SocialLinksSection.styled';

const socials = [
    'Website',
    'YouTube',
    'Facebook',
    'Vimeo',
    'Instagram',
    'TikTok',
    'Pinterest',
    'Spotify',
    'Twitter',
    'SoundCloud',
    'Google',
    'Yelp',
];

const SocialLinksSection = (props) => {
    const { labels } = props;
    const { watch, getValues, setValue } = useFormContext();
    const [currentTarget, setCurrentTarget] = useState(null);
    const [
        websiteFieldName,
        youtubeFieldName,
        facebookFieldName,
        vimeoFieldName,
        instagramFieldName,
        tikTokFieldName,
        pinterestFieldName,
        spotifyFieldName,
        twitterFieldName,
        soundCloudFieldName,
        googleFieldName,
        yelpFieldName,
    ] = [...socials];

    const cleanLink = (name, url) => {
        if (url) {
            const reduced = url.replace(/(^\w+:|^)\/\//, '');
            if (name) {
                if (name === websiteFieldName || name === googleFieldName) {
                    return reduced;
                } else if (name === youtubeFieldName) {
                    return reduced.match(/([^/]+)\/?$/)[0].replace(/\/+$/, '');
                } else {
                    return reduced
                        .match(/([^/]+)\/?$/)[0]
                        .replace(/^@/, '')
                        .replace(/\/+$/, '');
                }
            }
        }
        return url;
    };

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            setCurrentTarget(name);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (currentTarget) {
            if (socials.includes(currentTarget)) {
                setValue(currentTarget, cleanLink(currentTarget, getValues(currentTarget)));
            }
        }
    }, [watch(currentTarget)]);

    return (
        <SocialLinksSectionStyled data-testid="social-links-section">
            <div className="link-row">
                <FormRow
                    columns={[
                        <TextInput
                            formFieldName={websiteFieldName}
                            startIcon={IconName.WEB}
                            preText={socialLinksPrefixes.website.displayText}
                            providedLabel={labels?.Website}
                        />,
                        <TextInput
                            formFieldName={youtubeFieldName}
                            startIcon={IconName.YOUTUBE}
                            preText={socialLinksPrefixes.youtube.displayText}
                            providedLabel={labels?.YouTube}
                        />,
                    ]}
                />
            </div>
            <div className="link-row">
                <FormRow
                    columns={[
                        <TextInput
                            formFieldName={facebookFieldName}
                            startIcon={IconName.FACEBOOK}
                            preText={socialLinksPrefixes.facebook.displayText}
                            providedLabel={labels?.Facebook}
                        />,
                        <TextInput
                            formFieldName={vimeoFieldName}
                            startIcon={IconName.VIMEO}
                            preText={socialLinksPrefixes.vimeo.displayText}
                            providedLabel={labels?.Vimeo}
                        />,
                    ]}
                />
            </div>
            <div className="link-row">
                <FormRow
                    columns={[
                        <TextInput
                            formFieldName={instagramFieldName}
                            startIcon={IconName.INSTAGRAM}
                            preText={socialLinksPrefixes.instagram.displayText}
                            providedLabel={labels?.Instagram}
                        />,
                        <TextInput
                            formFieldName={tikTokFieldName}
                            startIcon={IconName.TIK_TOK}
                            preText={socialLinksPrefixes.tikTok.displayText}
                            providedLabel={labels?.TikTok}
                        />,
                    ]}
                />
            </div>
            <div className="link-row">
                <FormRow
                    columns={[
                        <TextInput
                            formFieldName={pinterestFieldName}
                            startIcon={IconName.PINTEREST}
                            preText={socialLinksPrefixes.pinterest.displayText}
                            providedLabel={labels?.Pinterest}
                        />,
                        <TextInput
                            formFieldName={spotifyFieldName}
                            startIcon={IconName.SPOTIFY}
                            preText={socialLinksPrefixes.spotify.displayText}
                            providedLabel={labels?.Spotify}
                        />,
                    ]}
                />
            </div>
            <div className="link-row">
                <FormRow
                    columns={[
                        <TextInput
                            formFieldName={twitterFieldName}
                            startIcon={IconName.TWITTER}
                            preText={socialLinksPrefixes.twitter.displayText}
                            providedLabel={labels?.Twitter}
                        />,
                        <TextInput
                            formFieldName={soundCloudFieldName}
                            startIcon={IconName.SOUNDCLOUD}
                            preText={socialLinksPrefixes.soundCloud.displayText}
                            providedLabel={labels?.SoundCloud}
                        />,
                    ]}
                />
            </div>
            <div className="link-row">
                <FormRow
                    columns={[
                        <TextInput
                            formFieldName={googleFieldName}
                            startIcon={IconName.GOOGLE}
                            preText={socialLinksPrefixes.google.displayText}
                            providedLabel={labels?.Google}
                        />,
                        <TextInput
                            formFieldName={yelpFieldName}
                            startIcon={IconName.YELP}
                            preText={socialLinksPrefixes.yelp.displayText}
                            providedLabel={labels?.Yelp}
                        />,
                    ]}
                />
            </div>
        </SocialLinksSectionStyled>
    );
};

export { SocialLinksSection };
