import React from 'react';
import styled from 'styled-components';
import { SolidButton, IconName, GhostButton } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const AccountSetupStepFooterStyled = styled.footer`
    position: fixed;
    right: 0;
    bottom: 0;
    width: 65%;
    z-index: 1;
    background: ${(props) => props.theme.colors.background.base};

    .navigationButtonWrapper {
        display: flex;
        justify-content: end;
        padding-bottom: 12px;
        margin-right: 48px;

        button:first-child {
            margin-right: 24px;
        }
    }

    .navigationProgress {
        width: ${(props) => props.progress * 100}%;
        background: ${(props) =>
            `linear-gradient(90deg, ${props.theme.colors.primary.base} 0%, #ff5680 100%)`};
        height: 5px;
    }
    .navigationProgressBar {
        margin-bottom: 12px;
        background: ${(props) => props.theme.colors.background.base};
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .navigationButtonWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            margin: unset;
            column-gap: 24px;
            button {
                width: auto;
            }
            button:first-child {
                margin-left: 20px;
            }
            button:last-child {
                margin-right: 20px;
            }
        }
    }
`;

const AccountSetupStepFooter = (props) => {
    const { progress, handleOnBack, handleOnSkip } = props;
    const { t } = useTranslation();
    const finalStep = progress === 1;
    return (
        <AccountSetupStepFooterStyled {...props}>
            <div className="navigationProgressBar">
                <div className="navigationProgress"></div>
            </div>
            <div className="navigationButtonWrapper">
                <SolidButton
                    data-testid="back-button"
                    startIcon={IconName.ARROW_LEFT}
                    disabled={!handleOnBack}
                    onClick={() => handleOnBack()}
                    variant="secondary"
                    iconVariant="arrow"
                >
                    {t('Forms.Labels.Back')}
                </SolidButton>
                {!handleOnSkip && !finalStep && (
                    <SolidButton
                        data-testid="next-button"
                        type="submit"
                        endIcon={IconName.ARROW_RIGHT}
                        iconVariant="arrow"
                        {...props}
                    >
                        {t('Forms.Labels.Next')}
                    </SolidButton>
                )}
                {!handleOnSkip && finalStep && (
                    <SolidButton data-testid="complete-button" type="submit" {...props}>
                        {t('Forms.Labels.Complete')}
                    </SolidButton>
                )}
                {handleOnSkip && (
                    <GhostButton
                        data-testid="skip-button"
                        type="button"
                        onClick={() => handleOnSkip()}
                        endIcon={IconName.ARROW_RIGHT}
                        variant="accent"
                        iconVariant="arrow"
                    >
                        {t('Forms.Labels.Skip')}
                    </GhostButton>
                )}
            </div>
        </AccountSetupStepFooterStyled>
    );
};

export { AccountSetupStepFooter };
