import styled from 'styled-components';

const thumbStyles = `
    width: 356px;
    height: 506px;

    border: 1px solid ${(props) => props.theme.colors.foreground.border};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
        0px 0px 1px rgba(0, 0, 0, 0.04);

    .package-image {
        height: 200px;
        width: 100%;
    }

    img {
        max-height: 200px;
    }

    .package-content {
        padding: 24px;

        .p3 {
            height: 90px;
            overflow: hidden;
        }
    }
    
    @media (max-width: 450px) {
        width: 325px;
    }
`;

const fullStyles = `
    width: 100%;
    height: unset;

    .package-image {
        width: 100%;
    }

    img {
        max-height: 326px;
    }

    .package-content {
        padding: 24px 0 0 0;
        
        .details-section {
            -webkit-line-clamp: unset;
        }
    }
`;

const WebstorePackageStyled = styled.div`
    color: ${(props) => props.theme.colors.foreground.text};

    img {
        width: 100%;
        object-fit: cover;
    }

    .placeholder {
        object-fit: contain;
    }

    .flagged {
        object-fit: contain;
    }

    .package-content {
        margin: 0px;

        .details-section {
            margin: 0 0 16px 0;
            padding: 0;

            & > * {
                margin: 0;
                padding: 0;
            }
        }

        p.label {
            margin: 0 0 4px 0;
            color: ${(props) => props.theme.colors.primary.base};
        }

        .p3 {
            display: block;
            word-break: break-all;
        }

        button {
            margin: 0;
            width: 100%;
        }
    }

    ${(props) => (props.isThumb ? thumbStyles : fullStyles)}
`;

export { WebstorePackageStyled };
