import { BusinessSummarySection } from 'vendor/components/Webstore/BusinessSummarySection/BusinessSummarySection';
import { BusinessSummarySectionEditStyled } from './BusinessSummarySectionEdit.styled';
import { SectionEditHeader } from '../SectionEditHeader/SectionEditHeader';
import { useTranslation } from 'react-i18next';

const BusinessSummarySectionEdit = () => {
    const { t } = useTranslation();

    return (
        <BusinessSummarySectionEditStyled data-testid="business-summary-section-edit">
            <SectionEditHeader
                title="Forms.BusinessSummary.StepName"
                description="Forms.BusinessSummary.EditSectionDescription"
            />
            <BusinessSummarySection
                placeholder={t('Forms.BusinessSummary.BusinessSummaryPlaceholder')}
                label={t('Forms.BusinessSummary.BusinessSummaryLabel')}
            />
        </BusinessSummarySectionEditStyled>
    );
};

export { BusinessSummarySectionEdit };
