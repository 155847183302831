import { useState } from 'react';
import styled from 'styled-components';
import { Outlet, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../shared/constants/navigation';
import { SolidButton } from 'dbi-pearl-ui-kit';
import Store, { StoreProvider } from '../../../stores/Store';

// admin banner/border
const StyledAdminWrapper = styled.div`
    &::before {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: 'admin';
        text-align: center;
        font-weight: bold;
        color: ${(props) => props.theme.colors.error.base};
        box-shadow: inset 0 0 0 2px ${(props) => props.theme.colors.error.base};
        z-index: 9999;
        pointer-events: none;
    }

    & > button {
        position: fixed;
        top: 4px;
        right: 4px;
        z-index: 9999;
    }
`;

const AdminWrapper = () => {
    const navigate = useNavigate();
    const [adminStore] = useState(new Store({ useInitializers: false }));

    const logout = () => navigate(APP_ROUTES.logout);

    return (
        <StyledAdminWrapper>
            <SolidButton type="button" onClick={logout}>
                Logout
            </SolidButton>
            <StoreProvider store={adminStore}>
                <Outlet />
            </StoreProvider>
        </StyledAdminWrapper>
    );
};

export { AdminWrapper };
