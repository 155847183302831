import { UpgradeSubscriptionStyled } from './UpgradeSubscription.styled';
import { useTranslation } from 'react-i18next';
import { LinearProgress } from 'framework';
import { SolidButton } from 'dbi-pearl-ui-kit';
import { useUpgradeSubscription } from '../../../hooks/UpgradeSubscription';
import { useVendorContext } from '../../../contexts/VendorContext';

const UpgradeSubscription = () => {
    const { vendor } = useVendorContext();
    const { t } = useTranslation();
    const { upgrade, isCreatingSession } = useUpgradeSubscription();

    const onUpgradeClick = () => {
        upgrade(vendor);
    };

    return (
        <UpgradeSubscriptionStyled>
            <span className="upgradeText">{t('WebstoreSections.Upgrade.UpgradeText')}</span>
            {isCreatingSession ? (
                <>
                    <p className="p1">
                        {t('Stripe.CreatingStripeSession')}
                        <LinearProgress />
                    </p>
                </>
            ) : (
                <SolidButton onClick={onUpgradeClick}>
                    {t('WebstoreSections.Upgrade.UpgradeButton')}
                </SolidButton>
            )}
        </UpgradeSubscriptionStyled>
    );
};

export { UpgradeSubscription };
