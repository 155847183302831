import { makeAutoObservable } from 'mobx';
import { initVendorFAQs } from './Initializers';

const initialValues = {};

export default class VendorStore {
    id = null;
    userId = null;
    name = null;
    contactFirstName = null;
    contactLastName = null;
    contactEmail = null;
    contactPhone = null;
    stripeData = {};
    settings = {};
    props = {};
    tosAccepted = false;
    webstoreCount = 0;

    faqOptions = null;
    unreadMessageCount = null;

    Loading = false;

    constructor(options) {
        for (const [k, v] of Object.entries(this)) {
            initialValues[k] = v;
        }

        makeAutoObservable(this);
        // not ref'd yet, but can be if this starts causing problems
        this.Initialized = initVendorFAQs(this);
    }

    setDefaults() {
        for (const [k, v] of Object.entries(initialValues)) {
            this[k] = v;
        }
    }

    clear() {
        this.setDefaults();
    }

    get patchData() {
        return {
            id: this.id,
            contactFirstName: this.contactFirstName,
            contactLastName: this.contactLastName,
            contactEmail: this.contactEmail,
            contactPhone: this.contactPhone,
            settings: this.settings,
            props: this.props,
        };
    }

    setVendor(vendor) {
        this.id = parseInt(vendor.id, 10);
        this.userId = parseInt(vendor.userId, 10);
        this.name = vendor.name;
        this.contactFirstName = vendor.contactFirstName;
        this.contactLastName = vendor.contactLastName;
        this.contactEmail = vendor.contactEmail;
        this.contactPhone = vendor.contactPhone;
        this.stripeData = vendor.stripeData || {};
        this.settings = vendor.settings || {};
        this.props = vendor.props || {};
        this.tosAccepted = !!vendor.tosAccepted;
        this.webstoreCount = vendor.webstoreCount;
    }

    updateVendor(update) {
        for (const [key, value] of Object.entries(update)) {
            if (this.hasOwnProperty(key)) {
                this[key] = value;
            }
        }
    }

    setLoading(value) {
        this.Loading = value;
    }

    setFaqOptions(options) {
        this.faqOptions = options;
    }

    // only to be used for display purposes
    // use feature checks for business logic
    get stripeTierName() {
        return this.stripeData?.subscription?.tier;
    }

    hasFeature(featureName) {
        const vendorFeatures = this.stripeData?.subscription?.activeFeatures || [];
        return vendorFeatures.includes(featureName);
    }

    get defaultWebstoreId() {
        return this.props.defaultWebstoreId;
    }

    set defaultWebstoreId(value) {
        this.props.defaultWebstoreId = value;
    }
}
