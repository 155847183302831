const { useState, useMemo, useEffect } = require('react');

function useIsInViewportRef(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
        []
    );

    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}

function useIsInViewportElement(element) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
        []
    );

    useEffect(() => {
        if (element) {
            observer.observe(element);

            return () => {
                observer.disconnect();
            };
        }
    }, [element, observer]);

    return isIntersecting;
}

export { useIsInViewportElement, useIsInViewportRef };
