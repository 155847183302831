import { APP_ROUTES } from 'shared/constants/navigation';
import { StorageService, StorageKeys } from '../lib/StorageService';

const RequireAdmin = (context) => {
    const storageService = StorageService();
    const isAdmin = context.store.authUser?.IsAdmin === true;

    if (!context.store.isAuthed) {
        const internalCallbackUrl = window.location.pathname;
        storageService.set(StorageKeys.internalCallbackUrl, internalCallbackUrl);
        return APP_ROUTES.admin.adminLoginPage;
    }

    if (context.store.isAuthed && !isAdmin) {
        return APP_ROUTES.admin.authError;
    }

    return 'next';
};

export { RequireAdmin };
