import { useTheme } from 'styled-components';
import { useMediaQuery } from '@mui/material';
import { BreakpointDirections, BreakpointSizeWidths } from 'dbi-pearl-ui-kit';

const usePearlMediaQuery = (options) => {
    if (!options) {
        throw new Error('options are required');
    }
    const min = options.min || 0;
    const max = options.max || Number.MAX_SAFE_INTEGER;

    const minResult = useMediaQuery(useTheme().pearlBreaks[BreakpointDirections.UP](min));
    const maxResult = useMediaQuery(useTheme().pearlBreaks[BreakpointDirections.DOWN](max));

    return minResult && maxResult;
};

const useIsScreenSmall = () => {
    return usePearlMediaQuery({ max: BreakpointSizeWidths.SM });
};

const useIsScreenMedium = () => {
    return usePearlMediaQuery({
        min: BreakpointSizeWidths.SM,
        max: BreakpointSizeWidths.MD,
    });
};

const useIsScreenLarge = () => {
    return usePearlMediaQuery({ min: BreakpointSizeWidths.MD });
};

const useIsScreenTablet = () => {
    return usePearlMediaQuery({ max: BreakpointSizeWidths.LG });
};

export {
    usePearlMediaQuery,
    useIsScreenSmall,
    useIsScreenMedium,
    useIsScreenTablet,
    useIsScreenLarge,
};
