import React, { useEffect, useState } from 'react';
import { FormRow, PearlError } from 'framework';
import { TextInput, IconName, IconButton, LinkButton } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { PearlDropzone } from 'shared/components/PearlDropzone/PearlDropzone';
import { AwardEditFormStyled } from './AwardEditForm.styled';
import { validateFileSize } from 'vendor/components/Webstore/WebstoreGalleryEdit/WebstoreGalleryEditFuncs';

const MAX_UPLOAD_COUNT = 1;
const MAX_FILE_SIZE = 15000000;

const EXCEEDS_MAX_UPLOAD_COUNT = 'Forms.MediaUpload.ExceedsMaxUploadCount';
const EXCEEDS_MAX_FILE_SIZE = 'Forms.MediaUpload.ExceedsMaxSize';
const UNSUPPORTED_FILE_TYPE = 'Forms.MediaUpload.UnsupportedFileType';

const AwardEditForm = (props) => {
    const { index, hasError, onDelete, isNew } = props;
    const { t } = useTranslation();
    const { setValue, watch, register, resetField } = useFormContext();
    const [imageError, setImageError] = useState(null);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);

    const loadedImage = watch(`${index}.Image`);
    const awardTitle = watch(`${index}.Name`);
    const open = watch(`${index}.Open`);

    register(`${index}.Ordinal`);

    useEffect(() => {
        if (isNew && (open || typeof open === 'undefined')) {
            setValue(`${index}.Open`, true);
        }
    }, [isNew]);

    const handleUpload = (droppedFiles, rejectedFiles) => {
        setImageError(null);

        if (rejectedFiles?.length > MAX_UPLOAD_COUNT) {
            setImageError({
                text: EXCEEDS_MAX_UPLOAD_COUNT,
                props: {
                    uploadLimit: MAX_UPLOAD_COUNT,
                },
            });
            return;
        }

        if (rejectedFiles?.length) {
            setImageError({
                text: UNSUPPORTED_FILE_TYPE,
                props: {
                    filename: rejectedFiles[0].file?.name,
                },
            });
            return;
        }

        if (droppedFiles.length) {
            const [validFiles, invalidFiles] = validateFileSize(droppedFiles, MAX_FILE_SIZE);

            if (invalidFiles?.length) {
                setImageError({
                    text: EXCEEDS_MAX_FILE_SIZE,
                    props: {
                        filename: invalidFiles[0].name,
                    },
                });
                return;
            }

            setValue(`${index}.Image`, validFiles[0], {
                shouldDirty: true,
                shouldTouch: true,
            });
        }
    };

    const removeImage = () => {
        setPreviewImageUrl(null);
        setValue(`${index}.Image`, null, {
            shouldDirty: true,
            shouldTouch: true,
        });
    };

    const deleteAwardForm = (e, index) => {
        resetField(index, { defaultValue: {} });
        onDelete(e, index);
    };

    const toggleForm = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setValue(`${index}.Open`, !open);
    };

    const showPreviewImage = () => {
        if (loadedImage instanceof File && !previewImageUrl) {
            const url = URL.createObjectURL(loadedImage);
            setPreviewImageUrl(url);
            return url;
        }

        return previewImageUrl;
    };

    useEffect(() => {
        // Make sure to revoke the data uri to avoid memory leaks, will run on unmount
        if (previewImageUrl) {
            return () => URL.revokeObjectURL(previewImageUrl);
        }
    }, [previewImageUrl]);

    return (
        <AwardEditFormStyled data-testid="award-form" error={hasError}>
            <div className="header">
                {awardTitle && (
                    <div className="p3 title">
                        <b>{awardTitle}</b>
                    </div>
                )}
                {!awardTitle && (
                    <div className="p3 title">
                        <b>{t('Forms.Awards.DefaultName')}</b>
                    </div>
                )}
                <div className="buttons">
                    <IconButton
                        id="delete-button"
                        iconName={IconName.TRASH}
                        ariaLabel={t('Forms.Awards.RemoveButtonText')}
                        clickHandler={(e) => deleteAwardForm(e, index)}
                    />
                    <IconButton
                        id="toggle-button"
                        iconName={open ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN}
                        clickHandler={(e) => toggleForm(e)}
                    />
                </div>
            </div>
            <div
                data-testid="form-section"
                className={`form ${open ? 'form-open' : 'form-closed'}`}
            >
                <FormRow
                    columns={[
                        <div key={0} {...register(`${index}.Image`)}>
                            {loadedImage && !(loadedImage instanceof FileList) ? (
                                <div className="image-section">
                                    <img
                                        data-testid={`${index}.Image`}
                                        className="image"
                                        src={loadedImage.url || showPreviewImage()}
                                        alt=""
                                    />
                                    <LinkButton
                                        data-testid="remove-image-button"
                                        className="remove"
                                        startIcon={IconName.TRASH}
                                        onClick={removeImage}
                                    >
                                        {t('Forms.Awards.RemoveButtonText')}
                                    </LinkButton>
                                </div>
                            ) : (
                                <div>
                                    <PearlDropzone
                                        handleUpload={handleUpload}
                                        showFileTypes={true}
                                        maxFiles={1}
                                    />
                                    {imageError && (
                                        <div>
                                            <p className="p2 error">
                                                {t(imageError.text, imageError.props)}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>,
                    ]}
                />
                <FormRow
                    columns={[
                        <div key={0}>
                            <TextInput
                                formFieldName={`${index}.Name`}
                                providedLabel="Forms.Awards.NameLabel"
                            />
                            <PearlError name={`${index}.Name`} />
                        </div>,
                    ]}
                />
                <FormRow
                    columns={[
                        <div key={0}>
                            <TextInput
                                formFieldName={`${index}.Link`}
                                providedLabel="Forms.Awards.LinkLabel"
                            />
                            <PearlError name={`${index}.Link`} />
                        </div>,
                    ]}
                />
            </div>
        </AwardEditFormStyled>
    );
};

export { AwardEditForm };
