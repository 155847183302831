import styled from 'styled-components';

const BasicsEditFormStyled = styled.section`
    .basics-category {
        .mini-container {
            margin-top: 0;
        }
    }

    .visit-pearl-page {
        width: 100%;
    }
`;

export { BasicsEditFormStyled };
