import DividerStyled from './Divider.styled';

const Divider = ({ children }) => {
    return (
        <DividerStyled data-testid="divider">
            <div className="divider" />
            {children && <span className="divider-content">{children}</span>}
            <div className="divider" />
        </DividerStyled>
    );
};

export { Divider };
