import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useStore } from 'stores/Store';

const installTealium = () => {
    const tealiumScript = document.createElement('script');
    tealiumScript.src = 'https://tags.tiqcdn.com/utag/davidsbridal/pearl/prod/utag.js';
    tealiumScript.type = 'text/javascript';
    tealiumScript.async = true;
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(tealiumScript, firstScript);
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
    window.utag_cfg_ovrd.noview = true;
};

const useTealium = () => {
    const view = (data) => {
        if (window.utag) {
            window.utag.view(data);
        }
    };

    const link = (data) => {
        if (window.utag) {
            window.utag.link(data);
        }
    };

    return {
        view,
        link,
    };
};

const TealiumProvider = ({ children }) => {
    const location = useLocation();
    const { authUser, eventUser } = useStore();
    const { view, link } = useTealium();

    const getUserTagProps = () => {
        if (authUser) {
            return {
                customer_id: authUser.Id,
                customer_username: authUser.username,
                customer_email: authUser.email,
            };
        } else if (eventUser) {
            return {
                customer_id: eventUser.Id,
                customer_username: eventUser.Username,
                customer_email: eventUser.Email,
                customer_firstName: eventUser.FirstName,
                customer_lastName: eventUser.LastName,
                customer_role: eventUser.Role,
            };
        }
        return {};
    };

    document.addEventListener(
        'click',
        (e) => {
            const tagValue = e
                .composedPath()
                .filter((t) => t.getAttribute && t.getAttribute('tag'))
                .map((t) => t.getAttribute('tag'))[0];

            if (tagValue) {
                link({ clicked: tagValue, ...getUserTagProps() });
            }
        },
        { capture: true }
    );

    useEffect(() => {
        let pageName = location.pathname;
        if (pageName === '/') {
            pageName = 'home';
        }
        view({ page_name: pageName, ...getUserTagProps() });
    }, [location]);

    return children;
};

const buildWebstoreClickData = (tealiumEventName, webstore, appData) => {
    return {
        event_name: tealiumEventName,
        webstoreID: webstore.id,
        vendor_type: appData.categoryList.find((cat) => cat.id === webstore.categoryId).name,
        vendor_name: webstore.name,
    };
};

const buildWebstoreSetupStepPageData = (stepName, webstore, currentStepCount, totalSteps) => {
    return {
        event_name: 'webstore-setup-step-page-view',
        setup_step_number: currentStepCount,
        setup_step_total_step_count: totalSteps,
        setup_step_name: stepName,
        setup_step_webstore_on_page_load: webstore,
        webstore_category: webstore.VendorCategoryName,
        webstore_subCategory: webstore.VendorSubCategoryName,
        webstore_id: webstore.Id,
    };
};

const buildWebstoreSetupStepNextClickData = (
    stepName,
    webstore,
    currentStepCount,
    totalSteps,
    formData,
    patchData
) => {
    return {
        event_name: 'setup-step-advance-click',
        setup_step_number: currentStepCount,
        setup_step_total_step_count: totalSteps,
        setup_step_name: stepName,
        webstore_category: webstore.VendorCategoryName,
        webstore_subCategory: webstore.VendorSubCategoryName,
        setup_step_form_data: formData,
        setup_step_patch_data: patchData,
        webstore_id: webstore.Id,
    };
};

export {
    useTealium,
    installTealium,
    TealiumProvider,
    buildWebstoreClickData,
    buildWebstoreSetupStepPageData,
    buildWebstoreSetupStepNextClickData,
};
