import { cleanNumber, generateCountryStartsWith } from './PhoneNumberUtils';

const usPhoneNumberFormatter = (() => {
    const usCountryCode = '1';
    const usCountryLength = 11; // length without plus or special chars: '12223334444'
    const usStartsWith = generateCountryStartsWith(usCountryCode);

    const getPhoneNumberParts = (text) => {
        const [, , area, prefix, line] = text.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        const country = '+1';
        return [country, area, prefix, line];
    };

    const belongsToCountry = (text) => {
        for (let item of usStartsWith) {
            if (text.startsWith(item)) {
                return true;
            }
        }

        // for numbers like '12223334444' that don't meet the generated startsWith criteria
        if (text.startsWith(usCountryCode) && text.length === usCountryLength) {
            return true;
        }

        // finally, fallback to false
        return false;
    };

    // modified from: https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
    const formatPhoneNumberForDisplay = (text) => {
        const cleaned = cleanNumber(text);

        try {
            const [country, area, prefix, line] = getPhoneNumberParts(cleaned);

            if (country && area && prefix && line) {
                const formatted = `${country} (${area}) ${prefix}-${line}`;
                return formatted;
            }
        } catch {}

        // fallback to given text
        return text;
    };

    const formatPhoneNumberForSave = (text) => {
        const cleaned = cleanNumber(text);

        try {
            const parts = getPhoneNumberParts(cleaned);
            const [country, area, prefix, line] = parts;

            if (country && area && prefix && line) {
                return parts.join('');
            }
        } catch {}

        // fall back to cleaned/stripped text
        return cleaned;
    };

    return {
        belongsToCountry,
        formatPhoneNumberForDisplay,
        formatPhoneNumberForSave,
    };
})();

export { usPhoneNumberFormatter };
