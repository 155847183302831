const useOnChangeDebounce = (debounceTime) => {
    const debounceChangeHandler = (event, inputRef, onChange) => {
        clearTimeout(inputRef.current);

        inputRef.current = setTimeout(() => {
            try {
                onChange(event.target.value);
            } catch (error) {
                console.error(error);
            }
        }, debounceTime);
    };

    return { debounceChangeHandler };
};

export { useOnChangeDebounce };
