import styled from 'styled-components';

const AmenitiesAndServicesSectionStyled = styled.section`
    display: flex;
    flex-direction: column;
    gap: 24px;

    .toggle-group-helper {
        margin-top: 18px;
    }

    .tag-group-label {
        margin-top: 0px;
        margin-bottom: 24px;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary.base};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

export { AmenitiesAndServicesSectionStyled };
