const url = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;

function loadAutocomplete(stateFunc, fieldRef, options = {}) {
    loadScript(
        () => handleAutocompleteScriptLoad(stateFunc, fieldRef, options),
        options.scriptId || 'autocomplete-script'
    );
}

function loadAutocompleteService(searchValue, callback, options = {}) {
    loadScript(
        () => handleAutocompleteServiceScriptLoad(searchValue, callback),
        options.scriptId || 'autocomplete-service-script'
    );
}

function loadScript(handleOnLoad, scriptId) {
    if (document.getElementById(scriptId)) {
        document.getElementById(scriptId).remove();
    }

    let script = document.createElement('script');
    script.setAttribute('id', scriptId);
    script.type = 'text/javascript';

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                handleOnLoad();
            }
        };
    } else {
        script.onload = () => handleOnLoad();
    }

    script.src = url;

    document.getElementsByTagName('head')[0].appendChild(script);
}

function handleAutocompleteScriptLoad(stateFunc, fieldRef, options = {}) {
    const placesAutocomplete = new window.google.maps.places.Autocomplete(fieldRef, {
        types: options.searchTypes,
        componentRestrictions: { country: 'us' },
    });

    placesAutocomplete.addListener('place_changed', () =>
        handlePlaceSelect(stateFunc, options.focusRef, placesAutocomplete)
    );

    placesAutocomplete.setFields(options.setFields);
}

function handleAutocompleteServiceScriptLoad(searchValue, callback) {
    const placesAutocompleteService = new window.google.maps.places.AutocompleteService();

    placesAutocompleteService.getPlacePredictions(
        {
            input: searchValue,
            componentRestrictions: { country: 'us' },
        },
        callback
    );
}

async function handlePlaceSelect(stateFunc, focusRef, placesAutocomplete) {
    const place = placesAutocomplete.getPlace();
    stateFunc(place);

    if (focusRef) {
        document.querySelector(focusRef).focus();
    }
}

export { loadAutocomplete, loadAutocompleteService };
