import styled from 'styled-components';

const GlobalNavigationStyled = styled.nav`
    align-items: center;
    background-color: ${(props) => props.theme.colors.background.container};
    border-bottom: 1px solid ${(props) => props.theme.colors.foreground.border};
    box-sizing: border-box;

    .mobileOnly {
        display: none;
    }

    .logoContainer {
        background-color: ${(props) => props.theme.colors.background.container};
        border: none;
        width: 80px;
        padding: 0px;
        cursor: pointer;
    }

    .logo {
        display: block;
        height: 22px;
        margin: auto;
    }

    .headerLogo {
        width: 80px;
    }

    &.fixedBottomBar {
        .topBar {
            margin-bottom: 82px;
        }

        .bottomBar {
            position: fixed;
            top: 0;
        }
    }

    .topBar {
        display: inline-flex;
        justify-content: space-between;
        background-color: ${(props) => props.theme.colors.foreground.base};
        width: 100%;

        .ctaSection {
            cursor: pointer;

            .becomeVendorButton {
                background-color: inherit;
                color: ${(props) => props.theme.colors.background.container};
                cursor: inherit;
                border: none;
            }

            label {
                cursor: inherit;
            }
        }

        .ctaSection {
            display: flex;
            justify-content: center;
            border-left: solid 1px ${(props) => props.theme.colors.background.container};
            min-width: fit-content;
            max-width: 20%;

            .icon-champagne-glasses {
                padding-right: 9px;
            }
        }
        .dbLink {
            padding: 10px 0px 10px 25px;
            & > a {
                color: ${(props) => props.theme.colors.background.container};
                text-transform: uppercase;
                font-size: 11px;
                line-height: 18px;
                letter-spacing: 1.65px;
            }
        }
        .companySiteButtons {
            display: inline-flex;
            height: 40px;
            gap: 1px;
            background-color: ${(props) => props.theme.colors.foreground.base};

            .siteButton {
                background-color: ${(props) => props.theme.colors.foreground.base};
                color: transparent;
                display: flex;
                width: 112px;
                justify-content: center;
                align-items: center;
                padding: 9px 0;
            }

            .pearlButton {
                background-color: ${(props) => props.theme.colors.background.container};
            }

            .loyaltyButtonLink {
                padding: 0;
            }

            .loyaltyButtonImg {
                height: 40px;
            }
        }
    }

    .bottomBar {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 82px;
        align-items: center;
        background: ${(props) => props.theme.colors.background.container};
        z-index: 2;

        .leftBottomBar {
            display: inline-flex;
            align-items: center;
            height: 100%;

            .hamburger {
                padding: 0 24px;
            }

            .linksContainer {
                display: flex;
                margin-left: 72px;
                height: 100%;

                @media (max-width: 900px) {
                    margin-left: 16px;
                }

                label {
                    cursor: pointer;
                }

                a {
                    display: flex;
                    align-items: center;
                    padding: 0 16px;
                    cursor: pointer;
                    height: 100%;
                    text-decoration: none;
                    color: ${(props) => props.theme.colors.foreground.text};

                    :visited {
                        color: inherit;
                    }

                    :hover {
                        color: ${(props) => props.theme.colors.primary.base};
                        cursor: pointer;
                    }
                }
            }
        }

        .searchControls {
            display: inline-flex;
            justify-content: center;
            width: 100%;
            margin-left: 16px;
            @media (max-width: 815px) {
                display: none;
            }

            @media (max-width: 767px) {
                display: inline-flex;
            }
        }

        .userControls {
            display: flex;
            align-items: center;
            justify-content: end;
            height: inherit;
            padding-right: 24px;
        }
    }

    //matches breakpoint for global brand bar on https://www.davidsbridal.com/
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        border-bottom: none;

        .mobileOnly {
            display: unset;
        }

        .topBar {
            width: 100vw;

            .companySiteButtons {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                width: 100%;

                .siteButton {
                    width: 100%;
                }
            }

            .ctaSection {
                display: none;
            }
        }

        &.fixedBottomBar {
            .topBar {
                margin-bottom: 0;
            }
        }

        .bottomBar {
            height: auto;
            justify-content: center;

            .leftBottomBar {
                .hamburger {
                    display: none;
                }

                .logoContainer {
                    display: none;
                }

                .linksContainer {
                    display: none;
                }

                .searchControls {
                    margin: 0;
                }
            }

            .userControls {
                display: none;
            }
        }

        .footerBar {
            position: absolute;
            width: 100vw;
            bottom: 0;
            left: 0;
            z-index: 2;

            .icons {
                display: inline-flex;
                justify-content: space-between;
                width: 100%;

                > div {
                    width: 100%;
                }

                button {
                    background-color: ${(props) => props.theme.colors.foreground.base};
                    border: none;
                    width: 100%;
                }
            }
        }
    }
`;

export { GlobalNavigationStyled };
