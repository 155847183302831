import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import PreferredPartnerTile from '../PreferredPartnerTile/PreferredPartnerTile';
import WebstorePreferredPartnersStyled from './WebstorePreferredPartners.styled';
import { useIsScreenLarge } from 'framework/hooks/PearlMediaQuery';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import { webstoreAddressTypes } from '../../../lib/WebstoreAddressService/WebstoreAddressService';
import { moderateImage } from 'framework/lib/ImageModerationService/ImageModerationService';
import { PearlApps } from 'framework/lib/apis/PearlApiClient';

const WebstoreSectionContentSlider = lazy(() =>
    import('../WebstoreSectionContentSlider/WebstoreSectionContentSlider')
);

export default function WebstorePreferredPartners(props) {
    const { webstore, sectionRef, sectionId, onViewVendorClick, iconName, banner } = props;
    const { t } = useTranslation();

    const isScreenLarge = useIsScreenLarge();

    const partners =
        webstore.Partners?.map((partner, index) => {
            let addressToUse = partner.partnerData.addresses?.find(
                (address) => address.addressType === webstoreAddressTypes.ServiceArea
            );

            if (!addressToUse) {
                addressToUse = partner.partnerData.addresses?.find(
                    (address) => address.addressType === webstoreAddressTypes.Store
                );
            }

            // should never see a flagged overlay or flagged image in this component, only placeholders and valid images
            moderateImage(partner.partnerData.coverPhoto, PearlApps.EVENT_USER);

            return (
                <PreferredPartnerTile
                    key={index}
                    coverPhoto={partner.partnerData.coverPhoto}
                    partnerName={partner.partnerData.name}
                    subCategoryName={partner.partnerData.subCategoryNames?.join(', ')}
                    serviceCity={addressToUse?.city || ''}
                    serviceState={addressToUse?.state || ''}
                    description={partner.description}
                    webstoreUrl={partner.partnerData.url}
                    onViewVendorClick={onViewVendorClick}
                />
            );
        }) || [];

    return (
        <WebstorePreferredPartnersStyled ref={sectionRef} id={sectionId}>
            <SectionHeader
                title={t('WebstoreSections.PreferredPartners.Title')}
                iconName={iconName}
                banner={banner}
            />
            <div className="partners-container">
                {!isScreenLarge ? <WebstoreSectionContentSlider slides={partners} /> : partners}
            </div>
        </WebstorePreferredPartnersStyled>
    );
}
