import styled from 'styled-components';

const HamburgerHeaderStyled = styled.div`
    padding: 16px;

    .content {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
    }

    border-bottom: 1px solid ${(props) => props.theme.colors.background.base};
`;

export { HamburgerHeaderStyled };
