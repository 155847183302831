import React, { useState } from 'react';
import Floater from 'react-floater';
import styled from 'styled-components';
import { IconButton, IconName, IconSizes } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';

const Overlay = styled.div`
    background-color: rgb(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
`;

const StyledCloseButton = styled.span`
    display: inline-flex;
    justify-content: right;
    width: 100%;
    button {
        padding: ${(props) => props.closeIconPadding || '0'};

        &:hover {
            background-color: initial !important;
            color: initial !important;
        }
    }
`;

export const PearlModalSizes = {
    SM: {
        WEB: {
            WIDTH: '60vw',
            HEIGHT: '32vw',
        },
        MOBILE: {
            WIDTH: '351px',
            HEIGHT: '351px',
        },
    },
    LG: {
        WIDTH: '80vw',
        HEIGHT: 'auto',
    },
    AUTO: {
        WIDTH: 'auto',
        HEIGHT: 'auto',
    },
    FORMS: {
        CONTACT: {
            WEB: {
                WIDTH: '495px',
                HEIGHT: '650px',
            },
            WEBSMALL: {
                WIDTH: '495px',
                HEIGHT: '250px',
            },
            MOBILE: {
                WIDTH: '80vw',
                HEIGHT: '65vh',
            },
            MOBILESMALL: {
                WIDTH: '80vw',
                HEIGHT: '35vh',
            },
        },
        VENDOR: {
            WEB: {
                WIDTH: '455px',
                HEIGHT: '600px',
            },
            MOBILE: {
                WIDTH: '80vw',
                HEIGHT: '60vh',
            },
        },
    },
};

const StyledModal = styled.div`
    width: 100%;
    height: 100%;

    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;
export const PearlModal = (props) => {
    const { isOpen, content, title, size, showCloseButton, containerPadding, closeIconPadding } =
        props;
    const { closeModal } = usePearlModal();
    const { t } = useTranslation();

    let displayedContent = content;

    if (showCloseButton) {
        displayedContent = (
            <StyledModal>
                <StyledCloseButton closeIconPadding={closeIconPadding}>
                    <IconButton
                        withBorder={false}
                        ariaLabel={t('PearlModalContext.CloseAriaLabel')}
                        variant="secondary"
                        size={IconSizes.SM}
                        iconName={IconName.CLOSE}
                        type="button"
                        clickHandler={closeModal}
                    />
                </StyledCloseButton>
                {content}
            </StyledModal>
        );
    }

    return (
        <div>
            {isOpen && <Overlay onClick={closeModal}></Overlay>}
            <Floater
                open={isOpen}
                content={displayedContent}
                title={title}
                hideArrow={true}
                placement="center"
                withOverlay={false}
                styles={{
                    container: {
                        backgroundColor: '#fff',
                        borderRadius: 3,
                        color: '#fff',
                        filter: 'none',
                        margin: 32,
                        display: isOpen ? 'flex' : 'none',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: size.WIDTH,
                        height: size.HEIGHT,
                        padding: containerPadding || '20px',
                    },
                    floater: {
                        display: 'inline-block',
                        filter: 'drop-shadow(0 0 3px rgba(0, 0, 0, 0.3))',
                        maxWidth: 'auto',
                        opacity: 0,
                        transition: 'opacity 0.6s',
                        visibility: 'hidden',
                        zIndex: 100,
                    },
                    title: { borderBottom: 'none' },
                }}
            ></Floater>
        </div>
    );
};

const PearlModalContext = React.createContext();
PearlModalContext.displayName = 'PearlModalContext';

const usePearlModal = () => {
    const [modalContext, setModalContext] = React.useContext(PearlModalContext);

    const openModal = (
        content,
        size = PearlModalSizes.LG,
        title,
        customCloseHandler,
        showCloseButton = true,
        containerPadding,
        closeIconPadding
    ) => {
        setModalContext({
            content: content,
            size: size,
            title: title,
            isOpen: true,
            customCloseHandler: customCloseHandler,
            showCloseButton: showCloseButton,
            containerPadding: containerPadding || '20px',
            closeIconPadding: closeIconPadding || 0,
        });
    };

    const closeModal = () => {
        modalContext.customCloseHandler && modalContext.customCloseHandler();
        setModalContext({
            content: <div />,
            title: '',
            isOpen: false,
            size: PearlModalSizes.LG,
            customCloseHandler: null,
            showCloseButton: false,
            containerPadding: '20px',
            closeIconPadding: 0,
        });
    };

    return { openModal, closeModal };
};

const PearlModalProvider = ({ children }) => {
    const defaultModalContext = {
        isOpen: false,
        content: '',
        size: PearlModalSizes.LG,
        title: '',
        customCloseHandler: null,
        showCloseButton: true,
        containerPadding: '20px',
        closeIconPadding: 0,
    };

    const [modalContext, setModalContext] = useState(defaultModalContext);
    return (
        <PearlModalContext.Provider value={[modalContext, setModalContext]}>
            <PearlModal {...modalContext} />
            {children}
        </PearlModalContext.Provider>
    );
};

export { PearlModalProvider, usePearlModal };
