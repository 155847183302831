import { Outlet } from 'react-router-dom';
import EventUserSetupWizardStyled from './EventUserSetupWizard.styled';
import { EventUserSetupStepForms } from '../../../constants/EventUserSetupSteps';
import { UserBasicsForm } from '../UserBasics/UserBasicsForm/UserBasicsForm';
import { EventDetailsForm } from '../EventDetails/EventDetailsForm/EventDetailsForm';
import { LocationForm } from '../Location/LocationForm';
import EventDateForm from '../../EventUserSetup/EventDateForm/EventDateForm';
import EventVendorsForm from '../../EventUserSetup/EventVendors/EventVendorsForm';

const EventUserSetupFormsMap = new Map([
    [EventUserSetupStepForms.EVENT_BASICS, <UserBasicsForm />],
    [EventUserSetupStepForms.EVENT_DATE, <EventDateForm />],
    [EventUserSetupStepForms.EVENT_LOCATION, <LocationForm />],
    [EventUserSetupStepForms.EVENT_DETAILS, <EventDetailsForm />],
    [EventUserSetupStepForms.EVENT_VENDORS, <EventVendorsForm />],
]);

function EventUserSetupWizard() {
    return (
        <EventUserSetupWizardStyled data-testid="event-user-setup-wizard-styled">
            <Outlet />
        </EventUserSetupWizardStyled>
    );
}

export { EventUserSetupWizard, EventUserSetupFormsMap };
