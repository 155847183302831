import { HamburgerUserMenuStyled } from './HamburgerUserMenu.styled';
import { useTranslation } from 'react-i18next';
import { SolidButton, LinkButton } from 'dbi-pearl-ui-kit';
import { useStore } from 'stores/Store';
import { useAuthUserContext } from 'contexts/AuthUserContext';
import { openUserChoiceLoginModal } from '../../UserChoiceLogin/UserChoiceLogin';
import { usePearlModal, useIsScreenLarge } from 'framework';
import { AuthType } from '../../../../constants/authType';

const NonAuthedUserMenu = (props) => {
    const { drawerCloser } = props;

    const { t } = useTranslation();
    const { openModal, closeModal } = usePearlModal();
    const isScreenLarge = useIsScreenLarge();

    const loginClickHandler = (authType) => {
        openUserChoiceLoginModal(openModal, closeModal, isScreenLarge, authType);
        drawerCloser();
    };

    return (
        <div className="non-authed-user-menu">
            <div className="user-menu-header">
                <h4>{t('Navigation.HamburgerMenu.YourProfile')}</h4>
                <p>{t('Navigation.HamburgerMenu.YourJourney')}</p>
            </div>
            <SolidButton
                className="sign-up-button"
                onClick={() => loginClickHandler(AuthType.signup)}
            >
                {t('Forms.Buttons.SignupSubmitButton')}
            </SolidButton>
            <div className="login-section-wrapper">
                <p className="p1">
                    {t('Navigation.HamburgerMenu.HaveAnAccount')}
                    <LinkButton
                        className="hamburger-user-menu-login-button"
                        variant="accent"
                        onClick={() => loginClickHandler(AuthType.login)}
                    >
                        {t('Forms.Buttons.LoginButton')}
                    </LinkButton>
                </p>
            </div>
        </div>
    );
};

const VendorGreeting = () => {
    const { vendor } = useStore();
    const { t } = useTranslation();

    return (
        <h3 className="greeting">
            {t('Navigation.HamburgerMenu.HiUser', {
                firstName: vendor?.contactFirstName,
            })}
        </h3>
    );
};

const EventUserGreeting = () => {
    const { t } = useTranslation();
    const { eventUser } = useStore();

    return (
        <h3 className="greeting">
            {t('Navigation.HamburgerMenu.HiUser', {
                firstName: eventUser?.FirstName,
            })}
        </h3>
    );
};

const AuthedUserMenu = () => {
    const { authUser } = useAuthUserContext();

    return (
        <div>
            {authUser.isAuthedVendor && <VendorGreeting />}
            {!authUser.isAuthedVendor && authUser.isAuthedEventUser && <EventUserGreeting />}
        </div>
    );
};

const HamburgerUserMenu = (props) => {
    const { drawerCloser } = props;

    const { authUser } = useAuthUserContext();

    return (
        <HamburgerUserMenuStyled>
            {authUser.isAuthedVendor || authUser.isAuthedEventUser ? (
                <AuthedUserMenu />
            ) : (
                <NonAuthedUserMenu drawerCloser={drawerCloser} />
            )}
        </HamburgerUserMenuStyled>
    );
};

export { HamburgerUserMenu };
