import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const ServiceCharacteristicsSectionStyled = styled.section`
    height: auto;

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .accordion-toggle:checked + .accordion-group-label + .collapsible-content: {
            max-height: 100%;
        }
    }
`;

export { ServiceCharacteristicsSectionStyled };
