import React, { useEffect, useRef, useState } from 'react';
import {
    TextAreaInput,
    IconName,
    IconButton,
    TextInput,
    IconSizes,
    TruncatedTextDisplay,
} from 'dbi-pearl-ui-kit';
import { FormRow, PearlError, useOnChangeDebounce } from 'framework';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { PartnersSectionEditStyled } from './PartnersSectionEdit.styled';
import { useVendorContext } from '../../../../contexts/VendorContext';
import { WebstoreAddressService } from 'framework';

const SearchResult = ({ result, onClick }, index) => {
    const { formatSingleLineAddress } = WebstoreAddressService;
    const resultAddress = formatSingleLineAddress(result, false);

    return (
        <button
            key={index + 'btn'}
            type={'button'}
            className={'result'}
            data-testid={'result-btn'}
            onClick={() => onClick(result)}
        >
            <div className={'info'}>
                <div>
                    <TruncatedTextDisplay maxWidth={'90%'} lineCount={1}>
                        <p className={'p3'}>
                            <b>{result.name}</b>
                        </p>
                    </TruncatedTextDisplay>
                </div>
                <div className={'details'}>
                    <div>{result.subCategoryNames}</div>
                    <div className="separator"></div>
                    <div>{resultAddress}</div>
                </div>
            </div>
        </button>
    );
};

const SelectedResult = ({ selectedResult, clickHandler }, index) => {
    const { formatSingleLineAddress } = WebstoreAddressService;
    const subCategoryNames = (
        selectedResult.subCategoryNames ||
        selectedResult.partnerData?.subCategoryNames ||
        []
    ).join(', ');

    let serviceAddress = selectedResult?.partnerData?.addresses ? selectedResult.partnerData : [];

    serviceAddress = formatSingleLineAddress(selectedResult, false);

    return (
        <div
            data-testid="selectedResult"
            key={index + 'test'}
            className={'selectedResultsContainer'}
        >
            <div className={'result'}>
                <div className="h3 resultTitle">
                    <TruncatedTextDisplay lineCount={2} maxWidth={'90%'}>
                        <b>{selectedResult.name || selectedResult.partnerData?.name}</b>
                    </TruncatedTextDisplay>
                    <div className="buttons">
                        <IconButton
                            iconName={IconName.CLOSE}
                            size={IconSizes.XS}
                            clickHandler={(e) => clickHandler(e)}
                            ariaLabel={'Reset Bestie'}
                        />
                    </div>
                </div>

                <div className={'p3 details'}>
                    {!!subCategoryNames && <div>{subCategoryNames}</div>}
                    {!!serviceAddress && (
                        <>
                            <div className="separator"></div>
                            <div>{serviceAddress}</div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const Search = ({ partnerWebstore, removeWebstore, onInputChange }) => {
    const searching = !(
        partnerWebstore &&
        (partnerWebstore?.name || partnerWebstore.partnerData?.name)
    );

    return (
        <div>
            {searching && <TextInput onChange={onInputChange} formFieldName={'SearchParam'} />}
            {!searching && (
                <SelectedResult
                    selectedResult={partnerWebstore}
                    clickHandler={(e) => removeWebstore(e)}
                />
            )}
        </div>
    );
};

const PartnersSectionEditForm = (props) => {
    const { result, hasError, onDelete, webstoreApi, onFieldChange } = props;
    const { t } = useTranslation();
    const { setValue } = useFormContext();
    const [open, setOpen] = useState(true);
    const [results, setResults] = useState(null);
    const [partner, setPartner] = useState(result);
    const ref = useRef(null);
    const { debounceChangeHandler } = useOnChangeDebounce(300);
    const { getCategoryByCategoryId, getSubCategoryBySubCategoryId } = useVendorContext();

    const toggleForm = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpen(!open);
    };

    const onInputChange = (event) => {
        if (event.target.value) {
            debounceChangeHandler(event, ref, (searchTerm) => {
                if (!!searchTerm) {
                    webstoreApi.getWebstoresByName(searchTerm).then((res) => {
                        if (Array.isArray(res) && res.length) {
                            res?.forEach((r) => {
                                r.categoryName = getCategoryByCategoryId(r.categoryId)?.name;
                                r.subCategoryNames = r.subCategoryIds?.map(
                                    (x) => getSubCategoryBySubCategoryId(r.categoryId, x)?.name
                                );
                            });

                            setResults(res);
                        } else setResults(null);
                    });
                }
            });
        } else {
            setResults(null);
        }
    };

    const onClick = (selectedResult) => {
        setPartner(selectedResult);
        setResults(null);
        result.partnerId = selectedResult.id;
        result.partnerData = selectedResult;
        setValue('SearchParam', '');
        setValue(`${result.ordinal}.partnerId`, result.partnerId);
        setValue(`${result.ordinal}.partnerData`, result.partnerData);
    };

    const onRemoveSelectedVendor = () => {
        result.partnerId = null;
        result.partnerData = null;
        setPartner(null);
        setValue(`${result.ordinal}.description`, '');
    };

    useEffect(() => {
        setValue(result.ordinal.toString(), partner);
    }, []);

    return (
        <PartnersSectionEditStyled data-testid={result.ordinal + '.partner-form'} error={hasError}>
            <div className="header">
                <p className="p3 title" data-testid="partner-title">
                    {partner &&
                        (partner.partnerId
                            ? partner.name || partner.partnerData?.name
                            : t('Forms.Partners.Title'))}
                </p>
                <div className="buttons">
                    <IconButton
                        iconName={IconName.TRASH}
                        clickHandler={(e) => onDelete(e, partner.partnerId)}
                        id="delete-button"
                        data-testid={'delete-button'}
                        ariaLabel={'Delete Bestie'}
                    />
                    <IconButton
                        iconName={open ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN}
                        clickHandler={(e) => toggleForm(e)}
                        id="toggle-button"
                    />
                </div>
            </div>
            <div
                className={`form ${open ? 'form-open' : 'form-closed'}`}
                data-testid="form-section"
            >
                <FormRow
                    columns={[
                        <div key={0 + 'formRowPartner'}>
                            <Search
                                partnerWebstore={partner}
                                removeWebstore={(e) => onRemoveSelectedVendor(e)}
                                onInputChange={onInputChange}
                            />
                            {results?.length && (
                                <div className="search-results">
                                    {results.map((result, index) => {
                                        return (
                                            <SearchResult
                                                key={index}
                                                result={result}
                                                onClick={onClick}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </div>,
                    ]}
                />
                <FormRow
                    columns={[
                        <div key={0 + 'formRowPartner'}>
                            <TextAreaInput
                                formFieldName={`${result.ordinal}.description`}
                                label={`${t('Forms.Partners.DescriptionLabel')}*`}
                                placeholder={t('Forms.Partners.DescriptionLabel')}
                                maxLength={240}
                                onChange={(e) =>
                                    onFieldChange(result.ordinal, e.currentTarget.value)
                                }
                            />
                            <PearlError name={`${result.ordinal}.description`} />
                        </div>,
                    ]}
                />
            </div>
        </PartnersSectionEditStyled>
    );
};

export { PartnersSectionEditForm };
