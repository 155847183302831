import React from 'react';
import PropTypes from 'prop-types';
import { TruncatedTextDisplay } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import WebstoreFooterStyled from './WebstoreFooter.styled';

function WebstoreFooter(props) {
    const { webstore, controls, hideBasics } = props;

    const { t } = useTranslation();

    const onlineOnlyWebstore = (webstore) => {
        return !webstore.PhysicalStore && !webstore.Travels && webstore.OnlineStore;
    };

    return (
        <WebstoreFooterStyled>
            <div className="footer-content" data-testid="footer-content">
                {hideBasics ? (
                    <div data-testid="no-basics" />
                ) : (
                    <div className="webstore-basics" data-testid="webstore-basics">
                        <TruncatedTextDisplay maxWidth={'500px'} lineCount={1} textAlign={'left'}>
                            <h3>{webstore.WebstoreName}</h3>
                        </TruncatedTextDisplay>
                        <div className="subtitle">
                            {!!onlineOnlyWebstore(webstore) && (
                                <p className="p2" data-testid="online-only">
                                    {t('WebstoreSections.About.OnlineOnly')}
                                </p>
                            )}
                            {!!webstore.ServiceAddressCity && (
                                <p className="p2" data-testid="service-area">
                                    {webstore.ServiceAddressCity}, {webstore.ServiceAddressState}
                                </p>
                            )}
                        </div>
                    </div>
                )}
                {!!controls && (
                    <div className="footer-controls" data-testid="footer-controls">
                        {controls}
                    </div>
                )}
            </div>
        </WebstoreFooterStyled>
    );
}

WebstoreFooter.propTypes = {
    /**
     * Webstore object - placeholder
     */
    webstore: PropTypes.object,
};

WebstoreFooter.displayName = 'WebstoreFooter';

export default WebstoreFooter;
