import styled from 'styled-components';

const ImageStyled = styled.div`
    .webstoreImage {
        width: 100%;
        height: 100%;

        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;

        .grid {
            cursor: pointer;
            width: 100%;
            height: 100%;

            .cover-photo {
                color: white;
                padding: 11px;
                background-color: ${(props) => props.theme.colors.error.base};
                width: 127px;
                text-align: center;
            }

            .caption {
                position: absolute;
                bottom: 0;
                line-height: 1.5em;
                background-color: #eeeeeecc;
                transition: transform 0.5s;
                transform: scaleY(0);
                transform-origin: bottom;
                border-radius: 10px 10px 0 0;

                p {
                    padding: 0 10px;
                }
            }
        }

        .grid:hover {
            .caption {
                transform: scaleY(1);
            }
        }
    }
`;

export { ImageStyled };
