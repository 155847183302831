import { AmenitiesAndCharacteristics, EmptyCaptions } from 'assets/Defaults';
import PearlForm from 'vendor/components/forms/PearlForm';
import { CapacitySection } from 'vendor/components/Webstore/CapacitySection/CapacitySection';
import { vendorCapacityFormSchema } from 'vendor/constants/schema/VendorCapacityFormSchema';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    AccountSetupStepLayout,
    AccountSetupStepGraphic,
    AccountSetupStepFooter,
    categoryExternalIds,
} from 'framework';
import { VendorCapacityFormStyled } from './VendorCapacityForm.styled';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useWebstoreSetupWizard } from 'vendor/components/WebstoreSetup/WebstoreSetupContext';
import {
    useTealium,
    buildWebstoreSetupStepPageData,
    buildWebstoreSetupStepNextClickData,
} from '../../../../Tealium';
import { WebstoreSetupHeader } from '../WebstoreSetupHeader';

function CapacityFooter() {
    const { onBack, progress, onSkip, onSubmit } = useWebstoreSetupWizard();

    const {
        watch,
        formState: { isDirty },
    } = useFormContext();

    const currentValue = watch();
    const isSkippable = !currentValue.Capacity;
    const shouldPatch = isSkippable && isDirty;

    const functionToPatch = () => onSubmit(currentValue);
    const functionToSkip = () => onSkip();

    return (
        <AccountSetupStepFooter
            handleOnBack={() => onBack()}
            handleOnSkip={isSkippable ? (shouldPatch ? functionToPatch : functionToSkip) : null}
            progress={progress}
        />
    );
}

const VendorCapacityForm = () => {
    const { link, view } = useTealium();
    const { webstore } = useWebstoreContext();
    const { onSubmit, onReturnToBusinessLocations, totalSteps, currentStepCount } =
        useWebstoreSetupWizard();
    const { t } = useTranslation();

    const formName =
        webstore.VendorCategoryExternalId === categoryExternalIds.venues
            ? 'VenueCapacity'
            : 'LodgingCapacity';

    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );

        view(tealiumData);
    }, []);

    const formOptions = {
        defaultValues: {
            Capacity: webstore.Capacity,
        },
    };

    const videoDetails = {
        image: AmenitiesAndCharacteristics,
        altText: `Forms.${formName}.GifAltText`,
        captions: EmptyCaptions,
    };

    const handleSubmit = (data) => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepNextClickData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps,
            data,
            data
        );

        link(tealiumData);

        onSubmit(data);
    };

    return (
        <PearlForm
            StyledForm={VendorCapacityFormStyled}
            formName={formName}
            formOptions={formOptions}
            validationSchema={vendorCapacityFormSchema}
            onSubmit={handleSubmit}
        >
            <AccountSetupStepLayout
                header={
                    <WebstoreSetupHeader
                        onReturnToBusinessLocations={onReturnToBusinessLocations}
                        stepName={formName}
                        currentStepCount={currentStepCount}
                        totalSteps={totalSteps}
                    />
                }
                formComponent={<CapacitySection />}
                image={<AccountSetupStepGraphic {...videoDetails} />}
                footer={<CapacityFooter />}
            />
        </PearlForm>
    );
};

export { VendorCapacityForm };
