import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

export default styled.div`
    background-color: ${(props) => props.theme.colors.background.container};
    position: relative;
    margin: 0 auto;
    padding: 30px 0;
    border-bottom: ${(props) => (props.divider ? '2px solid' : 'none')};
    border-bottom-color: ${(props) => props.theme.colors.background.base};

    ${(props) =>
        props.showSectionError &&
        `border: 1px solid ${props.theme.colors.error.base}; margin-bottom: 16px;`}

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        margin: 0 16px;
        padding: 15px 0;
        ${(props) => props.showSectionError && `margin-bottom: 16px;`}
    }

    ${(props) => props.theme.pearlBreaks.up(BreakpointSizes.MD)} {
        width: 80%;
        min-width: 740px;
    }

    .section-controls {
        position: absolute;
        right: 15px;
        top: 60px;

        @media (max-width: 815px) {
            top: 16px;
            left: 0;
        }
    }

    @media (min-width: 1400px) {
        width: 60%;
        min-width: 1130px;
    }
`;
