import styled from 'styled-components';

const BusinessSummarySectionStyled = styled.div`
    .BusinessSummary-container {
        height: 177px;
        margin-top: 24px;
    }
`;

export { BusinessSummarySectionStyled };
