import { useTranslation } from 'react-i18next';
import { useEventContext } from '../../../contexts/EventContext';
import { FormRow, PearlError, PriceTierTile } from 'framework';
import { Checkbox, IconName, TextInput } from 'dbi-pearl-ui-kit';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import EventDetailsStyled from './EventDetails.styled';
import { budgetTiers } from '../../../constants/accountCreationOptions';

const GUEST_COUNT_FIELD_NAME = 'GuestCount';
const GUEST_COUNT_KNOWN_FIELD_NAME = 'GuestCountKnown';
const BUDGET_TIER_FIELD_NAME = 'BudgetTier';
const BUDGET_TIER_KNOWN_FIELD_NAME = 'BudgetTierKnown';

const EventDetails = () => {
    const {
        register,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useFormContext();
    const { t } = useTranslation();
    const { event } = useEventContext();

    register(GUEST_COUNT_KNOWN_FIELD_NAME);
    register(BUDGET_TIER_KNOWN_FIELD_NAME);

    const [selectedBudgetTier, setSelectedBudgetTier] = useState(event?.EventDetails?.BudgetTier);
    const [unknownGuestCount, setUnknownGuestCount] = useState(() => {
        if (
            event?.EventDetails?.GuestCountKnown !== null &&
            !event?.EventDetails?.GuestCountKnown
        ) {
            setValue(GUEST_COUNT_KNOWN_FIELD_NAME, false);
            return true;
        }
        if (event?.EventDetails?.GuestCountKnown !== null && event?.EventDetails?.GuestCountKnown) {
            setValue(GUEST_COUNT_KNOWN_FIELD_NAME, true);
            return false;
        }

        setValue(GUEST_COUNT_KNOWN_FIELD_NAME, null);
        return false;
    });
    const [unknownBudgetTier, setUnknownBudgetTier] = useState(() => {
        if (
            event?.EventDetails?.BudgetTierKnown !== null &&
            !event?.EventDetails?.BudgetTierKnown
        ) {
            setSelectedBudgetTier(event.EventDetails.BudgetTier);
            setValue(BUDGET_TIER_KNOWN_FIELD_NAME, false);
            return true;
        }
        if (event?.EventDetails?.BudgetTierKnown !== null && event?.EventDetails?.BudgetTierKnown) {
            setValue(BUDGET_TIER_KNOWN_FIELD_NAME, true);
            return false;
        }

        setValue(BUDGET_TIER_KNOWN_FIELD_NAME, null);
        return false;
    });

    const guestCountField = watch(GUEST_COUNT_FIELD_NAME);

    useEffect(() => {
        if (getValues(GUEST_COUNT_FIELD_NAME) && !errors[GUEST_COUNT_FIELD_NAME]) {
            setValue(GUEST_COUNT_KNOWN_FIELD_NAME, true);
        }
    }, [guestCountField]);

    const handleUnknownBudgetTier = () => {
        setSelectedBudgetTier(null);
        setValue(BUDGET_TIER_FIELD_NAME, null);
        setValue(BUDGET_TIER_KNOWN_FIELD_NAME, !!unknownBudgetTier);
        setUnknownBudgetTier(!unknownBudgetTier);
    };

    const handleUnknownGuestCountSelect = () => {
        setValue(GUEST_COUNT_FIELD_NAME, null);
        setValue(GUEST_COUNT_KNOWN_FIELD_NAME, !!unknownGuestCount);
        setUnknownGuestCount(!unknownGuestCount);
    };

    const handleBudgetTierSelect = (event) => {
        if (event.target.value === selectedBudgetTier) {
            setSelectedBudgetTier(null);
            setValue(BUDGET_TIER_KNOWN_FIELD_NAME, false);
            setValue(BUDGET_TIER_FIELD_NAME, null);
        } else {
            setSelectedBudgetTier(event.target.value);
            setUnknownBudgetTier(false);
            setValue(BUDGET_TIER_KNOWN_FIELD_NAME, true);
        }
    };

    return (
        <EventDetailsStyled>
            <FormRow
                columns={[
                    <div key={0}>
                        <TextInput
                            formFieldName={GUEST_COUNT_FIELD_NAME}
                            startIcon={IconName.USER}
                            disabled={unknownGuestCount}
                        />
                        <PearlError name={GUEST_COUNT_FIELD_NAME} />
                    </div>,
                ]}
            />
            <div
                data-testid="unknown-guest-checkbox"
                className="checkBoxWrapper unknownGuestCheckbox"
            >
                <Checkbox
                    name={GUEST_COUNT_KNOWN_FIELD_NAME}
                    label={t('Forms.EventDetails.NotSureLabel')}
                    labelClassName="checkBoxLabel p2"
                    onChange={handleUnknownGuestCountSelect}
                    checked={unknownGuestCount}
                />
            </div>
            <PearlError name={GUEST_COUNT_KNOWN_FIELD_NAME} />
            <p className="budgetQuestion">{t('Forms.EventDetails.BudgetQuestion')}</p>
            <div className="price-tier-wrapper">
                <div className="boxes">
                    {budgetTiers.map((tier) => {
                        return (
                            <PriceTierTile
                                formFieldName={BUDGET_TIER_FIELD_NAME}
                                key={tier.id}
                                titleText={t(tier.title)}
                                bodyText={t(tier.body)}
                                monetarySymbol={t(tier.monetarySymbol)}
                                tierId={tier.id}
                                tierValue={tier.value}
                                disabled={unknownBudgetTier}
                                onClick={handleBudgetTierSelect}
                                isChecked={
                                    selectedBudgetTier === tier.value.toString() ? true : false
                                }
                            />
                        );
                    })}
                </div>
            </div>
            <div
                data-testid="unknown-budget-tier-checkbox"
                className="checkBoxWrapper unknownBudgetCheckbox"
            >
                <Checkbox
                    name={BUDGET_TIER_KNOWN_FIELD_NAME}
                    label={t('Forms.EventDetails.NotSureLabel')}
                    labelClassName="checkBoxLabel p2"
                    onChange={handleUnknownBudgetTier}
                    checked={unknownBudgetTier}
                    value={unknownBudgetTier}
                />
                <PearlError name={BUDGET_TIER_KNOWN_FIELD_NAME} />
            </div>
        </EventDetailsStyled>
    );
};

export { EventDetails };
