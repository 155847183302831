import styled from 'styled-components';

const EditFaqsPageStyled = styled.form`
    display: flex;
    flex-flow: column;

    .error {
        color: ${(props) => props.theme.colors.error.base};
        margin-bottom: 16px;
    }
`;

export { EditFaqsPageStyled };
