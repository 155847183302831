import { useIsScreenLarge, useIsScreenMedium } from '../../../hooks/PearlMediaQuery';
import React from 'react';

const elements = new Map([
    ['h1', ({ children, ...rest }) => <h1 {...rest}>{children}</h1>],
    ['h2', ({ children, ...rest }) => <h2 {...rest}>{children}</h2>],
    ['h3', ({ children, ...rest }) => <h3 {...rest}>{children}</h3>],
    ['h4', ({ children, ...rest }) => <h4 {...rest}>{children}</h4>],
    ['h5', ({ children, ...rest }) => <h5 {...rest}>{children}</h5>],
    ['h6', ({ children, ...rest }) => <h6 {...rest}>{children}</h6>],
    [
        'p1',
        ({ children, ...rest }) => (
            <p {...rest} className={`p1 ${rest?.className || ''}`}>
                {children}
            </p>
        ),
    ],
    [
        'p2',
        ({ children, ...rest }) => (
            <p {...rest} className={`p2 ${rest?.className || ''}`}>
                {children}
            </p>
        ),
    ],
    [
        'p3',
        ({ children, ...rest }) => (
            <p {...rest} className={`p3 ${rest?.className || ''}`}>
                {children}
            </p>
        ),
    ],
    [
        'p4',
        ({ children, ...rest }) => (
            <p {...rest} className={`p4 ${rest?.className || ''}`}>
                {children}
            </p>
        ),
    ],
]);

const ResponsiveTextElement = ({ large, small, children, medium, elementType, ...rest }) => {
    const isScreenLarge = useIsScreenLarge();
    const isScreenMedium = useIsScreenMedium();

    let Comp;

    if (isScreenLarge) {
        Comp = large ? elements.get(large) : medium ? elements.get(medium) : elements.get(small);
    } else if (isScreenMedium) {
        Comp = medium ? elements.get(medium) : elements.get(small);
    } else {
        Comp = elements.get(small);
    }

    return <Comp {...rest}>{children}</Comp>;
};

export { ResponsiveTextElement };
