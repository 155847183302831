import { PearlApiClient } from 'framework';
import { getAppName } from 'shared/hooks/AppName';

export const SystemService = () => {
    const appName = getAppName();
    const api = PearlApiClient(appName);

    const getWhoAmI = async () => {
        const res = await api.get('system/WhoAmI');
        if (res?.ok) {
            return res.data;
        }
    };

    const getWhereAmI = async () => {
        const res = await api.get('system/WhereAmI');
        if (res?.ok) {
            return res.data;
        }
    };

    const getFeatureToggles = async () => {
        const res = await api.get('system/featureToggles');
        if (res?.ok) {
            return res.data;
        }
    };

    return {
        getWhereAmI,
        getWhoAmI,
        getFeatureToggles,
    };
};
