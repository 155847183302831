import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const UpgradeSubscriptionStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: ${(props) => props.theme.colors.background.base};
    padding: 25px;

    .upgradeText {
        color: ${(props) => props.theme.colors.foreground.secondary_text};
        width: 70%;
    }

    & > p.p1 {
        align-self: center;
        margin: 0;
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        flex-direction: column;
        gap: 12px;

        .upgradeText {
            width: 100%;
        }

        & > p.p1 {
            text-align: center;
            width: 100%;
        }
    }
`;

export { UpgradeSubscriptionStyled };
