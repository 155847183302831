import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const ServiceTypeOptionsStyled = styled.div`
    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .store-options {
            flex-wrap: wrap;
        }

        .option-container {
            input {
                width: unset;
            }
        }
    }
`;

export { ServiceTypeOptionsStyled };
