import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { APP_ROUTES } from '../shared/constants/navigation';
import { EditableSections } from '../vendor/components/Webstore/WebstoreSectionFactories';
import { RouteGuard } from 'framework';
import { RequireAdmin } from './RequireAdmin';
import { AdminLoginPage } from '../vendor/pages/Admin/AdminLoginPage';
import { AdminWrapper } from '../vendor/pages/Admin/AdminWrapper';
import { AdminStoreProvider } from '../vendor/pages/Admin/AdminStoreProvider';
import { AdminAuthErrorPage } from '../vendor/pages/Admin/AdminAuthErrorPage';
const AdminWebstore = lazy(() => import('../vendor/pages/Admin/Webstore/AdminWebstore'));
const SectionEditor = lazy(() => import('../vendor/components/Webstore/SectionEdit/SectionEditor'));

const PearlAdminRoutes = ({ store }) => {
    return (
        <Route path={APP_ROUTES.admin.root} element={<AdminWrapper />}>
            <Route path={APP_ROUTES.admin.adminLoginPage} element={<AdminLoginPage />} />
            <Route
                path={APP_ROUTES.admin.webstore.root}
                element={
                    <RouteGuard guards={[RequireAdmin]} context={{ store }}>
                        <AdminStoreProvider />
                    </RouteGuard>
                }
            >
                <Route path={APP_ROUTES.admin.webstore.byId} element={<AdminWebstore />} />
                <Route element={<SectionEditor />}>
                    {[...EditableSections.keys()].map((key) => (
                        <Route
                            key={key}
                            path={`${APP_ROUTES.admin.webstore.byId}/edit${key}`}
                            element={EditableSections.get(key)}
                        />
                    ))}
                </Route>
            </Route>
            <Route path={APP_ROUTES.admin.authError} element={<AdminAuthErrorPage />} />
        </Route>
    );
};

export default PearlAdminRoutes;
