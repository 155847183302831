import { APP_ROUTES } from 'shared/constants/navigation';

const RequireVendorRole = (context) => {
    if (!context.store.isAuthedVendor) {
        return APP_ROUTES.vendor.auth.welcome;
    }
    return 'next';
};

export default RequireVendorRole;
