import React from 'react';

const protocolRegex = new RegExp(`^http(s?)://`);

const ensureExternalLink = (link) => {
    const prefix = protocolRegex.test(link) ? '' : '//';
    return `${prefix}${link}`;
};

const NewTabLink = ({ link, children, target, rel, ...rest }) => (
    <a href={link} target={target} rel={rel} {...rest}>
        {children}
    </a>
);

NewTabLink.defaultProps = {
    target: '_blank',
    rel: 'noreferrer',
};

export { NewTabLink, ensureExternalLink };
