import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import VendorStore from './VendorStore';
import WebstoreStore from './WebstoreStore';
import EventUserStore from './EventUserStore';
import EventStore from './EventStore';
import AuthUserStore from './AuthUserStore';
import {
    loadVendorReaction,
    loadWebstoreReaction,
    mapCategoryIdToName,
    mapSubCategoryIdToName,
    updateEventUserWithAuthUserReaction,
    updateVendorWithAuthUserReaction,
    mapCategoryIdToExternalId,
} from './Reactions';
import AppDataStore from './AppDataStore';
import { loadEventReaction, loadEventUserReaction } from './Reactions';
import { SiteLoader } from '../shared/components/SiteLoader/SiteLoader';

export default class Store {
    // for IDEs / intellisense
    /**
     * @type {AppDataStore}
     */
    appData;
    /**
     * @type {VendorStore}
     */
    vendor;
    /**
     * @type {WebstoreStore}
     */
    webstore;
    /**
     * @type {EventUserStore}
     */
    eventUser;
    /**
     * @type {EventStore}
     */
    event;
    /**
     * @type {AuthUserStore}
     */
    authUser;

    constructor(options) {
        this.clearStores = this.clearStores.bind(this);

        this.appData = new AppDataStore(options);
        this.authUser = new AuthUserStore(options);
        this.vendor = new VendorStore(options);
        this.webstore = new WebstoreStore(options);
        this.eventUser = new EventUserStore(options);
        this.event = new EventStore(options);

        // default true for now,
        // could add explicit defaults to all stores as needed
        if (options?.useInitializers !== false) {
            this.event.Reaction = loadEventReaction(this.eventUser, this.event);

            this.eventUser.Reaction = loadEventUserReaction(this.authUser, this.eventUser);

            this.vendor.Reaction = loadVendorReaction(this.authUser, this.vendor);

            this.webstore.Reaction = loadWebstoreReaction(
                this.authUser,
                this.vendor,
                this.webstore
            );

            updateVendorWithAuthUserReaction(this.authUser, this.vendor);
            updateEventUserWithAuthUserReaction(this.authUser, this.eventUser);
        }

        mapCategoryIdToName(this.webstore, this.appData);
        mapSubCategoryIdToName(this.webstore, this.appData);
        mapCategoryIdToExternalId(this.webstore, this.appData);
    }

    get isAuthed() {
        return !!this.authUser.Username;
    }

    get isAuthedEventUser() {
        return this.authUser.isAuthedEventUser;
    }

    get isAuthedVendor() {
        return this.authUser.isAuthedVendor;
    }

    get loading() {
        return (
            this.appData.Loading ||
            this.vendor.Loading ||
            this.webstore.Loading ||
            this.eventUser.Loading ||
            this.event.Loading ||
            this.authUser.Loading
        );
    }

    clearStores() {
        this.authUser.clear();
        this.event.clear();
        this.eventUser.clear();
        this.webstore.clear();
        this.vendor.clear();
    }
}

const StoreContext = React.createContext();
StoreContext.displayName = 'StoreContext';

export const StoreProvider = observer(({ children, store }) => {
    // don't use this pattern here
    // this was causing every child to be born, destroyed, and rebirthed anew
    // need to only render the provider once,
    // but can at least wait for initialization to resolve before rendering children
    // if (store.loading) {
    //     return <SiteLoader />;
    // } else {
    //     return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    // }

    const [isInitialized, setIsInitialized] = useState(false);
    const [, setRerenderMe] = useState({});

    const rerender = useCallback(() => {
        setRerenderMe({});
    }, []);

    useEffect(() => {
        const work = async () => {
            await store.appData.Initialized;
            await store.authUser.Initialized;

            if (store.authUser.Id) {
                await store.vendor.Reaction;
                await store.eventUser.Reaction;
                await store.event.Reaction;
            }

            if (store.vendor.id) {
                await store.webstore.Reaction;
            }

            setIsInitialized(true);
        };

        work();
    }, []);

    return (
        <StoreContext.Provider value={{ ...store, rerender }}>
            {!isInitialized ? <SiteLoader /> : children}
        </StoreContext.Provider>
    );
});

export const useStore = () => React.useContext(StoreContext);
