import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButtonGroup, ToggleButton, IconName } from 'dbi-pearl-ui-kit';
import { FormRow, PearlError } from 'framework';
import { formFieldNames } from '../Functions/ServiceAreaForm.Functions';
import { OnlineOptionsStyled } from './OnlineOptions.styled';

const OnlineOptions = (props) => {
    const { webstore, mapFunctions } = props;

    const { t } = useTranslation();
    const { setMapZoom } = mapFunctions || {};

    return (
        <OnlineOptionsStyled>
            <p className="option-question">
                <b> {t('Forms.ServiceArea.ShippingSelection')}</b>
            </p>{' '}
            <FormRow
                columns={[
                    <div key={0}>
                        <ToggleButtonGroup
                            groupName={formFieldNames.ShippingRange}
                            exclusive={true}
                            selectedGroupItems={[webstore.ShippingRange]}
                            onChange={() => {}}
                        >
                            <ToggleButton
                                startIcon={IconName.AIRPLANE}
                                value={t('Forms.ServiceArea.National')}
                                label={t('Forms.ServiceArea.National')}
                                iconName={IconName.CHECKMARK}
                                onClick={() => {
                                    mapFunctions && setMapZoom(4);
                                }}
                                data-testid="ship-nationally"
                            />
                            <ToggleButton
                                startIcon={IconName.WEB}
                                value={t('Forms.ServiceArea.Global')}
                                label={t('Forms.ServiceArea.Global')}
                                iconName={IconName.CHECKMARK}
                                onClick={() => {
                                    mapFunctions && setMapZoom(2);
                                }}
                                data-testid="ship-globally"
                            />
                        </ToggleButtonGroup>
                        <PearlError name={formFieldNames.ShippingRange}></PearlError>
                    </div>,
                ]}
            />
        </OnlineOptionsStyled>
    );
};

export { OnlineOptions };
