import styled from 'styled-components';

const WebstoreGalleryEditStyled = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    scrollbar-width: none;
    user-select: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .dropTarget {
        border-left: ${(props) => `dashed 1px ${props.theme.colors.primary.base}`};
    }

    .header {
        margin: 16px 0 16px 0;
    }

    .subtitle {
        margin: 0 0 58px 0;
    }

    .addLabel {
        color: ${(props) => props.theme.colors.primary.base};
    }

    .p2 {
        margin: 8px 0 0;
    }

    .error {
        color: ${(props) => props.theme.colors.error.base};
    }

    .imageContainer {
        display: flex;
        margin: 24px 0 0;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 24px;
    }

    .dropPlaceholder {
        background: ${(props) => props.theme.colors.background.container};
        box-sizing: border-box;
        height: 240px;
        width: 240px;
    }

    .preview:hover {
        cursor: pointer;
    }

    .preview {
        display: inline-flex;
        border: 1px solid ${(props) => props.theme.colors.foreground.border};
        width: 240px;
        height: 240px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;

        .loader {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -48px;
            right: -48px;
            bottom: -48px;
            left: -48px;
            background-color: ${(props) => props.theme.colors.background.base};
            opacity: 0.5;
            animation: rotation 2s infinite linear;
        }

        .delete {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            padding: 8px;
            margin: 8px;
            border-radius: 50%;
            box-sizing: border-box;
            border: none;
            background-color: ${(props) => props.theme.colors.background.container};
            cursor: pointer;
            height: 40px;
            width: 40px;
            color: ${(props) => props.theme.colors.error.base};
        }

        img {
            display: block;
            width: auto;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .legalInfo {
        font-size: 10px;
        line-height: 15px;
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }

    .validation-failed {
        filter: blur(0.25rem);
    }

    @media (max-width: 390px) {
        .dropPlaceholder {
            width: 150px !important;
            height: 150px !important;
        }

        .preview {
            width: 150px !important;
            height: 150px !important;
        }
    }

    @media (min-width: 391px) and (max-width: 815px) {
        .dropPlaceholder {
            width: 210px !important;
            height: 210px !important;
        }

        .preview {
            width: 210px !important;
            height: 210px !important;
        }
    }
`;

export { WebstoreGalleryEditStyled };
