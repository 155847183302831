import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

export default styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 0;

    .option {
        display: flex;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
    }

    .option-image {
        background: ${(props) => props.theme.colors.background.base};
        height: 50px;
        width: 50px;

        .image-wrapper {
            height: 24px;
            width: 24px;
            margin: 13px;
        }
    }

    .option-label {
        margin: auto 0 auto 8px;
        color: ${(props) => props.theme.colors.foreground.base};
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .option {
            width: 100%;
        }

        .option-label {
            text-align: left;
            width: 100%;
        }
    }
`;
