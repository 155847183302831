import styled from 'styled-components';

export default styled.div`
    margin-bottom: 30px;

    .checkBoxWrapper label {
        padding-left: 1px;
        height: 0;
    }

    .checkBoxLabel {
        color: ${(props) => props.theme.colors.foreground.text};
        padding-top: 5px;
    }

    .check {
        color: ${(props) => props.theme.colors.primary.contrast};
    }

    .unknownGuestCheckbox {
        padding-top: 16px;
    }

    .unknownBudgetCheckbox {
        margin-top: 0;
    }

    .budgetQuestion {
        margin-block: 0;
        padding-top: 32px;
        padding-bottom: 16px;
    }

    .price-tier-wrapper {
        height: 100%;
    }
`;
