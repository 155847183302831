import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountSetupStepLayout, AccountSetupStepGraphic, AccountSetupStepFooter } from 'framework';
import { vendorBusinessAddressFormSchema } from 'vendor/constants/schema/VendorBusinessAddressFormSchema';
import { VendorBusinessAddressFormStyled } from './VendorBusinessAddressForm.styled';
import PearlForm from 'vendor/components/forms/PearlForm';
import { BusinessAddress, EmptyCaptions } from 'assets/Defaults';
import { AddressAutoComplete } from 'vendor/components/AddressAutoComplete/AddressAutoComplete';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useWebstoreSetupWizard } from 'vendor/components/WebstoreSetup/WebstoreSetupContext';
import {
    useTealium,
    buildWebstoreSetupStepPageData,
    buildWebstoreSetupStepNextClickData,
} from '../../../../Tealium';
import { formFieldNames } from 'vendor/components/Webstore/ServiceAreaForm/Functions/ServiceAreaForm.Functions';
import { WebstoreSetupHeader } from '../WebstoreSetupHeader';
import { WebstoreAddressService } from 'framework';

const formName = 'BusinessAddress';

const Footer = () => {
    const { onBack, progress } = useWebstoreSetupWizard();

    return <AccountSetupStepFooter handleOnBack={() => onBack()} progress={progress} />;
};

const VendorBusinessAddressForm = () => {
    const { getBusinessAddress, createNewBusinessAddress, copyBusinessAddressToServiceLocation } =
        WebstoreAddressService;
    const { link, view } = useTealium();
    const { webstore } = useWebstoreContext();
    const { onSubmit, onReturnToBusinessLocations, totalSteps, currentStepCount } =
        useWebstoreSetupWizard();
    const { t } = useTranslation();

    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );

        view(tealiumData);
    }, []);

    const businessAddress = getBusinessAddress(webstore);

    const formOptions = {
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: {
            [formFieldNames.ServiceAddressStreet]: businessAddress?.address1,
            [formFieldNames.ServiceAddressStreet2]: businessAddress?.address2,
            [formFieldNames.ServiceAddressCity]: businessAddress?.city,
            [formFieldNames.ServiceAddressState]: businessAddress?.state,
            [formFieldNames.ServiceAddressZipcode]: businessAddress?.zipCode,
        },
    };

    const handleSubmit = (data) => {
        const updatedAddress = {
            address1: data[formFieldNames.ServiceAddressStreet],
            address2: data[formFieldNames.ServiceAddressStreet2],
            city: data[formFieldNames.ServiceAddressCity],
            state: data[formFieldNames.ServiceAddressState],
            zipCode: data[formFieldNames.ServiceAddressZipcode],
            addressType: 'business',
        };

        let businessAddress = getBusinessAddress(webstore);

        if (businessAddress) {
            Object.keys(updatedAddress).forEach((key) => {
                businessAddress[key] = updatedAddress[key];
            });
        } else {
            businessAddress = {
                ...createNewBusinessAddress(),
                ...updatedAddress,
            };
            webstore.Addresses.push(businessAddress);
        }

        if (!!webstore.ServiceAddressIsBusinessAddress) {
            copyBusinessAddressToServiceLocation(webstore);
        }

        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepNextClickData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps,
            data,
            webstore.Addresses
        );

        link(tealiumData);

        onSubmit({ Addresses: webstore.Addresses });
    };

    return (
        <PearlForm
            StyledForm={VendorBusinessAddressFormStyled}
            formName={formName}
            formId={formName}
            formOptions={formOptions}
            validationSchema={vendorBusinessAddressFormSchema}
            onSubmit={handleSubmit}
        >
            <AccountSetupStepLayout
                header={
                    <WebstoreSetupHeader
                        onReturnToBusinessLocations={onReturnToBusinessLocations}
                        stepName={formName}
                        currentStepCount={currentStepCount}
                        totalSteps={totalSteps}
                    />
                }
                formComponent={
                    <AddressAutoComplete
                        addressLine1Label={t('Forms.BusinessAddress.AddressLine1')}
                        addressLine2Label={t('Forms.BusinessAddress.AddressLine2')}
                        cityLabel={t('Forms.BusinessAddress.AddressCity')}
                        stateLabel={t('Forms.BusinessAddress.AddressState')}
                        zipcodeLabel={t('Forms.BusinessAddress.AddressZipcode')}
                    />
                }
                image={
                    <AccountSetupStepGraphic
                        translatedAltText={t(`Forms.${formName}.GifAltText`)}
                        image={BusinessAddress}
                        captions={EmptyCaptions}
                    />
                }
                footer={<Footer />}
            />
        </PearlForm>
    );
};

export { VendorBusinessAddressForm };
