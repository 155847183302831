import { useNavigate } from 'react-router-dom';
import { EXTERNAL_LINKS } from './shared/constants/navigation';

// these should be allowed to 'escape' the react router
const externalRoutes = [EXTERNAL_LINKS.dbiBlog, EXTERNAL_LINKS.pressKit];

const useHrefCatcher = () => {
    const navigate = useNavigate();

    document.addEventListener(
        'click',
        (e) => {
            const targetLocation = e.srcElement.href;
            const appLocation = process.env.REACT_APP_BASE_URL;
            const appHost = new URL(appLocation);

            if (!targetLocation) return;

            const target = new URL(targetLocation);

            for (let route of externalRoutes) {
                if (target.href.startsWith(route)) return;
            }

            if (target.hostname === appHost.hostname) {
                e.preventDefault();
                e.stopImmediatePropagation();
                navigate(target.pathname + target.hash, { replace: false });
            }
        },
        { capture: true }
    );
};

export { useHrefCatcher };
