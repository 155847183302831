import { IconButton, IconName, IconSizes, TruncatedTextDisplay } from 'dbi-pearl-ui-kit';
import { ConversationHeaderStyled } from './ConversationHeader.styled';

const ConversationHeader = ({ displayName, onBackClick }) => (
    <ConversationHeaderStyled className="conversation-header">
        <div>
            {!!onBackClick && (
                <IconButton
                    iconName={IconName.CHEVRON_LEFT}
                    size={IconSizes.SMD}
                    clickHandler={onBackClick}
                    withBorder={false}
                />
            )}
        </div>
        <div>
            <TruncatedTextDisplay lineCount={1} textAlign={'center'}>
                <p className="p2">
                    <b>{displayName}</b>
                </p>
            </TruncatedTextDisplay>
        </div>
        <div />
    </ConversationHeaderStyled>
);

export { ConversationHeader };
