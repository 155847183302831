import styled from 'styled-components';

const UserChoiceLoginStyled = styled.div`
    color: ${(props) => props.theme.colors.foreground.base};
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 16px;
    height: 83%;
    justify-content: space-between;

    .text-section {
        display: flex;
        flex-direction: column;
        width: 100%;

        p {
            margin-top: unset;
        }
    }
    .button-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
    }

    .headerLogo {
        width: 80%;
    }
`;

export { UserChoiceLoginStyled };
