import * as Yup from 'yup';
import i18n from 'i18n';
import { formFieldNames } from 'vendor/components/Webstore/ServiceAreaForm/Functions/ServiceAreaForm.Functions';

const vendorBusinessAddressFormSchema = Yup.object()
    .shape({
        [formFieldNames.ServiceAddressStreet]: Yup.string()
            .nullable()
            .max(120, i18n.t('Forms.Validation.CannotExceedCharacterLimit'))
            .required(
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.VendorBusinessAddressLine1'),
                })
            ),
        [formFieldNames.ServiceAddressCity]: Yup.string()
            .nullable()
            .max(120, i18n.t('Forms.Validation.CannotExceedCharacterLimit'))
            .required(
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.VendorBusinessAddressCity'),
                })
            ),
        [formFieldNames.ServiceAddressState]: Yup.string()
            .nullable()
            .notOneOf(
                ['DEFAULT'],
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.VendorBusinessAddressState'),
                })
            )
            .required(
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.VendorBusinessAddressState'),
                })
            ),
        [formFieldNames.ServiceAddressZipcode]: Yup.string()
            .nullable()
            .max(10, i18n.t('Forms.Validation.CannotExceedCharacterLimit'))
            .required(
                i18n.t('Forms.Validation.FieldRequired', {
                    field: i18n.t('Forms.Labels.VendorBusinessAddressZipcode'),
                })
            ),
    })
    .required();
export { vendorBusinessAddressFormSchema };
