import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsScreenLarge } from '../../../hooks/PearlMediaQuery';
import { WebstoreAward } from '../WebstoreAward/WebstoreAward';
import { WebstoreAwardsStyled } from './WebstoreAwards.styled';
import { SectionHeader } from '../SectionHeader/SectionHeader';
const WebstoreSectionContentSlider = lazy(() =>
    import('../WebstoreSectionContentSlider/WebstoreSectionContentSlider')
);

const WebstoreAwards = (props) => {
    const { webstore, sectionRef, sectionId, showFlaggedOverlay, iconName, banner } = props;
    const { t } = useTranslation();

    const isScreenLarge = useIsScreenLarge();

    const awards =
        webstore.Awards?.map((award) => (
            <WebstoreAward key={award.id} award={award} showFlaggedOverlay={showFlaggedOverlay} />
        )) || [];

    return (
        <WebstoreAwardsStyled ref={sectionRef} id={sectionId}>
            <SectionHeader
                title={t('WebstoreSections.Awards.Title')}
                iconName={iconName}
                banner={banner}
            />
            <div className="awards-container">
                {!isScreenLarge ? <WebstoreSectionContentSlider slides={awards} /> : awards}
            </div>
        </WebstoreAwardsStyled>
    );
};

export { WebstoreAwards };
