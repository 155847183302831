// Were any categories added to the DB? Were any external IDs in the DB changed? If so, update this list to match.
// Include archived
// Used for frontend to maap to categories returned from API in appData
// Always use these IDs for any logic used around categories in the app
// Displaying category text can still use VendorCategoryName
const categoryExternalIds = {
    beauty: 'beauty',
    bridalTuxedo: 'bridaltuxedo',
    entertainmentOtherServices: 'entertainmentotherservices',
    favorsGifts: 'favorsgifts',
    florist: 'florist',
    foodBeverage: 'foodbeverage',
    healthWellness: 'healthwellness',
    home: 'home',
    invitations: 'invitationsstationery',
    jewelry: 'jewelry',
    lodging: 'lodging',
    music: 'music',
    officiants: 'officiants',
    otherServices: 'otherservices',
    photobooths: 'photobooths',
    photographyVideography: 'photographyvideography',
    planning: 'planning',
    rentalEquipment: 'rentalequipment',
    transportation: 'transportation',
    travel: 'travel',
    tuxedos: 'tuxedos',
    venues: 'venues',
    videography: 'videography',
    weddingCakes: 'weddingcakes',
};

export { categoryExternalIds };
