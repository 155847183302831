import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PearlModalSizes, usePearlModal } from './PearlModalContext';
import { IconName, PearlIcon, SolidButton } from 'dbi-pearl-ui-kit';

const PearlUpdateFieldAlertStyled = styled.div`
    display: block;
    text-align: center;
    max-width: 100%;
    min-width: 100%;
    margin-top: 12px;
    color: ${(props) => props.theme.colors.foreground.secondary_text};
    gap: 17px;

    > * {
        width: 100%;
    }
    h3 {
        margin-bottom: 8px;
        text-transform: none;
    }

    #warning {
        font-size: 35px;
        color: ${(props) => props.theme.colors.error.base};
    }

    .p2 {
        margin-top: 0;
        margin-bottom: 24px;
    }

    .pearlAlertContent {
        margin-top: 22px;
    }

    #got-it-button {
        width: 200px;
        margin-bottom: 18px;
    }
`;

const PearlUpdateFieldAlert = (props) => {
    const { handleCloseModal } = props;
    const { t } = useTranslation();

    return (
        <PearlUpdateFieldAlertStyled>
            {/* The <div> element has a child <button> element that allows keyboard interaction */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div className="pearlAlertContent">
                <PearlIcon iconName={IconName.WARNING} id="warning" />
                <h3>{t('Alert.UpdatePromptPrimary')}</h3>
                <p className={'p2'}>{t('Alert.UpdatePromptSecondary')}</p>
                <SolidButton id="got-it-button" onClick={handleCloseModal}>
                    {t('Alert.UpdateBtnPrimary')}
                </SolidButton>
                <br />
            </div>
        </PearlUpdateFieldAlertStyled>
    );
};

const usePearlUpdateFieldAlert = () => {
    const { openModal, closeModal } = usePearlModal();

    const openFieldUpdateAlert = (callback) => {
        const handleClose = () => {
            closeModal();
            callback && callback();
        };

        openModal(
            <PearlUpdateFieldAlert handleCloseModal={() => handleClose()} />,
            PearlModalSizes.SM.MOBILE,
            '',
            handleClose
        );
    };

    return { openFieldUpdateAlert };
};

export default usePearlUpdateFieldAlert;
