import { useTranslation } from 'react-i18next';
import { SectionEditHeaderStyled } from './SectionEditHeader.styled';
import { Link } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';

const StringWithLinkBuilder = (props) => {
    const { description, textToMatch, target, className, stateToSend } = props;

    return (
        <span>
            {reactStringReplace(description, textToMatch, (match, i) => {
                return (
                    <Link key={target} to={target} className={className} state={stateToSend}>
                        {textToMatch}
                    </Link>
                );
            })}
        </span>
    );
};

const SectionEditHeader = (props) => {
    const { title, description, linkObject } = props;

    const { t } = useTranslation();

    return (
        <SectionEditHeaderStyled data-testid={`section-edit-header-${title}`}>
            <label className="title">
                <b>{t(`${title}`)}</b>
            </label>

            {description &&
                (linkObject ? (
                    <p className="subtitle">
                        <StringWithLinkBuilder
                            description={t(`${description}`)}
                            textToMatch={t(`${linkObject.DisplayText}`)}
                            target={t(`${linkObject.Src}`)}
                            stateToSend={linkObject.StateToSend}
                        />
                    </p>
                ) : (
                    <p className="subtitle">{t(`${description}`)}</p>
                ))}
        </SectionEditHeaderStyled>
    );
};

export { SectionEditHeader };
