import { GoogleReviews, loadScript } from 'framework';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NoInformationProvided } from '../NoInformationProvided/NoInformationProvided';
import { UpgradeSubscription } from '../UpgradeSubscription/UpgradeSubscription';
import { IconName } from 'dbi-pearl-ui-kit';

function GoogleReviewsSection(props) {
    const { webstore, sectionRef, sectionId } = props;
    const { t } = useTranslation();
    const needsUpgrade = !webstore.ActiveFeatures?.feature_reviews;

    return (
        <>
            {webstore.GooglePlaceId ? (
                <GoogleReviews
                    webstore={webstore}
                    sectionRef={sectionRef}
                    sectionId={sectionId}
                    loadScript={loadScript}
                    iconName={needsUpgrade ? IconName.LOCK : null}
                    banner={!!needsUpgrade && <UpgradeSubscription />}
                />
            ) : (
                <NoInformationProvided
                    sectionRef={sectionRef}
                    sectionId={sectionId}
                    title={t('WebstoreSections.Reviews.Title')}
                    iconName={needsUpgrade ? IconName.LOCK : null}
                    banner={!!needsUpgrade && <UpgradeSubscription />}
                />
            )}
        </>
    );
}

export { GoogleReviewsSection };
