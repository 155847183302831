import { useCallback, useEffect, useMemo } from 'react';
import { realtimeActions, useRealtimeContext } from '../../contexts/RealtimeContext';
import { usePearlToast } from '../contexts/PearlToastContext';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/Store';

export const GlobalMessageListeners = () => {
    const realtimeService = useRealtimeContext();
    const { rerender, vendor, webstore } = useStore();
    const { t } = useTranslation();
    const { toastInfo } = usePearlToast();

    const handleIncomingMessage = useCallback((m) => {
        if (!document.location.pathname.includes('/inbox')) {
            toastInfo(
                <div>
                    <a href={`/inbox?conversationId=${m.data.conversationId}`}>
                        {t('Messaging.NewMessages')}
                    </a>
                </div>
            );
        }
    }, []);

    const handleSubscriptionUpdated = useCallback(async (m) => {
        // if no vendor in context, no need to do anything
        if (vendor?.id) {
            const newData = m.data;
            if (!newData?.subscription) {
                // something broke, leave state in place and bail
                return;
            }
            // update vendor in context
            vendor.updateVendor({ stripeData: newData });
            // if also have webstore in context, need to update it
            if (webstore?.Id) {
                // if it's assigned a seat
                if (newData.subscription.assignedWebstores.includes(webstore.Id)) {
                    // update features
                    // incoming is an array of strings / feature names
                    // webstore context is an object with feature name keys { feature_name: enabled? }
                    // don't need to keep track of disabled features, so just replace object
                    const newFeatures = {};
                    newData.subscription.activeFeatures?.forEach((f) => {
                        newFeatures[f] = true;
                    });
                    webstore.ActiveFeatures = newFeatures;
                    // and update tier name
                    webstore.SubscriptionTier = newData.subscription.tier;
                } else {
                    webstore.ActiveFeatures = null;
                    webstore.SubscriptionTier = null;
                }
            }
            rerender();
            toastInfo(t('SubscriptionUpdated'));
        }
    }, []);

    const handlers = useMemo(
        () => ({
            [realtimeActions.IncomingMessage]: handleIncomingMessage,
            [realtimeActions.SubscriptionUpdated]: handleSubscriptionUpdated,
        }),
        [handleIncomingMessage, handleSubscriptionUpdated]
    );

    useEffect(() => {
        const name = 'GlobalMessageListener';
        const actions = Object.keys(handlers);
        const callback = async (m) => await handlers[m.action]?.(m);
        const { unsubscribe } = realtimeService.subscribe(name, actions, callback);
        return unsubscribe;
    }, [handlers]);

    return <></>;
};
