import { APP_ROUTES } from 'shared/constants/navigation';

const RequireAuthenticated = (context) => {
    if (!context.store.isAuthed) {
        return APP_ROUTES.landing;
    }

    return 'next';
};

export { RequireAuthenticated };
