import { APP_ROUTES } from 'shared/constants/navigation';

const RequireNoStripeCustomerId = (context) => {
    if (context.store.vendor.stripeData?.customerId) {
        return APP_ROUTES.aem.join.pricing;
    }
    return 'next';
};

export { RequireNoStripeCustomerId };
