import styled from 'styled-components';

const VendorServiceAreaStyled = styled.form`
    display: flex;
    height: 100%;

    .p1 {
        margin: unset;
    }
`;

export { VendorServiceAreaStyled };
