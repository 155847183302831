import React, { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import PearlUnsavedChangesAlert from '../components/PearlUnsavedChangesAlert/PearlUnsavedChangesAlert';
import { PearlModalSizes, usePearlModal } from './PearlModalContext';

export function useBlocker(blocker, when) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!when) return;

        const unblock = navigator.block((tx) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, blocker, when]);
}

const useUnsavedChangesPrompt = (when) => {
    const { openModal, closeModal } = usePearlModal();
    const { submitForm } = useFormContext();

    const handleOnSave = async (tx) => {
        await submitForm();
        await closeModal();
        await tx.retry();
    };

    const handleDiscard = async (tx) => {
        await closeModal();
        await tx.retry();
    };

    const handleCloseModal = () => {
        closeModal();
    };

    const blocker = useCallback(
        (tx) => {
            openModal(
                <PearlUnsavedChangesAlert
                    handleOnSave={() => handleOnSave(tx)}
                    handleDiscard={() => handleDiscard(tx)}
                    handleCloseModal={() => handleCloseModal()}
                />,
                PearlModalSizes.SM.MOBILE,
                null,
                handleCloseModal
            );
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useBlocker(blocker, when);

    return;
};

export { useUnsavedChangesPrompt };
