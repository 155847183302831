import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { serviceAreaFormSchema } from '../../Webstore/ServiceAreaForm/ServiceAreaFormSchema';
import { distanceOptions } from 'vendor/constants/select-options/distance';
import { VendorServiceAreaStyled } from './VendorServiceArea.styled';
import PearlForm from 'vendor/components/forms/PearlForm';
import { AccountSetupStepLayout, AccountSetupStepFooter } from 'framework';
import { ServiceAreaForm } from 'vendor/components/Webstore/ServiceAreaForm/ServiceAreaForm';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useWebstoreSetupWizard } from 'vendor/components/WebstoreSetup/WebstoreSetupContext';
import {
    useTealium,
    buildWebstoreSetupStepPageData,
    buildWebstoreSetupStepNextClickData,
} from '../../../../Tealium';
import { getFormDefaults } from 'vendor/components/Webstore/ServiceAreaForm/Functions/ServiceAreaForm.Functions';
import { WebstoreSetupHeader } from '../WebstoreSetupHeader';
import { WebstoreAddressService } from 'framework';
const {
    formatServiceAreaForPatch,
    formatServiceLocationForPatch,
    getServiceLocationAddress,
    getBusinessAddress,
    getServiceArea,
} = WebstoreAddressService;

const formName = 'ServiceArea';

const Footer = () => {
    const { onBack, progress } = useWebstoreSetupWizard();

    return <AccountSetupStepFooter handleOnBack={() => onBack()} progress={progress} />;
};

const VendorServiceAreaForm = () => {
    const { link, view } = useTealium();
    const { webstore } = useWebstoreContext();
    const { onSubmit, onReturnToBusinessLocations, currentStepCount, totalSteps } =
        useWebstoreSetupWizard();
    const { t } = useTranslation();

    useEffect(() => {
        const stepName = t(`Forms.${formName}.StepName`);
        const tealiumData = buildWebstoreSetupStepPageData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps
        );

        view(tealiumData);
    }, []);

    const businessAddress = getBusinessAddress(webstore);
    const serviceLocationAddress = getServiceLocationAddress(webstore);
    const serviceArea = getServiceArea(webstore);
    const serviceAreaFormDefaults = getFormDefaults(webstore, false);

    const formOptions = {
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: serviceAreaFormDefaults,
    };

    const setInitialMapZoom = (storedRange) => {
        const initialRange = parseInt(storedRange);
        const option = distanceOptions.find((option) => option.value === initialRange);
        return option.mapZoom;
    };

    const [mapCenter, setMapCenter] = useState({
        city: serviceLocationAddress?.city ? serviceLocationAddress.city : businessAddress?.city,
        state: serviceLocationAddress?.city ? serviceLocationAddress.state : businessAddress?.state,
    });

    const [mapZoom, setMapZoom] = useState(
        serviceArea?.travelRadius ? setInitialMapZoom(serviceArea?.travelRadius) : 10
    );

    const [mapAlt, setMapAlt] = useState();
    const [mapImg, setMapImg] = useState(
        `https://maps.googleapis.com/maps/api/staticmap?center=${mapCenter.city},${mapCenter.state}&zoom=${mapZoom}&size=350x350&scale=2&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    );

    const handleDistanceSelect = (e) => {
        const selectedValue = parseInt(e?.target?.value);
        const option = distanceOptions?.find((option) => option.value === selectedValue);

        if (option) {
            setMapZoom(option.mapZoom);
        }
    };

    useEffect(() => {
        if (mapCenter.lat && mapCenter.lng) {
            setMapImg(
                `https://maps.googleapis.com/maps/api/staticmap?center=${mapCenter.lat},${mapCenter.lng}&zoom=${mapZoom}&size=400x400&scale=2&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
            );
        } else {
            setMapImg(
                `https://maps.googleapis.com/maps/api/staticmap?center=${mapCenter.city},${mapCenter.state}&zoom=${mapZoom}&size=400x400&scale=2&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
            );
        }
    }, [mapCenter, mapAlt, mapZoom]);

    const mapFunctions = {
        setMapCenter: setMapCenter,
        setMapZoom: setMapZoom,
        setMapAlt: setMapAlt,
        selectDistance: handleDistanceSelect,
    };

    const handleOnSubmit = (data) => {
        const stepName = t(`Forms.${formName}.StepName`);

        formatServiceLocationForPatch(webstore, data);
        formatServiceAreaForPatch(webstore, data);

        data.Addresses = webstore.Addresses;

        const tealiumData = buildWebstoreSetupStepNextClickData(
            stepName,
            webstore,
            currentStepCount,
            totalSteps,
            data,
            data
        );

        link(tealiumData);
        onSubmit(data);
    };

    return (
        <PearlForm
            StyledForm={VendorServiceAreaStyled}
            formName={formName}
            validationSchema={serviceAreaFormSchema}
            formOptions={formOptions}
            onSubmit={handleOnSubmit}
        >
            <AccountSetupStepLayout
                header={
                    <WebstoreSetupHeader
                        onReturnToBusinessLocations={onReturnToBusinessLocations}
                        stepName={formName}
                        currentStepCount={currentStepCount}
                        totalSteps={totalSteps}
                    />
                }
                formComponent={
                    <>
                        <ServiceAreaForm
                            webstore={webstore}
                            mapFunctions={mapFunctions}
                            showOnlineOptions={false}
                        />
                    </>
                }
                image={<img src={mapImg} id="vendor-business-service-map" alt={mapAlt} />}
                footer={<Footer />}
            />
        </PearlForm>
    );
};

export { VendorServiceAreaForm };
