import { PearlApiClient, PearlApps } from 'framework';

export function TermsOfServiceService() {
    const api = PearlApiClient(PearlApps.VENDOR);

    const getCurrentTermsOfService = async () => {
        const res = await api.get(`termsofservice`);
        return res;
    };

    const putAcceptTermsOfService = async (vendorId) => {
        const res = await api.put(`vendors/${vendorId}/termsofserviceaccepted`);
        return res;
    };

    return {
        getCurrentTermsOfService,
        putAcceptTermsOfService,
    };
}
