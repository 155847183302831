import styled from 'styled-components';

const EditSectionLayoutStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;

    .top {
        margin: 48px 15% 14px 15%;
        display: flex;
        flex-direction: column;
        height: 100%;

        .back-button {
            display: flex;
            align-self: start;
            label {
                align-text: center;
                border: unset;
                padding-bottom: 0;
            }
        }

        main {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            max-width: 1000px;

            section {
                margin-bottom: 40px;
            }

            .add-button {
                max-width: 150px;
            }
        }

        .mediaSection {
            max-width: none;
        }
    }

    .footer {
        position: sticky;
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        z-index: 1;
        bottom: 0;
        display: flex;
        gap: 18px;
        padding: 18px 15%;
        height: 50px;

        box-shadow: 0px -24px 32px rgba(0, 0, 0, 0.04), 0px -16px 24px rgba(0, 0, 0, 0.04),
            0px -4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        background: ${(props) => props.theme.colors.background.container};

        .save-button {
            min-width: 204px;
        }

        .back-button {
            display: flex;
            align-self: center;
            justify-content: center;
        }
    }

    .bottomCopy {
        padding-top: 24px;
        font-size: 12px;
        display: flex;
    }

    .addressCopy {
        padding-left: 8px;
    }

    @media (max-width: 815px) {
        width: unset;

        .top {
            padding: 5px 16px;
            margin: 25px 0 0 0;
        }
        .footer {
            padding: 18px 16px;
            justify-content: flex-start;
            column-gap: 10px;
            bottom: 65px;

            .save-button,
            .back-button {
                min-width: 163px;
            }
        }
    }
`;

export { EditSectionLayoutStyled };
