import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const CappedCarouselStyled = styled.section`
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
    background-color: #fcfcfc;

    h2,
    h3 {
        color: ${(props) => props.theme.colors.background.base};
    }

    h2 {
        text-align: left;
    }

    .vendor-results {
        width: 100%;
        max-width: 1344px;
    }

    .tile-container {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
    }

    .inner-carousel-tile-container {
        display: grid;
        grid-template-columns: 340px 1fr;
        gap: 16px;
        width: 100%;
        padding: 40px 0 60px;

        .vendorResultsSlider {
            padding-bottom: 15px;
            height: 378px;
            width: 100%;

            .swiper-wrapper {
                max-width: 1122px;
            }
        }

        .swiper-slide {
            max-width: 340px;
        }
    }

    .carousel-cap {
        width: 340px;

        .cap-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            border-radius: 50px 0 0 50px;
            justify-content: center;

            background: linear-gradient(#e92455, #af1139);
        }

        .cap-inner {
            padding: 32px;
        }
    }

    .carousel-icon {
        width: 48px;
        height: 48px;
        font-size: 48px;
        color: ${(props) => props.theme.colors.background.base};
    }

    .swiper-button-next,
    .swiper-button-prev {
        color: ${(props) => props.theme.colors.foreground.base};
    }

    button {
        width: 50%;
        margin-left: 50%;
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        padding-left: 5%;
        padding-right: 5%;

        button {
            width: 100%;
            margin-left: 0%;
        }

        .inner-tile-container {
            grid-template-columns: 35% 1fr;

            .vendorResultsSlider {
                height: 375px;
            }
        }

        .carousel-cap {
            width: 100%;

            .cap-content {
                height: 375px;
            }
        }
    }

    @media (max-width: 600px) {
        .inner-tile-container {
            grid-template-columns: 47% 1fr;
        }
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.SM)} {
        display: flex;
        justify-content: center;
        height: 578px;
        padding: 16px;
        background: linear-gradient(#e92455, #af1139);

        h3 {
            margin-block: 0;
            margin-bottom: 8px;
        }

        .swiper {
            margin-bottom: 32px;
        }

        .inner-tile-container {
            display: flex;
        }

        .vendor-results {
            height: 100%;
            text-align: center;
        }
    }
`;

export { CappedCarouselStyled };
