import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ServiceAreaFormStyled } from './ServiceArea.styled';
import { ServiceTypeOptions } from './ServiceTypeOptions/ServiceTypeOptions';
import { OnlineOptions } from './OnlineOptions/OnlineOptions';
import { PhysicalLocationOptions } from './PhysicalLocationOptions/PhysicalLocationOptions';
import {
    formFieldNames,
    onlineStoreClickHandler,
    physicalStoreClickHandler,
    physicalStoreOptionsClickHandler,
    travelsClickHandler,
    travelOptionsClickHandler,
    localTravelClickHandler,
    localOptionClickHandler,
    setServiceCityState,
} from './Functions/ServiceAreaForm.Functions';
import { TravelOptions } from './TravelOptions/TravelOptions';
import { WebstoreAddressService } from 'framework';

//This is used in the webstore setup flow & shown in the webstore edit pgs.
//Online isn't an option for the setup flow, but could be an option on webstore edit pgs based off subscription feature
function ServiceAreaForm(props) {
    const { webstore, mapFunctions, showOnlineOptions } = props;
    const { ensureTravelOptionIfDistanceProvided, travelOptions } = WebstoreAddressService;

    const { setValue, getValues } = useFormContext();

    const { selectDistance } = mapFunctions || {};

    //Online State Management
    const [canShowOnline] = useState(!!showOnlineOptions && !!webstore.OnlineAllowed);
    const [onlineStore, setOnlineStore] = useState(canShowOnline && !!webstore.OnlineStore);

    //Physical Store State Management
    const [physicalStore, setPhysicalStore] = useState(!!webstore.PhysicalStore);

    //Travels State Management
    const [travel, setTravel] = useState(!!webstore.Travels);
    const [localTravel, setLocalTravel] = useState(webstore.TravelOption === travelOptions.Locally);
    const [travelOptionSelected, setTravelOptionSelected] = useState(
        ensureTravelOptionIfDistanceProvided(webstore)
    );

    const categoryHasTravel = !webstore.hasCapacity;

    return (
        <ServiceAreaFormStyled showBottomBorder={!!onlineStore || !!physicalStore || !!travel}>
            <ServiceTypeOptions
                onlineOptionAllowed={canShowOnline}
                travelOptionAllowed={categoryHasTravel}
                onlineStore={onlineStore}
                onlineStoreClickHandler={() =>
                    onlineStoreClickHandler(
                        setValue,
                        onlineStore,
                        setOnlineStore,
                        travel,
                        physicalStore
                    )
                }
                physicalStore={physicalStore}
                physicalStoreClickHandler={() =>
                    physicalStoreClickHandler(
                        setValue,
                        physicalStore,
                        setPhysicalStore,
                        travel,
                        canShowOnline,
                        onlineStore
                    )
                }
                travel={travel}
                travelsClickHandler={() =>
                    travelsClickHandler(
                        setValue,
                        travel,
                        setTravel,
                        physicalStore,
                        setTravelOptionSelected,
                        canShowOnline,
                        onlineStore
                    )
                }
            />
            {!!onlineStore && <OnlineOptions webstore={webstore} mapFunctions={mapFunctions} />}
            {!!physicalStore && (
                <PhysicalLocationOptions
                    physicalStoreOptionsClickHandler={physicalStoreOptionsClickHandler}
                    mapFunctions={mapFunctions}
                    webstore={webstore}
                />
            )}
            {!!categoryHasTravel && !!travel && (
                <TravelOptions
                    mapFunctions={mapFunctions}
                    travelOptionsClickHandler={(optionSelected) =>
                        travelOptionsClickHandler(
                            setValue,
                            optionSelected,
                            setTravelOptionSelected,
                            localTravelClickHandler,
                            setLocalTravel
                        )
                    }
                    localOptionClickHandler={(e) =>
                        localOptionClickHandler(
                            setValue,
                            e,
                            setLocalTravel,
                            setTravelOptionSelected,
                            localTravelClickHandler,
                            physicalStore,
                            mapFunctions,
                            selectDistance,
                            getValues
                        )
                    }
                    travelOptionSelected={travelOptionSelected}
                    localTravel={localTravel}
                    physicalStore={physicalStore}
                    setServiceCityState={setServiceCityState}
                />
            )}
        </ServiceAreaFormStyled>
    );
}

export { ServiceAreaForm, formFieldNames };
