import React from 'react';
import { ConditionalWrapper } from '../../utility/ConditionalWrapper';
import { NewTabLink, ensureExternalLink } from '../../utility/NewTabLink';
import FlaggedImage from '../../utility/FlaggedImage/FlaggedImage';
import { WebstoreAwardStyled } from './WebstoreAward.styled';

const WebstoreAward = (props) => {
    const { award, showFlaggedOverlay } = props;

    // if image failed validation, a flagged overlay with help text is presented in the vendor app.
    // if image failed validation, a placeholder image is displayed for the other app views
    const displayFlaggedOverlay =
        award.image &&
        !award.image.validationResult &&
        !award.image.adminApproved &&
        showFlaggedOverlay;

    return (
        <WebstoreAwardStyled>
            <ConditionalWrapper
                condition={award.link}
                wrapper={(children) => (
                    <NewTabLink link={ensureExternalLink(award.link)}>{children}</NewTabLink>
                )}
            >
                <div className="award-image">
                    {!!displayFlaggedOverlay && <FlaggedImage image={award.image} />}
                    {!displayFlaggedOverlay && !!award.image && (
                        <img
                            data-testid={
                                award.image.placeholder ? 'placeholder-image' : 'award-image'
                            }
                            src={award.image.url}
                            alt={award.image.caption || ''}
                            className={award.image.placeholder ? 'placeholder' : ''}
                        />
                    )}
                </div>
                <div className="award-content">
                    <p className="p1">
                        <b>{award.name}</b>
                    </p>
                </div>
            </ConditionalWrapper>
        </WebstoreAwardStyled>
    );
};

export { WebstoreAward };
