import styled from 'styled-components';

const AwardEditFormStyled = styled.div`
    box-sizing: border-box;
    border: 1px solid
        ${(props) =>
            props.error ? props.theme.colors.error.base : props.theme.colors.foreground.border};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
        0px 0px 1px rgba(0, 0, 0, 0.04);
    padding: 16px;
    margin-bottom: 16px;

    .header {
        display: flex;
        justify-content: space-between;

        .title {
            display: flex;
            align-items: center;
        }

        .buttons {
            display: flex;

            button {
                border: none;
            }

            .icon-trash {
                color: ${(props) => props.theme.colors.error.base};
            }
        }
    }

    .dropzone {
        padding: 64px;
    }

    .image-section {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .image {
            width: 100%;
        }

        .remove {
            margin-top: 16px;
        }
    }

    .form {
        flex-direction: column;
    }

    .form-open {
        display: flex;
    }

    .form-closed {
        display: none;
    }
`;

export { AwardEditFormStyled };
