import { IconName, LinkButton } from 'dbi-pearl-ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ReturnToBusinessLocationsButton = (props) => {
    const { className, onClick } = props;
    const { t } = useTranslation();

    return (
        <LinkButton
            type="button"
            onClick={onClick}
            startIcon={IconName.ARROW_LEFT}
            variant="accent"
            className={className}
        >
            {t('WebstoreSetup.ReturnToBusinessLocations')}
        </LinkButton>
    );
};

export { ReturnToBusinessLocationsButton };
