import React from 'react';
import { IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';
import styled from 'styled-components';

const QuoteHeaderStyled = styled.div`
    background-color: ${(props) => props.theme.colors.auxiliary.base};
    color: ${(props) => props.theme.colors.primary.contrast};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 15px;

    h4 {
        margin: 0;
    }
`;

const QuoteHeader = ({ iconName, text }) => (
    <QuoteHeaderStyled className="quote-header">
        <PearlIcon iconName={iconName} size={IconSizes.LG} />
        <h4>{text}</h4>
    </QuoteHeaderStyled>
);

export { QuoteHeader };
