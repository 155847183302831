import styled from 'styled-components';

const StatusBarStyled = styled.div`
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    height: env(safe-area-inset-top);
    background-color: white;
`;

export { StatusBarStyled };
