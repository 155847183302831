import styled from 'styled-components';

const GoogleReviewsDisconnectorStyled = styled.div`
    color: ${(props) => props.theme.colors.foreground.text};
    display: grid;
    justify-items: center;
    width: 100%;

    .promptHeader,
    .promptQuestion {
        margin-top: 0;
    }

    .promptButtons {
        display: grid;
        row-gap: 36px;
        margin: 24px 0;
        justify-items: center;

        button {
            width: 207px;
        }
    }
`;

export { GoogleReviewsDisconnectorStyled };
