import { createContext, useEffect, useState, useContext } from 'react';
import { WebstoreService } from '../lib/apis/WebstoreService';
import { useParams } from 'react-router-dom';
import { NotFoundPage } from 'shared/pages/NotFoundPage/NotFoundPage';
import { SiteLoader } from '../components/SiteLoader/SiteLoader';

const WebstoreContext = createContext();

const useWebstore = () => {
    const [webstoreContext] = useContext(WebstoreContext);

    return {
        ...webstoreContext,
    };
};

const WebstoreProvider = ({ children }) => {
    const { webstoreUrl } = useParams();
    const webstoreService = WebstoreService();
    const defaultWebstoreContext = {
        webstore: null,
    };
    const [webstoreContext, setWebstoreContext] = useState(defaultWebstoreContext);
    const [webstoreLoading, setWebstoreLoading] = useState(false);
    const [webstoreNotFound, setWebstoreNotFound] = useState(false);

    useEffect(() => {
        const work = async () => {
            if (webstoreUrl) {
                setWebstoreLoading(true);
                try {
                    const res = await webstoreService.getWebstoreByUrl(webstoreUrl, {
                        getPublished: true,
                    });
                    if (res) {
                        setWebstoreNotFound(false);
                        setWebstoreContext({ webstore: res });
                    } else {
                        setWebstoreNotFound(true);
                    }
                } catch (err) {
                    setWebstoreNotFound(true);
                } finally {
                    setWebstoreLoading(false);
                }
            } else {
                setWebstoreContext(defaultWebstoreContext);
            }
        };
        work();
    }, [webstoreUrl]);

    const Content = () => {
        if (webstoreUrl) {
            if (webstoreLoading) {
                return <SiteLoader />;
            } else if (webstoreContext?.webstore) {
                return children;
            } else if (webstoreNotFound) {
                return <NotFoundPage />;
            } else {
                return '';
            }
        } else {
            return children;
        }
    };

    return (
        <WebstoreContext.Provider value={[webstoreContext, setWebstoreContext]}>
            <Content />
        </WebstoreContext.Provider>
    );
};

export { WebstoreProvider, useWebstore };
