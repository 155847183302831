import styled from 'styled-components';

const PreferredPartnerTileStyled = styled.div`
    width: 356px;
    height: 506px;
    color: ${(props) => props.theme.colors.foreground.text};
    border: 1px solid ${(props) => props.theme.colors.foreground.border};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
        0px 0px 1px rgba(0, 0, 0, 0.04);

    .partner-image {
        height: 200px;
        width: 100%;

        img {
            width: 100%;
            max-height: 200px;
            object-fit: cover;
        }
    }

    .partner-details {
        margin: 0px;
        padding: 24px;

        h4,
        .p3,
        .p4 {
            margin: 0px;
            padding: 0px;
            text-transform: unset;
        }

        .label {
            margin: 17.5px 0 8px 0;
            color: ${(props) => props.theme.colors.primary.base};
        }

        .description {
            display: block;
            margin-bottom: 16px;
            height: 90px;
            overflow: hidden;
        }

        .partner-subtitle {
            display: inline-flex;
        }

        .separator {
            font-size: 28px;
            font-weight: 900;
            padding: 2px 6px 0 6px;
        }

        .location,
        .category {
            word-break: break-word;
        }

        button {
            margin: 0;
            width: 100%;
        }
    }

    @media (max-width: 450px) {
        width: 325px;
    }
`;

export { PreferredPartnerTileStyled };
