import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

export default styled.div`
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    bottom: 0;
    z-index: 1;
    box-shadow: 0px -1px 8px rgba(19, 33, 34, 0.08);
    background-color: ${(props) => props.theme.colors.background.container};

    .footer-content {
        margin: auto;
        display: flex;
        justify-content: space-between;
    }

    .webstore-basics {
        align-self: center;
        display: flex;
        column-gap: 16px;
        align-items: center;

        div {
            width: unset;
        }
    }

    h3 {
        margin: unset;
        line-height: 60px;
        text-transform: unset;
    }

    .subtitle {
        align-self: center;
        display: flex;
        flex-flow: row wrap;
        column-gap: 16px;

        .separator {
            margin-top: 8px;
            margin-bottom: unset;
        }
    }

    .footer-controls {
        align-self: center;
    }

    ${(props) => props.theme.pearlBreaks.up(BreakpointSizes.MD)} {
        .footer-content {
            width: 80%;
            min-width: 740px;
        }
    }

    @media (min-width: 1400px) {
        .footer-content {
            width: 60%;
            min-width: 1130px;
        }
    }
`;
