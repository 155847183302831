import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    COMPLETE_STEP,
    DEFAULT_STEP,
    WebstoreSetupStepForms,
    WebstoreSetupStepsMap,
} from 'vendor/constants/WebstoreSetupSteps';
import { WebstoreService } from 'vendor/lib/apis/WebstoreService';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useVendorContext } from 'vendor/contexts/VendorContext';
import { APP_ROUTES } from 'shared/constants/navigation';
import { unwrapResponse } from '../../../lib/apis/utils';

const WebstoreSetupContext = React.createContext();
WebstoreSetupContext.displayName = 'WebstoreSetupContext';

const setupRoutePrefix = APP_ROUTES.vendor.webstore.setup + '/';
const getSetupStepRoute = (step) => `${setupRoutePrefix}${step}`;

const useWebstoreSetupWizard = () => {
    const { vendor } = useVendorContext();
    const [webstoreSetupProps] = React.useContext(WebstoreSetupContext);
    const {
        webstore,
        progress,
        totalSteps,
        currentStepCount,
        onCategoryChange,
        stepsArray,
        patchFunction,
    } = webstoreSetupProps;

    const navigate = useNavigate();

    const getStep = (indexOffset) => {
        const currentIndex = stepsArray.indexOf(webstore.CurrentSetupStep);
        return stepsArray[currentIndex + indexOffset];
    };

    const onBack = () => {
        const previousStep = getStep(-1);
        navigate(getSetupStepRoute(previousStep));
    };

    const onSkip = () => {
        const nextStep = getStep(1);
        navigate(getSetupStepRoute(nextStep));
    };

    const returnToBusinessLocations = async () => {
        webstore.CurrentSetupStep = COMPLETE_STEP;
        await webstoreSetupProps.updateStep();
        navigate(APP_ROUTES.vendor.account.webstoreManagement);
    };

    const onReturnToBusinessLocations = vendor.webstoreCount > 1 ? returnToBusinessLocations : null;

    const onSubmit = async (formData) => {
        try {
            if (formData) {
                webstore.updateWebstore(formData);
                const patch = await patchFunction(webstore.forApi);
                if (patch.ok) {
                    webstore.setFromApi(patch.data);
                } else {
                    console.error(patch.originalError);
                }
            }
            const nextStep = getStep(1);
            if (nextStep) {
                navigate(getSetupStepRoute(nextStep));
            } else {
                webstore.CurrentSetupStep = COMPLETE_STEP;
                await webstoreSetupProps.updateStep();
                navigate(APP_ROUTES.vendor.webstore.root);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return {
        onSubmit,
        onBack,
        onSkip,
        onReturnToBusinessLocations,
        progress,
        totalSteps,
        currentStepCount,
        onCategoryChange,
    };
};

const WebstoreSetupWizardProvider = ({ children }) => {
    const webstoreApi = WebstoreService();
    const { webstore } = useWebstoreContext();
    const location = useLocation();
    const navigate = useNavigate();
    const { getCategoryByCategoryId } = useVendorContext();

    const getMaxStepCount = () => {
        let stepCount = 0;

        for (let [, value] of WebstoreSetupStepsMap) {
            stepCount = Math.max(stepCount, value.length);
        }

        return stepCount + 1; // to account for 'details' step
    };

    const recalcSteps = (options) => {
        const { locationChanged, newCategoryId } = options;
        const updateStep = webstoreSetup.updateStep;

        if (locationChanged) {
            const currentPage = location.pathname.slice(
                location.pathname.indexOf(setupRoutePrefix) + setupRoutePrefix.length
            );

            const validPages = Object.values(WebstoreSetupStepForms);

            if (validPages.includes(currentPage)) {
                if (webstore.CurrentSetupStep !== currentPage) {
                    webstore.CurrentSetupStep = currentPage;
                    updateStep();
                }
            } else {
                if (!validPages.includes(webstore.CurrentSetupStep)) {
                    webstore.CurrentSetupStep = DEFAULT_STEP;
                    updateStep();
                }
                navigate(getSetupStepRoute(webstore.CurrentSetupStep));
            }
        }

        let stepsArray = webstoreSetup.stepsArray;
        const categoryId = newCategoryId || webstore.VendorCategoryId;

        if (categoryId) {
            const categoryExternalId = getCategoryByCategoryId(categoryId).externalId;
            const categorySteps = WebstoreSetupStepsMap.get(categoryExternalId);

            if (categorySteps.length) {
                stepsArray = [WebstoreSetupStepForms.DETAILS, ...categorySteps];
            }
        }

        const currentStepCount = stepsArray.indexOf(webstore.CurrentSetupStep) + 1;

        const totalSteps = stepsArray.length > 1 ? stepsArray.length : getMaxStepCount();

        const progress = currentStepCount / totalSteps;

        setWebstoreSetup({
            ...webstoreSetup,
            progress: progress,
            totalSteps: totalSteps,
            currentStepCount: currentStepCount,
            stepsArray: stepsArray,
        });
    };

    const onCategoryChange = (newCategoryId) => {
        recalcSteps({ newCategoryId });
    };

    const updateStep = async () => {
        const response = await webstoreApi.patchWebstore(webstore.forPatchSetupStep);
        const patchedStore = unwrapResponse(response);
        webstore.setFromApi(patchedStore);
    };

    const defaultWebstoreSetupProps = {
        patchFunction: webstoreApi.patchWebstore,
        onCategoryChange,
        stepsArray: [WebstoreSetupStepForms.DETAILS],
        progress: 0,
        totalSteps: getMaxStepCount(),
        currentStepCount: 1,
        webstore,
        updateStep,
    };

    const [webstoreSetup, setWebstoreSetup] = useState(defaultWebstoreSetupProps);

    useEffect(() => {
        recalcSteps({ locationChanged: true });
    }, [location]);

    return (
        <WebstoreSetupContext.Provider value={[webstoreSetup, setWebstoreSetup]}>
            {children}
        </WebstoreSetupContext.Provider>
    );
};

export { useWebstoreSetupWizard, WebstoreSetupWizardProvider };
