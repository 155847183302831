import styled from 'styled-components';
import { Accordion } from '@mui/material';

const WebstoreFaqsStyled = styled.section`
    .faqs-container {
        display: flex;
        flex-direction: column;
        gap: 24px;

        // use display css vs rendering only a slice of
        // the array, makes the accordions more consistent
        .MuiAccordion-root:nth-child(n + ${(props) => props.defaultCount + 1}) {
            display: ${(props) => (props.showAll ? 'initial' : 'none')};
        }
    }

    .button-container {
        margin-top: 40px;
        text-align: center;
    }
`;

const StyledAccordion = styled(Accordion)`
    // normalize component/sub-components
    &,
    .MuiAccordionSummary-root,
    .MuiAccordionSummary-content,
    .MuiAccordionSummary-content h4,
    .MuiAccordionDetails-root,
    .MuiAccordionDetails-root .p2 {
        margin: 0;
        padding: 0;
    }

    // hide the top 'border' from MUI
    &:before {
        display: none;
    }

    border: 1px solid ${(props) => props.theme.colors.foreground.border};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
        0px 0px 1px rgba(0, 0, 0, 0.04);

    .MuiAccordionSummary-root {
        // setting padding here (vs the container) allows the
        // whole header/summary to be clickable for expand/contract
        padding: 24px;
        min-height: unset;
    }

    .MuiAccordionDetails-root {
        margin: 0px 24px 24px;
    }
`;

export { WebstoreFaqsStyled, StyledAccordion };
