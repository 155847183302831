import { useEffect } from 'react';
import { SiteLoaderStyled } from './SiteLoader.styled';
import { IconName, IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';
import logo from '../../../assets/logos/Pearl_Launch_Logo_Black_On_Clear_Tinyfied.png';
import { newPageTrigger } from 'LivePerson';

const SiteLoader = () => {
    useEffect(() => {
        newPageTrigger(window.location.href);
    }, [window.location.href]);

    return (
        <SiteLoaderStyled id="site-loader">
            <div className="loader">
                <img className={'logo'} src={logo} alt={'#'} />
                <div className={'icons'}>
                    <div className="icon">
                        <PearlIcon iconName={IconName.DIAMOND_FILLED} size={IconSizes.MD} />
                    </div>
                    <div className="icon">
                        <PearlIcon iconName={IconName.DIAMOND_FILLED} size={IconSizes.MD} />
                    </div>
                    <div className="icon">
                        <PearlIcon iconName={IconName.DIAMOND_FILLED} size={IconSizes.MD} />
                    </div>
                    <div className="icon">
                        <PearlIcon iconName={IconName.DIAMOND_FILLED} size={IconSizes.MD} />
                    </div>
                    <div className="icon">
                        <PearlIcon iconName={IconName.DIAMOND_FILLED} size={IconSizes.MD} />
                    </div>
                </div>
            </div>
        </SiteLoaderStyled>
    );
};

export { SiteLoader };
