import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { PearlErrorStyled } from './PearlError.styled.js';

const pearlErrorClass = 'pearl-error';

const PearlError = ({ name }) => {
    const {
        formState: { errors },
    } = useFormContext();

    return (
        <ErrorMessage
            className={`${pearlErrorClass} ${pearlErrorClass}-${name}`}
            errors={errors}
            name={name}
            as={PearlErrorStyled}
        />
    );
};

export { PearlError, pearlErrorClass };
