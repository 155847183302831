import { BreakpointSizes } from 'dbi-pearl-ui-kit';
import styled from 'styled-components';

const AccordionStyled = styled.div`
    .accordion-toggle {
        display: none;
    }

    .accordion-group-label {
        margin-top: 0px;
        margin-bottom: 24px;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary.base};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;
        cursor: pointer;
        transition: all 0.25s ease-out;

        & .sub-label {
            padding: 2px 24px 0px 24px;
            margin: 0px 0px 0px auto;
            color: ${(props) => props.theme.colors.foreground.text};
        }

        & > span {
            color: ${(props) => props.theme.colors.foreground.text};
        }
    }

    .accordion-group-label {
        text-align: start;
    }

    .accordion-group-label:hover {
        color: ${(props) => props.theme.colors.primary.hover};
    }

    .collapsible-content {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.25s ease-in-out;
    }

    .content-inner {
        display: none;
    }

    // VH is problematic but it's ONLY used for the accordion animation
    // and the height of the once-hidden ToggleButtons
    .accordion-toggle:checked + .accordion-group-label + .collapsible-content {
        max-height: 200vh;
    }

    .accordion-toggle:checked ~ .collapsible-content .content-inner {
        display: block;
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} { {
        .accordion-group-label {
            flex-wrap: wrap;

            & .sub-label {
                padding: 0;
                order: 1;
                margin: 0;
                flex-basis: 100%;
            }
        }
    }
`;

export { AccordionStyled };
