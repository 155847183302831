import styled from 'styled-components';

const avatarSize = 48;

const AvatarStyled = styled.div`
    margin: 0;
    padding: 0;
    width: ${avatarSize}px;
    min-width: ${avatarSize}px;
    height: ${avatarSize}px;
    border: 1px solid ${(props) => (props.showBorder ? props.theme.colors.foreground.text : 'none')};
    border-radius: 50%;
    text-align: center;
    user-select: none;

    .avatar-image {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .avatar-text {
        margin: ${Math.floor(avatarSize / 4)}px 0 0;

        p {
            margin: 0;
        }
    }

    .avatar-default {
        margin: ${Math.floor(avatarSize / 3.5)}px 0 0;
    }
`;

export { AvatarStyled };
