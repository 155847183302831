import { APP_ROUTES } from 'shared/constants/navigation';

const RequireNonVendor = (context) => {
    if (context.store.isAuthedVendor) {
        return APP_ROUTES.vendor.root;
    }

    return 'next';
};

export { RequireNonVendor };
