const installBluecore = () => {
    // eslint-disable-next-line
    const triggermail = {};
    triggermail.load = function (a) {
        const b = document.createElement('script');
        b.type = 'text/javascript';
        b.async = !0;
        b.src = 'https://api.bluecore.com/triggermail.js/' + a + '.js';
        a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(b, a);
    };
    triggermail.load('davids_bridal_pearl');
    window.triggermail = triggermail;
};

export { installBluecore };
