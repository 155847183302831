import React from 'react';
import { ServiceAreaSectionEditStyled } from './ServiceAreaSectionEdit.styled';
import { SectionEditHeader } from '../SectionEditHeader/SectionEditHeader';
import { ServiceAreaForm } from '../../ServiceAreaForm/ServiceAreaForm';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';

const ServiceAreaSectionEdit = () => {
    const { webstore } = useWebstoreContext();

    return (
        <ServiceAreaSectionEditStyled className="section-header">
            <SectionEditHeader
                title="Forms.ServiceArea.EditSectionTitle"
                description="Forms.ServiceArea.EditSectionDescription"
            />
            <ServiceAreaForm webstore={webstore} showOnlineOptions={true} />
        </ServiceAreaSectionEditStyled>
    );
};

export { ServiceAreaSectionEdit };
