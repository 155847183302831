import React from 'react';
import { TextInput, GhostButton } from 'dbi-pearl-ui-kit';
import {
    FormRow,
    PearlError,
    loadAutocomplete,
    addressFieldToGoogleResponseMapping,
} from 'framework';
import { BasicsEditFormStyled } from './BasicsEditForm.styled';
import { SectionEditHeader } from '../SectionEditHeader/SectionEditHeader';
import { useStore } from 'stores/Store';
import { Capacitor, Plugins } from '@capacitor/core';
import { openWindow } from 'vendor/lib/WindowService';
import { useTranslation } from 'react-i18next';
import { VendorCategorySelectDropdown } from 'vendor/components/VendorCategorySelectDropdown';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { formFieldNames } from 'framework/lib/WebstoreAddressService/WebstoreAddressService';

const { Browser } = Plugins;

const BasicsEditForm = (props) => {
    const { displayUrlInfo } = props;
    const { webstore } = useStore();
    const { t } = useTranslation();
    const { setValue, watch } = useFormContext();

    const [queryRef, setQueryRef] = useState(null);
    const [svcLocationQueryResult, setSvcLocationQueryResult] = useState('');

    const businessAddressFieldName = 'BusinessAddress';
    const textBoxRef = '#business-address-autocomplete';
    const focusOnLocationSelection = '#business-address-wrapper';

    const hiddenFieldNames = {
        street1: formFieldNames.BusinessAddressStreet1,
        street2: formFieldNames.BusinessAddressStreet2,
        city: formFieldNames.BusinessAddressCity,
        state: formFieldNames.BusinessAddressState,
        zipcode: formFieldNames.BusinessAddressZipcode,
    };

    const businessAddressField = watch(businessAddressFieldName);

    useEffect(() => {
        if (svcLocationQueryResult && svcLocationQueryResult.address_components) {
            addressFieldToGoogleResponseMapping(setValue, svcLocationQueryResult, hiddenFieldNames);
        }
    }, [svcLocationQueryResult]);

    useEffect(() => {
        if (queryRef) {
            const autocompleteOptions = {
                searchTypes: ['address'],
                setFields: ['address_components', 'formatted_address', 'geometry'],
                focusRef: focusOnLocationSelection,
                scriptId: 'business-address-autocomplete-script',
            };

            loadAutocomplete(setSvcLocationQueryResult, queryRef, autocompleteOptions);

            if (!svcLocationQueryResult && businessAddressField?.length > 0) {
                const commaCount = businessAddressField.match(/,/g)?.length;

                setValue('BusinessAddress', businessAddressField);
                setValue(hiddenFieldNames.street1, businessAddressField.split(',')?.[0]?.trim());

                if (commaCount < 4) {
                    setValue(hiddenFieldNames.city, businessAddressField.split(',')?.[1]?.trim());
                    setValue(hiddenFieldNames.state, businessAddressField.split(',')?.[2]?.trim());
                    setValue(
                        hiddenFieldNames.zipcode,
                        businessAddressField.split(',')?.[3]?.trim()
                    );
                } else {
                    setValue(
                        hiddenFieldNames.street2,
                        businessAddressField.split(',')?.[1]?.trim()
                    );
                    setValue(hiddenFieldNames.city, businessAddressField.split(',')?.[2]?.trim());
                    setValue(hiddenFieldNames.state, businessAddressField.split(',')?.[3]?.trim());
                    setValue(
                        hiddenFieldNames.zipcode,
                        businessAddressField.split(',')?.[4]?.trim()
                    );
                }
            }
        } else {
            const ref = document.querySelector(textBoxRef);
            setQueryRef(ref);
        }
    }, [queryRef, businessAddressField]);

    const visitPearlPageButtonHandler = () => {
        const webstorePreviewUrl = process.env.REACT_APP_BASE_URL + '/' + webstore.WebstoreUrl;
        if (Capacitor.isNativePlatform()) {
            Browser.open({ url: webstorePreviewUrl });
        } else {
            openWindow(webstorePreviewUrl);
        }
    };

    return (
        <BasicsEditFormStyled>
            <SectionEditHeader title="WebstoreSections.VendorBasics.Title" />
            <FormRow
                columns={[
                    <div key={0}>
                        <TextInput
                            formFieldName="WebstoreName"
                            providedLabel="Forms.EditBasics.BusinessNameLabel"
                        />
                        <PearlError name={'WebstoreName'} />
                    </div>,
                    <div
                        key={1}
                        data-testid="business-address-wrapper"
                        id="business-address-wrapper"
                    >
                        <TextInput
                            formFieldName={businessAddressFieldName}
                            name={businessAddressFieldName}
                            id="business-address-autocomplete"
                            data-testid="business-address-autocomplete"
                            providedLabel="Forms.EditBasics.BusinessLocationLabel"
                        />
                        <PearlError name={'BusinessAddress'} />
                    </div>,
                ]}
            />
            <FormRow
                columns={[
                    <div key={0} className="basics-category">
                        <VendorCategorySelectDropdown mini={true} />
                    </div>,
                ]}
            />
            {!!displayUrlInfo && (
                <FormRow
                    columns={[
                        <div key={0}>
                            <TextInput
                                formFieldName="WebstoreUrl"
                                data-testid="webstore-url"
                                disabled={!webstore.HasBeenPublished && !webstore.WebstoreUrl}
                                providedLabel="Forms.Labels.PearlWebstoreUrl"
                                preText={`${window.location.origin.replace(
                                    /^http(s?):\/\//i,
                                    ''
                                )}/`}
                            />
                            <PearlError name={'WebstoreUrl'} />
                        </div>,
                        <div key={1}>
                            <GhostButton
                                type="button"
                                className="visit-pearl-page"
                                onClick={visitPearlPageButtonHandler}
                                variant={'accent'}
                                data-testid="preview-webstore-button"
                                disabled={!webstore.HasBeenPublished && !webstore.WebstoreUrl}
                            >
                                {t('VisitWebstore')}
                            </GhostButton>
                        </div>,
                    ]}
                />
            )}
        </BasicsEditFormStyled>
    );
};

export { BasicsEditForm };
