import { useTranslation } from 'react-i18next';
import { StoreFront } from 'assets/Defaults';
import { UnauthedLayout } from 'shared/components/UnauthedLayout/UnauthedLayout';
import { APP_ROUTES } from 'shared/constants/navigation';
import { useNavigate } from 'react-router-dom';

const BodyContent = () => {
    const { t } = useTranslation();

    return <p>{t('Pages.UnauthedDashboard.InstructionsSubtext')}</p>;
};

const DashboardUnauthedPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const redirectTo = {
        eventUser: APP_ROUTES.dashboard,
        vendorUser: APP_ROUTES.vendor.dashboard,
    };

    const loginClickHandler = () => {
        navigate(APP_ROUTES.auth.login, {
            state: { redirect: redirectTo },
        });
    };

    return (
        <UnauthedLayout
            image={StoreFront}
            translatedAltText={t('Pages.UnauthedDashboard.GifAltText')}
            loginClickHandler={loginClickHandler}
            header="Pages.UnauthedDashboard.LoginInstructions"
            bodyContent={<BodyContent />}
        />
    );
};

export { DashboardUnauthedPage };
