// TODO: swap state/province dropdown selector for localized list
const stateOptions = [
    { label: 'ALABAMA', value: 'AL' },
    { label: 'ALASKA', value: 'AK' },
    { label: 'AMERICAN SAMOA', value: 'AS' },
    { label: 'ARIZONA', value: 'AZ' },
    { label: 'ARKANSAS', value: 'AR' },
    { label: 'CALIFORNIA', value: 'CA' },
    { label: 'COLORADO', value: 'CO' },
    { label: 'CONNECTICUT', value: 'CT' },
    { label: 'DELAWARE', value: 'DE' },
    { label: 'DISTRICT OF COLUMBIA', value: 'DC' },
    { label: 'FEDERATED STATES OF MICRONESIA', value: 'FM' },
    { label: 'FLORIDA', value: 'FL' },
    { label: 'GEORGIA', value: 'GA' },
    { label: 'GUAM', value: 'GU' },
    { label: 'HAWAII', value: 'HI' },
    { label: 'IDAHO', value: 'ID' },
    { label: 'ILLINOIS', value: 'IL' },
    { label: 'INDIANA', value: 'IN' },
    { label: 'IOWA', value: 'IA' },
    { label: 'KANSAS', value: 'KS' },
    { label: 'KENTUCKY', value: 'KY' },
    { label: 'LOUISIANA', value: 'LA' },
    { label: 'MAINE', value: 'ME' },
    { label: 'MARSHALL ISLANDS', value: 'MH' },
    { label: 'MARYLAND', value: 'MD' },
    { label: 'MASSACHUSETTS', value: 'MA' },
    { label: 'MICHIGAN', value: 'MI' },
    { label: 'MINNESOTA', value: 'MN' },
    { label: 'MISSISSIPPI', value: 'MS' },
    { label: 'MISSOURI', value: 'MO' },
    { label: 'MONTANA', value: 'MT' },
    { label: 'NEBRASKA', value: 'NE' },
    { label: 'NEVADA', value: 'NV' },
    { label: 'NEW HAMPSHIRE', value: 'NH' },
    { label: 'NEW JERSEY', value: 'NJ' },
    { label: 'NEW MEXICO', value: 'NM' },
    { label: 'NEW YORK', value: 'NY' },
    { label: 'NORTH CAROLINA', value: 'NC' },
    { label: 'NORTH DAKOTA', value: 'ND' },
    { label: 'NORTHERN MARIANA ISLANDS', value: 'MP' },
    { label: 'OHIO', value: 'OH' },
    { label: 'OKLAHOMA', value: 'OK' },
    { label: 'OREGON', value: 'OR' },
    { label: 'PALAU', value: 'PW' },
    { label: 'PENNSYLVANIA', value: 'PA' },
    { label: 'PUERTO RICO', value: 'PR' },
    { label: 'RHODE ISLAND', value: 'RI' },
    { label: 'SOUTH CAROLINA', value: 'SC' },
    { label: 'SOUTH DAKOTA', value: 'SD' },
    { label: 'TENNESSEE', value: 'TN' },
    { label: 'TEXAS', value: 'TX' },
    { label: 'UTAH', value: 'UT' },
    { label: 'VERMONT', value: 'VT' },
    { label: 'VIRGIN ISLANDS', value: 'VI' },
    { label: 'VIRGINIA', value: 'VA' },
    { label: 'WASHINGTON', value: 'WA' },
    { label: 'WEST VIRGINIA', value: 'WV' },
    { label: 'WISCONSIN', value: 'WI' },
    { label: 'WYOMING', value: 'WY' },
];

const stateOptionsCapitalized = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'Californa', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District Of Columbia', value: 'DC' },
    { label: 'Federated States of Micronesia', value: 'FM' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Guam', value: 'GU' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Palau', value: 'PW' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virgin Islands', value: 'VI' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
];

export default stateOptions;
export { stateOptionsCapitalized };
