import styled from 'styled-components';

const WebstoreAwardStyled = styled.div`
    width: 260px;
    height: 214px;

    a,
    a:link,
    a:visited,
    a:active {
        text-decoration: none;
    }

    .award-image {
        height: 174px;
        width: 100%;

        border: 1px solid ${(props) => props.theme.colors.foreground.border};
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
            0px 0px 1px rgba(0, 0, 0, 0.04);

        .placeholder {
            object-fit: contain;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .award-content {
        margin: 13px 0 0 0;
        padding: 0px;

        .p1 {
            color: ${(props) => props.theme.colors.foreground.text};
            text-align: center;
            margin: 0px;
            padding: 0px;
        }
    }
`;

export { WebstoreAwardStyled };
