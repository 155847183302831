import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'i18n';
import './index.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { installBluecore } from './Bluecore';
import { installTealium } from './Tealium';
import { installLivePerson } from './LivePerson';
import { pearlTxt } from './constants/pearl';
import { checkAndApplyImpersonation } from './impersonation';

console.log('%c%s', 'color: #E10098;', pearlTxt);

const isProduction = process.env.NODE_ENV === 'production';
const disableLivePerson = process.env.REACT_APP_LIVEPERSON_DISABLED === '1';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
installTealium();
installBluecore();
!disableLivePerson && installLivePerson();

const rootElement = document.getElementById('root');

const isTouchDevice = () => 'ontouchstart' in window;

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

checkAndApplyImpersonation();

if (!isProduction) {
    import('@axe-core/react').then((axe) => {
        axe.default(React, ReactDOM, 1000);
        ReactDOM.render(
            <DndProvider backend={backendForDND}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </DndProvider>,
            rootElement
        );
    });
} else {
    ReactDOM.render(
        <DndProvider backend={backendForDND}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </DndProvider>,
        rootElement
    );
}
