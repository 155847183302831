import { WebstoreAwards } from 'framework';
import { useTranslation } from 'react-i18next';
import { NoInformationProvided } from '../NoInformationProvided/NoInformationProvided';
import { UpgradeSubscription } from '../UpgradeSubscription/UpgradeSubscription';
import { IconName } from 'dbi-pearl-ui-kit';

const AwardsSection = (props) => {
    const { webstore, sectionRef, sectionId, showFlaggedOverlay } = props;
    const { t } = useTranslation();
    const needsUpgrade = !webstore.ActiveFeatures?.feature_awards;

    return (
        <>
            {webstore.Awards?.length ? (
                <WebstoreAwards
                    webstore={webstore}
                    sectionRef={sectionRef}
                    sectionId={sectionId}
                    showFlaggedOverlay={showFlaggedOverlay}
                    iconName={needsUpgrade ? IconName.LOCK : null}
                    banner={!!needsUpgrade && <UpgradeSubscription />}
                />
            ) : (
                <NoInformationProvided
                    sectionRef={sectionRef}
                    sectionId={sectionId}
                    title={t('WebstoreSections.Awards.Title')}
                    iconName={needsUpgrade ? IconName.LOCK : null}
                    banner={!!needsUpgrade && <UpgradeSubscription />}
                />
            )}
        </>
    );
};

export { AwardsSection };
