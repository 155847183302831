import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RouteGuard = ({ children, ...props }) => {
    const [passed, setPassed] = useState(false);
    const { guards, context } = props;

    const navigate = useNavigate();

    useEffect(() => {
        let redirect = null;

        for (let i = 0; i < guards.length; i++) {
            const guard = guards[i];
            const result = guard(context);

            if (result !== 'next') {
                redirect = result;
                break;
            }
        }

        if (redirect) {
            navigate(redirect);
        } else {
            setPassed(true);
        }

        return () => {};
    }, [guards, context, navigate]);

    return passed && children;
};

export default RouteGuard;
