import { UnauthedLayoutStyled } from './UnauthedLayout.styled';
import { useTranslation } from 'react-i18next';
import { SolidButton } from 'dbi-pearl-ui-kit';
import { AccountSetupStepGraphic, useScreenSize } from 'framework';
import { EmptyCaptions } from 'assets/Defaults';

const UnauthedLayout = (props) => {
    const { image, translatedAltText, loginClickHandler, header, bodyContent } = props;
    const { t } = useTranslation();
    const screenSize = useScreenSize();

    return (
        <UnauthedLayoutStyled data-testid="layout-container">
            <div className="graphic">
                <AccountSetupStepGraphic
                    image={image}
                    translatedAltText={translatedAltText}
                    captions={EmptyCaptions}
                />
            </div>
            <div className="contentContainer">
                {screenSize.small && <h3 className="contentHeader">{t(`${header}`)}</h3>}
                {!screenSize.small && <h1 className="contentHeader">{t(`${header}`)}</h1>}
                {bodyContent}
                <SolidButton className="loginButton" type="submit" onClick={loginClickHandler}>
                    {t('Forms.Buttons.LoginButton')}
                </SolidButton>
            </div>
        </UnauthedLayoutStyled>
    );
};

export { UnauthedLayout };
