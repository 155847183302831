import styled from 'styled-components';

const MediaEditStyled = styled.form`
    .edit-container {
        display: grid;

        .media-preview-container {
            grid-column: 1;
            grid-row: 1;

            display: grid;
            grid-template-rows: max-content auto;

            .media-flagged {
                grid-column: 1;
                grid-row: 1;

                padding-bottom: 10px;

                text-align: center;

                .icon-warning {
                    color: ${(props) => props.theme.colors.error.base};
                    font-weight: bold;
                    font-size: xx-large;
                }

                .flagged-icon {
                    margin-top: 3px;
                }

                .flagged-header {
                    margin-top: 3px;
                }

                .flagged-text {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                .flagged-contact {
                    padding-bottom: 1px;
                    border-bottom: solid 2px ${(props) => props.theme.colors.foreground.base};
                }
            }

            .media-preview {
                grid-column: 1;
                grid-row: 2;
                width: 100%;
                height: 100%;
                background-size: contain !important;
                background-repeat: no-repeat !important;
                background-position: center center !important;
            }
        }

        .edit-form {
            grid-column: 2;
            grid-row: 1;
            margin-left: 24px;
            border: solid 1px ${(props) => props.theme.colors.foreground.border};
            padding: 40px 40px 24px 40px;

            div.label {
                color: ${(props) => props.theme.colors.primary.base};
                display: block;
            }

            label {
                padding: 0px;
                height: auto;
            }

            .coverPhotoLabel {
                margin-bottom: 16px;
            }

            .captionLabel {
                margin-top: 24px;
                margin-bottom: 8px;
            }

            p {
                display: inline;
            }

            textarea {
                display: block;
                width: 100%;
            }

            .saveButton {
                width: 100%;
            }
        }

        .action-buttons {
            grid-column: 1;
            grid-row: 2;
            margin-top: 16px;
            text-align: center;
        }

        .media-edit-error-wrapper {
            height: 0px;

            div {
                position: relative;
                top: -24px;
            }
        }
    }

    @media (max-width: 815px) {
        .edit-container {
            grid-template-rows: 1fr 60px 1fr;

            .edit-form {
                grid-column: 1;
                grid-row: 3;
                border: none;
                padding: 0;
                margin-left: 0;
            }

            .action-buttons {
                grid-row: 2;
            }
        }
    }
`;

export { MediaEditStyled };
