import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ToggleButtonGroup,
    ToggleButton,
    ToggleSwitch,
    IconName,
    TextInput,
    PearlIcon,
    IconSizes,
} from 'dbi-pearl-ui-kit';
import {
    FormRow,
    PearlError,
    loadAutocomplete,
    addressFieldToGoogleResponseMapping,
} from 'framework';
import { formFieldNames } from '../Functions/ServiceAreaForm.Functions';
import { PhysicalLocationOptionsStyled } from './PhysicalLocationOptions.styled';

const updateMap = (mapFunctions, svcLocationQueryResult) => {
    if (mapFunctions) {
        const { setMapCenter, setMapZoom, setMapAlt } = mapFunctions;

        setMapCenter(svcLocationQueryResult.geometry.location.toJSON());
        setMapZoom(15);
        setMapAlt(svcLocationQueryResult.formatted_address);
    }
};

const hiddenFieldNames = {
    street1: 'ServiceAddressStreet',
    city: 'ServiceAddressCity',
    state: 'ServiceAddressState',
    zipcode: 'ServiceAddressZipcode',
};

const PhysicalLocationOptions = (props) => {
    const { physicalStoreOptionsClickHandler, mapFunctions, webstore } = props;

    const { setValue, watch, getValues } = useFormContext();
    const { t } = useTranslation();

    const [queryRef, setQueryRef] = useState(null);
    const [svcLocationQueryResult, setSvcLocationQueryResult] = useState('');

    const addressTypeField = watch(formFieldNames.LocationAddressType);
    const serviceAddressField = watch(formFieldNames.ServiceAddress);

    const [displayAddressInput, setDisplayAddressInput] = useState(
        addressTypeField === formFieldNames.ServiceAddress
    );

    useEffect(() => {
        if (addressTypeField === formFieldNames.ServiceAddress) {
            setDisplayAddressInput(true);
        } else {
            setDisplayAddressInput(false);
        }
    }, [addressTypeField]);

    useEffect(() => {
        if (queryRef) {
            const autocompleteOptions = {
                searchTypes: ['address'],
                setFields: ['address_components', 'formatted_address', 'geometry'],
                focusRef: 'label[role="checkbox"]',
                scriptId: 'physical-location-options-autocomplete',
            };
            loadAutocomplete(setSvcLocationQueryResult, queryRef, autocompleteOptions);

            if (!svcLocationQueryResult && serviceAddressField?.length > 0) {
                setValue(formFieldNames.ServiceAddress, serviceAddressField);
                setValue(
                    formFieldNames.ServiceAddressStreet,
                    serviceAddressField.split(',')?.[0]?.trim()
                );
                setValue(
                    formFieldNames.ServiceAddressCity,
                    serviceAddressField.split(',')?.[1]?.trim()
                );
                setValue(
                    formFieldNames.ServiceAddressState,
                    serviceAddressField.split(',')?.[2]?.trim()
                );
                setValue(
                    formFieldNames.ServiceAddressZipcode,
                    serviceAddressField.split(',')?.[3]?.trim()
                );
            }
        } else {
            const ref = document.querySelector('#service-address');
            setQueryRef(ref);
        }
    }, [queryRef, serviceAddressField]);

    useEffect(() => {
        if (svcLocationQueryResult && svcLocationQueryResult.address_components) {
            addressFieldToGoogleResponseMapping(setValue, svcLocationQueryResult, hiddenFieldNames);

            if (mapFunctions) {
                updateMap(mapFunctions, svcLocationQueryResult);
            }
        }
    }, [svcLocationQueryResult]);

    return (
        <PhysicalLocationOptionsStyled className={`display-`}>
            <p className="option-question">
                <b>{t('Forms.ServiceArea.ServiceAddress')}</b>
            </p>
            <div className="feature-flag-container">
                <div className="feature-flag">
                    <PearlIcon iconName={IconName.INFO} size={IconSizes.MD} />
                    <p className="p2">{t('Forms.ServiceArea.AdditionalLocationsTip')}</p>
                </div>
            </div>
            <div className="address-type">
                <ToggleButtonGroup
                    groupName={formFieldNames.LocationAddressType}
                    exclusive={true}
                    onChange={() => {}}
                    selectedGroupItems={[addressTypeField]}
                >
                    <ToggleButton
                        label={''}
                        iconName={null}
                        value={formFieldNames.BusinessAddress}
                        onClick={(e) =>
                            physicalStoreOptionsClickHandler(
                                setValue,
                                webstore,
                                formFieldNames.BusinessAddress,
                                getValues
                            )
                        }
                        className={`toggle-button${
                            addressTypeField === formFieldNames.BusinessAddress ? '-selected' : ''
                        }`}
                    >
                        <span
                            data-testid="business-selector"
                            className={`radio-button${
                                addressTypeField === formFieldNames.BusinessAddress
                                    ? '-selected'
                                    : ''
                            }`}
                        />
                        <p>{t('Forms.ServiceArea.ServiceAddressSame')}</p>
                    </ToggleButton>
                    <ToggleButton
                        label={''}
                        iconName={null}
                        value={formFieldNames.ServiceAddress}
                        onClick={() =>
                            physicalStoreOptionsClickHandler(
                                setValue,
                                webstore,
                                formFieldNames.ServiceAddress,
                                getValues
                            )
                        }
                        className={`toggle-button${
                            addressTypeField === formFieldNames.ServiceAddress ? '-selected' : ''
                        }`}
                    >
                        <span
                            data-testid="service-address-selector"
                            className={`radio-button${
                                addressTypeField === formFieldNames.ServiceAddress
                                    ? '-selected'
                                    : ''
                            }`}
                        />
                        <p>{t('Forms.ServiceArea.ServiceAddressDifferent')}</p>
                    </ToggleButton>
                </ToggleButtonGroup>
                <PearlError name={formFieldNames.LocationAddressType} />
            </div>
            {!!displayAddressInput && (
                <FormRow
                    columns={[
                        <div key={0}>
                            <TextInput
                                formFieldName={formFieldNames.ServiceAddress}
                                name={formFieldNames.ServiceAddress}
                                id="service-address"
                                data-testid="service-address"
                            />
                            <PearlError name={formFieldNames.ServiceAddress} />
                        </div>,
                    ]}
                />
            )}
            <FormRow
                columns={[
                    <div key={0} className="checkboxGroup displayToggle">
                        <div>
                            <h5 className="toggle-title ">
                                {t('Forms.ServiceArea.DisplayToggle')}
                            </h5>
                            <p className="toggle-subtitle p2">
                                {t('Forms.ServiceArea.DisplayToggleSubtitle')}
                            </p>
                        </div>
                        <ToggleSwitch
                            checked={getValues(formFieldNames.DisplayStoreAddress)}
                            onChange={(e) => {
                                setValue(formFieldNames.DisplayStoreAddress, e);
                            }}
                            name={formFieldNames.DisplayStoreAddress}
                        />
                    </div>,
                ]}
            />
        </PhysicalLocationOptionsStyled>
    );
};

export { PhysicalLocationOptions };
