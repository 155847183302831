import React from 'react';
import { CapacitySectionStyled } from './CapacitySection.styled';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'dbi-pearl-ui-kit';
import { PearlError, categoryExternalIds } from 'framework';
import { useWebstoreContext } from '../../../contexts/WebstoreContext/WebstoreContext';

function CapacitySection() {
    const { t } = useTranslation();
    const { webstore } = useWebstoreContext();

    const formName =
        webstore.VendorCategoryExternalId === categoryExternalIds.venues
            ? 'VenueCapacity'
            : 'LodgingCapacity';

    return (
        <CapacitySectionStyled>
            <TextInput
                formFieldName="Capacity"
                providedLabel={t(`Forms.${formName}.MaxGuestCapacity`)}
            />
            <PearlError name="Capacity" />
        </CapacitySectionStyled>
    );
}

export { CapacitySection };
