import { BreakpointSizes } from 'dbi-pearl-ui-kit';
import styled from 'styled-components';

export default styled.footer`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    background: ${(props) => props.theme.colors.background.container};
    box-shadow: 0px -1px 8px rgba(19, 33, 34, 0.08);

    .upper-footer {
        padding: 48px;
        display: flex;
        gap: 64px;
        align-items: center;

        ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
            padding: 24px 32px 48px;
            flex-direction: column;
            gap: 32px;
        }

        a {
            color: ${(props) => props.theme.colors.foreground.text};
        }

        .social {
            display: flex;
            flex-direction: column;

            .logo {
                width: 112px;
                margin-bottom: 18px;
            }

            .icons-label {
                margin-bottom: 12px;
                margin-top: 12px;
            }

            .icons {
                display: flex;
                gap: 24px;
                align-items: center;
            }

            .app-buttons {
                img {
                    width: 134px;
                }
            }

            ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
                align-items: center;
                text-align: center;
            }
        }

        .divider {
            background-color: ${(props) => props.theme.colors.foreground.border};
            height: 1px;
            width: 33%;
            display: none;

            ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
                display: block;
            }
        }

        .links {
            flex-grow: 1;
            display: flex;
            gap: 24px;

            .column {
                padding: 8px;
                display: flex;
                flex-direction: column;
                flex-basis: 25%;

                a {
                    text-decoration: none;
                    margin: 4px 0;
                }
            }

            .label {
                margin-bottom: 16px;
            }

            .name {
                margin-bottom: 16px;
                font-family: 'Objektiv Mk2 Bold';
            }

            .spaced {
                margin-top: 16px;
            }

            ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
                flex-wrap: wrap;
                justify-content: space-between;

                .column {
                    flex-basis: 40%;
                }
            }
        }
    }

    .lower-footer {
        color: ${(props) => props.theme.colors.accent.contrast};
        background: ${(props) => props.theme.colors.accent.base};
        padding: 21px 60px;
        display: flex;
        flex-direction: column;

        ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
            padding: 15px 24px 84px;
            text-align: center;
        }

        .links {
            margin-top: 5px;

            a {
                text-decoration: none;
                color: ${(props) => props.theme.colors.accent.contrast};

                &:not(:first-child) {
                    margin-left: 5px;
                }

                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
    }
`;
