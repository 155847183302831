import React from 'react';
import { ChatBubble } from './ChatBubble/ChatBubble';
import { QuoteRequest } from './Quote/QuoteRequest/QuoteRequest';
import { MessageTypes } from '../MessageTypes';

const ChatElement = ({ message }) => {
    const comps = new Map([
        [MessageTypes.QUOTE_REQUEST, QuoteRequest],
        [MessageTypes.TEXT, ChatBubble],
        [MessageTypes.IMAGE, ChatBubble],
        [MessageTypes.ATTACHMENT, ChatBubble],
    ]);

    const Comp = comps.get(message.type) || ChatBubble;

    return <Comp message={message} />;
};

export { ChatElement };
