import styled from 'styled-components';

const PartnersSectionEditStyled = styled.div`
    box-sizing: border-box;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
        0px 0px 1px rgba(0, 0, 0, 0.04);
    padding: 16px;
    margin-bottom: 16px;

    .search-results {
        display: flex;
        flex-direction: column;
        font-size: 8px;
        height: 100%;
        max-height: 380px;
        overflow-y: scroll;
        ::-webkit-scrollbar {
            display: none;
        }
        margin-top: 8px;

        .result {
            padding-left: 16px;
            padding-top: 5px;
            height: 80px;
            display: grid;
            background: none;
            grid-template-columns: 0fr 1fr 3fr;
            grid-template-rows: 1fr 1fr 1fr;
            gap: 0px 0px;

            .info {
                display: flex;
                flex-direction: column;
                margin-left: 16px;
                width: max-content;
            }

            .details {
                display: flex;
                flex-direction: row;
                font-size: 10px;
            }

            .separator {
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        .result:hover {
            background: ${(props) => props.theme.colors.background.base};
            cursor: pointer;
        }
    }

    .active {
        display: flex;
    }

    .header {
        display: flex;
        justify-content: space-between;

        .title {
            font-weight: 700;
            display: flex;
            align-items: center;
        }

        .buttons {
            display: flex;

            button {
                border: none;
            }

            .icon-trash {
                color: ${(props) => props.theme.colors.error.base};
            }
        }
    }

    .form {
        flex-direction: column;
    }

    .form-open {
        display: flex;
    }

    .form-closed {
        display: none;
    }

    .selectedResultsContainer {
        .separator {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .details {
        display: flex;
        flex-direction: row;
        width: max-content;
    }
    .resultTitle {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        button {
            background: none;
            border: none;
        }

        text-transform: unset;
    }
`;

export { PartnersSectionEditStyled };
