import styled from 'styled-components';

const UnreadMessageDot = styled.div`
    height: 10px;
    width: 10px;
    background-color: ${(props) => props.theme.colors.error.base};
    border-radius: 50%;
`;

export { UnreadMessageDot };
