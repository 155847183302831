import styled from 'styled-components';

const HamburgerSectionsStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    color: ${(props) => props.theme.colors.foreground.text};
    background-color: transparent;
    overflow: auto;

    .pageContainer {
        transition: none;
        background-color: transparent;

        .MuiBackdrop-root {
            background-color: transparent;
            color: unset;
        }
    }

    a {
        text-decoration: none;
    }

    a,
    a:visited {
        color: ${(props) => props.theme.colors.foreground.text};
    }

    ul,
    li {
        list-style: none;
        margin-block: 0;
        padding-inline: 0;
    }

    hr {
        color: ${(props) => props.theme.colors.border};
        margin-block: 0;
    }

    .hamburgerSection {
        margin-top: 20px;

        .sectionTitle {
            display: flex;
            column-gap: 9px;
            padding-left: 24px;
            padding-bottom: 12px;
        }

        .sectionLinks {
            display: flex;
            flex-direction: column;
            padding-left: 53px;
            row-gap: 16px;

            .p2 {
                margin-block: 0;
            }
        }

        .sectionTitleLink {
            cursor: pointer;

            * {
                cursor: pointer;
            }
        }
    }
`;

export { HamburgerSectionsStyled };
