import styled from 'styled-components';

export default styled.div`
    max-width: 54px;
    padding: 16px;

    .googleLogoImage {
        max-height: 16px;
        left: 0;
    }
`;
