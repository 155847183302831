import styled from 'styled-components';

const EmptyInboxStyled = styled.div`
    text-align: center;
    padding-top: 40px;
    margin: 0 auto;

    & > span {
        color: ${(props) => props.theme.colors.foreground.secondary_icon};
        margin: 0;
    }

    p {
        margin: 4px 0 0 0;
    }
`;

export { EmptyInboxStyled };
