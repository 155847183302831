import React from 'react';
import styled from 'styled-components';

const QuoteSectionStyled = styled.div`
    p {
        margin: 0;
        padding: 0;
    }
`;

const QuoteSection = ({ title, content }) => (
    <QuoteSectionStyled className="quote-section">
        <p className="p2">
            <b>{title}</b>
        </p>
        <p className="p2">{content}</p>
    </QuoteSectionStyled>
);

export { QuoteSection };
