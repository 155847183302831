import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PearlToast, ToastStatuses } from '../components/PearlToast/PearlToast';

const PearlToastContext = createContext();
PearlToastContext.displayName = 'PearlToastContext';

const usePearlToast = () => {
    const { t } = useTranslation();
    const [, setToastContext] = useContext(PearlToastContext);

    const closeToast = () => {
        setToastContext({
            closeToast,
            isOpen: false,
        });
    };

    const showToast = ({ message, status }) => {
        closeToast();
        setToastContext({
            // inject close as prop for future portability
            // of the PearlToast component
            closeToast,
            message,
            isOpen: true,
            status,
        });
    };

    const toastSuccess = (message) => {
        showToast({
            message: message || t('Toast.Success'),
            status: ToastStatuses.SUCCESS,
        });
    };

    const toastError = (message) => {
        showToast({
            message: message || t('Toast.Error'),
            status: ToastStatuses.ERROR,
        });
    };

    const toastInfo = (message) => {
        showToast({
            message,
            status: ToastStatuses.INFO,
        });
    };

    return { toastSuccess, toastError, toastInfo };
};

const PearlToastProvider = ({ children }) => {
    const defaultToastContext = {
        isOpen: false,
    };

    const [toastContext, setToastContext] = useState(defaultToastContext);

    return (
        <PearlToastContext.Provider value={[toastContext, setToastContext]}>
            <PearlToast {...toastContext} />
            {children}
        </PearlToastContext.Provider>
    );
};

export { PearlToastProvider, usePearlToast };
