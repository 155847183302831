import { useTranslation } from 'react-i18next';
import { PenWriting } from 'assets/Defaults';
import { APP_ROUTES } from 'shared/constants/navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import { UnauthedLayout } from 'shared/components/UnauthedLayout/UnauthedLayout';
import { useAppName } from '../../hooks/AppName';
import { PearlApps } from '../../../framework';

const BodyContent = () => {
    const { t } = useTranslation();

    return <p>{t('Pages.UnauthedInbox.InstructionsSubtext')}</p>;
};

const InboxUnauthedPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { appName } = useAppName();
    const location = useLocation();

    const loginClickHandler = () => {
        const miniRouter = {
            [PearlApps.VENDOR]: APP_ROUTES.vendor.auth.login,
            [PearlApps.EVENT_USER]: APP_ROUTES.auth.login,
        };
        navigate(miniRouter[appName], {
            state: {
                redirect: location?.state?.redirect || APP_ROUTES.landing,
            },
        });
    };

    return (
        <UnauthedLayout
            image={PenWriting}
            translatedAltText={t('Pages.UnauthedInbox.GifAltText')}
            loginClickHandler={loginClickHandler}
            header="Pages.UnauthedInbox.LoginInstructions"
            bodyContent={<BodyContent />}
        />
    );
};

export { InboxUnauthedPage };
