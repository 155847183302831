import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

export default styled.div`
    display: grid;
    grid-template-columns: 35% 65%;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow-y: hidden;

    .rightSide {
        height: 100vh;
    }

    .innerRight {
        flex: 2 1;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 78px);
        position: relative;
        overflow-y: auto;
        background-color: ${(props) => props.theme.colors.background.container};
    }

    footer {
        height: 78px;
    }

    .stepHeader,
    .stepComponent {
        padding-left: 120px;
        padding-right: 120px;
        width: auto;

        ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.LG)} {
            padding-left: 16%;
            padding-right: 16%;
        }

        ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 20px;
        }
    }

    .stepHeader {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        & > :first-child.actions {
            height: 76px;
            ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
                height: 56px;
            }

            padding-top: 24px;
        }

        & > :first-child:not(.actions) {
            padding-top: 100px;
        }

        .eventUserTitles {
            margin: 20px 0 0;
        }
    }

    .stepComponent {
        min-height: 100px;
        margin-top: 20px;
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding-top: 0;
        ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
            padding-bottom: 20px;
            min-height: auto;
        }

        .formWrapper {
            padding-bottom: 100px;
            margin-bottom: 100px;

            ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }

    .formComponent {
        ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
            margin: 0px 0px 0px 0px;
        }
    }

    .image {
        display: flex;
        position: relative;
        width: 100%;
        height: 100vh;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        .logo {
            height: auto;
            width: 25%;
        }
    }

    .subtitle {
        color: ${(props) => props.theme.colors.foreground.secondary_text};
        margin-bottom: 0;
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        display: grid;
        grid-template-rows: 32% auto;
        grid-template-columns: unset;
        height: 100%;
        overflow-y: auto;
        width: 100vw;

        footer {
            position: sticky;
            position: -webkit-sticky;
            position: -moz-sticky;
            position: -ms-sticky;
            position: -o-sticky;
            bottom: 0;
            left: 0;
            width: 100%;
        }

        .image {
            height: 100%;
            flex: 1 0 auto;
            width: 100vw;
            max-width: unset;
        }

        .rightSide {
            height: 100%;
            width: 100vw;
        }

        .innerRight {
            height: calc(100% - 79px);
            overflow: visible;
        }

        .stepHeader {
            padding-top: 16px;
        }
    }
`;
