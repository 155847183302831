import { GeocodeService } from 'lib/apis/GeocodeService';
import topCities, { defaultCityLabel } from 'event-user/constants/topCities';

const GenericLocationParams = ':location';

const getCats = (category, searchCategory, appData) => {
    let cat;
    if (searchCategory) {
        cat = searchCategory;
    } else if (category) {
        cat = appData.getCategoryByExternalId(category);
    } else {
        // make sure we set something for category.
        // pick venues if nothing is set by the user
        // if for some reason venues doesn't exist but the category list does, pick the first one
        cat =
            appData.categoryList.find((c) => c.externalId === 'venues') || appData.categoryList[0];
    }
    return cat;
};

const shouldGeoCode = (loc) => {
    return !loc?.latitude || !loc?.longitude;
};

const buildParamsWithFilters = async (params) => {
    let locToUse = params.location;

    // if includeLocation is true and provided location is not valid, update it to be correct
    if (params.includeLocation) {
        if (!locToUse?.latitude && !locToUse?.longitude && !locToUse?.displayText) {
            const geocodeService = GeocodeService();

            locToUse = await deriveLocation(
                locToUse,
                params.event,
                params.citystate,
                geocodeService,
                params.appData,
                null
            );
        }
    }

    let cat = getCats(params.category, params.searchCategory, params.appData);

    let newFilters = {};

    if (params.createFiltersFromSearchParams) {
        newFilters = params.createFiltersFromSearchParams(
            params.searchParams,
            params.filters,
            cat,
            params.resultsIncludeFeatures,
            params.resultsExcludeFeatures,
            params.pageSize,
            params.currentPage
        );
    }

    return [locToUse, cat, newFilters];
};

const getSubCatsFromSearchParams = (cat, searchParams, filters) => {
    const subCat =
        searchParams
            .get('subCategories')
            ?.split('-')
            .map((s) => cat.subCategories?.find((sc) => s === sc.externalId)) ||
        filters?.subCategories;

    return subCat;
};

const getStylesFromSearchParams = (cat, searchParams, filters) => {
    const styles =
        searchParams
            .get('styles')
            ?.split('-')
            .map((s) => cat.styles?.find((ss) => s === ss.externalId)) || filters?.styles;

    return styles;
};

const getPricesFromSearchParams = (searchParams, filters, priceFilter) => {
    const prices =
        searchParams
            .get('price')
            ?.split('-')
            .map((p) => priceFilter.options?.find((o) => p === o.externalId)) ||
        filters?.priceTiers;

    return prices;
};

const getDate = (searchParams, filters) => {
    return searchParams.get('date') || filters?.availabilityDate;
};

const getName = (searchParams, filters) => {
    return searchParams.get('name') || filters?.name;
};

const getCapacityFromSearchParams = (searchParams, filters, capacityFilter) => {
    const capacity =
        searchParams
            .get('capacity')
            ?.split('-')
            .map((c) => capacityFilter.options.find((cc) => c === cc.externalId)) ||
        filters?.capacity;

    return capacity;
};

const getServicesFromSearchParams = (cat, searchParams, filters) => {
    const services =
        searchParams
            .get('services')
            ?.split('-')
            .map((s) =>
                cat.serviceTypes
                    .reduce((acc, curr) => [...acc, ...curr.services], [])
                    .find((st) => s === st.externalId)
            ) || filters?.serviceAmenities;

    return services;
};

const getShippingOptionsFromSearchParams = (searchParams, filters) => {
    return searchParams.get('shippingOptions')?.split('-').join(',') || filters?.shippingOptions;
};

const getTravelOptionsFromSearchParams = (searchParams, filters) => {
    return searchParams.get('travelOptions')?.split('-').join(',') || filters?.travelOptions;
};

const getCityState = (cityState) => {
    return cityState.split('-');
};

const geocodeCityState = async (loc, city, state, geocodeService) => {
    if (!!city && !!state) {
        loc = await geocodeService.getGeocodeByAddress(city, state);
    }

    return loc;
};

const deriveLocation = async (
    existingLocation,
    event,
    cityState,
    geocodeService,
    appData,
    searchContextLocation
) => {
    //return the existing location if it already exists
    if (
        existingLocation &&
        existingLocation.displayText &&
        existingLocation.latitude &&
        existingLocation.longitude
    ) {
        return existingLocation;
    } else {
        let updatedLocation;

        // if there is not a citystate param in the URL, configure location
        if (!cityState) {
            updatedLocation = await appData.getCurrentLocation(event);
        } else {
            const defaultCity = topCities.find((c) => c.label === defaultCityLabel);

            // if the cityState isn't the default city and doesn't have lat/long, then geocode it
            if (cityState !== defaultCity.urlFormat) {
                const [city, state] = getCityState(cityState);

                updatedLocation = await geocodeCityState(
                    searchContextLocation,
                    city,
                    state,
                    geocodeService
                );
            }
            // set the loc info to default city lat/long to avoid extra geocode cost
            else {
                updatedLocation = {
                    latitude: defaultCity.latitude,
                    longitude: defaultCity.longitude,
                    displayText: defaultCity.label,
                };
            }
        }
        return updatedLocation;
    }
};

const searchConstants = {
    premierePlacementFeatures:
        'feature_vipPlacement,feature_premiumPlacement,feature_secondaryPlacement',
};

const emptyFilters = {
    styles: null,
    priceTiers: null,
    availabilityDate: null,
    capacity: null,
    serviceAmenities: null,
    serviceAreas: null,
    name: null,
    travelOptions: null,
    shippingOptions: null,
    resultsIncludeFeatures: null,
    resultsExcludeFeatures: null,
    subCategories: null,
};

const buildDefaultSearchContext = (category, location, pageSize) => {
    const defaultSearchContext = {
        category: category || null,
        location: location || null,
        searchResults: null,
        numResults: null,
        filters: {
            subCategories: null,
            styles: null,
            priceTiers: null,
            availabilityDate: null,
            capacity: null,
            serviceAmenities: null,
            serviceAreas: null,
            travelOptions: null,
            name: null,
            resultsIncludeFeatures: null,
            resultsExcludeFeatures: null,
        },
        sortBy: null, // when null api defaults to LOCATION
        currentPage: 1,
        pageSize: pageSize || 24,
        loading: false,
        hasMoreResults: false,
        searchPath: null,
    };
    return defaultSearchContext;
};

export {
    getCats,
    shouldGeoCode,
    buildParamsWithFilters,
    getSubCatsFromSearchParams,
    getStylesFromSearchParams,
    getPricesFromSearchParams,
    getDate,
    getName,
    getCapacityFromSearchParams,
    getServicesFromSearchParams,
    getShippingOptionsFromSearchParams,
    getCityState,
    geocodeCityState,
    deriveLocation,
    searchConstants,
    emptyFilters,
    buildDefaultSearchContext,
    getTravelOptionsFromSearchParams,
    GenericLocationParams,
};
