import React from 'react';
import { useTranslation } from 'react-i18next';
import EventDetailsFormStyled from './EventDetailsForm.styled';
import { eventDetailsSchema } from '../../../../constants/schema/EventDetailsSchema';
import { Calculator, EmptyCaptions } from '../../../../../assets/Defaults';
import { EventDetails } from '../EventDetails';
import {
    AccountSetupStepLayout,
    AccountSetupStepCounter,
    AccountSetupStepFooter,
    AccountSetupStepGraphic,
    PearlForm,
} from 'framework';
import { useEventUserSetupWizard } from '../../context/EventUserSetupContext';
import { useEventContext } from '../../../../contexts/EventContext';

function Footer() {
    const { progress, onBack } = useEventUserSetupWizard();

    return <AccountSetupStepFooter progress={progress} handleOnBack={onBack} />;
}

function EventDetailsForm() {
    const { currentStepCount, totalSteps, onSubmit } = useEventUserSetupWizard();

    const { t } = useTranslation();
    const { event } = useEventContext();

    const videoDetails = {
        image: Calculator,
        altText: 'Forms.PriceTier.GifAltText',
        captions: EmptyCaptions,
    };

    const formOptions = {
        mode: 'onSubmit',
        defaultValues: {
            GuestCount: event.EventDetails.GuestCount ? event.EventDetails.GuestCount : null,
            BudgetTier: event.EventDetails.BudgetTier ? event.EventDetails.BudgetTier : null,
        },
    };

    const handleSubmit = (formData) => {
        const payload = {
            EventDetails: formData,
        };
        onSubmit(payload);
    };

    return (
        <PearlForm
            formOptions={formOptions}
            StyledForm={EventDetailsFormStyled}
            formName="EventDetails"
            onSubmit={(data) => handleSubmit(data)}
            validationSchema={eventDetailsSchema}
        >
            <AccountSetupStepLayout
                header={
                    <div>
                        <AccountSetupStepCounter
                            stepCountText={t('Forms.Labels.Steps', {
                                completedSteps: currentStepCount,
                                totalSteps: totalSteps,
                            })}
                            stepNameText={t('Forms.EventDetails.StepName')}
                        />
                        <h1 className="eventUserTitles">{t('Forms.EventDetails.StepTitle')}</h1>
                    </div>
                }
                formComponent={<EventDetails />}
                image={<AccountSetupStepGraphic {...videoDetails} />}
                footer={<Footer />}
            />
        </PearlForm>
    );
}

export { EventDetailsForm };
