import styled from 'styled-components';

export const MainLayoutStyled = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    background-color: ${(props) => props.theme.colors.background.container};
`;
