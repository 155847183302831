import styled from 'styled-components';

const PhoneNumberInputStyled = styled.div`
    & .disabled * {
        cursor: default;
        color: ${(props) => props.theme.colors.foreground.text};
    }

    .phoneInput {
        width: 100%;
        padding-left: 87px;
    }

    .phoneInput,
    .flagDropdown {
        height: 50px;
        border-radius: 0;
        font: inherit;
        font-size: 12px;
        border: 0;
        box-shadow: inset 0 0 0 1px #132122;

        &.disabled {
            cursor: default;
            background-color: ${(props) => props.theme.colors.background.base};
            box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.foreground.border};
        }
    }

    .flagDropdown {
        width: 71px;
        padding: 0;

        &.open {
            z-index: unset;
        }

        .selected-flag {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 16px 0;

            .arrow {
                margin-top: -3px;
                left: 32px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 6px solid #555;
            }

            :focus {
                background-color: unset;
            }
        }

        .icon-arrow-down,
        .icon-arrow-up {
            padding-left: 16px;
            padding-right: 8px;
            font-size: 9px;
        }

        .disabledArrow {
            padding-left: 32px;
        }
    }
`;

export { PhoneNumberInputStyled };
