import styled from 'styled-components';

const AmenitiesAndServicesSectionEditStyled = styled.form`
    display: flex;
    flex-flow: column;

    main > h2:not(:first-child) {
        margin-top: 0px;
    }
`;

export { AmenitiesAndServicesSectionEditStyled };
