import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { get, useFormContext } from 'react-hook-form';
import { toNestError } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import { GhostButton } from 'dbi-pearl-ui-kit';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { IMAGE_CATEGORIES, WebstoreService } from 'vendor/lib/apis/WebstoreService';
import PearlForm, { FormType } from 'vendor/components/forms/PearlForm';
import { AwardEditForm } from 'vendor/components/Webstore/SectionEdit/AwardEditForm/AwardEditForm';
import { EditSectionLayout } from '../EditSectionLayout/EditSectionLayout';
import { EditAwardsPageStyled } from './EditAwardsPage.styled';
import { WebstoreSectionTypes } from 'framework';
import { ToggleableSectionsValidation } from 'vendor/components/Webstore/WebstoreSectionFactories';

const EditAwardsPage = () => {
    const { webstore } = useWebstoreContext();
    const { onSubmit } = useOutletContext();
    const webstoreApi = WebstoreService();
    const { t } = useTranslation();
    const [awardErrors, setAwardErrors] = useState([]);
    const [awards, setAwards] = useState(webstore.awardsForEditForm());
    const [originalAwards] = useState(webstore.Awards);

    const formOptions = {
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: awards,
        shouldUnregister: true,
    };

    const enableToggle = (awardsToSubmit) => {
        if (originalAwards.length === 0) {
            const validationCheck =
                ToggleableSectionsValidation[WebstoreSectionTypes.AWARDS].UIValidation;
            return validationCheck(webstore.ActiveFeatures, awardsToSubmit);
        }
        return false;
    };

    const handleSubmit = async (data) => {
        await syncImages(data);
        Object.keys(awards).forEach((key) => {
            if (awards[key].Id && data[key]) {
                data[key].Id = awards[key].Id;
            }
        });

        const awardsToSubmit = webstore.awardsFromEditForm(data);
        let optionalSectionToSubmit = [];
        const canEnable = enableToggle(awardsToSubmit);

        if (canEnable) {
            const sectionId = webstore.OptionalSections.find(
                (os) => os.name === WebstoreSectionTypes.AWARDS
            )?.id;

            optionalSectionToSubmit = [
                {
                    id: sectionId,
                    name: WebstoreSectionTypes.AWARDS,
                    enabled: true,
                },
            ];
        }

        await onSubmit({ Awards: awardsToSubmit });

        if (optionalSectionToSubmit.length > 0 && webstore.Awards.length > 0) {
            onSubmit({ OptionalSections: optionalSectionToSubmit });
        }
    };

    const syncImages = async (data) => {
        await Promise.all(
            Object.keys(data).map(async (key) => {
                let newImage = data[key]?.Image;
                const previousImage = awards[key]?.Image;

                // If new image was added, upload it
                if (newImage instanceof File) {
                    const image = await webstoreApi.uploadImage(
                        webstore.Id,
                        newImage,
                        IMAGE_CATEGORIES.AWARDS
                    );
                    data[key].Image = newImage = image;
                }

                // If award had a previous image and it doesn't match new image, delete it
                if (previousImage && (!newImage || previousImage.id !== newImage.id)) {
                    await webstoreApi.deleteImage(webstore.Id, previousImage.id);
                }
            })
        );
    };

    const addAward = () => {
        const nextIndex = Object.keys(awards).length;
        const appendedAwards = { ...awards };
        appendedAwards[nextIndex] = { Ordinal: nextIndex, isAdded: true, Open: true };
        setAwards(appendedAwards);
    };

    const removeAward = (event, index) => {
        event.stopPropagation();
        event.preventDefault();
        const awardsArray = Object.values(awards);
        awardsArray.splice(index, 1);
        const updatedAwards = Object.assign({}, awardsArray);
        setAwards(updatedAwards);
    };

    const validateAwardForms = (values, options) => {
        setAwardErrors([]);
        const errors = {};
        Object.keys(values).forEach((key) => {
            const p = values[key];
            errors[key] = {};
            if (!p.Name) {
                errors[key].Name = {
                    message: t('Forms.Validation.FieldRequired', {
                        field: t('Forms.Awards.NameLabel'),
                    }),
                    ref: get(options.fields, `${key}.Name`),
                    type: 'required',
                };
            }

            if (!Object.keys(errors[key]).length) {
                delete errors[key];
            } else {
                setAwardErrors([...awardErrors, key]);
            }
        });
        if (!Object.keys(errors).length) {
            return { errors: {}, values };
        } else {
            return { errors: toNestError(errors, options), values: {} };
        }
    };

    const AwardErrorMessage = () => {
        return (
            <div>
                {!!awardErrors.length && (
                    <div data-testid="error-message" className="error">
                        <label>
                            <b>{t('Forms.Awards.Error')}</b>
                        </label>
                    </div>
                )}
            </div>
        );
    };

    const Awards = () => {
        const { getValues } = useFormContext();
        let formValues = getValues();
        let currentFormRepresentation = [];

        function getKeyByValue(object, value) {
            let key = Object.keys(object).find((key) => {
                return object[key].Ordinal === value.Ordinal;
            });
            return key;
        }

        if (awards) {
            Object.keys(awards).forEach((key) => {
                let value = getKeyByValue(formValues, awards[key]);
                if (value) {
                    currentFormRepresentation.push(value);
                }
            });
            Object.keys(awards).forEach((key) => {
                if (awards[key].isAdded) {
                    currentFormRepresentation.push(awards[key].Ordinal.toString());
                }
            });
        }

        return (
            <div>
                {currentFormRepresentation.length > 0 &&
                    currentFormRepresentation.map((awdForm, i) => (
                        <AwardEditForm
                            key={i}
                            index={awdForm}
                            hasError={awardErrors.includes(awards[formValues[awdForm]])}
                            onDelete={removeAward}
                            isNew={awards[awdForm]?.isAdded ? awards[awdForm].isAdded : false}
                        />
                    ))}
            </div>
        );
    };

    return (
        <PearlForm
            formName="Awards"
            formOptions={formOptions}
            onSubmit={(data) => handleSubmit(data)}
            formType={FormType.EDITABLE}
            StyledForm={EditAwardsPageStyled}
            customValidator={validateAwardForms}
        >
            <EditSectionLayout sectionName={WebstoreSectionTypes.AWARDS}>
                <h2>{t('Pages.EditPages.AwardsPageTitle')}</h2>
                <AwardErrorMessage />
                <Awards />
                <GhostButton
                    className={'add-button'}
                    data-testid="add-award-button"
                    variant="accent"
                    endIcon="plus"
                    onClick={addAward}
                >
                    {t('Forms.Awards.AddItem')}
                </GhostButton>
            </EditSectionLayout>
        </PearlForm>
    );
};

export { EditAwardsPage };
