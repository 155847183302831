import { useAuthUserContext } from 'contexts/AuthUserContext';
import { useEffect } from 'react';
import styled from 'styled-components';
import { SiteLoader } from '../../../shared/components/SiteLoader/SiteLoader';
import { setAppName } from '../../../shared/hooks/AppName';
import { PearlApps } from '../../../framework';
import { IdentityProviders } from '../../../lib/apis/TokenService';

const StyledAdminLogin = styled.div`
    p {
        margin: 0;
        padding-top: 30px;
        text-align: center;
    }
`;

const AdminLoginPage = () => {
    const { login } = useAuthUserContext();

    useEffect(async () => {
        setAppName(PearlApps.ADMIN);
        await login(IdentityProviders.cognito);
    }, []);

    return (
        <StyledAdminLogin>
            <p>Redirecting to Auth...</p>
            <SiteLoader />
        </StyledAdminLogin>
    );
};

export { AdminLoginPage };
